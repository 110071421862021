import React, {Component} from 'react';
import styles from "./TimeLine.less";
import CommonViewDiv from "../../../CommonViewDiv";
import {Timeline} from "antd";

//倒序的话队目前的动画逻辑有影响，非要做，需要改代码
export default class TimeLine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: []
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    componentWillUnmount(){
        this.inertAction && clearInterval(this.inertAction);
    }

    refresh(){
        this.lastData && this.showValue(this.lastData);
    }


    showValue(value) {
        this.lastData = _.cloneDeep(value);

        this.inertAction && clearInterval(this.inertAction);
        if(this.props.contentMsg?.dataConfig?.contentParams?.insertAction && Array.isArray(value)){
            //需要动画
            let temp = _.cloneDeep(value);
            let looppInsert = ()=>{
                if(temp.length){
                    if(this.props.contentMsg?.dataConfig?.contentParams.reverse){
                        this.state.data.push(temp.pop())
                    }else{
                        this.state.data.push(temp.shift())
                    }

                    this.setState({
                        data: this.state.data
                    },()=>{
                        // this.showboxDom && (this.showboxDom.scrollTop = this.showboxDom.scrollHeight)
                        this.endMark && this.endMark.scrollIntoView(
                            {
                                block: "end",
                                inline: "center",
                                behavior:"smooth"
                            }
                        )
                    })
                }else{
                    temp = _.cloneDeep(value);
                    if(this.props.contentMsg?.dataConfig?.contentParams.reverse){
                        this.setState({
                            data: [temp.pop()]
                        })
                    }else{
                        this.setState({
                            data: [temp.shift()]
                        })
                    }
                }
            }
            looppInsert();
            this.inertAction = setInterval(()=>{
                looppInsert()
            },(this.props.contentMsg?.dataConfig?.contentParams?.insertTime || 3) * 1000)
        }else{
            if(this.props.contentMsg?.dataConfig?.contentParams?.markField){
                //标记了默认展示位置
                this.centerMark = null;
                this.setState({
                    data: value
                },()=>{
                    //滚动到默认展示位置
                    this.centerMark && this.centerMark.scrollIntoView(
                        {
                            block: "start",
                            inline: "nearest",
                            behavior:"smooth"
                        }
                    )
                })
            }else{
                this.setState({
                    data: value
                })
            }
        }
    }

    render() {
        let data = this.state.data;
        let contentParams = this.props.contentMsg?.dataConfig?.contentParams || {};

        if(!Array.isArray(data)){
            return "格式错误：需要数组格式数据";
        }

        return <div
            className={styles.outerBox}
        >
            <Timeline
                mode={contentParams.mode || "left"}
            >
                {data.map((eachItem,index)=>{

                    let ifRev;
                    if(contentParams.mode === "alternate"){
                        ifRev = index%2===1;
                    }else if(contentParams.mode === "left"){
                        ifRev = false;
                    }else {
                        ifRev = true;
                    }

                    return <Timeline.Item
                        key={`${JSON.stringify(eachItem)}` + index}
                        color={contentParams.statusColorField
                            ?eachItem[contentParams.statusColorField]
                            :"blue"
                        }
                        label={contentParams.labelField
                            ?<div
                                className={ifRev?styles.insertActionBox:styles.insertActionBoxRev}
                                style={{flexDirection: ifRev?"row":"row-reverse"}}
                            >
                                <div
                                    style={CommonViewDiv.getTextStyleObj(contentParams.labelFontSet)}
                                    dangerouslySetInnerHTML={{
                                        __html: eachItem[contentParams.labelField]
                                    }}
                                ></div>
                                {/*{CommonViewDiv.getText(contentParams.labelFontSet,eachItem[contentParams.labelField])}*/}
                            </div>
                            :null}
                    >
                        <div
                            ref={(ref)=>{
                                if(eachItem[contentParams.markField] && !this.centerMark){
                                    // console.log("找到了定位节点");
                                    this.centerMark = ref;
                                }
                            }}
                            className={ifRev?styles.insertActionBoxRev:styles.insertActionBox}>
                            {
                                contentParams.titleField
                                    ?<div
                                        className={styles.timeItem}
                                        style={{flexDirection: ifRev?"row-reverse":"row"}}
                                    >
                                        <div
                                            style={CommonViewDiv.getTextStyleObj(contentParams.titleFontSet)}
                                            dangerouslySetInnerHTML={{
                                                __html: eachItem[contentParams.titleField]
                                            }}
                                        ></div>
                                        {/*{CommonViewDiv.getText(contentParams.titleFontSet,eachItem[contentParams.titleField])}*/}
                                </div>
                                    :null
                            }
                            <div
                                className={styles.timeItem}
                                style={{flexDirection: ifRev?"row-reverse":"row"}}
                            >
                                <div
                                    style={CommonViewDiv.getTextStyleObj(contentParams.descFontSet)}
                                    dangerouslySetInnerHTML={{
                                        __html: eachItem[contentParams.descField]
                                    }}
                                ></div>
                                {/*{CommonViewDiv.getText(contentParams.descFontSet,eachItem[contentParams.descField])}*/}
                            </div>
                              {
                                contentParams.imgField
                                  ?<div
                                    className={styles.timeItem}
                                    style={{flexDirection: ifRev?"row-reverse":"row"}}
                                  >
                                    <img
                                      src={eachItem[contentParams.imgField]}
                                      width={window.changeRemNum(contentParams.imgSet.widthSet)}
                                      style={{
                                        borderRadius: window.changeRemNum(contentParams.imgSet.radiusSet),
                                      }}
                                      alt="图片信息"
                                    />
                                  </div>
                                  :null
                              }
                        </div>
                    </Timeline.Item>
                })}
            </Timeline>
            <div
                className={styles.timeEndMark}
                ref={(ref)=>{
                    this.endMark = ref;
                }}
            ></div>
        </div>
    }
}