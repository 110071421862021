import { Form, Divider, Checkbox } from "antd";

//与图表展示方式有关的配置
function getSetItems() {
    return <>
        <Divider orientation="left">
            展示方式配置
        </Divider>

        <Form.Item
            name={"ranking"}
            label={"展示数据"}
            rules={[{ required: true }]}
        >
            <Checkbox.Group>
                <Checkbox value={"ranking"} >岗位任务分布</Checkbox>
            </Checkbox.Group>
        </Form.Item>
    </>
}
//getSetItems配置内容的默认值
function getDefaultValues() {
    return {
    }
}

//可由外部传入的参数定义
function getInputArg() {
    return {
       
    }
}
export default {
    getSetItems,
    getDefaultValues,
    getInputArg
}