import React from "react";
import {message, Tooltip, Switch, Space, Input, Select, Empty, Drawer, Button, Modal,Segmented,Popover} from "antd";
import styles from "./SystemComponentManage.less";
import {
  PlusOutlined,
  BarsOutlined,
  AppstoreOutlined,
  SearchOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import AllContentMap, {ViewContentArray} from "../../contentView/AllContentDefinition";
import _ from "lodash";
import EmptyView from "../../../../../components/EmptyView/index";
import {SystemComponentContentArray} from "./definition";
import LocalStorage from "../../../../../tool/LocalStorage";
import ContentView from "../../contentView/ContentView";
import DefaultThemeConfig from "../../DefaultThemeConfig";

export default class CustomRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupList: null, //分类数组
      registerList: null, //系统组件数组
      filterName: null,
      filterType: null,
      ifListShowCard: true,
      selectContent: null,  //选中的
    }

    this.userInfo = LocalStorage.getWithoutId('userInfo');
    this.access = props.access;

    this.themeConfig = _.cloneDeep(DefaultThemeConfig)
    this.themeConfig.commonSet.padding={  //容器外层壳的padding
      top: 5,
      topUnit: "vh",//px rem
      left: 5,
      leftUnit: "vw",
      right: 5,
      rightUnit: "vw",
      bottom: 5,
      bottomUnit: "vh",
    }
  }

  componentDidMount() {
    this.getListData()
  }

  //请求后台添加的自定义组件列表
  getListData() {
    let registerList = [];
    let groupList = [];

    //提取系统组件配置
    SystemComponentContentArray.forEach((eachGroup)=>{
      groupList.push({
        groupName: eachGroup.groupName,
        groupId: eachGroup.groupName, //没有id，用name代替
      })

      eachGroup.children?.forEach((eachComponent)=>{
        if(eachComponent.usageArray.includes(this.props.usage)){
          registerList.push({
            groupName: eachGroup.groupName,
            groupId: eachGroup.groupName,
            _id: eachComponent.type,
            name: eachComponent.title,
            contentMsg: {
              contentMode: "systemComponent",
              dataConfig: {
                contentTitle: null, //容器标题
                contentParams: eachComponent.setFile.getDefaultValues(),//用户可自定义调整的属性对象，每个组件不同
                contentType: eachComponent.type, //系统组件类型
                usage: this.props.usage, //组件用于哪里  company  space unit device
                dataParamsConfig: { //数据查询配置(相当于数据集的查询实体 configs)
                  inputArg: eachComponent.setFile.getInputArg(),//输入参数配置
                },
                dataSetInputMap: null,     //有输入参数时，这个字段存已经修改过的
              },
              styleConfig: {},
              proConfig: null,
            }
          })
        }
      })
    })

    this.setState({
      groupList: groupList,
      registerList: registerList,
    })
  }


  //左侧已注册组件的视图
  listShow() {
    let {ifListShowCard, filterName, filterType, registerList, selectContent,groupList} = this.state;

    if (!registerList?.length) {
      return <div
        className={styles.emptyMsg}
      >
        <Empty
          description={"暂无注册的组件"}
        />
      </div>
    }

    let list = registerList;
    let matchStr = _.trim(filterName)
    if (matchStr || filterType) {
      list = list.filter((eachItem) => {
        let ifMatch = true;

        if (matchStr) {
          ifMatch = eachItem.name.toLowerCase().includes(matchStr.toLowerCase())
        }

        if (ifMatch && filterType) {
          ifMatch = eachItem.groupId === filterType
        }

        return ifMatch
      })
    }

    if (!list?.length) {
      return <div
        className={styles.emptyMsg}
      >
        <Empty
          description={"筛选后无组件"}
        />
      </div>
    }

    if (ifListShowCard) {
      //card模式
      return list?.map((eachChart, index) => {
        let coverUrl;
        if(eachChart.contentMode === "systemComponent"){
          coverUrl = eachChart.cover
        }else if (eachChart.contentMsg?.dataConfig?.coverUrl) {
          coverUrl = eachChart.contentMsg?.dataConfig?.coverUrl;
        } else if (eachChart.contentMsg?.dataConfig?.contentType) {
          coverUrl = AllContentMap[eachChart.contentMsg.dataConfig.contentType]?.cover
        }

        return <Popover
          title={eachChart.name}
          content={<div
            className={styles.chartCover}
            style={{
              width: "250px",
              height: "200px",
              backgroundImage: `url(${coverUrl})`
            }}
          >
          </div>}
        >
          <div
            key={"c_" + eachChart._id}
            className={selectContent?._id === eachChart._id ? styles.chartItemBoxSel : styles.chartItemBox}
            onClick={() => {
              console.log(eachChart)
              this.setState({
                selectContent: eachChart
              })
            }}
          >
            <div
              className={styles.chartTitle}
            >{eachChart.name}</div>
            {
              coverUrl
                ? <div
                  className={styles.chartCover}
                  style={{
                    backgroundImage: `url(${coverUrl})`
                  }}
                >
                </div>
                : <div
                  className={styles.chartCover}
                >
                  暂无封面
                </div>
            }
          </div>
        </Popover>
      })
    } else {
      //list模式
      return list?.map((eachChart, index) => {
        return <div
          key={"l_" + eachChart._id}
          className={selectContent?._id === eachChart._id ? styles.chartItemBoxSel : styles.chartItemBox}
          onClick={() => {
            this.setState({
              selectContent: eachChart
            })
          }}
        >
          <div
            className={styles.chartTitle}
          >{eachChart.name}</div>
        </div>
      })
    }
  }

  render() {
    let {ifListShowCard, filterName, filterType, selectContent,groupList,mockParams,mockChange} = this.state;

    return <div
      className={styles.outerBox}
    >
      <div
        className={styles.leftBox}
      >
        <div className={styles.leftTopBar}>
          <div className={styles.leftTitle}>系统组件</div>
          <Space>
            <Segmented
              options={[
                {
                  value: 'list',
                  icon: <BarsOutlined />,
                },
                {
                  value: 'card',
                  icon: <AppstoreOutlined />,
                },
              ]}
              value={ifListShowCard?"card":"list"}
              onChange={(value)=>{
                this.setState({
                  ifListShowCard: value==="list"?false:true
                })
              }}
            />
          </Space>
        </div>
        <div className={styles.leftTopBar}>
          <Input
            szie={"small"}
            prefix={<SearchOutlined/>}
            style={{flex: 1, flexShrink: 0}}
            placeholder={"输入名称过滤"}
            value={filterName}
            onChange={(e) => {
              this.setState({
                filterName: e.target.value
              })
            }}
          />
          <Select
            szie={"small"}
            popupMatchSelectWidth={false}
            allowClear={true}
            style={{flex: 1, flexShrink: 0}}
            placeholder={"选择分类过滤"}
            showSearch={{
              filter: (inputValue, path) =>
                path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
            }}
            value={filterType}
            onChange={(value, selectedOptions) => {
              this.setState({
                filterType: value
              })
            }}
            options={
              groupList?.map((group) => {
                return {
                  label: group.groupName,
                  value: group.groupId,
                }
              })
            }
          />
        </div>
        <div
          className={styles.listShow}
        >
          {this.listShow()}
          <div className={styles.chartItemBoxEmpty}/>
        </div>
      </div>
      {
        selectContent
          ? <div className={styles.rightBox}>
            <div className={styles.rightTopBar}>
              <div className={styles.rightTitle}>{selectContent.name}</div>
            </div>
            <div className={styles.rightCenter}>
              <ContentView
                uid={selectContent._id}
                ifTemplate={true}
                identifyMsg={this.props.mockParams}
                dataUpdate={selectContent._id}
                contentMsg={selectContent.contentMsg}
                themeConfig={this.themeConfig}
                scencStyleConfig={{}}
              />
            </div>
          </div>
          : <div
            className={styles.emptyMsg}
          >
            <EmptyView
              title={"请选择系统组件展示"}
            />
          </div>
      }
    </div>
  }
}