import React, { useEffect, useRef, useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./DigitalHumanDeviceTypeBar.less";
import * as echarts from 'echarts'
import { Select } from 'antd';


const DigitalHumanDeviceTypeBar: React.FC<any> = (props) => {
  console.error(props)
  const chartRef = useRef<HTMLDivElement>(null)
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const [errorMsg, setErrorMsg] = useState(null)
  const [status, setStatus] = useState('1,2,3,4')
  const [dataSource, setDataSource] = useState<Array<{ name: string, value: number }>>([])

  // 初始化图表
  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current, "dark", {
        locale: "ZH"
      });
    }
    return () => {
      // 清理图表实例
      chartInstance.current?.dispose();
    };
  }, []);

  // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}robot/robotTerminalRatio`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     status: status.split(',').map(Number),
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate, status],
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       setErrorMsg(null)
  //       const total = res?.data.reduce((acc: number, cur: any) => acc + cur?.count, 0)
  //       setDataSource((res?.data)?.map((item: { count: number, name: string }) => ({
  //         value: item?.count * 100 / total,
  //         ...item
  //       })) || [])
  //     } else {
  //       setErrorMsg(res?.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })
  
  useEffect(() => {
    if (dataSource) {
      chartInstance.current?.setOption(getOption())
    }
  }, [dataSource])

  const getSeries = () => {
    if (dataSource?.length === 0) {
      return []
    }

    return {
      type: 'bar',
      barWidth: 25,
      barGap: 5,
      label: {
        show: true,
        position: 'right',
        distance: 6,
        rotate: 0,
        formatter: (param: any) => {
          return param?.value.toFixed(1) + '%' + `  (${dataSource[param.dataIndex]?.count})`
        },
      },
      emphasis: {
        focus: 'series'
      },
      data: dataSource?.map(item => item.value)
    }
  }

  const getOption = (): echarts.EChartsOption => {
    return {
      backgroundColor: "transparent",
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        right: '15%',  // 调整右边距，留出更多空间
        top: '5%',
        bottom: '10%',
      },
      legend: {
        textStyle: {
          color: '#fff', // 图例文字颜色
        },
        data: dataSource?.map(item => item.name)
      },
      xAxis: [
        {
          type: 'value',
          min: 0, // 最小刻度
          splitLine: {
            show: false // 不显示网格线
          },
          axisLabel: {
            formatter: '{value}%' // 显示百分号
          }
        }
      ],
      yAxis: {
        type: 'category',
        data: dataSource?.map(item => item.name)
      },
      series: getSeries()
    };
  };

  return <div className={styles.outer + " swiper-no-swiping"}>
    <div className={styles.controlBar}>
      <Select
        style={{ width: "120px" }}
        value={status}
        onChange={(status) => {
          setStatus(status)
        }}
        options={[
          { label: "全部", value: '1,2,3,4' },
          { label: "在线", value: '2,3' },
        ]}
      />
    </div>
    {
      props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('deviceRatio')
      && <div className={styles.chartBox}>
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <div ref={chartRef} style={{ width: '100%', height: '100%' }} >
            </div>
        }
      </div>
    }
  </div>
}


export default DigitalHumanDeviceTypeBar