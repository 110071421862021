import React from "react";
import {
    Form,
    Switch,
    Select,
    Collapse,
    Checkbox
} from "antd";
import ColorPicker from "../../../formDiyItem/ColorPicker";
import CommonFormItems from "../../../CommonFormItems";
import DataDefinitionFile from "../../../dataDisposition/DataDefinitionFile";

const Item = Form.Item;

function getSetItems(extendData= {}){
    let opts = [];
    if(extendData?.bindData){
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }

    let currentSceneMsg = extendData.currentSceneMsg;

    let parameterBoxArr = [];
    if(currentSceneMsg?.contentArray){
        currentSceneMsg.contentArray?.forEach((eachContent)=>{
            if(eachContent.contentMsg.contentMode === "parameter"){
                if(DataDefinitionFile.ifParameterAllowSetValue(eachContent.contentMsg.dataConfig?.contentType)){
                    //可接收参数
                    parameterBoxArr.push({
                        uid: eachContent.uid,
                    })
                }
            }
        })
    }

    return <>
        <Item
            label={"表头背景色"}
            name={["headBackgroundColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"表头文字"}
            name={["headFontColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"表格背景色"}
            name={["bodyBackgroundColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"表格文字"}
            name={["bodyFontColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"边框颜色"}
            name={["borderColor"]}
        >
            <ColorPicker/>
        </Item>
        <Item
            label={"单页时隐藏分页器"}
            name={["hideOnSinglePage"]}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"隐藏底部工具条"}
            name={["hideFooter"]}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            valuePropName={"checked"}
            extra={"此配置修改后需要手动刷新视图矫正排版"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
          label={"每列宽度自适应"}
          name={["autoTableLayout"]}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          valuePropName={"checked"}
        >
            <Switch
              checkedChildren={"是"}
              unCheckedChildren={"否"}
            />
        </Item>
        <Item
          label={"指定图片列"}
          name={["imgColArr"]}
          extra={"图片列地址将展示为缩略图"}
        >
            <Select
              placeholder={"请选择或填写"}
              style={{width: "100%"}}
              allowClear={true}
              mode={"tags"}
              options={opts}
            >

            </Select>
        </Item>
        <Item
          label={"缩略图大小"}
          name={["imgSize"]}
        >
            {CommonFormItems.getSliderAndInputItemGroup("imgSize",{
                min: 5,
                max: 250,
                step: 1,
                addonAfter: "px"
            })}
        </Item>
        <Collapse.Panel
          forceRender={true}
          key="cellItemClick"
          header="点击单元格取值"
        >
            <Item
              label={"启用点击取值"}
              name={["clickActionSet", "use"]}
              rules={[{required: true}]}
              valuePropName={"checked"}
            >
                <Switch
                  checkedChildren={"是"}
                  unCheckedChildren={"否"}
                />
            </Item>
            <Item
              noStyle={true}
              dependencies={["clickActionSet", "use"]}
            >
                {({getFieldValue}) => {
                    let seeOption = getFieldValue(["clickActionSet", "use"])
                    let readDataColArr = getFieldValue(["clickActionSet", "readDataColArr"])
                    return <Item
                      noStyle={true}
                      hidden={!seeOption}
                    >
                        <Item
                          label={"获取的值类型"}
                          name={["clickActionSet", "valueType"]}
                        >
                            <Select>
                                <Select.Option value="originValue">原始值</Select.Option>
                                <Select.Option value="formatTxt">格式化值</Select.Option>
                            </Select>
                        </Item>
                        <Item
                          label={"点击可取值列"}
                          name={["clickActionSet","readDataColArr"]}
                          extra={"鼠标点击可获取值"}
                        >
                            <Select
                              placeholder={"请选择或填写"}
                              style={{width: "100%"}}
                              allowClear={true}
                              mode={"tags"}
                              options={opts}
                            >

                            </Select>
                        </Item>
                        {
                            readDataColArr?.map((readDataColSet)=>{
                                return <Item
                                  label={readDataColSet + " 传值给"}
                                  name={["clickActionSet", "fromKeysToWhere", readDataColSet]}
                                >
                                    <Checkbox.Group>
                                        {
                                            parameterBoxArr?.length
                                              ?parameterBoxArr.map((eachBox)=>{
                                                  return <Checkbox value={eachBox.uid}>容器-{eachBox.uid.slice(0,4)}</Checkbox>
                                              })
                                              : "没有可以接收数据的参数容器"
                                        }

                                    </Checkbox.Group>
                                </Item>
                            })
                        }

                    </Item>
                }}
            </Item>
        </Collapse.Panel>
    </>
}

function getDefaultValues(){
    return {
        headBackgroundColor: "#373C5188",
        headFontColor: "#ffffff",
        bodyBackgroundColor: "#00000000",
        bodyFontColor: "#ffffff",

        borderColor: "#373c51",

        hideOnSinglePage: true,
        hideFooter: false,
        imgColArr: [],
        imgSize: 60,
        autoTableLayout: false,
        //点击事件配置
        clickActionSet: {
            use: false,
            valueType: "formatTxt",
            readDataColArr: [],
            fromKeysToWhere: [],
        }
    }
}

function getDemoCode(){
    return `遵循元数据查询规则所得数据结构。`
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}