import React from "react";
import {
  Form,
  Divider,
  Checkbox
} from "antd";

//与图表展示方式有关的配置
function getSetItems() {
  return <>
    <Divider orientation="left">
      展示方式配置
    </Divider>
    <Form.Item
      name={"typeShowSel"}
      label={"展示数据"}
      rules={[{ required: true }]}
    >
      <Checkbox.Group
        options={[
          { label: "分发占比", value: 1 },
        ]}
      />
    </Form.Item>
  </>
}
//getSetItems配置内容的默认值
function getDefaultValues() {
  return {
    typeShowSel: [1, 2],
  }
}

//可由外部传入的参数定义
function getInputArg() {
  //不需要额外的查询参数传入，全部组件内部处理，返回空对象
  return {}
}
export default {
  getSetItems,
  getDefaultValues,
  getInputArg
}