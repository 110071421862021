import React, {Component} from 'react';
import styles from './ShowListData.less';
import {Table, Button, Select, message, Space, Modal} from 'antd';
import Help from "../../../tool/Help";
import Excel from 'exceljs';
import MetaDataGet from "../../metaDataGet/MetaDataGet";

const DefaultBorderColor= "#373c51";
const DefaultHeadBackgroundColor= "#373C5188";
const DefaultBodyBackgroundColor= "transparent";
const DefaultHeadFontColor="#ffffff";
const DefaultBodyFontColor="#ffffff";

//展示用Table,导出用Excel
class ShowListData extends Component {
    constructor(props) {
        super(props);

        if(!Array.isArray(this.props.values)){
            message.warning("表格视图只适合用来展示数组类型的数据")
        }

        this.state = {
            cols: this.props.cols || [],
            values: this.props.values || [],

            exportBtnLoading: false,    //导出数据的时候要处理数据，会花时间，按钮禁用
            formatType: "useFormat",    //是否格式化展示

            tableKey: 0,

            tableScroll: false
        };

        //结构处理完成的表头和内容
        this.formatCols = this._getFormatColumns(this.state.cols);
        this.formatValues = this._getFormatValues(this.state.values);

        this.meteDataGet = new MetaDataGet();
        this.meteNameFlat = null;
        this.needDealShowAction = null; //等元数据加载完毕需要处理的数据
    }

    showValue(values,cols){
        if(!Array.isArray(values)){
            message.warning("表格视图只适合用来展示数组类型的数据")
        }

        this.lastData = {
            values: values,
            cols: cols,
        };

        if(!cols){
            if(!this.meteNameFlat){
                //元数据未加载完毕，先不处理
                this.needDealShowAction = {
                    values: values,
                    cols: cols
                }
                return;
            }
            cols = this.matchColsByValuesFromMetaTreeFull(values)
        }

        this.formatCols = this._getFormatColumns(cols);
        this.formatValues = this._getFormatValues(values);
        this.setState({
            cols,
            values,
            tableKey: this.state.tableKey + 1,
        })

    }
    componentDidMount() {
        setTimeout(() => {
            let boxHeight = window.getComputedStyle(this.tableBox, null).height;
            console.log(boxHeight);
            let theadBox = this.tableBox.getElementsByTagName("thead");
            if (theadBox && theadBox.length > 0) {
                let headerHeight = window.getComputedStyle(theadBox[0], null).height;
                //-60是底部分页的高度
                let footerH = this.props.hideFooter?25:60;
                this.setState({
                    tableScroll: {y: parseFloat(boxHeight) - parseFloat(headerHeight) - footerH}
                })
            }
        });

        if(this.props.needMatchCols){
            //需要从值中匹配出表头，提前准备元数据
            this.meteDataGet.getMeteNameFlatData((data)=>{
                this.meteNameFlat = data;
                if(this.needDealShowAction){
                    this.showValue(this.needDealShowAction.values,this.needDealShowAction.cols);
                    this.needDealShowAction = null;
                }else if(!this.props.cols?.length && this.props.values?.length){
                    this.showValue(this.props.values,null)
                }
            },(msg)=>{
                message.error(msg)
            })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate){
            setTimeout(()=>{
                this.refresh()
            },0)
        }
    }

    refresh(){
        this.lastData && this.showValue(this.lastData.values,this.lastData.cols);
    }

    //测试表头
    getTestCols() {
        return [
            {
                title: "数字人编号",
                _id: "deviceId",
                dataType: "String",
                idArray: false,
            },
            {
                title: "姓名",
                _id: "aa",
                dataType: "String",
                idArray: false,
            },
            {
                title: "是否已经登录",
                _id: "bb",
                dataType: "Boolean",
                idArray: false,
            },
            {
                title: "申请好友数",
                _id: "cc",
                dataType: "Number",
                idArray: false,
            },
            {
                title: "实名认证",
                _id: "dd",
                dataType: "Object",
                idArray: false,
                children: [
                    {
                        title: "手机号",
                        _id: "dd_aa",
                        dataType: "String",
                        idArray: false,
                    },
                    {
                        title: "住址",
                        _id: "dd_bb",
                        dataType: "Object",
                        idArray: false,
                        children: [
                            {
                                title: "城市",
                                _id: "dd_bb_aa",
                                dataType: "String",
                                idArray: false,
                            },
                            {
                                title: "街道",
                                _id: "dd_bb_bb",
                                dataType: "String",
                                idArray: false,
                            }
                        ]
                    }
                ]
            },
            {
                title: "每日刷金币",
                _id: "ee",
                dataType: "Number",
                idArray: true,
            },
            {
                title: "通讯录",
                _id: "ff",
                dataType: "Object",
                idArray: true,
                children: [
                    {
                        title: "名称",
                        _id: "ff_aa",
                        dataType: "String",
                        idArray: false,
                    },
                    {
                        title: "电话",
                        _id: "ff_bb",
                        dataType: "String",
                        idArray: false,
                    }
                ]
            },
            {
                title: "好友列表",
                _id: "gg",
                dataType: "Object",
                idArray: true,
                children: [
                    {
                        title: "昵称",
                        _id: "gg_aa",
                        dataType: "String",
                        idArray: false,
                    },
                    {
                        title: "互动时间",
                        _id: "gg_bb",
                        dataType: "String",
                        idArray: true,
                    },
                ]
            },
        ]
    }

    //测试值
    getTestValue() {
        return [
            {
                key: 1,
                deviceId: "JIDINGHE_683201452D22B5A1305F951",
                aa: "周凌峰",
                bb: true,
                cc: 40,
                dd: {
                    dd_aa: "18800641282",
                    dd_bb: {
                        dd_bb_aa: "杭州市",
                        dd_bb_bb: "大桐柏路227号",
                    }
                },
                ee: [2, 2, 5, 1, 4, 1, 6, 2],
                ff: [
                    {ff_aa: "罗丽琦", ff_bb: "0684-88910054"},
                    {ff_aa: "李红登", ff_bb: "0684-88568541"},
                ],
                gg: [
                    {gg_aa: "小明", gg_bb: ["2020-10-09", "2020-10-10"]},
                    {gg_aa: "小白", gg_bb: ["2020-09-23"]},
                ],
            },
            {
                key: 2,
                deviceId: "JIDINGHE_683201452D22B5A1305F685",
                aa: "李白时",
                bb: true,
                cc: 15,
                dd: {
                    dd_aa: "18800641222",
                    dd_bb: {
                        dd_bb_aa: "绍兴市",
                        dd_bb_bb: "白鹤路151号",
                    }
                },
                ee: [35, 34, 50],
                ff: [
                    {ff_aa: "杜天一", ff_bb: "0684-88910222"},
                    {ff_aa: "方远夏", ff_bb: "0684-88568121"},
                    {ff_aa: "周名里", ff_bb: "0684-88562221"},
                ],
                gg: [
                    {gg_aa: "小李", gg_bb: ["2020-10-09"]},
                    {gg_aa: "小刚", gg_bb: ["2020-09-23", "2020-09-24", "2020-09-25"]},
                ],
            }
        ]
    }

    //更新表格
    upDateTableView() {
        this.setState({
            tableKey: this.state.tableKey + 1
        })
    }

    //从values中反向推导出表头结构（遍历所有value）
    matchColsByValuesFromMetaTreeFull(values){
        if(!values?.length){
            return [];
        }

        // console.warn("反向生成表头");
        // console.log("value:",values)

        let getTreeNodeFromV = (eachValue, setObj)=>{
            if(!eachValue){
                return
            }

            Object.keys(eachValue).forEach((key)=>{
                let treeNode;
                if(!setObj[key] || setObj[key].typeUnChecked){
                    //新增
                    treeNode = this.meteNameFlat[key];
                    let v = eachValue[key];
                    if(treeNode){
                        //从元数据中拿出来，isArray和dataType已经存在了
                        //如果值的类型和元数据定义的不一致，以元数据为准。列表展示的时候提示错误信息
                        setObj[key] = treeNode;
                    }else{
                        //是筛选器中新定义的
                        let isArray = Array.isArray(v);
                        let typeUnChecked = false;
                        let dataType;
                        if(!isArray){
                            //直接判断
                            if(v === null){
                                //值不存在，无法判断
                                typeUnChecked = true;
                            }

                            dataType = typeof v
                        }else{
                            if(v[0] === null){
                                //值不存在，无法判断
                                typeUnChecked = true;
                            }

                            dataType = typeof v[0]
                        }
                        dataType = dataType[0].toUpperCase() + dataType.substr(1);
                        treeNode = {
                            type: "diyKey",
                            title: key,
                            _id: key,
                            dataType: typeUnChecked?"String":dataType,
                            isArray: isArray,
                            typeUnChecked: typeUnChecked
                        }

                        setObj[key] = treeNode;
                    }

                    let dataType = treeNode.dataType;
                    if(dataType === "Object"){
                        if(!treeNode.childTree){
                            treeNode.childTree = {}
                        }
                        //继续设置子集
                        if(Array.isArray(v)){
                            //遍历多行数据获得键的并集
                            v.forEach((eachV)=>{
                                getTreeNodeFromV(eachV, treeNode.childTree)
                            })
                        }else{
                            getTreeNodeFromV(v, treeNode.childTree)
                        }
                    }
                }else{
                    //已经存在，如果是对象类型的元数据，需要检查子集字段是否完整
                    let v = eachValue[key];
                    if(setObj[key].dataType === "Object"){
                        //继续检查子集
                        if(!setObj[key].childTree){
                            setObj[key].childTree = {}
                        }
                        if(Array.isArray(v)){
                            //遍历多行数据获得键的并集
                            v.forEach((eachV)=>{
                                getTreeNodeFromV(eachV, setObj[key].childTree)
                            })
                        }else{
                            getTreeNodeFromV(v, setObj[key].childTree)
                        }
                    }
                }
            })
        }

        //测试反向生成
        // values = [
        //     {
        //         bn0: "asdfsdfasdf",
        //         bp0: [{
        //             br0: "bbb",
        //             bs0: [{
        //                 bt0: "aa_1",
        //             }],
        //         }],
        //         ce0: {
        //             cf0: "111",
        //             cg0: "222"
        //         }
        //     },
        //     {
        //         bn0: "asdfsdfasdf",
        //         bo0: ["aa","bb","cc"],
        //         bp0: [{
        //             bq0: "aaa",
        //             br0: "bbb",
        //             bs0: [{
        //                 bu0: "aa_2"
        //             }],
        //         }],
        //         ce0: {
        //             cf0: "111",
        //             cg0: "222"
        //         }
        //     },
        //     {
        //         bn0: "asdfsdfasdf",
        //         bo0: ["aa","bb","cc"],
        //         bp0: [{
        //             bq0: "aaa",
        //             br0: "bbb",
        //         }],
        //         ce0: {
        //             cf0: "111",
        //             cg0: "222"
        //         }
        //     },
        // ];

        let treeObj = {};
        values.forEach((eachValue)=>{
            getTreeNodeFromV(eachValue, treeObj)
        })

        // console.log("看看合并完成的表头树结构");
        // console.log(treeObj)

        //最后一步，把键与节点的映射对象 转成 节点数组


        let loopChange = (treeObj)=>{
            if(!treeObj){
                return [];
            }
            let cols = [];
            Object.keys(treeObj).forEach((key)=>{
                let col = treeObj[key];
                if(col.childTree){
                    col.children = loopChange(col.childTree)
                }

                cols.push(col);
            })

            return cols;
        }

        // console.log(loopChange(treeObj))

        return loopChange(treeObj);
    }

    //表头
    _getFormatColumns(cols) {
        let borderColor = this.props.styleDiy?.borderColor || DefaultBorderColor;

        let headFontColor = this.props.styleDiy?.headFontColor || DefaultHeadFontColor;
        let bodyFontColor = this.props.styleDiy?.bodyFontColor || DefaultBodyFontColor;

        let getCol = (treeNode) => {
            if (treeNode.dataType === "Object" && treeNode.children) {
                return {
                    title: treeNode.title,
                    onHeaderCell: (record,rowIndex)=>{
                        return {
                            style: {
                                borderColor: borderColor,
                                color: headFontColor
                            }
                        }
                    },
                    onCell: (record,rowIndex)=>{
                        return {
                            style: {
                                borderColor: borderColor,
                                color: bodyFontColor
                            }
                        }
                    },
                    children: treeNode.children.map((childNode) => {
                        return getCol(childNode)
                    })
                }
            } else {
                return {
                    title: treeNode.title,
                    dataIndex: treeNode._id,
                    key: treeNode._id,
                    width: (this.props.imgColArr && this.props.imgColArr.includes(treeNode._id))?this.props.imgSize + 10:null,
                    onHeaderCell: (record,rowIndex)=>{
                        return {
                            style: {
                                borderColor: borderColor,
                                color: headFontColor
                            }
                        }
                    },
                    onCell: (record,rowIndex)=>{
                        return {
                            style: {
                                borderColor: borderColor,
                                color: bodyFontColor
                            }
                        }
                    },
                    render: (text, record, index) => {
                        // console.log("看看treeNode",treeNode)

                        if (!record.hasOwnProperty(treeNode._id)) {
                            return ""
                        }

                        if(typeof text === "object"){
                            //这里不该出现对象或数组，一定出错了
                            return <span style={{color: "red"}}>{JSON.stringify(text)}</span>
                        }

                        let formatTxt = this.state.formatType === "useFormat" ? Help.formatDataShow(text, treeNode.dataType, treeNode.format) : text;

                        if(this.props.imgColArr && this.props.imgColArr.includes(treeNode._id)){
                            //需要展示成缩略图
                            return <div
                              onClick={()=>{
                                  Modal.info({
                                      title: "图片展示",
                                      width: 600,
                                      closable: true,
                                      content: <img
                                        // crossOrigin={"anonymous"}
                                        src={formatTxt}
                                        width={"100%"}
                                      />
                                  })
                              }}
                            >
                                <img
                                  // crossOrigin={"anonymous"}
                                  src={formatTxt}
                                  width={this.props.imgSize}
                                />
                            </div>
                        }

                        let result;
                        switch (treeNode.dataType) {
                            case "Number":
                                if(typeof text !== "number"){
                                    result = <span style={{color: "red"}}>{formatTxt}</span>;
                                }else{
                                    result = <span style={{textDecoration: "underline"}}>{formatTxt}</span>;
                                }
                                break;
                            case "Boolean":
                                if(typeof text !== "boolean"){
                                    result = <span style={{color: "red"}}>{""+formatTxt}</span>;
                                }else{
                                    result = <span style={{fontStyle: "italic", fontWeight: "bold"}}>{""+formatTxt}</span>;
                                }

                                break;
                            case "String":
                                if(typeof text !== "string"){
                                    result = <span style={{color: "red"}}>{formatTxt}</span>;
                                }else{
                                    if(text && text.indexOf("http") === 0){
                                        if(treeNode.format && typeof formatTxt === "string"){
                                            return <div
                                              dangerouslySetInnerHTML={{__html: formatTxt}}
                                            ></div>
                                        }else{
                                            //连接没有配置格式化，展示成a标签
                                            result = <a href={formatTxt} target={"_black"}>{formatTxt}</a>
                                        }
                                    }else{
                                        result = formatTxt;
                                    }
                                }
                                break;
                            default:
                                result = formatTxt;
                        }


                        return <pre
                          className={styles.preValueShow}
                          onClick={()=>{
                              this.props.onCellClick?.({
                                  value: text,
                                  formatTxt: formatTxt,
                                  dataType: treeNode.dataType,
                                  colId: treeNode._id
                              })
                          }}
                          onDoubleClick={()=>{
                              window.copyText(formatTxt)
                          }}
                        >{result}</pre>;
                    }
                }
            }
        };

        return cols.map((col) => {
            return getCol(col)
        });
    }

    //设置结构化的值
    _getFormatValues(values) {
        if(!Array.isArray(values)){
            return []
        }

        let result = [];

        let dealObj = (obj) => {
            let resultObj = {};
            let innerArr = [];
            Object.keys(obj).forEach((key) => {
                if (Array.isArray(obj[key])) {
                    //对象某个键的值是数组
                    if(this.meteNameFlat && this.meteNameFlat[key] && this.meteNameFlat[key].isArray === false){
                        //和元数据定义的数据类型不一致
                        resultObj[key] = obj[key];
                    }else{
                        innerArr = innerArr.concat(dealArr(obj[key], key))
                    }

                } else {
                    if(obj[key] === null){
                        //默认按字符串处理
                        resultObj[key] = "" + obj[key];
                    }else{
                        switch (typeof obj[key]) {
                            case "object": //对象某个键的值还是对象
                                if(this.meteNameFlat && this.meteNameFlat[key] && this.meteNameFlat[key].dataType !== "Object"){
                                    //和元数据定义的数据类型不一致
                                    resultObj[key] = obj[key];
                                }else{
                                    let dealResult = dealObj(obj[key]);
                                    Object.assign(resultObj, dealResult.resultObj);
                                    innerArr = innerArr.concat(dealResult.innerArr);
                                }
                                break;
                            case "number"://数字要处理最大值  9007199254740992最大
                                resultObj[key] = obj[key] > 9000000000000000 ? "" + obj[key] : obj[key];
                                break;
                            case "boolean":
                                resultObj[key] = obj[key];
                                break;
                            default://默认按字符串处理
                                resultObj[key] = "" + obj[key];
                        }
                    }
                }
            });

            return {
                resultObj: resultObj,
                innerArr: innerArr,
            };
        };

        //给dealObj调用，需要数组外层的键名
        let dealArr = (arr, parentKey) => {
            let resultArr = [];
            arr.forEach((item) => {
                if (item) {
                    //数组不会直接嵌套数组，只判断是否是对象即可
                    if (typeof item === "object") {
                        //对象
                        let dealResult = dealObj(item);
                        if (Object.keys(dealResult.resultObj).length !== 0) {
                            resultArr.push(dealResult.resultObj)
                        }
                        resultArr = resultArr.concat(dealResult.innerArr);
                    } else {
                        //基本值
                        resultArr.push({
                            [parentKey]: "" + item
                        })
                    }
                }

            });


            // console.log("数组里的值");
            // console.log(resultArr);
            return resultArr;
        };

        values.forEach((item) => {
            let dealResult = dealObj(item);
            if (Object.keys(dealResult.resultObj).length !== 0) {
                result.push(dealResult.resultObj)
            }
            // console.log("接收到dealResult.innerArr");
            // console.log(dealResult.innerArr);
            result = result.concat(dealResult.innerArr)
        });

        result = result.map((item, index) => {
            item.key = index;
            return item
        });

        // console.error("组装后的数据");
        // console.log(result);
        return result;
    }

    render() {
        let borderColor = this.props.styleDiy?.borderColor || DefaultBorderColor;
        let backgroundColor = this.props.styleDiy?.headBackgroundColor || DefaultHeadBackgroundColor;
        let bodyBackgroundColor = this.props.styleDiy?.bodyBackgroundColor || DefaultBodyBackgroundColor;

        return <div
            ref={ref => this.tableBox = ref}
            className={styles.tableBox + " swiper-no-swiping"}
        >
            <Table
                key={"table_" + this.state.tableKey}
                size={"small"}
                bordered={true}
                className={styles.table}
                columns={this.formatCols}
                dataSource={this.formatValues}
                rowClassName={()=>{
                    return styles.testCCC
                }}
                scroll={this.state.tableScroll}
                tableLayout={this.props.autoTableLayout?"auto":"fixed"}
                onHeaderRow={(columns,index)=>{
                    return {
                        style: {
                            backgroundColor: backgroundColor,
                            borderColor: borderColor
                        }
                    }
                }}

                onRow={(columns,index)=>{
                    return {
                        style: {
                            backgroundColor: bodyBackgroundColor,
                            borderColor: borderColor
                        }
                    }
                }}

                footer={this.props.hideFooter?null:(currentPageData)=>{
                    return <div>
                        <Space>
                            {
                                this.props.extraFooter
                                    ?this.props.extraFooter()
                                    :null
                            }
                            <Button
                                loading={this.state.exportBtnLoading}
                                size={"small"}
                                onClick={() => {
                                    this.setState({
                                        exportBtnLoading: true,
                                    }, () => {
                                        this.modifyExportDataMergeCell()
                                    });
                                }}
                            >导出{this.formatValues?this.formatValues.length:0}条数据</Button>
                            <Select
                                size={"small"}
                                value={this.state.formatType}
                                style={{width: 100}}
                                dropdownMatchSelectWidth={false}
                                onChange={(v) => {
                                    this.setState({
                                        formatType: v,
                                    }, () => {
                                        this.upDateTableView()
                                    })
                                }}
                            >
                                <Select.Option value="useFormat">格式化</Select.Option>
                                <Select.Option value="useOrigin">原始值</Select.Option>
                            </Select>
                        </Space>
                    </div>
                }}
                pagination={this.props.hidePagination?false:{
                    hideOnSinglePage: this.props.hideOnSinglePage,
                    defaultPageSize: 100,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ["20", "50", "100", "500", "1000"],
                }}
            />
        </div>

    }

    //导出
    exportFile(data, merges, maxWidth, titleDeep) {
        /* convert state to workbook */

        const workBook = new Excel.Workbook();
        const worksheet = workBook.addWorksheet('sheet1');

        worksheet.addRows(data);

        if (merges) {
            merges.forEach((item) => {
                // worksheet.mergeCells(item);
                worksheet.mergeCells(item[0], item[1], item[2], item[3]);
            });
        }
        for (let i = 1; i <= maxWidth; i++) {
            worksheet.getColumn(i).alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
        }
        for (let i = 1; i <= titleDeep; i++) {
            for (let j = 1; j <= maxWidth; j++) {
                worksheet.getCell(i, j).font = {
                    bold: true,
                    color: { argb: 'ffffffff'}
                };
                worksheet.getCell(i, j).border = {
                    top: {style:'thin', color: {argb:'ff005f99'}},
                    left: {style:'thin', color: {argb:'ff005f99'}},
                    bottom: {style:'thin', color: {argb:'ff005f99'}},
                    right: {style:'thin', color: {argb:'ff005f99'}}
                };
                worksheet.getCell(i, j).fill = {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{argb:'ff373C51'},
                };
            }
            worksheet.getRow(i).height = 25;
        }

        workBook.xlsx.writeBuffer().then((buffer)=>{
            window.createDataDownload(buffer, "ELF导出数据.xlsx", "text/plain")
        })
    };


    //合并表头写法
    modifyExportDataMergeCell() {
        let data = [];

        //解析表头
        let cols = this.state.cols;

        //计算出每个对象类型的表头所占的单元格范围
        let calculating = (treeNode, parentIndex, parentDeepth)=>{
            if(!treeNode){
                return 0;
            }
            if(treeNode.dataType === "Object"){
                let index = parentIndex;
                treeNode._width = 0;
                treeNode.children?.forEach((item)=>{
                    item._index = index;
                    item._deepth = parentDeepth + 1;
                    item._width = calculating(item, item._index, item._deepth);
                    index+= item._width;
                    treeNode._width += item._width;
                })
            }else{
                treeNode._width = 1;
                treeNode._index = parentIndex;
            }

            return treeNode._width;
        }

        let index = 1;
        let maxWidth = 0;
        cols?.forEach((col)=>{
            col._width = calculating(col, index, 1);
            col._index = index;
            col._deepth = 1;
            index+= col._width;
            maxWidth += col._width
        })


        let titleLines = [];    //用于存储层级不一致的表头，用于表头绘制与合并
        let titleArr = [];      //用于存储最底层的键，用于匹配取值
        let merges = [];    //存储表头合并项

        let dealObj = (treeNode) => {
            let currentTitle = treeNode.title;

            if(!titleLines[treeNode._deepth - 1]){
                titleLines[treeNode._deepth - 1] = new Array(maxWidth).fill(null);
            }
            titleLines[treeNode._deepth - 1][treeNode._index - 1] = {
                title: currentTitle,
                matchKey: treeNode._id,
                format: treeNode.format,
                dataType: treeNode.dataType
            }

            if(treeNode._width > 1){
                //设置上占位的内容，用于合并列判断
                for(let i = treeNode._index;i<treeNode._width;i++){
                    titleLines[treeNode._deepth - 1][i] = {
                         useForPlaceholder: true
                    }
                }
            }

            if (treeNode.dataType === "Object") {
                treeNode.children&&treeNode.children.forEach((item) => {
                    dealObj(item)
                });

                // XLSX.utils.encode_col(0) ==> A
                // merges.push(`A2:B2`)

                //设置行单元格合并(表头里每一个占用宽度大于1的节点(即对象节点)都要合并，合并数为节点宽度)
                let lineNum = treeNode._deepth;
                merges.push([lineNum, treeNode._index - 1 + 1, lineNum, treeNode._index + treeNode._width - 2 + 1]);
            }else{
                titleArr.push({
                    title: currentTitle,
                    matchKey: treeNode._id,
                    format: treeNode.format,
                    dataType: treeNode.dataType
                });
            }
        };

        cols?.forEach((item) => {
            dealObj(item);
        });

        //设置表头
        titleLines.forEach((titleLine)=>{
            data.push(titleLine.map((item) => {
                if(!item){
                    return ""
                }else if(item.useForPlaceholder){
                    return ""
                }else{
                    return item.title
                }
            }));
        })


        //设置列单元格合并
        let maxDeep = titleLines.length;
        let lastLine = titleLines[maxDeep -  1];

        lastLine?.forEach((item,index)=>{
            //如果遇到空内容，则向上找，直到找到值，并设置合并参数
            if(item){
                //不需要合并
            }else{
                let findStep = maxDeep;
                while (findStep >=0){
                    findStep--;
                    if(titleLines[findStep][index]){
                        break;
                    }
                }
                let lineStartNum = findStep + 1;
                let lineEndNum = maxDeep;
                merges.push([lineStartNum, index + 1, lineEndNum, index + 1]);
            }
        })


        // console.log(data);
        // console.log(titleLines);
        // console.log(titleArr);
        // console.log(merges);

        let dealValItem = (val) => {
            let result = [];
            titleArr.forEach((item) => {
                //处理格式化数据
                if (!val.hasOwnProperty(item.matchKey)) {
                    result.push("")
                } else {
                    // result.push(this.state.formatType === "useFormat" ? Help.formatDataShow(val[item.matchKey], item.dataType, item.format) : val[item.matchKey])

                    let v = val[item.matchKey];
                    if(typeof v === "boolean"){
                        v = "" + v;
                    }else if(typeof v === "object"){
                        v = JSON.stringify(v);
                    }
                    result.push(v)
                }
            });

            return result;
        };

        //设置表格内容
        let values = this.formatValues;
        values.forEach((val) => {
            data.push(dealValItem(val));
        });

        this.exportFile(data, merges, maxWidth, maxDeep);

        this.setState({
            exportBtnLoading: false,
        });
    }

    //导出前，组装成插件需要的数据格式(已经废弃)
    // modifyExportData() {
    //     let data = [];
    //
    //     //解析表头
    //     let cols = this.state.cols;
    //     let titleArr = [];
    //
    //     let dealObj = (treeNode, prevTitle) => {
    //         let currentTitle = (prevTitle ? prevTitle + "/" : "") + treeNode.title;
    //         if (treeNode.dataType === "Object") {
    //             treeNode.children&&treeNode.children.forEach((item) => {
    //                 dealObj(item, currentTitle)
    //             });
    //         } else {
    //             titleArr.push({
    //                 title: currentTitle,
    //                 matchKey: treeNode._id,
    //                 format: treeNode.format,
    //                 dataType: treeNode.dataType
    //             });
    //         }
    //     };
    //
    //     cols.forEach((item) => {
    //         dealObj(item, "");
    //     });
    //
    //
    //     // console.log(titleArr);
    //
    //     //设置表头
    //     data.push(titleArr.map((item) => {
    //         return item.title
    //     }));
    //
    //     let dealValItem = (val) => {
    //         let result = [];
    //         titleArr.forEach((item) => {
    //             //处理格式化数据
    //             if (!val.hasOwnProperty(item.matchKey)) {
    //                 result.push("")
    //             } else {
    //                 result.push(this.state.formatType === "useFormat" ? Help.formatDataShow(val[item.matchKey], item.dataType, item.format) : val[item.matchKey])
    //             }
    //         });
    //
    //         return result;
    //     };
    //
    //     //设置表格内容
    //     let values = this.formatValues;
    //     values.forEach((val) => {
    //         data.push(dealValItem(val));
    //     });
    //
    //     // console.log("查看需要导出的数据");
    //     // console.log(data);
    //
    //     this.exportFile(data);
    //
    //     this.setState({
    //         exportBtnLoading: false,
    //     });
    // }
}

export default ShowListData