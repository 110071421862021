import React from "react";
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import {Button, message, Popover, Tooltip, Tag} from "antd";
import TimeHelp from "../../../tool/TimeHelp";
import less from "./OpenApiList.less";
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import ModalBase from '../../../components/modalBase/index.js';
import ArgSetForOpenApi from "./ArgSetForOpenApi"
import HttpTool from "../../../tool/HttpTool";

const MB = new ModalBase();
const ModalDelete = new ModalDel();

const ApiGroupNames = ["公司级","执行单元级","账号级","其它级"];

class OpenApiList extends ListPage {
    constructor(props) {
        super(props);
    }

    base_getListConfig() {
        return {
            table: {
                columns: this.getTableColumns(),
                url: APILXD.getBuiltInQueryList,
            },
            search: {
                seniorSearch: this.getSeniorSearch(),
                option: {
                    placeholder: "请输入关键词搜索",
                    openSenior: true,
                    reg: /^[\s\S]*$/,
                    beforeSubmit: (value) => {
                        value.status = value.status?Number(value.status):null
                        return true;
                    }
                }
            },
            new: {
                defaultValue: '新增接口',
                action: () => {
                    this.add();
                }
            },
        }
    }
    getSeniorSearch() {
        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: false,
        };
        return {
            colCount: 3,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    ver: true,
                    verMessage: "请选择",
                    field: "groupId",
                    name: "分组名",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        allowClear: true,
                        dropdownMatchSelectWidth: false,
                    },
                    data: ApiGroupNames.map((str)=>{
                        return {title: str, value: str}
                    })
                },
                {
                    ...defaultOption,
                    field: "status",
                    name: "状态",
                    type: 'select',
                    selectType: 'value',
                    reg: (v) => {
                        return true;
                    },
                    option: {
                        defaultValue: {
                            key: "",
                            value: "",
                        },
                    },
                    data: [
                        {
                            title: '全部状态',
                            value: "",
                        },
                        {
                            title: <span style={{color: "limegreen"}}>启用</span>,
                            value: "1",
                        },
                        {
                            title: <span style={{color: "red"}}>禁用</span>,
                            value: "2",
                        },
                    ]
                },
            ],
        }
    }

    getAddData(data) {
        let defaultGroupId = "";
        if(!data && this.searchParam && this.searchParam.groupId){
            defaultGroupId = this.searchParam.groupId;
        }else if(data){
            defaultGroupId = data.groupId || "";
        }

        let defaultOption = {
            type: "input",
            ver: true,
            reg: /^[\S\s]{0,50}$/,
            verMessage: "最多50个字符",
            required: true,
        };
        let props = {
            colCount: 2,
            formItemLayout: {
                labelCol: {span: 6},
                wrapperCol: {span: 18, offset: 0},
            },
            parameterArr: [
                {
                    ...defaultOption,
                    field: "groupId",
                    name: "分组",
                    type: 'Select',
                    selectType: 'value',
                    reg: (v) => {
                        return !!v;
                    },
                    verMessage: "请选择分组",
                    option: {
                        defaultValue: {
                            key: defaultGroupId,
                            value: defaultGroupId,
                        },
                    },
                    data: ApiGroupNames.map((str)=>{
                        return {title: str, value: str}
                    })
                },
                {
                    ...defaultOption,
                    field: "name",
                    name: "接口名称",
                    type: 'input',
                    reg: (v) => {
                        return !!v;
                    },
                    verMessage: "1-50个字符",
                    option: {
                        placeholder: "请填写接口名称",
                        maxLength: "50",
                        defaultValue: data?data.name:undefined
                    }
                },
                {
                    ...defaultOption,
                    field: "url",
                    name: "接口地址",
                    type: 'input',
                    reg: (v) => {
                        return !!v;
                    },
                    verMessage: "请填写",
                    option: {
                        maxLength: "128",
                        defaultValue: data?data.url:undefined
                    }
                },
                {
                    ...defaultOption,
                    field: "status",
                    name: "状态",
                    type: 'Select',
                    selectType: 'value',
                    reg: (v) => {
                        return !!v;
                    },
                    option: {
                        defaultValue: data?{
                            key: "" +  data.status,
                            value:"" +  data.status,
                        }:{
                            key: "1",
                            value: "1",
                        },
                    },
                    data: [
                        {
                            title: <span style={{color: "limegreen"}}>启用</span>,
                            value: "1",
                        },
                        {
                            title: <span style={{color: "red"}}>禁用</span>,
                            value: "2",
                        },
                    ]
                },
                {
                    ...defaultOption,
                    field: "defaultConfigsStr",
                    name: "系统参数",
                    type: 'TextArea',
                    verMessage: "请填写正确格式的配置对象",
                    reg: (v) => {

                        return !!v;
                    },
                    option: {
                        maxLength: 10000,
                        placeholder: "请填写配置对象",
                        defaultValue: data?JSON.stringify(data.defaultConfigs):"{}",
                        autoSize: {
                            minRows: 6,
                            maxRows: 10
                        }
                    }
                },
                {
                    ...defaultOption,
                    field: "configs",
                    name: "用户参数",
                    type: 'diy',
                    component: ArgSetForOpenApi,
                    verMessage: "请填写正确格式的配置对象",
                    reg: (v) => {
                        console.log("看看参数变化：",v)
                        return true;
                    },
                    option: {
                        placeholder: "请填写配置对象",
                        defaultValue: data?data.configs: {},
                    }
                },
            ],
        };
        return {
            props,
            otherParam: null
        }
    }

    add(data){
        let addData = this.getAddData(data);
        MB.show(
            {
                title: data?"编辑接口":"添加接口",
                okTitle: "提交",
                closeTitle: "取消",
                width: "90%"
            },
            addData.props
            ,
            {
                url: data?APILXD.editBuiltInQuery:APILXD.addBuiltInQuery,
                otherParam: {
                    _id: data?data._id: null
                },
                beforeSubmit: (param) => {

                    try {
                        let o;
                        let code = `o = ` + param.defaultConfigsStr;
                        eval(code);
                        console.log(o);
                        if(!o || typeof o !== "object"){
                            message.warning("系统参数内容格式不正确");
                            return false
                        }
                        param.defaultConfigs= o
                    }catch (e){
                        console.error(e)
                        message.warning("系统参数转换出错");
                        return false
                    }

                    param.status = Number(param.status);
                    param.sort = 1;

                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }

    /**
     *  列表数据配置
     */
    getTableColumns() {
        return [
            {
                title: '分组',
                dataIndex: 'groupId',
                key: 'groupId',
            },
            {
                title: '接口名称',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '接口地址',
                dataIndex: 'url',
                key: 'url',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record, index) => {
                    return <div>
                        {text===1?<Tag color={"limegreen"}>启用</Tag>:<Tag color={"red"}>禁用</Tag>}
                        {/*{*/}
                        {/*    this.powerConfig.Edit*/}
                        {/*        ?<span*/}
                        {/*            className={less.btnEdit}*/}
                        {/*            onClick={()=>{*/}
                        {/*                HttpTool.post("",(code,msg,json)=>{*/}
                        {/*                    message.success(msg);*/}
                        {/*                    this._loadDataForNet()*/}
                        {/*                },(code,msg)=>{*/}
                        {/*                    message.error(msg);*/}
                        {/*                }, {*/}
                        {/*                    _id: record._id,*/}
                        {/*                    status: text===1?2:1*/}
                        {/*                });*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*                切换*/}
                        {/*        </span>*/}
                        {/*        :null*/}
                        {/*}*/}
                    </div>
                }
            },
            {
                title: '排序编号',
                dataIndex: 'sort',
                key: 'sort',
                render: (text, record, index) => {
                    return <div>
                        {text}
                        {
                            this.powerConfig.Edit
                                ?<span
                                    className={less.btnEdit}
                                    onClick={()=>{
                                        this.changeSort(record)
                                    }}
                                >
                                        修改
                                </span>
                                :null
                        }
                    </div>
                }
            },
            {
                title: '创建/更新时间',
                dataIndex: 'updatetime',
                key: 'updatetime',
                render: (text, record, index) => {
                    return  <div>{record.updatetime ? TimeHelp.getYMDHM(record.updatetime) : "-"}</div>
                }
            },
            {
                title: '创建者',
                dataIndex: 'developer',
                key: 'developer',
                render: (text, record, index) => {
                    return  text?text.name:"-"
                }
            },
            {
                title: '操作',
                width: 120,
                render: (text, record, index) => {
                    return (
                        <div>
                            {
                                this.powerConfig.Edit
                                    ?(  <div
                                        className={less.btnEdit}
                                        onClick={() => {
                                            HttpTool.post(APILXD.getBuiltInQuery,(code,msg,json)=>{
                                                this.add(json)
                                            },(code,msg)=>{
                                                message.error(msg);
                                            }, {
                                                _id: record._id
                                            });

                                        }}
                                    >编辑
                                    </div>)
                                    :null
                            }
                            {
                                this.powerConfig.Delete
                                    ?(  <div
                                        className={less.btnDelete}
                                        onClick={() => {
                                            ModalDelete.show({
                                                    title: "提示",
                                                    okTitle: "确定",
                                                    closeTitle: "取消",
                                                },
                                                {}
                                                ,
                                                {
                                                    otherParam: {_id: record._id},
                                                    content: record.name,
                                                    url: APILXD.delBuiltInQuery,
                                                    apiType: 'post',
                                                    tip: '删除后不可恢复',
                                                    callBack: (state) => {
                                                        //删除成功回调
                                                        state === "success" && this._loadDataForNet();
                                                    }
                                                });
                                        }}
                                    >删除
                                    </div>)
                                    :null
                            }
                        </div>)
                }
            },
        ]
    }


    changeSort(data){
        MB.show(
            {
                title: "修改排序",
                okTitle: "提交",
                closeTitle: "取消",
            },
            {
                colCount: 1,
                formItemLayout: {
                    labelCol: {span: 6},
                    wrapperCol: {span: 12, offset: 0},
                },
                parameterArr: [
                    {
                        ver: true,
                        field: "sort",
                        name: "新的排序",
                        type: 'inputNumber',
                        verMessage: "请填写",
                        required: true,
                        reg: (v) => {
                            return v>0;
                        },
                        option: {
                            defaultValue: data?data.sort:undefined
                        }
                    },
                ],
            }
            ,
            {
                url: APILXD.setBuiltInQuerySort,
                otherParam: {
                    _id: data._id
                },
                beforeSubmit: (param) => {
                    return true;
                },
                callBack: (state, msg) => {
                    //添加成功回调
                    state == 'success' && this._loadDataForNet();
                }
            });
    }
}

module.exports = OpenApiList;