import React, { memo, useEffect, useState } from 'react'
import styles from './digitalHumanOnlineAnalysis24.less'
import { Flex, Select, Statistic } from 'antd'
import { request } from '../../../../../../tool/http'
// import HumanLineChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanLineChart/humanLineChart'


function DigitalHumanOnlineAnalysis24(props: any) {
  console.log('每天的在线人数---展示', props)
  // const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1])
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(1)
  const [data, setData] = useState<any>([])
  const [option, setOption] = useState<any>({})
  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg('')
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/digitalHumanHourAvgOnline`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }
  useEffect(() => {
    getBusinessData()
  }, [showType, time, props.dataUpdate])

  //获取图表数据
  const getOption = () => {
    let xAxis = [
      {
        color: '#5470C6',
        chartTitle: '在线人数',
        data: data.map((item: any) => item._id)
      }
    ]

    let series = [{
      type: (props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanOnlineAnalysis24Two' || props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanOnlineAnalysis24Three') ? 'line' : 'bar', //这里判断是折线状还是柱状图
      name: '在线人数',
      data: data.map((item: any) => item.sum),
      color: '#5470C6',
      label: {
        show: props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanOnlineAnalysis24' ? false : true,
        position: 'top',
        color: '#5470C6'
      }
      // areaColor: 'rgba(238, 102, 102,0.2)'
    }]
    let option = {
      polar: props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanOnlineAnalysis24Three',
      xAxis,
      series
    }
    setOption(option)
  }
  useEffect(() => {
    console.log('24平均获取到值--', data)
    if (data.length > 0) {
      getOption()
    }
  }, [data])

  return (
    <div className={styles.outer}>
      <div
        className={styles.chartBox + " swiper-no-swiping"}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex>
                <Flex justify='end' flex={1} className={" swiper-no-swiping"}>
                  <Select
                    style={{ width: "120px" }}
                    value={time}
                    onChange={(v) => {
                      setTime(v)
                    }}
                    options={[
                      { label: "今日", value: 1 },
                      { label: "30日", value: 30 },
                      { label: "15日", value: 15 },
                      { label: "7日", value: 7 },
                    ]}
                  >
                  </Select>
                </Flex>
              </Flex>

              {/* {showType.includes(1) && <HumanLineChart option={option}></HumanLineChart>} */}
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(DigitalHumanOnlineAnalysis24)