import { useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./UnitRefRanking.less";
// import { Bar } from '@ant-design/charts'
import { Select } from 'antd';

type BarData = {
    name: string;
    type: string;
    sum: number;
}

const UnitRefRanking: React.FC<any> = (props) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const [dataSource, setDataSource] = useState<Array<BarData>>([])
    const [size, setSize] = useState<number>(5)

    // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}/execUnit/getExecUnitCiteCount`, {
    //     data: {
    //         businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
    //         businessType: props.contentMsg?.proConfig?.usageParams?.usageCode, 
    //         count: size,
    //     }
    // }), {
    //     formatResult(res) {
    //         return res
    //     },
    //     refreshDeps: [props.dataUpdate, size],
    //     onSuccess: (res) => {
    //         if (res.code === 200) {
    //             //转化数据成需要的格式
    //             setDataSource(res?.data || 0)
    //         } else {
    //             setErrorMsg(res.message)
    //         }
    //     },
    //     onError(err: any) {
    //         setErrorMsg(err.toString())
    //     }
    // })

    const config = {
        theme: 'dark',
        data: dataSource,
        xField: 'name',
        yField: 'count',
        colorField: 'name',
        interaction: {
            elementSelect: true,
        },
    };

    return <div className={styles.outer}>
        <div className={styles.controlBar + " swiper-no-swiping"}>
            <Select
                style={{ width: "90px" }}
                value={size}
                onChange={(v) => {
                    setSize(v)
                }}
                options={[
                    { label: "5条", value: 5 },
                    { label: "10条", value: 10 },
                ]}
            />
        </div>
        {/* {
            errorMsg
                ? <div className={styles.errorMsg}>
                    {errorMsg}
                </div>
                : <Bar autoFit={true} {...config} />
        } */}
    </div>
}

export default UnitRefRanking