import React from "react";
import styles from "./ContentView.less";
import {Spin, Empty,Modal,message,Divider,Checkbox} from "antd";
import {DeliveredProcedureOutlined,QuestionCircleFilled} from "@ant-design/icons";
import HttpTool from "../../../../tool/HttpTool";
import DataDefinitionFile from "../dataDisposition/DataDefinitionFile";
import BaseSetItem from "../configSet/BaseSetItem";
import BackgroundBgDiv from "./BackgroundBgDiv";
import DataGetHelp from "../../dataSet/components/dataSet/DataGetHelp";
import ShowCode from "../../../edit/ShowCode";
import AllContentMap from "../contentView/AllContentDefinition";
import {UsageToCodeMap} from "./customComponent/UsageDictionary";

const ContentMergeFields = ["commonSet", "contentBackgroundSet", "contentSet", "contentTitle", "contentFont", "titlePlus"];

export default class ContentView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            errorMsg: null,
            dataSetDetail: null,
            dataSetData: null,
            dataSetOriginData: null,

            systemComponentDateUpdate: 0,   //系统组件内部处理请求，需要单独通知
        }

        this.BaseSetItem = new BaseSetItem();
        this.DataGetHelp = new DataGetHelp();

        //合并样式
        this.newProps = this.props;
        this.dealStylesConfigMerge()

        this.childInputArgParamsMap = {};

        //视图容器ref
        this.viewBoxRef = null;
        //参数容器ref
        this.parameterBoxRef = null;

        //初始化一些根据环境决定的动态参数
        this.initProConfig();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate) {
            //需要样式更新，数据不用更新
            console.log("样式变了，重新渲染")
            this.newProps = nextProps;
            this.dealStylesConfigMerge();
        }
        if (this.props.dataUpdate !== nextProps.dataUpdate) {
            //需要重新请求数据
            this.newProps = nextProps;
            if(this.newProps.contentMsg.contentMode === "view" && this.newProps.contentMsg.dataConfig?.dataSetMdId){
                this.loadDataSetData(this.newProps.contentMsg.dataConfig?.dataSetId,true)
            }
            if (this.newProps.contentMsg.contentMode === "customComponent" && this.newProps.contentMsg.dataConfig?.dataRequestConfig) {
                this.loadDataForCustomComponent(this.newProps.contentMsg.dataConfig.dataRequestConfig,true)
            }
        }
    }


    componentDidMount() {
        this.initData();
    }

    componentWillUnmount(){
        this.loopGetData && clearInterval(this.loopGetData)
    }

    //初始化高级配置
    initProConfig(){
        // props.identifyMsg
        this.proConfig = {
            apiPrefix: "/platform/platform/",
            usageParams: {
                usage: this.props.identifyMsg?.usage || "company",
                usageCode: UsageToCodeMap[this.props.identifyMsg?.usage|| "company"], //服务端对应的数字
                companyId: this.props.identifyMsg?.companyId,
                spaceId: this.props.identifyMsg?.spaceId,
                unitId: this.props.identifyMsg?.unitId,
                deviceId: this.props.identifyMsg?.deviceId,
                businessId: null,
            }
        }

        if(this.props.showType === "dev" && ["customComponent","systemComponent"].includes(this.props.contentMsg.contentMode)){
            //开发模式，采用模拟数据
            this.proConfig.usageParams = Object.assign({},this.proConfig.usageParams,this.props.contentMsg.dataConfig.mockParams)
        }

        this.proConfig.usageParams.businessId = this.proConfig.usageParams[this.props.identifyMsg?.usage + "Id"];
    }


    //初始化数据
    initData(loadingStyle=true) {
        // console.log("initData", this.newProps);
        //视图组件
        if (this.newProps.contentMsg.contentMode === "view" && this.newProps.contentMsg.dataConfig?.dataSetMdId) {
            console.log("视图：请求容器数据");
            this.loadDataSetDetail(this.newProps.contentMsg.dataConfig?.dataSetMdId,loadingStyle)
        }
        //自定义组件
        if (this.newProps.contentMsg.contentMode === "customComponent" && this.newProps.contentMsg.dataConfig?.dataRequestConfig) {
            console.log("自定义组件：请求容器数据");
            this.childInputArgParamsMap = {};
            let dataRequestConfig = this.newProps.contentMsg.dataConfig.dataRequestConfig;
            if(dataRequestConfig.dataType ===1 && dataRequestConfig.configs?.inputArg && Object.keys(dataRequestConfig.configs?.inputArg).length){
                //是元数据查询类型，且需要配置输入参数
                this.childInputArgParamsMap[this.props.uid] = {
                    dataType: 1,
                    title: "自定义组件请求参数",
                    inputArg: _.cloneDeep(dataRequestConfig.configs.inputArg)
                };
            }
            this.loadDataForCustomComponent(this.newProps.contentMsg.dataConfig.dataRequestConfig,loadingStyle)
        }
        //系统组件
        if(this.newProps.contentMsg.contentMode === "systemComponent" && this.newProps.contentMsg.dataConfig?.dataParamsConfig){
            this.childInputArgParamsMap = {};
            let dataParamsConfig = this.newProps.contentMsg.dataConfig.dataParamsConfig;
            if(dataParamsConfig.inputArg && Object.keys(dataParamsConfig.inputArg).length){
                //是元数据查询类型，且需要配置输入参数
                this.childInputArgParamsMap[this.props.uid] = {
                    dataType: 1,
                    title: "系统组件请求参数",
                    inputArg: _.cloneDeep(dataParamsConfig.inputArg)
                };
            }
        }
    }

    dealStylesConfigMerge() {
        let contentStyle = this.newProps.contentMsg?.styleConfig || {};
        let parentStylesConfig;
        if (this.newProps.parentStylesConfig) {
            //传入了合并完的场景样式
            parentStylesConfig = _.cloneDeep(this.newProps.parentStylesConfig)
        } else {
            //分别传入 仪表板 和 场景未合并的样式
            let themeConfig = _.cloneDeep(this.newProps.themeConfig);
            let sceneStyleConfig = _.cloneDeep(this.newProps.scencStyleConfig);
            parentStylesConfig = this.BaseSetItem.mergeFinallyConfig(sceneStyleConfig, themeConfig, ContentMergeFields);
        }

        this.finallyStyleConfig = this.BaseSetItem.mergeFinallyConfig(contentStyle, parentStylesConfig, ContentMergeFields);
        console.log("this.finallyStyleConfig", this.finallyStyleConfig)

        if(this.finallyStyleConfig?.commonSet?.refreshSet){
            this.loopGetData && clearInterval(this.loopGetData)
            this.loopGetData = setInterval(()=>{
                if (this.newProps.contentMsg.contentMode === "systemComponent") {
                    //系统组件的请求是内部实现的，单独通知
                    this.setState({
                        systemComponentDateUpdate: this.state.systemComponentDateUpdate + 1
                    })
                }else{
                    //其他组件重新初始化
                    this.initData(false)
                }
            },this.finallyStyleConfig?.commonSet.refreshSet.time * this.finallyStyleConfig?.commonSet.refreshSet.unit * 1000 || 60000)
        }
    }

    //请求数据集定义详情
    loadDataSetDetail(mdId,loadingStyle){

        message.warning("后台系统不允许查询数据集数据");
        return;

        this.setState({
            loading: loadingStyle
        },()=>{
            HttpTool.post("/platform/xxx/dataset/detailByMdId", (code, msg, json) => {
                this.childInputArgParamsMap = {};
                let dealAction = (data)=>{
                    if(data.type === 1){
                        let dataSetId = data.mdId || data._id;
                        //独立数据
                        if(data.dataType ===1 && data.configs?.inputArg && Object.keys(data.configs?.inputArg).length){
                            //是元数据查询类型，且需要配置输入参数
                            this.childInputArgParamsMap[dataSetId] = {
                                dataType: 1,
                                title: data.title,
                                inputArg: _.cloneDeep(data.configs.inputArg)
                            };
                        }
                        if(data.dataType ===2 && data.configs?.configs && Object.keys(data.configs?.configs).length){
                            //是绑定通用数据类型，且需要配置输入参数
                            this.childInputArgParamsMap[dataSetId] = {
                                dataType: 2,
                                title: data.title,
                                inputArg: _.cloneDeep(data.configs.configs)
                            };
                        }
                        if(data.dataType ===5 && data.configs?.inputArg && Object.keys(data.configs?.inputArg).length){
                            //是依赖参数注入的模式
                            this.childInputArgParamsMap[dataSetId] = {
                                dataType: 5,
                                title: data.title,
                                inputArg: _.cloneDeep(data.configs.inputArg)
                            };
                        }
                    }else if(data.type === 2){
                        //联合数据
                        data.children?.forEach((eachChild)=>{
                            dealAction(eachChild);
                        })
                    }
                }

                dealAction(json);
                this.setState({
                    dataSetDetail: json
                }, () => {
                    this.loadDataSetData(mdId,loadingStyle)
                })
            }, (code, msg, json) => {
                this.setState({
                    errorMsg: "数据集请求失败：" + msg,
                    loading: false
                })
            },{
                mdId: mdId,
                  spaceId: this.proConfig.usageParams?.spaceId,
                  execUnitId: this.proConfig.usageParams?.unitId,
            })
        })
    }

    //请求数据集数据
    loadDataSetData(id,loadingStyle) {
        if(!this.state.dataSetDetail){
            this.loadDataSetDetail(id,loadingStyle);
            return;
        }


        //合并参数
        let baseCustomParamsObj = this.newProps.contentMsg.dataConfig?.dataSetInputMap || {};
        if(this.newProps.contentMsg.beConnectedConfig) {
            Object.keys(this.newProps.contentMsg.beConnectedConfig).forEach((parameterBoxUid)=>{
                this.newProps.contentMsg.beConnectedConfig[parameterBoxUid].forEach((bindKey)=>{
                    let bindMsg = bindKey.split("_"); //数据集key + 参数key
                    let item = baseCustomParamsObj[bindMsg[0]]?.inputArg?.[bindMsg[1]];
                    if(item){
                        //替换成参数组件的值
                        item.value.value = this.newProps.allParameterBoxValues[parameterBoxUid];
                    }else{
                        //模拟
                        if(!baseCustomParamsObj[bindMsg[0]]){
                            baseCustomParamsObj[bindMsg[0]] = {
                                notFull: true,  //表示结构不完整
                                inputArg: {}
                            };
                        }
                        baseCustomParamsObj[bindMsg[0]].inputArg[bindMsg[1]] = {
                            value: {
                                value: this.newProps.allParameterBoxValues[parameterBoxUid]
                            }
                        };
                    }
                })
            })
        }

        this.setState({
            loading: loadingStyle
        }, async () => {
            let err;
            let resultData = await this.DataGetHelp.getDataByNodeDetail(this.state.dataSetDetail,{
                customParamsObj:baseCustomParamsObj
            }).catch((e) => {
                err = e;
            })

            if (err) {
                this.setState({
                    errorMsg: "数据集请求失败：" + err,
                    loading: false
                })
            } else {

                this.setState({
                    dataSetData: resultData.tranData,
                    dataSetOriginData: resultData.originData,
                    errorMsg: null,
                    loading: false
                })
                this.initDataForView(resultData.tranData, this.state.dataSetDetail)
            }
        })
    }

    //自定义组件的数据请求
    loadDataForCustomComponent(config,loadingStyle){
        this.setState({
            loading: loadingStyle
        }, async () => {
            let err;

            //合并参数
            let baseCustomParamsObj = _.cloneDeep(this.newProps.contentMsg.dataConfig?.dataSetInputMap || {});

            if(this.newProps.contentMsg.beConnectedConfig) {
                Object.keys(this.newProps.contentMsg.beConnectedConfig).forEach((parameterBoxUid)=>{
                    this.newProps.contentMsg.beConnectedConfig[parameterBoxUid].forEach((bindKey)=>{
                        let bindMsg = bindKey.split("_"); //数据集key + 参数key
                        let item = baseCustomParamsObj[bindMsg[0]]?.inputArg?.[bindMsg[1]];
                        if(item){
                            //替换成参数组件的值
                            item.value.value = this.newProps.allParameterBoxValues[parameterBoxUid];
                        }else{
                            //模拟
                            if(!baseCustomParamsObj[bindMsg[0]]){
                                baseCustomParamsObj[bindMsg[0]] = {
                                    notFull: true,  //表示结构不完整
                                    inputArg: {}
                                };
                            }
                            baseCustomParamsObj[bindMsg[0]].inputArg[bindMsg[1]] = {
                                value: {
                                    value: this.newProps.allParameterBoxValues[parameterBoxUid]
                                }
                            };
                        }
                    })
                })
            }

            if (baseCustomParamsObj?.[this.props.uid]) {
                //存在填过的参数，替换掉默认的
                let newObj = baseCustomParamsObj?.[this.props.uid];
                if (newObj.hasOwnProperty("inputArg")) {
                    //新版本参数结构 {title:xxx,inputArg:{}}
                    if (newObj.notFull) {
                        //newObj.inputArg 结构不全，需要挨个替换
                        Object.keys(newObj.inputArg).forEach((key) => {
                            if (config.configs.inputArg[key]?.value) {
                                config.configs.inputArg[key].value.value = newObj.inputArg[key].value?.value
                            }
                        })
                    } else {
                        config.configs.inputArg = newObj.inputArg;
                    }
                } else {
                    //老版本参数结构
                    config.configs.inputArg = newObj;
                }
            }

            //自定义组件，在仪表板渲染时，动态传入业务id
            config.configs.apiParams = Object.assign({},config.configs.apiParams,{
                companyId: this.proConfig.usageParams.companyId,
                spaceId: this.proConfig.usageParams.spaceId,
                execUnitId: this.proConfig.usageParams.unitId,
                humanId: this.proConfig.usageParams.humanId,
            })

            let resultData = await this.DataGetHelp.getDataByBindConfig(config).catch((e) => {
                err = e;
            })

            if (err) {
                this.setState({
                    errorMsg: "数据集请求失败：" + err,
                    loading: false
                })
            } else {

                this.setState({
                    dataSetData: resultData.tranData,
                    dataSetOriginData: resultData.originData,
                    errorMsg: null,
                    loading: false
                })
                this.initDataForCustomView(resultData.tranData)
            }
        })
    }

    //展示数据
    showDataModal(){
        Modal.info({
            width: "80%",
            maskClosable: true,
            okText: "关闭",
            okButtonProps: {
                style: { zIndex: 99 }
            },
            content: <div
                style={{
                    height: "70vh",
                    paddingTop: "20px",
                }}
            >
                <ShowCode
                    readOnly={true}
                    hiddenHelp={true}
                    noFocus={true}
                    defaultValue={this.state.dataSetData}
                    wait={500}
                />
            </div>
        })
    }

    //参数选择
    getParamsSelView(){
        let {connectMe,selParameterBoxType} = this.props;

        let mapKey = Object.keys(this.childInputArgParamsMap);

        if(!mapKey.length){
            return <Empty description={"没有参数可关联"}/>;
        }

        let needType = DataDefinitionFile.getRealParameterType(selParameterBoxType)

        return <Checkbox.Group
          value={connectMe || []}
          onChange={(checkedValue)=>{
              this.props.paramesChecked?.(checkedValue)
          }}
        >
            {
                mapKey.map((keyStr)=>{
                    let scriptObj = this.childInputArgParamsMap[keyStr].inputArg;
                    return <div
                      key={"lay_" + keyStr}
                    >
                        <Divider orientation={"left"}>{this.childInputArgParamsMap[keyStr].title}</Divider>
                        {
                            Object.keys(scriptObj)?.map((key)=>{
                                let item = scriptObj[key];
                                let realType = DataDefinitionFile.getBaseTypeFromArgType(item.type)

                                return <Checkbox
                                  key={keyStr + "_" + key}
                                  value={keyStr + "_" + key}
                                  disabled={realType !== needType && !(item.type === "Select" && needType === "String")}
                                >{item.name}</Checkbox>
                            })
                        }
                    </div>
                })
            }
        </Checkbox.Group>
    }

    //备注图标的父级样式，用于控制显示
    getDescParentDivStyle(descConfig){
        if(!descConfig?.desc){
            return  "";
        }

        if(descConfig.showType === "always"){
            return  "";
        }

        if(descConfig.showType === "hover"){
            return  styles.hoverShowDesc;
        }
    }

    getDescIconStyle(descConfig){
        let styleObj = {
            fontSize: window.changeRemNum({value:descConfig.sizeSet.value || 14,unit:descConfig.sizeSet.unit || "px"},"value","unit"),
            color: descConfig.color || "#fff"
        };

        if(descConfig.showType !== "hover"){
            styleObj.opacity = "1";
        }

        let margin = window.changeRemNum({value:descConfig.marginSet.value || 10,unit:descConfig.marginSet.unit || "px"},"value","unit");
        switch (descConfig?.position){
            case "topLeft":
                styleObj.top = margin;
                styleObj.left = margin;
                break;
            case "topRight":
                styleObj.top = margin;
                styleObj.right = margin;
                break;
            case "bottomLeft":
                styleObj.bottom = margin;
                styleObj.left = margin;
                break;
            case "bottomRight":
                styleObj.bottom = margin;
                styleObj.right = margin;
                break;
            default:
                styleObj.top = margin;
                styleObj.left = margin;
                break;
        }

        return styleObj;
    }

    render() {
        let {loading, errorMsg} = this.state;

        if (!this.newProps.contentMsg) {
            return <div className={styles.warnMsgBox}><Empty description={"未配置过容器信息"}/></div>
        }

        let {contentBackgroundSet, contentTitle, contentFont, contentSet, commonSet, titlePlus} = this.finallyStyleConfig;

        return <Spin
            size={"large"}
            spinning={loading}
            wrapperClassName={styles.spinBoxStyle + " " + this.getDescParentDivStyle(this.newProps.contentMsg.descConfig)}
        >
            {
                this.newProps.contentMsg.descConfig?.desc
                    ?<QuestionCircleFilled
                        style={this.getDescIconStyle(this.newProps.contentMsg.descConfig)}
                        className={styles.descIcon}
                        onClick={()=>{
                            Modal.info({
                                title: "备注",
                                width: "50%",
                                content: <div
                                    style={{whiteSpace: "pre",maxHeight: "80vh", overflow: "auto"}}
                                >
                                    <div dangerouslySetInnerHTML={{__html:this.newProps.contentMsg.descConfig?.desc}}></div>
                                </div>
                            })
                        }}
                    />
                    :null
            }
            {
                this.props.showType==="dev" && this.newProps.contentMsg.contentMode === "view"
                    ?<DeliveredProcedureOutlined
                        className={styles.seeDataBtn}
                        onClick={()=>{
                            if(!this.newProps.contentMsg.dataConfig.dataSetId){
                                message.warning("未绑定数据集");
                            }else{
                                this.showDataModal()
                            }

                        }}
                    />
                    :null
            }
            {
                this.props.selParameterBoxType && ["view","customComponent","systemComponent"].includes(this.props.contentMsg.contentMode)
                    ?<div className={styles.paramsSelLayer}
                          onClick={(e)=>{
                              e.stopPropagation();
                          }}
                          onMouseDown={(e)=>{
                              e.stopPropagation();
                          }}
                    >
                        {this.getParamsSelView()}
                    </div>
                    :null
            }
            <div
                className={styles.contentOuter}
                style={{
                    paddingTop: window.changeRemNum(commonSet.padding,"top","topUnit"),
                    paddingBottom: window.changeRemNum(commonSet.padding,"bottom","bottomUnit"),
                    paddingLeft: window.changeRemNum(commonSet.padding,"left","leftUnit"),
                    paddingRight: window.changeRemNum(commonSet.padding,"right","rightUnit"),

                    flexDirection: (contentTitle.positionSet?.flexSet?.flexWhere === "top" || contentTitle.positionSet?.flexSet?.flexWhere === "bottom") ? "column" : "row",
                    borderRadius: window.changeRemNum(contentBackgroundSet.radiusSet),
                    overflow: contentBackgroundSet.overflowHidden?"hidden":"unset",
                }}
                onContextMenu={(event)=>{
                    event.preventDefault();
                    return false
                }}
            >
                {
                    contentBackgroundSet.use
                        ?<BackgroundBgDiv
                            backgroundSet={contentBackgroundSet}
                        />
                        :null
                }
                {
                    contentTitle.show
                        ? <div
                            className={styles.contentTitleBox}
                            style={this.getContentTitleStyleObj()}
                        >
                            <div
                                style={this.getContentTitleInnerStyleObj()}
                            >
                                {
                                    titlePlus?.use
                                        ?<BackgroundBgDiv
                                            backgroundSet={titlePlus}
                                        />
                                        :null
                                }
                                {
                                    contentTitle.iconSet?.use
                                        ?this.getIcon(contentTitle.iconSet)
                                        :null
                                }
                                {this.newProps.contentMsg?.dataConfig?.contentTitle || ""}
                            </div>
                        </div>
                        : null
                }
                <div
                    className={styles.contentPaddingBox}
                    style={{
                        opacity: contentSet.opacity / 100,
                        paddingTop: window.changeRemNum(contentSet.padding,"top","topUnit"),
                        paddingBottom: window.changeRemNum(contentSet.padding,"bottom","bottomUnit"),
                        paddingLeft: window.changeRemNum(contentSet.padding,"left","leftUnit"),
                        paddingRight: window.changeRemNum(contentSet.padding,"right","rightUnit"),
                    }}
                >
                    {
                        errorMsg
                            ? <div className={styles.errorMsg}>{errorMsg}</div>
                            : null
                    }
                    <div
                        className={styles.realContentBox}
                        style={{
                            justifyContent: contentSet.justifyContent || "center",
                            alignItems: contentSet.alignItems || "center",
                            opacity: errorMsg?0:1
                        }}
                        onMouseDown={(event)=>{
                            if(this.props.showType === "dev"){
                                //开发模式
                                if(event.ctrlKey){
                                    event.stopPropagation();
                                    event.preventDefault();
                                }
                            }else{
                                event.stopPropagation();
                                event.preventDefault();
                            }
                        }}
                        onMouseMove={(event)=>{
                            if(this.props.showType === "dev"){
                                //开发模式
                                if(event.ctrlKey){
                                    event.stopPropagation();
                                    event.preventDefault();
                                }
                            }else{
                                //如果不注释，演示模式下，地图组件无法执行拖拽操作

                                // event.stopPropagation();
                                // event.preventDefault();
                            }
                        }}
                        // 如果使整体字体生效，所有子元素字体都会跟着走，这个方案不太行
                        // style={{
                        //     color: contentFont.color,
                        //     justifyContent: contentSet.justifyContent || "center",
                        //     alignItems: contentSet.alignItems || "center",
                        //     fontSize: window.changeRemNum(contentFont.fontSet,"fontSize","sizeUnit"),
                        //     wordSpacing: window.changeRemNum(contentFont.fontSet,"wordSpacing","sizeUnit"),
                        //     lineHeight: window.changeRemNum(contentFont.fontSet,"lineHeight","sizeUnit"),
                        //     fontWeight: contentFont.fontSet.fontBold ? "bold" : "normal",
                        //     fontStyle: contentFont.fontSet.fontItalic ? "italic" : "normal",
                        //     fontFamily: (contentFont.fontSet.fontFamily && contentFont.fontSet.fontFamily !== "default")?contentFont.fontSet.fontFamily:"unset"
                        // }}
                    >
                        {this.getContentMsgShow()}
                    </div>
                </div>
            </div>
        </Spin>
    }

    //标题样式
    getContentTitleStyleObj() {
        let {contentTitle,titlePlus} = this.finallyStyleConfig;

        let resultObj;
        if (contentTitle.positionSet.layoutMode === "absolute") {
            resultObj = {
                position: "absolute",
                top: (typeof contentTitle.positionSet.absoluteSet.top === "number")
                    ? contentTitle.positionSet.absoluteSet.top + contentTitle.positionSet.absoluteSet.topUnit
                    : "unset",
                left: (typeof contentTitle.positionSet.absoluteSet.left === "number")
                    ? contentTitle.positionSet.absoluteSet.left + contentTitle.positionSet.absoluteSet.leftUnit
                    : "unset",
                right: (typeof contentTitle.positionSet.absoluteSet.right === "number")
                    ? contentTitle.positionSet.absoluteSet.right + contentTitle.positionSet.absoluteSet.rightUnit
                    : "unset",
                bottom: (typeof contentTitle.positionSet.absoluteSet.bottom === "number")
                    ? contentTitle.positionSet.absoluteSet.bottom + contentTitle.positionSet.absoluteSet.bottomUnit
                    : "unset",
                transform: `translate(${(typeof contentTitle.positionSet.absoluteSet.translateX === "number")
                    ? contentTitle.positionSet.absoluteSet.translateX + contentTitle.positionSet.absoluteSet.translateXUnit : "0px"},${(typeof contentTitle.positionSet.absoluteSet.translateY === "number")
                    ? contentTitle.positionSet.absoluteSet.translateY + contentTitle.positionSet.absoluteSet.translateYUnit : "0px"})`,
                borderRadius: window.changeRemNum(titlePlus.radiusSet),
                overflow: titlePlus.overflowHidden?"hidden":"unset",
            }
        } else {
            resultObj = {
                position: "relative",
                display: "flex",
                flexDirection: (contentTitle.positionSet?.flexSet?.flexWhere === "top" || contentTitle.positionSet?.flexSet?.flexWhere === "bottom") ? "row" : "column",
                justifyContent: contentTitle.positionSet.flexSet.flexAlignItems,
                order: (contentTitle.positionSet.flexSet.flexWhere === "right" || contentTitle.positionSet.flexSet.flexWhere === "bottom") ? 10 : 1,
                borderRadius: window.changeRemNum(titlePlus.radiusSet),
                overflow: titlePlus.overflowHidden?"hidden":"unset",
            }
        }

        return resultObj;
    }

    //标题内部样式
    getContentTitleInnerStyleObj() {
        let {contentTitle, titlePlus} = this.finallyStyleConfig;

        let resultObj = {
            position: "relative",
            display: "flex",
            alignItems: "center",
            writingMode: contentTitle.positionSet.writingMode,
            color: contentTitle.color,

            fontSize: window.changeRemNum(contentTitle.fontSet,"fontSize","sizeUnit"),
            wordSpacing: window.changeRemNum(contentTitle.fontSet,"wordSpacing","sizeUnit"),
            lineHeight: window.changeRemNum(contentTitle.fontSet,"lineHeight","sizeUnit"),

            fontWeight: contentTitle.fontSet.fontBold ? "bold" : "normal",
            fontStyle: contentTitle.fontSet.fontItalic ? "italic" : "normal",

            fontFamily: (contentTitle.fontSet.fontFamily && contentTitle.fontSet.fontFamily !== "default")?contentTitle.fontSet.fontFamily:"unset",


            paddingTop: window.changeRemNum(contentTitle.padding,"top","topUnit"),
            paddingBottom: window.changeRemNum(contentTitle.padding,"bottom","bottomUnit"),
            paddingLeft: window.changeRemNum(contentTitle.padding,"left","leftUnit"),
            paddingRight: window.changeRemNum(contentTitle.padding,"right","rightUnit"),
        }

        if(titlePlus?.use){
            //需要增强
            //lr-tb  tb-rl
            if(contentTitle.positionSet.writingMode === "tb-rl"){
                //竖直的，设置高度
                resultObj.height = `${titlePlus.longSet.num}${titlePlus.longSet.unit}`
            }else{
                //水平的，设置宽度
                resultObj.width = `${titlePlus.longSet.num}${titlePlus.longSet.unit}`
            }
        }

        return resultObj;
    }

    //图标
    getIcon(iconSet){
        return <div
            className={styles.iconSetImg}
            style={{
                width: window.changeRemNum(iconSet,"iconW","iconWUnit"),
                height: window.changeRemNum(iconSet,"iconH","iconHUnit"),
                marginRight: window.changeRemNum(iconSet,"spaceWidthTxt","spaceWidthTxtUnit"),
                backgroundImage: `url(${iconSet.upload_imageUrl})`,
            }}
        >
        </div>
    }

    //根据不同的容器类型，展示不同的div
    getContentMsgShow() {
        let {contentMsg} = this.newProps;

        let showDiv;

        //_.cloneDeep的目的是切断关联，子组件UNSAFE_componentWillReceiveProps才能判断出不同
        let renderContentMsg = _.cloneDeep(Object.assign({},contentMsg,{
            styleConfig: this.finallyStyleConfig
        }))

        if(!renderContentMsg?.dataConfig?.contentType){
            return <div className={styles.warnMsgBox}><Empty description={"未选择内容类型"}/></div>
        }

        //设置空间、执行单元、数字人身份证
        renderContentMsg.proConfig = this.proConfig

        switch (contentMsg.contentMode) {
            case "view":
                showDiv = this.getViewTypeDiv(renderContentMsg);
                break;
            case "widget":
                showDiv = this.getWidgetTypeDiv(renderContentMsg);
                break;
            case "parameter":
                showDiv = this.getParameterTypeDiv(renderContentMsg);
                break;
            case "systemComponent":
                showDiv = this.getSystemComponentTypeDiv(renderContentMsg);
                break;
            case "customComponent":
                showDiv = this.getCustomViewTypeDiv(renderContentMsg);
                break;
        }
        return showDiv
    }

    //视图
    getViewTypeDiv(contentMsg) {
        let matchContent = AllContentMap[contentMsg.dataConfig.contentType];

        if (!matchContent) {
            return <div className={styles.warnMsgBox}><Empty description={"未知类型的视图"}/></div>
        }

        let ViewDiv = matchContent.renderFile;

        return <ViewDiv
          ref={ref => this.viewBoxRef = ref}
          showType={this.props.showType}    //录制模式、展示模式
          styleUpdate={this.newProps.styleUpdate}
          contentMsg={contentMsg}
          broadcastData={this.props.broadcastData} //可以在内部发出广播
        />
    }

    //自定义组件
    getCustomViewTypeDiv(contentMsg) {
        let matchContent = AllContentMap[contentMsg.dataConfig.contentType];

        if (!matchContent) {
            return <div className={styles.warnMsgBox}><Empty description={"未知类型的视图"}/></div>
        }

        let ViewDiv = matchContent.renderFile;

        return <ViewDiv
          ref={ref => this.customViewBoxRef = ref}
          showType={this.props.showType}    //录制模式、展示模式
          styleUpdate={this.newProps.styleUpdate}
          contentMsg={contentMsg}
          broadcastData={this.props.broadcastData} //可以在内部发出广播
        />
    }

    //部件
    getWidgetTypeDiv(contentMsg) {
        let matchContent = AllContentMap[contentMsg.dataConfig.contentType];

        if (!matchContent) {
            return <div className={styles.warnMsgBox}><Empty description={"未知类型的部件"}/></div>
        }

        let WidgetDiv = matchContent.renderFile;

        return <WidgetDiv
          ref={ref => this.widgetBoxRef = ref}
          showType={this.props.showType}    //录制模式、展示模式
          styleUpdate={this.newProps.styleUpdate}
          contentMsg={contentMsg}
        />
    }

    //参数
    getParameterTypeDiv(contentMsg){
        let matchContent = AllContentMap[contentMsg.dataConfig.contentType];

        if (!matchContent) {
            return <div className={styles.warnMsgBox}><Empty description={"未知类型的参数"}/></div>
        }

        this.props.registerValueSetListener?.(this.setValueToParameter.bind(this));

        let ParameterDiv = matchContent.renderFile;

        return <ParameterDiv
          ref={(ref)=>{this.parameterBoxRef = ref}}
          contentMsg={contentMsg}
          valueChanged={(newValue,oldValue)=>{
              this.props.valueChanged?.(newValue,oldValue)
          }}
        />
    }

    //系统组件
    getSystemComponentTypeDiv(contentMsg){
        let matchContent = AllContentMap[contentMsg.dataConfig.contentType];


        if (!matchContent) {
            return <div className={styles.warnMsgBox}><Empty description={"未知类型的系统组件"}/></div>
        }

        if(this.props.ifTemplate){
            //系统组件在模板中拿不到业务id，只需要展示封面
            return <div className={styles.coverContent}
                style={{backgroundImage: `url(${matchContent.cover})`}}
            />
        }

        let SystemComponentDiv = matchContent.renderFile;

        //系统组件请求由内部处理，需要把查询参数传入
        //合并参数
        let baseCustomParamsObj = _.cloneDeep(this.newProps.contentMsg.dataConfig?.dataSetInputMap || {});
        if(this.newProps.contentMsg.beConnectedConfig) {
            Object.keys(this.newProps.contentMsg.beConnectedConfig).forEach((parameterBoxUid)=>{
                this.newProps.contentMsg.beConnectedConfig[parameterBoxUid].forEach((bindKey)=>{
                    let bindMsg = bindKey.split("_"); //数据集key + 参数key
                    let item = baseCustomParamsObj[bindMsg[0]]?.inputArg?.[bindMsg[1]];
                    if(item){
                        //替换成参数组件的值
                        item.value.value = this.newProps.allParameterBoxValues[parameterBoxUid];
                    }else{
                        //模拟
                        if(!baseCustomParamsObj[bindMsg[0]]){
                            baseCustomParamsObj[bindMsg[0]] = {
                                notFull: true,  //表示结构不完整
                                inputArg: {}
                            };
                        }
                        baseCustomParamsObj[bindMsg[0]].inputArg[bindMsg[1]] = {
                            value: {
                                value: this.newProps.allParameterBoxValues[parameterBoxUid]
                            }
                        };
                    }
                })
            })
        }
        let inputArgConfig = baseCustomParamsObj[this.props.uid]?.inputArg || {};

        //提取最终的值
        let inputArgValues = {};
        Object.values(inputArgConfig).forEach((eachInput)=>{
            inputArgValues[eachInput.key] = eachInput.value.value;
        })

        return <SystemComponentDiv
          ref={ref => this.systemComponentBoxRef = ref}
          showType={this.props.showType}    //录制模式、展示模式
          styleUpdate={this.newProps.styleUpdate}
          dataUpdate={this.state.systemComponentDateUpdate}
          contentMsg={contentMsg}
          inputArgValues={inputArgValues}
        />
    }

    //给参数内容设置新的值
    setValueToParameter(newValue){
        if(!this.parameterBoxRef){
            message.warning("初始化关联错误，无法传值");
            return;
        }
        if(!this.parameterBoxRef.setValue){
            message.warning("该参数容器不支持被动接收数据");
            return;
        }
        this.parameterBoxRef.setValue(newValue)
    }


    //为视图类型填充数据
    initDataForView(resultData, dataSetDetail) {
        let {contentMsg} = this.newProps;
        switch (contentMsg.dataConfig.contentType) {
            case "table":
                //特殊处理一下
                let newResultData;
                if (Array.isArray(resultData) && resultData.length === 1 && resultData[0].hasOwnProperty("total") && resultData[0].hasOwnProperty("list") && Array.isArray(resultData[0].list)) {
                    newResultData = resultData[0].list;

                    //这里应该是以前的处理外层分页组件的逻辑，以后可能要改成在本组件处理，不需要往外传
                    this.newProps.loadFinish?.(newResultData, resultData[0].total);
                } else {
                    newResultData = resultData;

                    this.newProps.loadFinish && this.newProps.loadFinish?.(newResultData, -1);
                }

                this.viewBoxRef?.showValue(newResultData, null);
                break;
          // case "map":
          //     //特殊处理一下
          //     let extraOption = dataSetDetail?.configs?.extraOption || {};
          //     //地图类型，进行地图参数的检查，如果不存在地图参数配置
          //     let map = extraOption.map;
          //     let error = null;
          //     if (!map) {
          //         error = ("请进行地图配置，声明【经度，纬度】数据所在位置")
          //     } else if (!map.lng) {
          //         error = ("请进行地图配置，声明【经度】所在位置")
          //     } else if (!map.lat) {
          //         error = ("请进行地图配置，声明【纬度】所在位置")
          //     } else if (!Array.isArray(resultData)) {
          //         error = ("不是一个有效的数据")
          //     }
          //     if (error) {
          //         this.viewBoxRef?.renderError(error)
          //     } else {
          //         let devices = []
          //         for (let item of resultData) {
          //             devices.push({
          //                 noDevice: !map.isDevice,
          //                 _id: item[map.name],
          //                 icon: item[map.icon],
          //                 lng: item[map.lng],
          //                 lat: item[map.lat],
          //             })
          //         }
          //         console.log("this.devices", devices)
          //         this.viewBoxRef?.renderDataToMap({
          //             devices: devices,
          //             zoom: 1,
          //             selAreas: extraOption.selAreas,
          //             centerArea: extraOption.centerArea,
          //         })
          //     }
          //     break;
            default:
                this.viewBoxRef?.showValue(resultData);
        }
    }

    //自定义组件
    initDataForCustomView(resultData){
        let {contentMsg} = this.newProps;
        switch (contentMsg.dataConfig.contentType) {
            case "table":
                //特殊处理一下
                let newResultData;
                if (Array.isArray(resultData) && resultData.length === 1 && resultData[0].hasOwnProperty("total") && resultData[0].hasOwnProperty("list") && Array.isArray(resultData[0].list)) {
                    newResultData = resultData[0].list;

                    //这里应该是以前的处理外层分页组件的逻辑，以后可能要改成在本组件处理，不需要往外传
                    this.newProps.loadFinish?.(newResultData, resultData[0].total);
                } else {
                    newResultData = resultData;

                    this.newProps.loadFinish && this.newProps.loadFinish?.(newResultData, -1);
                }

                this.customViewBoxRef?.showValue(newResultData, null);
                break;
            default:
                this.customViewBoxRef?.showValue(resultData);
        }
    }
}