import React, { useEffect, useState } from 'react';
import { request } from '../../../../../../tool/http'
import styles from "./DigitalHumanDataSourceCount.less";
import { Card, Flex, Space, } from "antd";

const DigitalHumanApiUrls: { [key: string]: string } = {
  'total': 'digitalHuman/getDigitalHumanCount',
  'onlineAndOffline': 'digitalHuman/onlineToOfflineRatio',
  'idleAndWork': 'digitalHuman/idleToWorkRatio'
}

const DigitalHumanDataSourceCount: React.FC<any> = (props) => {
  console.error(props)
  const [errorMsg, setErrorMsg] = useState(null)
  const [errorMsg1, setErrorMsg1] = useState(null)
  const [errorMsg2, setErrorMsg2] = useState(null)

  const [total, setTotal] = useState()
  const [onlineAndOffline, setOnlineAndOffline] = useState<{
    offline: number;
    online: number;
    offlinePrecent: number;
    onlinePrecent: number;
  }>()
  const [workAndIdle, setWorkAndIdle] = useState<{
    idle: number;
    work: number;
    workPrecent: number;
    idlePrecent: number;
  }>()

  useEffect(() => {
    // 数字人总数统计
    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('total')) {
      request(`${props.contentMsg?.proConfig?.apiPrefix}${DigitalHumanApiUrls['total']}`, {
        data: {
          businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
          businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        }
      }).then(res => {
        setErrorMsg(null)
        if (res.code === 200) {
          setTotal(res?.data)
        } else {
          setErrorMsg(res?.message)
        }
      }).catch(err => {
        setErrorMsg(err.toString())
      })
    }

    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('onlineAndOffline')) {
      request(`${props.contentMsg?.proConfig?.apiPrefix}${DigitalHumanApiUrls['onlineAndOffline']}`, {
        data: {
          businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
          businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        }
      }).then(res => {
        setErrorMsg1(null)
        if (res.code === 200) {
          setOnlineAndOffline({
            ...res?.data,
            offlinePrecent: Math.round(res?.data?.offline * 100 / (res?.data?.online + res?.data?.offline)),
            onlinePrecent: Math.round(res?.data?.online * 100 / (res?.data?.online + res?.data?.offline)),
          })
        } else {
          setErrorMsg1(res?.message)
        }
      }).catch(err => {
        setErrorMsg1(err.toString())
      })
    }

    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('idleAndWork')) {
      request(`${props.contentMsg?.proConfig?.apiPrefix}${DigitalHumanApiUrls['idleAndWork']}`, {
        data: {
          businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
          businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        }
      }).then(res => {
        setErrorMsg2(null)
        if (res.code === 200) {
          setWorkAndIdle({
            ...res?.data,
            workPrecent: Math.round(res?.data?.work * 100 / (res?.data?.work + res?.data?.idle)),
            idlePrecent: Math.round(res?.data?.idle * 100 / (res?.data?.work + res?.data?.idle)),
          })
        } else {
          setErrorMsg2(res?.message)
        }
      }).catch(err => {
        setErrorMsg2(err.toString())
      })
    }
  }, [JSON.stringify(props.contentMsg?.dataConfig?.contentParams?.typeShowSel)])

  return <div className={styles.outer + " swiper-no-swiping"}>
    {
      <div className={styles.inner}>
        <Flex vertical gap={12} style={{ width: '100%', height: '100%' }} justify='space-between'>
          {
            props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('total') ?
              <Flex vertical align='center'>
                <div style={{ fontSize: '1rem' }}>数字人总数</div>
                <div style={{ fontSize: '1.5rem' }}>{total || 0}人</div>
              </Flex> : null
          }

          {
            props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('onlineAndOffline') ?
              <Flex vertical gap={12}>
                <Flex justify='space-between'>
                  <Space style={{ flex: 1 }} direction='vertical'>
                    <span>在线人数</span>
                    <div>{onlineAndOffline?.online}人</div>
                  </Space>
                  <Space style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }} direction='vertical'>
                    <span>离线人数</span>
                    <div>{onlineAndOffline?.offline}人</div>
                  </Space>
                </Flex>
                <Flex gap={10} align={"center"} justify={"space-between"}>
                  <div className={styles.barBox}>
                    <div
                      className={styles.onlineBar}
                      style={onlineAndOffline?.online ? {
                        flex: onlineAndOffline?.onlinePrecent,
                      } : {
                        flex: onlineAndOffline?.onlinePrecent,
                        backgroundColor: "transparent"
                      }}
                    >
                      {onlineAndOffline?.onlinePrecent ? onlineAndOffline?.onlinePrecent + '%' : 0}
                    </div>
                    <div
                      className={styles.offlineBar}
                      style={onlineAndOffline?.offline ? {
                        flex: onlineAndOffline?.offlinePrecent,
                      } : {
                        flex: onlineAndOffline?.offlinePrecent,
                        backgroundColor: "transparent"
                      }}
                    >
                      {onlineAndOffline?.offlinePrecent ? onlineAndOffline?.offlinePrecent + '%' : 0}
                    </div>
                  </div>
                </Flex>
              </Flex> : null
          }

          {
            props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('idleAndWork') ?
              <Flex vertical gap={12}>
                <Flex>
                  <Space style={{ flex: 1 }} direction='vertical'>
                    <span>忙碌人数</span>
                    <div>{workAndIdle?.work}人</div>
                  </Space>
                  <Space style={{ flex: 1, display: 'flex', alignItems: 'flex-end' }} direction='vertical'>
                    <span>空闲人数</span>
                    <div>{workAndIdle?.idle}人</div>
                  </Space>
                </Flex>

                <Flex gap={10} align={"center"} justify={"space-between"}>
                  <div className={styles.barBox}>
                    <div
                      className={styles.workBar}
                      style={workAndIdle?.work ? {
                        flex: workAndIdle?.workPrecent,
                      } : {
                        flex: workAndIdle?.workPrecent,
                        backgroundColor: "transparent"
                      }}
                    >
                      {workAndIdle?.workPrecent ? workAndIdle?.workPrecent + '%' : 0}
                    </div>
                    <div
                      className={styles.idleBar}
                      style={workAndIdle?.idle ? {
                        flex: workAndIdle?.idlePrecent,
                      } : {
                        flex: workAndIdle?.idlePrecent,
                        backgroundColor: "transparent"
                      }}
                    >
                      {workAndIdle?.idlePrecent ? workAndIdle?.idlePrecent + '%' : 0}
                    </div>
                  </div>
                </Flex>
              </Flex> : null
          }
        </Flex>
      </div>
    }
  </div>
}


export default DigitalHumanDataSourceCount