import React, { memo, useEffect, useState } from 'react'
import styles from './distributionLogRanking.less'
import { Flex, Select, Statistic } from 'antd'
import { request } from '../../../../../../tool/http'
// import HumanChartTwo from '@/pages/containers/dashboard/contentView/view/showEchart/humanChartTwo/humanChartTwo'


function DistributionLogRanking(props: any) {
  console.log('每天的在线人数---展示', props)
  // const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1])
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(30)
  const [data, setData] = useState<any>([])
  const [option, setOption] = useState<any>({})
  const [size, setSize] = useState(10)
  const [showTypeCur, setShowTypeCur] = useState(showType?.[0] || 0)
  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg('')
    request(`${props.contentMsg?.proConfig?.apiPrefix}/taskStatistics/getRanking`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time,
        size,
        group: showTypeCur === 1 ? 'spaceId' : 'execUnitId'
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }
  useEffect(() => {
    getBusinessData()
  }, [time, props.dataUpdate, showTypeCur, size])

  //获取图表数据
  const getOption = () => {
    let yAxis = [
      {
        color: '#5470C6',
        chartTitle: '在线人数',
        data: data.map((item: any) => item.name)
      }
    ]
    //对应数据字段
    let dataKey: any = {
      running: '执行中',
      success: '成功',
      fail: '失败',
      noStart: '未开始',
      distribute: '已分发',
      timeout: '超时',
      noExec: '未执行'
    }
    let dataFilter = [] as any[]
    Object.keys(dataKey).forEach((key: any) => {
      dataFilter.push({
        _id: dataKey[key],
        count: data.map((item: any) => item[key] || 0)
      })
    })
    console.log('dataFilter排名--', dataFilter)

    let series = dataFilter.map((item: any) => {
      return {
        name: item._id,
        type: 'bar',
        xAxisIndex: 0,
        data: item.count,
      }
    })
    let option = {
      radar: props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanOnlineAnalysisAllTwo',
      yAxis,
      series
    }
    setOption(option)
  }
  useEffect(() => {
    console.log('分发日志排名--', data)
    if (data.length > 0) {
      getOption()
    }
  }, [data])

  let showTypeCurList = [] as any[]
  if (showType.includes(1) && showType.includes(2)) {
    showTypeCurList = [
      { label: "空间排名", value: 1 },
      { label: "执行单元排名", value: 2 },
    ]
  } else if (showType.includes(1)) {
    showTypeCurList = [
      { label: "空间排名", value: 1 },
    ]
  } else if (showType.includes(2)) {
    showTypeCurList = [
      { label: "执行单元排名", value: 2 },
    ]
  }
  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      <div
        className={styles.chartBox}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex style={{ marginBottom: '16px' }}>
                <Flex justify='end' flex={1} className={" swiper-no-swiping"} gap={16}>
                  <Select
                    style={{ width: "120px" }}
                    value={showTypeCur}
                    onChange={(v) => {
                      setShowTypeCur(v)
                    }}
                    options={showTypeCurList}
                  >
                  </Select>
                  <Select
                    style={{ width: "120px" }}
                    value={time}
                    onChange={(v) => {
                      setTime(v)
                    }}
                    options={[
                      { label: "今日", value: 1 },
                      { label: "30日", value: 30 },
                      { label: "15日", value: 15 },
                      { label: "7日", value: 7 },
                    ]}
                  >
                  </Select>
                  <Select
                    style={{ width: "120px" }}
                    value={size}
                    onChange={(v) => {
                      setSize(v)
                    }}
                    options={[
                      { label: "10条", value: 10 },
                      { label: "5条", value: 5 },
                    ]}
                  >
                  </Select>
                </Flex>
              </Flex>
              <HumanChartTwo option={option}></HumanChartTwo>
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(DistributionLogRanking)