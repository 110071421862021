import React, { memo, useEffect, useState } from 'react'
import styles from './taskLogAllOverview.less'
import { Flex, Statistic } from 'antd'
import { request } from '../../../../../../tool/http'
function TaskLogAllOverview(props: any) {
  // console.log('每天的在线人数11111---展示', props)
  const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1])
  // let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(1)
  const [data, setData] = useState<any>([])

  //分发日志总数
  const [totalLog, setTotalLog] = useState(0)

  //任务分发日志数量  单次任务 周期任务日志
  //周期任务日志数量
  const [cycleLogTask, setCycleLogTask] = useState(0)
  //单次任务日志数量
  const [singleLogTask, setSingleLogTask] = useState(0)

  //运行中分发日志数量
  const [runningLog, setRunningLog] = useState(0)

  //任务数量
  //单次任务数量
  const [singleTaskCount, setSingleTaskCount] = useState(0)
  //周期任务数量
  const [cycleTaskCount, setCycleTaskCount] = useState(0)
  const [taskCount, setTaskCount] = useState(0)

  //触发任务和期次数量
  const [triggerTaskCount, setTriggerTaskCount] = useState(0)
  const [triggerPlanCount, setTriggerPlanCount] = useState(0)

  //进行中任务数量
  //进行中期次数量
  const [runningTriggerPlanCount, setRunningTriggerPlanCount] = useState(0)
  const [runningTriggerTaskCount, setRunningTriggerTaskCount] = useState(0)

  //请求接口
  const getBusinessDataQuery = async (url?: any) => {
    try {
      let data = await request(`${props.contentMsg?.proConfig?.apiPrefix}${url}`, {
        data: {
          businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
          businessId: props.contentMsg?.proConfig?.usageParams?.businessId ? [props.contentMsg?.proConfig?.usageParams?.businessId] : null,
        }
      })
      if (data.code === 200) {
        return data.data
      } else {
        setErrorMsg(data.message)
      }
    } catch (error: any) {
      setErrorMsg(error.toString())
    }

  }


  const getBusinessData = (showType?: any) => {
    switch (showType) {
      case 1:
        getBusinessDataQuery('taskStatistics/getTaskCount').then((res: any) => {
          setTotalLog(res)
        })
        break;
      case 2:
        getBusinessDataQuery('taskStatistics/getPeriodCount').then((res: any) => {
          setSingleLogTask(res?.trigger)
          setCycleLogTask(res?.plan)
        })
        break;
      case 3:
        getBusinessDataQuery('taskStatistics/getRunningCount').then((res: any) => {
          setRunningLog(res)
        })
        break;
      case 4:
        getBusinessDataQuery('taskTrigger/getTaskCount').then((res: any) => {
          setTaskCount(res?.total)
          setSingleTaskCount(res?.trigger)
          setCycleTaskCount(res?.plan)
        })
        break;
      case 5:
        getBusinessDataQuery('taskTrigger/getPeriodCount').then((res: any) => {
          setTriggerPlanCount(res?.plan)
          setTriggerTaskCount(res?.trigger)
        })
        break;
      case 6:
        getBusinessDataQuery('taskTrigger/getRunningCount').then((res: any) => {
          setRunningTriggerPlanCount(res?.plan)
          setRunningTriggerTaskCount(res?.trigger)
        })
        break;
    }
  }

  // 使用 useEffect 监听 props.contentMsg?.dataConfig?.contentParams?.typeShowSel 的变化
  useEffect(() => {
    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel !== showType) {
      // 如果 props 发生了变化，更新本地状态并触发接口调用
      setShowType(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]);
    }
  }, [props.contentMsg?.dataConfig?.contentParams?.typeShowSel]); // 监听 props 中的这个值

  useEffect(() => {
    if (showType.length > 0) {
      showType.forEach((item: any) => {
        getBusinessData(item)
      })
    }
  }, [showType, props.dataUpdate])


  return (
    <div className={styles.outer}>
      <div
        className={styles.chartBox}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex gap={16} wrap style={{ padding: '0 16px' }}>
                {showType.includes(1) &&
                  <Flex className={styles.quantityBulk} justify='center' align='center'>
                    <Statistic title="分发日志总数" value={totalLog} suffix="次" />
                  </Flex>
                }
                {showType.includes(2) &&
                  <Flex className={styles.quantityBulkTwo} justify='center' align='center' gap={24}>
                    <Statistic title="单次任务日志" value={singleLogTask} suffix="次" />
                    <Statistic title="周期任务日志" value={cycleLogTask} suffix="次" />
                  </Flex>
                }
                {showType.includes(3) &&
                  <Flex className={styles.quantityBulkTwo} justify='center' align='center' gap={24}>
                    <Statistic title="运行中分发日志" value={runningLog} suffix="次" />
                  </Flex>
                }
              </Flex>
              <Flex gap={16} wrap style={{ padding: '0 16px' }} >
                {showType.includes(4) &&
                  <Flex vertical className={`${styles.quantityBulkTwo} ${styles.taskCountAll}`} justify='center' align='center' style={{ padding: '10px 20px' }}>
                    <Statistic title="任务总数" valueStyle={{ fontSize: 20 }} value={taskCount} suffix="个" />
                    <Flex>
                      <Statistic title="单次任务数" valueStyle={{ fontSize: 16 }} style={{ marginRight: 16, fontSize: 12 }} value={singleTaskCount} suffix="个" />
                      <Statistic title="周期任务数" valueStyle={{ fontSize: 16 }} value={cycleTaskCount} suffix="个" />
                    </Flex>
                  </Flex>
                }
                {showType.includes(5) &&
                  <Flex className={styles.quantityBulkThree} justify='center' align='center' gap={24}>
                    <Statistic title="触发任务数" value={triggerTaskCount} suffix="个" />
                    <Statistic title="周期任务期次" value={triggerPlanCount} suffix="个" />
                  </Flex>
                }
                {showType.includes(6) &&
                  <Flex className={styles.quantityBulkThree} justify='center' align='center' gap={24}>
                    <Statistic title="进行中触发器任务" value={runningTriggerTaskCount} suffix="个" />
                    <Statistic title="进行中期次" value={runningTriggerPlanCount} suffix="个" />
                  </Flex>
                }
              </Flex>
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(TaskLogAllOverview)