import React from "react";
import {
  PieChartOutlined,
  TableOutlined,
  BarChartOutlined,
  LineChartOutlined,
  GlobalOutlined,
  AreaChartOutlined,
  RadarChartOutlined,
} from "@ant-design/icons";

import PutDataSourceCount from "./putDataSourceCount/PutDataSourceCount.js";
import PutDataSourceCountSet from "./putDataSourceCount/PutDataSourceCountSet.js";
import PutDataSourceMsg from "./putDataSourceMsg/PutDataSourceMsg.js";
import PutDataSourceMsgSet from "./putDataSourceMsg/PutDataSourceMsgSet.js";
import DigitalHumanAreaMap from "./digitalHumanAreaMap/DigitalHumanAreaMap.js";
import DigitalHumanAreaMapSet from "./digitalHumanAreaMap/DigitalHumanAreaMapSet.js";
import DigitalHumanAreaAna from "./digitalHumanAreaAna/DigitalHumanAreaAna.js";
import DigitalHumanAreaAnaSet from "./digitalHumanAreaAna/DigitalHumanAreaAnaSet.js";
import DigitalHumanAreaBarAna from "./digitalHumanGenderAnaBar/DigitalHumanAreaBarAna.js";
import DigitalHumanAreaAnaBarSet from "./digitalHumanGenderAnaBar/DigitalHumanAreaAnaBarSet.js";
import DigitalHumanAreaPieAna from "./digitalHumanGenderAnaPie/DigitalHumanAreaPieAna.js";
import DigitalHumanAreaAnaPieSet from "./digitalHumanGenderAnaPie/DigitalHumanAreaAnaPieSet.js";
import DigitalHumanSpaceAnaBar from "./digitalHumanSpaceAnaBar/DigitalHumanSpaceAnaBar.js";
import DigitalHumanSpaceAnaBarSet from "./digitalHumanSpaceAnaBar/DigitalHumanSpaceAnaBarSet.js";
import DigitalHumanSpaceAnaLine from "./digitalHumanSpaceAnaLine/DigitalHumanSpaceAnaLine.js";
import DigitalHumanSpaceAnaLineSet from "./digitalHumanSpaceAnaLine/DigitalHumanSpaceAnaLineSet.js";
import DigitalHumanUnitAnaBar from "./digitalHumanUnitAnaBar/DigitalHumanUnitAnaBar.js";
import DigitalHumanUnitAnaBarSet from "./digitalHumanUnitAnaBar/DigitalHumanUnitAnaBarSet.js";
import DigitalHumanUnitAnaLine from "./digitalHumanUnitAnaLine/DigitalHumanUnitAnaLine.js";
import DigitalHumanUnitAnaLineSet from "./digitalHumanUnitAnaLine/DigitalHumanUnitAnaLineSet.js";
import DigitalHumanOnlineTimeRatio from "./digitalHumanOnlineTimeRatio/DigitalHumanOnlineTimeRatio.js";
import DigitalHumanOnlineTimeRatioSet from "./digitalHumanOnlineTimeRatio/DigitalHumanOnlineTimeRatioSet.js";
import DigitalHumanAvgMaxTimeRatio from "./digitalHumanAvgMaxTimeRatio/DigitalHumanAvgMaxTimeRatio.js";
import DigitalHumanAvgMaxTimeRatioSet from "./digitalHumanAvgMaxTimeRatio/DigitalHumanAvgMaxTimeRatioSet.js";

import PositionDigitalHuamnCountSet from "./positionDigitalHumanCount/PositionDigitalHumanCountSet.tsx";
import PositionDigitalHumanCount from "./positionDigitalHumanCount/PositionDigitalHumanCount.tsx";
import UnitsCount from "./unitsCount/UnitsCount.tsx";
import UnitsCountSet from "./unitsCount/UnitsCountSet.tsx";
import UnitRefRanking from "./unitRefRanking/UnitRefRanking.tsx";
import UnitRefRankingSet from "./unitRefRanking/UnitRefRankingSet.tsx";
import PositionLogCount from "./positionLogCount/PositionLogCount.tsx";
import PositionLogCountSet from "./positionLogCount/PositionLogCountSet.tsx";
import PositionTaskCount from "./positionTaskCount/PositionTaskCount.tsx";
import PositionTaskCountSet from "./positionTaskCount/PositionTaskCountSet.tsx";
import PutDataSourceTime from './putDataSourceTime/PutDataSourceTime.tsx'
import PutDataSourceTimeSet from './putDataSourceTime/PutDataSourceTimeSet.tsx'
import DigitalHumanDataSourceCount from './digitalHumanDataSourceCount/DigitalHumanDataSourceCount.tsx'
import DigitalHumanDataSourceCountSet from './digitalHumanDataSourceCount/DigitalHumanDataSourceCountSet.tsx'
import DigitalHumanDataSourcePie from "./digitalHumanDataSourcePie/DigitalHumanDataSourcePie.tsx";
import DigitalHumanDataSourcePieSet from './digitalHumanDataSourcePie/DigitalHumanDataSourcePieSet.tsx';
import DigitalHumanDataSourceBar from "./digitalHumanDataSourceBar/DigitalHumanDataSourceBar.tsx";
import DigitalHumanDataSourceBarSet from "./digitalHumanDataSourceBar/DigitalHumanDataSourceBarSet.tsx";
import DigitalHumanOnlineOfflineRatioPie from "./digitalHumanOnlineOfflineRatioPie/DigitalHumanOnlineOfflineRatioPie.tsx";
import DigitalHumanOnlineOfflineRatioPieSet from "./digitalHumanOnlineOfflineRatioPie/DigitalHumanOnlineOfflineRatioPieSet.tsx";
import DigitalHumanDevicePie from "./digitalHumanDevicePie/DigitalHumanDevicePie.tsx";
import DigitalHumanDevicePieSet from "./digitalHumanDevicePie/DigitalHumanDevicePieSet.tsx";
import DigitalHumanDeviceBar from "./digitalHumanDeviceBar/DigitalHumanDeviceBar.tsx";
import DigitalHumanDeviceBarSet from "./digitalHumanDeviceBar/DigitalHumanDeviceBarSet.tsx";
import DigitalHumanDeviceTypeBar from './digitalHumanDeviceTypeBar/DigitalHumanDeviceTypeBar.tsx'
import DigitalHumanDeviceTypeBarSet from "./digitalHumanDeviceTypeBar/DigitalHumanDeviceTypeBarSet.tsx";
import DigitalHumanLoadRate from "./digitalHumanLoadRate/DigitalHumanLoadRate.tsx";
import DigitalHumanLoadRateSet from './digitalHumanLoadRate/DigitalHumanLoadRateSet.tsx'
import DigitalHumanWorkTimeRate from './digitalHumanWorkTimeRate/DigitalHumanWorkTimeRate.tsx'
import DigitalHumanWorkTimeRateSet from './digitalHumanWorkTimeRate/DigitalHumanWorkTimeRateSet.tsx'
import DigitalHumanUnitWorkTime from './digitalHumanUnitWorkTime/DigitalHumanUnitWorkTime.tsx'
import DigitalHumanUnitWorkTimeSet from './digitalHumanUnitWorkTime/DigitalHumanUnitWorkTimeSet.tsx'

import DigitalHumanOnlineAnalysisOne from "./digitalHumanOnlineAnalysisOne/digitalHumanOnlineAnalysisOne.tsx";
import DigitalHumanOnlineAnalysisOneSet from "./digitalHumanOnlineAnalysisOne/digitalHumanOnlineAnalysisOneSet.tsx";
import DigitalHumanOnlineAnalysis24 from "./digitalHumanOnlineAnalysis24/digitalHumanOnlineAnalysis24.tsx"
import DigitalHumanOnlineAnalysis24Set from "./digitalHumanOnlineAnalysis24/digitalHumanOnlineAnalysis24Set.tsx"
import DigitalHumanOnlineAnalysisAll from "./digitalHumanOnlineAnalysisAll/digitalHumanOnlineAnalysisAll.tsx"
import DigitalHumanOnlineAnalysisAllSet from "./digitalHumanOnlineAnalysisAll/digitalHumanOnlineAnalysisAllSet.tsx"
import DigitalHumanGoOnline from "./digitalHumanGoOnline/digitalHumanGoOnline.tsx"
import DigitalHumanGoOnlineSet from "./digitalHumanGoOnline/digitalHumanGoOnlineSet.tsx"
import DigitalPersonLongOffline from "./digitalPersonLongOffline/digitalPersonLongOffline.tsx"
import DigitalPersonLongOfflineSet from "./digitalPersonLongOffline/digitalPersonLongOfflineSet.tsx"
import TaskLogAllOverview from "./taskLogAllOverview/taskLogAllOverview.tsx"
import TaskLogAllOverviewSet from "./taskLogAllOverview/taskLogAllOverviewSet.tsx"
import TaskLogStatistics from "./taskLogStatistics/taskLogStatistics.tsx"
import TaskLogStatisticsSet from "./taskLogStatistics/taskLogStatisticsSet.tsx"
import TaskDeviceStatistics from "./taskDeviceStatistics/taskDeviceStatistics.tsx"
import TaskDeviceStatisticsSet from "./taskDeviceStatistics/taskDeviceStatisticsSet.tsx"
import TaskLogProportion from "./taskLogProportion/taskLogProportion.tsx"
import TaskLogProportionSet from "./taskLogProportion/taskLogProportionSet.tsx"
import TaskDeviceProportion from "./taskDeviceProportion/taskDeviceProportion.tsx"
import TaskDeviceProportionSet from "./taskDeviceProportion/taskDeviceProportionSet.tsx"
import TaskStatistics from "./taskStatistics/taskStatistics.tsx"
import TaskStatisticsSet from "./taskStatistics/taskStatisticsSet.tsx"
import TaskLogStatisticsTwo from "./taskLogStatisticsTwo/taskLogStatisticsTwo.tsx"
import TaskLogStatisticsTwoSet from "./taskLogStatisticsTwo/taskLogStatisticsTwoSet.tsx"
import TaskList from "./taskList/taskList.tsx"
import TaskListSet from "./taskList/taskListSet.tsx"
import DistributionLogRanking from "./distributionLogRanking/distributionLogRanking.tsx"
import DistributionLogRankingSet from "./distributionLogRanking/distributionLogRankingSet.tsx"
import DistributionLogRankingTwo from "./distributionLogRankingTwo/distributionLogRankingTwo.tsx"
import DistributionLogRankingTwoSet from "./distributionLogRankingTwo/distributionLogRankingTwoSet.tsx"
import DistributionLogDigitalManRanking from "./distributionLogDigitalManRanking/distributionLogDigitalManRanking.tsx"
import DistributionLogDigitalManRankingSet from "./distributionLogDigitalManRanking/distributionLogDigitalManRankingSet.tsx"
import TaskLogDistribution from "./taskLogDistribution/taskLogDistribution.tsx"
import TaskLogDistributionSet from "./taskLogDistribution/taskLogDistributionSet.tsx"
import TaskLogSpaceDistribution from "./taskLogSpaceDistribution/taskLogSpaceDistribution.tsx"
import TaskLogSpaceDistributionSet from "./taskLogSpaceDistribution/taskLogSpaceDistributionSet.tsx"
import TaskLogUnitDistribution from "./taskLogUnitDistribution/taskLogUnitDistribution.tsx"
import TaskLogUnitDistributionSet from "./taskLogUnitDistribution/taskLogUnitDistributionSet.tsx"
import DistributionTaskTimeAnalysis from "./distributionTaskTimeAnalysis/distributionTaskTimeAnalysis.tsx"
import DistributionTaskTimeAnalysisSet from "./distributionTaskTimeAnalysis/distributionTaskTimeAnalysisSet.tsx"
import DistributionTaskTimeAnalysisOther from "./distributionTaskTimeAnalysisOther/distributionTaskTimeAnalysisOther.tsx"
import DistributionTaskTimeAnalysisOtherSet from "./distributionTaskTimeAnalysisOther/distributionTaskTimeAnalysisOtherSet.tsx"
import DistributionLogDuration from "./distributionLogDuration/distributionLogDuration.tsx"
import DistributionLogDurationSet from "./distributionLogDuration/distributionLogDurationSet.tsx"
import TaskOccupancyDistribution from "./taskOccupancyDistribution/taskOccupancyDistribution.tsx"
import TaskOccupancyDistributionSet from "./taskOccupancyDistribution/taskOccupancyDistributionSet.tsx"
import DigitalHumanWokingAnalysis from "./digitalHumanWokingAnalysis/digitalHumanWokingAnalysis.tsx"
import DigitalHumanWokingAnalysisSet from "./digitalHumanWokingAnalysis/digitalHumanWokingAnalysisSet.tsx"

let COVER_URL = "https://cyber-resource.fwgcloud.com/platform/system-cover/"

//系统组件内容的注册
const SystemComponentContentArray = [
  {
    groupName: "执行单元相关",  //分组名称
    children: [
      {
        title: '岗位数字人分布',   //名称
        type: "system_PositionDigitalHumanCount",   //匹配类型
        icon: <PieChartOutlined />,  //小图标
        cover: require("./positionDigitalHumanCount/cover.png"),  //封面图
        renderFile: PositionDigitalHumanCount, //渲染视图文件
        setFile: PositionDigitalHuamnCountSet,  //配置文件
        usageArray: ["space", "unit"],  //可以在哪些板块使用  company:公司,space:空间,unit:执行单元,device:数字人
        sizeConfig: {   //创建图后的默认容器占位大小
          w: 14, //宽度格子数量
          h: 12,  //高度格子数量
        }
      },
      {
        title: '执行单元数量',
        type: "system_UnitsCount",
        icon: <PieChartOutlined />,
        cover: require("./unitsCount/cover.png"),
        renderFile: UnitsCount,
        setFile: UnitsCountSet,
        usageArray: ["space", "company"],
        sizeConfig: {
          w: 14,
          h: 12,
        }
      },
      {
        title: '执行单元引用次数排行',
        type: "system_UnitsRefRanking",
        icon: <PieChartOutlined />,
        cover: require("./unitRefRanking/cover.png"),
        renderFile: UnitRefRanking,
        setFile: UnitRefRankingSet,
        usageArray: ["company"],
        sizeConfig: {
          w: 24,
          h: 24,
        }
      },
      {
        title: '岗位分发日志分布',
        type: "system_PositionLogCount",
        icon: <PieChartOutlined />,
        cover: require("./positionLogCount/cover.png"),
        renderFile: PositionLogCount,
        setFile: PositionLogCountSet,
        usageArray: ["unit"],
        sizeConfig: {
          w: 24,
          h: 23,
        }
      },
      {
        title: '岗位任务分布',
        type: "system_PositionTaskCount",
        icon: <PieChartOutlined />,
        cover: require("./positionTaskCount/cover.png"),
        renderFile: PositionTaskCount,
        setFile: PositionTaskCountSet,
        usageArray: ["unit"],
        sizeConfig: {
          w: 24,
          h: 23,
        }
      },
      {
        title: '输入输出数据添加时间分布',
        type: "system_PutDataSourceTime",
        icon: <PieChartOutlined />,
        cover: require("./putDataSourceTime/cover.png"),
        renderFile: PutDataSourceTime,
        setFile: PutDataSourceTimeSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 24,
          h: 23,
        }
      },
      {
        title: '输入输出来源分布饼图',
        type: "system_putDataSourceCount",
        icon: <PieChartOutlined />,
        cover: require("./putDataSourceCount/cover.png"),
        renderFile: PutDataSourceCount,
        setFile: PutDataSourceCountSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 18,
          h: 20,
        }
      },
      {
        title: '输入输出来源分布表格',
        type: "system_putDataSourceMsg",
        icon: <TableOutlined />,
        cover: require("./putDataSourceMsg/cover.png"),
        renderFile: PutDataSourceMsg,
        setFile: PutDataSourceMsgSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 24,
          h: 20,
        }
      },
    ]
  },
  {
    groupName: "数字人及设备数量分析相关",
    children: [
      {
        title: '数字人数据总览',
        type: "system_DigitalHumanDataSourceCount1",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanDataSourceCount/cover.png"),
        renderFile: DigitalHumanDataSourceCount,
        setFile: DigitalHumanDataSourceCountSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 20,
          h: 24,
        }
      },
      {
        title: '数字人数据总览饼图',
        type: "system_DigitalHumanDataSourcePie",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanDataSourcePie/cover.png"),
        renderFile: DigitalHumanDataSourcePie,
        setFile: DigitalHumanDataSourcePieSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 20,
          h: 20,
        }
      },
      {
        title: '数字人状态数据总览柱状图',
        type: "system_DigitalHumanDataSourceBar",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanDataSourceBar/cover.png"),
        renderFile: DigitalHumanDataSourceBar,
        setFile: DigitalHumanDataSourceBarSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 20,
          h: 20,
        }
      },
      {
        title: '数字人在线离线比例饼图',
        type: "system_DigitalHumanOnlineOfflineRatioPie",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanOnlineOfflineRatioPie/cover.png"),
        renderFile: DigitalHumanOnlineOfflineRatioPie,
        setFile: DigitalHumanOnlineOfflineRatioPieSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 20,
          h: 20,
        }
      },
      {
        title: '工作设备数据总览饼图',
        type: "system_DigitalHumanDevicePie",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanDevicePie/cover.png"),
        renderFile: DigitalHumanDevicePie,
        setFile: DigitalHumanDevicePieSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 20,
          h: 30,
        }
      },
      {
        title: '工作设备数据总览2',
        type: "system_DigitalHumanDeviceBar",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanDeviceBar/cover.png"),
        renderFile: DigitalHumanDeviceBar,
        setFile: DigitalHumanDeviceBarSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 20,
          h: 30,
        }
      },
      {
        title: '工作设备设备类型占比',
        type: "system_DigitalHumanDeviceTypeBar",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanDeviceTypeBar/cover.png"),
        renderFile: DigitalHumanDeviceTypeBar,
        setFile: DigitalHumanDeviceTypeBarSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 20,
          h: 20,
        }
      },
    ]
  },
  {
    groupName: "数字人分布分析相关",
    children: [
      {
        title: '数字人地区分布地图',
        type: "system_digitalHumanAreaMap",
        icon: <GlobalOutlined />,
        cover: require("./digitalHumanAreaMap/cover.png"),
        renderFile: DigitalHumanAreaMap,
        setFile: DigitalHumanAreaMapSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 20,
          h: 16,
        }
      },
      {
        title: '数字人地区分布柱状图',
        type: "system_digitalHumanAreaAna",
        icon: <BarChartOutlined />,
        cover: require("./digitalHumanAreaAna/cover.png"),
        renderFile: DigitalHumanAreaAna,
        setFile: DigitalHumanAreaAnaSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 14,
          h: 12,
        }
      },
      {
        title: '数字人性别分布对比图',
        type: "system_digitalHumanGenderBar",
        icon: <BarChartOutlined />,
        cover: require("./digitalHumanGenderAnaBar/cover.png"),
        renderFile: DigitalHumanAreaBarAna,
        setFile: DigitalHumanAreaAnaBarSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 12,
          h: 4,
        }
      },
      {
        title: '数字人性别分布饼图',
        type: "system_digitalHumanGenderPie",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanGenderAnaPie/cover.png"),
        renderFile: DigitalHumanAreaPieAna,
        setFile: DigitalHumanAreaAnaPieSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 12,
          h: 12,
        }
      },
      {
        title: '数字人空间分布柱状图',
        type: "system_digitalHumanSpaceAnaBar",
        icon: <BarChartOutlined />,
        cover: require("./digitalHumanSpaceAnaBar/cover.png"),
        renderFile: DigitalHumanSpaceAnaBar,
        setFile: DigitalHumanSpaceAnaBarSet,
        usageArray: ["company"],
        sizeConfig: {
          w: 14,
          h: 12,
        }
      },
      {
        title: '数字人空间分布折线图',
        type: "system_digitalHumanSpaceAnaLine",
        icon: <LineChartOutlined />,
        cover: require("./digitalHumanSpaceAnaLine/cover.png"),
        renderFile: DigitalHumanSpaceAnaLine,
        setFile: DigitalHumanSpaceAnaLineSet,
        usageArray: ["company"],
        sizeConfig: {
          w: 14,
          h: 12,
        }
      },
      {
        title: '数字人执行单元分布柱状图',
        type: "system_digitalHumanUnitAnaBar",
        icon: <BarChartOutlined />,
        cover: require("./digitalHumanUnitAnaBar/cover.png"),
        renderFile: DigitalHumanUnitAnaBar,
        setFile: DigitalHumanUnitAnaBarSet,
        usageArray: ["company", "space"],
        sizeConfig: {
          w: 14,
          h: 12,
        }
      },
      {
        title: '数字人执行单元分布折线图',
        type: "system_digitalHumanUnitAnaLine",
        icon: <LineChartOutlined />,
        cover: require("./digitalHumanUnitAnaLine/cover.png"),
        renderFile: DigitalHumanUnitAnaLine,
        setFile: DigitalHumanUnitAnaLineSet,
        usageArray: ["company", "space"],
        sizeConfig: {
          w: 14,
          h: 12,
        }
      },
    ]
  },
  {
    groupName: "数字人工作分析相关",
    children: [
      {
        title: '数字人平均在线离线分析',
        type: "system_digitalHumanOnlineTimeRatio",
        icon: <LineChartOutlined />,
        cover: require("./digitalHumanOnlineTimeRatio/cover.png"),
        renderFile: DigitalHumanOnlineTimeRatio,
        setFile: DigitalHumanOnlineTimeRatioSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 16,
          h: 18,
        }
      },
      {
        title: '数字人平均最高最低在线离线时长',
        type: "system_digitalHumanAvgMaxTimeRatio",
        icon: <LineChartOutlined />,
        cover: require("./digitalHumanAvgMaxTimeRatio/cover.png"),
        renderFile: DigitalHumanAvgMaxTimeRatio,
        setFile: DigitalHumanAvgMaxTimeRatioSet,
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 20,
          h: 24,
        }
      },
      {
        title: '数字人负载率',
        type: "system_DigitalHumanLoadRate",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanLoadRate/cover.png"),
        renderFile: DigitalHumanLoadRate,
        setFile: DigitalHumanLoadRateSet,
        usageArray: ["company", "space", "unit", "device"],
        sizeConfig: {
          w: 20,
          h: 24,
        }
      },
      {
        title: '数字人工作时间占比',
        type: "system_DigitalHumanWorkTimeRate",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanWorkTimeRate/cover.png"),
        renderFile: DigitalHumanWorkTimeRate,
        setFile: DigitalHumanWorkTimeRateSet,
        usageArray: ["company", "space", "unit", "device"],
        sizeConfig: {
          w: 17,
          h: 10,
        }
      },
      {
        title: '数字人执行单元工作时长',
        type: "system_DigitalHumanUnitWorkTime",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanUnitWorkTime/cover.png"),
        renderFile: DigitalHumanUnitWorkTime,
        setFile: DigitalHumanUnitWorkTimeSet,
        usageArray: ["company", "space", "device"],
        sizeConfig: {
          w: 20,
          h: 24,
        }
      },
      {
        title: '数字人执行单元工作时长2',
        type: "system_DigitalHumanUnitWorkTimeLine",
        icon: <PieChartOutlined />,
        cover: require("./digitalHumanUnitWorkTime/cover2.png"),
        renderFile: DigitalHumanUnitWorkTime,
        setFile: DigitalHumanUnitWorkTimeSet,
        usageArray: ["company", "space", "device"],
        sizeConfig: {
          w: 20,
          h: 24,
        }
      },
      {
        title: '任务占用数字人和设备分布',
        type: "system_taskOccupancyDistribution",
        icon: <LineChartOutlined />,
        cover: COVER_URL + "task-occupancy-distribution.png", 
        renderFile: TaskOccupancyDistribution,
        setFile: TaskOccupancyDistributionSet,
        usageArray: ["company", "space", "unit"], 
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '任务占用数字人和设备分布1',
        type: "system_taskOccupancyDistributionTwo",
        icon: <LineChartOutlined />,
        cover: COVER_URL + "task-occupancyDistribution-two.png", 
        renderFile: TaskOccupancyDistribution,
        setFile: TaskOccupancyDistributionSet, 
        usageArray: ["company", "space", "unit"],
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人每日工作情况分析', 
        type: "system_digitalHumanWokingAnalysis", 
        icon: <LineChartOutlined />, 
        cover: COVER_URL + "digitalHuman-woking-analysis.png", 
        renderFile: DigitalHumanWokingAnalysis,
        setFile: DigitalHumanWokingAnalysisSet, 
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
    ]
  },
  {
    groupName: "数字人在线上线分析相关",
    children: [
      {
        title: '数字人每天在线人数1',  
        type: "system_digitalHumanOnlineAnalysisOne",   
        icon: <AreaChartOutlined />,  
        cover: COVER_URL + "digitalHuman-online-analysis-one.png",  
        renderFile: DigitalHumanOnlineAnalysisOne, 
        setFile: DigitalHumanOnlineAnalysisOneSet, 
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人每天在线人数2',   
        type: "system_digitalHumanOnlineAnalysisTwo",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "digitalHuman-online-analysis-two.png",  
        renderFile: DigitalHumanOnlineAnalysisOne, 
        setFile: DigitalHumanOnlineAnalysisOneSet, 
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人每天在线人数3',   
        type: "system_digitalHumanOnlineAnalysisThree",   
        icon: <RadarChartOutlined />, 
        cover: COVER_URL + "digitalHuman-online-analysis-three.png",  
        renderFile: DigitalHumanOnlineAnalysisOne, 
        setFile: DigitalHumanOnlineAnalysisOneSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人24时平均在线人数',   
        type: "system_digitalHumanOnlineAnalysis24",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "digitalHuman-online-analysis24.png",  
        renderFile: DigitalHumanOnlineAnalysis24, 
        setFile: DigitalHumanOnlineAnalysis24Set, 
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人24时平均在线2',   
        type: "system_digitalHumanOnlineAnalysis24Two",   
        icon: <AreaChartOutlined />,  
        cover: COVER_URL + "digitalHuman-online-analysis24-two.png",  
        renderFile: DigitalHumanOnlineAnalysis24, 
        setFile: DigitalHumanOnlineAnalysis24Set,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人24时平均在线3',   
        type: "system_digitalHumanOnlineAnalysis24Three",   
        icon: <RadarChartOutlined />,  
        cover: COVER_URL + "digitalHuman-online-analysis24-three.png",  
        renderFile: DigitalHumanOnlineAnalysis24, 
        setFile: DigitalHumanOnlineAnalysis24Set, 
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人总在线时长分布',   
        type: "system_digitalHumanOnlineAnalysisAll",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "digitalHuman-online-analysis-all.png",  
        renderFile: DigitalHumanOnlineAnalysisAll, 
        setFile: DigitalHumanOnlineAnalysisAllSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人总在线时长分布1',   
        type: "system_digitalHumanOnlineAnalysisAllTwo",   
        icon: <RadarChartOutlined />,  
        cover: COVER_URL + "digitalHuman-online-analysis-all-two.png",  
        renderFile: DigitalHumanOnlineAnalysisAll, 
        setFile: DigitalHumanOnlineAnalysisAllSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人上线时间分布1',   
        type: "system_digitalHumanGoOnline",
        icon: <RadarChartOutlined />,  
        cover: COVER_URL + "digitalHuman-go-online.png",  
        renderFile: DigitalHumanGoOnline, 
        setFile: DigitalHumanGoOnlineSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '数字人上线时间分布2',   
        type: "system_digitalHumanGoOnlineTwo",   
        icon: <RadarChartOutlined />,  
        cover: COVER_URL + "digitalHumanGoOnline-two.png",  
        renderFile: DigitalHumanGoOnline, 
        setFile: DigitalHumanGoOnlineSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '长时间离线数字人分布',   
        type: "system_digitalPersonLongOffline",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "digitalPerson-long-offline.png",  
        renderFile: DigitalPersonLongOffline, 
        setFile: DigitalPersonLongOfflineSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '长时间离线数字人分布2',   
        type: "system_digitalPersonLongOfflineTwo",   
        icon: <PieChartOutlined />,  
        cover: COVER_URL + "digitalPerson-long-offline-two.png",  
        renderFile: DigitalPersonLongOffline, 
        setFile: DigitalPersonLongOfflineSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
    ]
  },
  {
    groupName: "分发日志和任务总览相关",
    children: [
      {
        title: '分发日志和任务总览',   
        type: "system_taskLogAllOverview",   
        icon: <PieChartOutlined />,  
        cover: COVER_URL + "taskLog-all-overview.png",  
        renderFile: TaskLogAllOverview, 
        setFile: TaskLogAllOverviewSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 21,
          h: 12
        }
      },
      {
        title: '日志统计',   
        type: "system_taskLogStatistics",   
        icon: <PieChartOutlined />,  
        cover: COVER_URL + "taskLog-statistics.png",  
        renderFile: TaskLogStatistics, 
        setFile: TaskLogStatisticsSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '日志统计1',   
        type: "system_taskLogStatisticsTwo",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "taskLog-statistics-two.png",  
        renderFile: TaskLogStatisticsTwo, 
        setFile: TaskLogStatisticsTwoSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '执行设备统计',   
        type: "system_taskDeviceStatistics",   
        icon: <PieChartOutlined />,  
        cover: COVER_URL + "taskDevice-statistics.png",  
        renderFile: TaskDeviceStatistics, 
        setFile: TaskDeviceStatisticsSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '日志分发占比',   
        type: "system_taskLogProportion",   
        icon: <PieChartOutlined />,  
        cover: COVER_URL + "taskLog-proportion.png",  
        renderFile: TaskLogProportion, 
        setFile: TaskLogProportionSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '设备分发占比',   
        type: "system_taskDeviceProportion",   
        icon: <PieChartOutlined />,  
        cover: COVER_URL + "taskDevice-proportion.png",  
        renderFile: TaskDeviceProportion, 
        setFile: TaskDeviceProportionSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '任务统计',   
        type: "system_taskStatistics",   
        icon: <PieChartOutlined />,  
        cover: COVER_URL + "task-statistics.png",  
        renderFile: TaskStatistics, 
        setFile: TaskStatisticsSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '任务列表',   
        type: "system_taskList",   
        icon: <TableOutlined />,  
        cover: COVER_URL + "task-list.png",  
        renderFile: TaskList, 
        setFile: TaskListSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志排名',   
        type: "system_distributionLogRanking",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "distributionLog-ranking.png",  
        renderFile: DistributionLogRanking, 
        setFile: DistributionLogRankingSet,  
        usageArray: ["company"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志排名',   
        type: "system_distributionLogRankingTwo",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "distributionLog-ranking.png",  
        renderFile: DistributionLogRankingTwo, 
        setFile: DistributionLogRankingTwoSet,  
        usageArray: ["space", "company"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志数字人排名',   
        type: "system_distributionLogDigitalManRanking",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "distributionLog-digitalMan-ranking.png",  
        renderFile: DistributionLogDigitalManRanking, 
        setFile: DistributionLogDigitalManRankingSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '日志状态分布',   
        type: "system_taskLogDistribution",   
        icon: <LineChartOutlined />,  
        cover: COVER_URL + "taskLog-distribution.png",  
        renderFile: TaskLogDistribution, 
        setFile: TaskLogDistributionSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '日志状态分布1',   
        type: "system_taskLogDistributionTwo",   
        icon: <LineChartOutlined />,  
        cover: COVER_URL + "taskLog-distribution-two.png",  
        renderFile: TaskLogDistribution, 
        setFile: TaskLogDistributionSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志空间分布',   
        type: "system_taskLogSpaceDistribution",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "taskLog-space-distribution.png",  
        renderFile: TaskLogSpaceDistribution, 
        setFile: TaskLogSpaceDistributionSet,  
        usageArray: ["company", "space"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志执行单元分布',   
        type: "system_taskLogUnitDistribution",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "taskLog-unit-distribution.png",  
        renderFile: TaskLogUnitDistribution, 
        setFile: TaskLogUnitDistributionSet,  
        usageArray: ["company", "space"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志分发和开始时间1',   
        type: "system_distributionTaskTimeAnalysis",   
        icon: <RadarChartOutlined />,  
        cover: COVER_URL + "distribution-taskTime-analysis.png",  
        renderFile: DistributionTaskTimeAnalysis, 
        setFile: DistributionTaskTimeAnalysisSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志分发和开始时间2',   
        type: "system_distributionTaskTimeAnalysisTwo",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "distribution-taskTime-analysis-two.png",  
        renderFile: DistributionTaskTimeAnalysis, 
        setFile: DistributionTaskTimeAnalysisSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志分发和开始时间3',   
        type: "system_distributionTaskTimeAnalysisThree",   
        icon: <LineChartOutlined />,  
        cover: COVER_URL + "distribution-taskTime-analysis-three.png",  
        renderFile: DistributionTaskTimeAnalysis, 
        setFile: DistributionTaskTimeAnalysisSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志开始时间',   
        type: "system_distributionTaskTimeAnalysisOther",   
        icon: <LineChartOutlined />,  
        cover: COVER_URL + "distribution-taskTime-analysisOther.png",  
        renderFile: DistributionTaskTimeAnalysisOther, 
        setFile: DistributionTaskTimeAnalysisOtherSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志用时分析',   
        type: "system_distributionLogDuration",   
        icon: <LineChartOutlined />,  
        cover: COVER_URL + "distributionLog-duration.png",  
        renderFile: DistributionLogDuration, 
        setFile: DistributionLogDurationSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
      {
        title: '分发日志用时分析1',   
        type: "system_distributionLogDurationTwo",   
        icon: <BarChartOutlined />,  
        cover: COVER_URL + "distributionLog-duration-two.png",  
        renderFile: DistributionLogDuration, 
        setFile: DistributionLogDurationSet,  
        usageArray: ["company", "space", "unit"],  
        sizeConfig: {
          w: 17,
          h: 22
        }
      },
    ]
  },
]

export { SystemComponentContentArray };