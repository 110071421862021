import HttpTool from "../../../../../tool/HttpTool";
import {message} from "antd";
import md5 from "md5";
import moment from "moment";
import APILXD from '../../../../../http/APILXD';

class DataGetHelp {
    constructor() {
    }

    //根据数据集查询参数获取完整的数据
    //requestParams：请求参数  mdId,spaceId,execUnitId
    async getDataByDataSetMdId(requestParams = {},extraMsg) {
        return new Promise(async (resolve, reject) => {
            if (!requestParams.mdId) {
                reject("未传入数据集mdId");
                return;
            }

            reject("后台项目无法查询数据集数据");
        })
    }

    //根据数据集配置详情获取完整的数据
    //extraMsg: 额外的配置  customParamsObj: 定制化的参数对象
    async getDataByNodeDetail(selectNodeDetail, extraMsg = {}) {
        let customParamsObj = extraMsg.customParamsObj || {}

        return new Promise(async (resolve, reject) => {
            if (!selectNodeDetail) {
                reject("未传入数据集详情");
                return;
            }

            switch (selectNodeDetail.type) {
                case 1:
                    //独立数据
                    let err;
                    //仪表板里可能配置过数据参数，需要合并默认参数数据  和  用户填过的数据
                    let configs = _.cloneDeep(selectNodeDetail.configs);
                    if (selectNodeDetail.dataType === 1 || selectNodeDetail.dataType === 5) {
                        //元数据类型
                        let newObj = customParamsObj?.[selectNodeDetail._id] || customParamsObj?.[selectNodeDetail.mdId];
                        if (newObj) {
                            //存在填过的参数，替换掉默认的
                            if (newObj.hasOwnProperty("inputArg")) {
                                //新版本参数结构 {title:xxx,inputArg:{}}
                                if (newObj.notFull) {
                                    //newObj.inputArg 结构不全，需要挨个替换
                                    Object.keys(newObj.inputArg).forEach((key) => {
                                        if (configs.inputArg[key]?.value) {
                                            configs.inputArg[key].value.value = newObj.inputArg[key].value?.value
                                        }
                                    })
                                } else {
                                    configs.inputArg = newObj.inputArg;
                                }
                            } else {
                                //老版本参数结构
                                configs.inputArg = newObj;
                            }
                        }
                    } else if (selectNodeDetail.dataType === 2) {
                        //自定义接口类型
                        let newObj = customParamsObj?.[selectNodeDetail._id] || customParamsObj?.[selectNodeDetail.mdId];
                        if (newObj) {
                            //存在填过的参数，替换掉默认的
                            if (newObj.hasOwnProperty("inputArg")) {
                                //这个结构就是 configs.configs
                                if (newObj.notFull) {
                                    //newObj.inputArg 结构不全，需要挨个替换
                                    Object.keys(newObj.inputArg).forEach((key) => {
                                        if (configs.configs[key]?.value) {
                                            configs.configs[key].value.value = newObj.inputArg[key].value?.value
                                        }
                                    })
                                } else {
                                    configs.configs = newObj.inputArg;
                                }
                            }
                        }
                    }

                    if(!configs.apiParams){
                        configs.apiParams = {};
                    }
                    if(selectNodeDetail.spaceId){
                        //追加空间id
                        configs.apiParams.spaceId = selectNodeDetail.spaceId;
                    }
                    if(selectNodeDetail.execUnitId){
                        //追加执行单元Id
                        configs.apiParams.execUnitId = selectNodeDetail.execUnitId;
                    }

                    if(extraMsg.baseApiParams){
                        //存在baseApiParams，强行替换
                        configs.apiParams = Object.assign({},configs.apiParams,extraMsg.baseApiParams)
                    }

                    let resultData = await this.getDataByBindConfig({
                        dataType: selectNodeDetail.dataType,
                        configs: configs,
                        tranArr: selectNodeDetail.tranArr,
                        title: selectNodeDetail.title
                    }).catch((e) => {
                        err = e;
                    })

                    if (err) {
                        reject(err)
                    }
                    resolve(resultData)
                    break;
                case 2:
                    //联合数据
                    let originData = {};
                    let len = selectNodeDetail.children?.length || 0;
                    for (let i = 0; i < len; i++) {
                        let childNode = selectNodeDetail.children[i];
                        let err;
                        let resultData = await this.getDataByNodeDetail(childNode, extraMsg).catch((e) => {
                            err = e
                        });

                        if (err) {
                            message.error(err);
                            originData[childNode.title] = null;
                        } else {
                            originData[childNode.title] = resultData.tranData;
                        }
                    }

                    let tranData = originData;
                    if (selectNodeDetail.format) {
                        //需要转换
                        tranData = this.changeDataAction(selectNodeDetail.format, tranData)
                    }
                    resolve({
                        originData: originData,
                        tranData: tranData
                    })
                    break;
                case 4:
                    //联动数据
                    let lastLevelData = null;
                    let childLength = selectNodeDetail.children?.length || 0;
                    for (let i = 0; i < childLength; i++) {
                        let childNode = selectNodeDetail.children[i];
                        let err;

                        //跳过第一层
                        if (i !== 0) {
                            //联动数据，上一层的数据结果，作为参数传给下一层，以参数名做匹配(不是通过键匹配)
                            //提取child节点的参数配置
                            let inputArgClone = this.getInputArgSetFromNodeMsg(childNode);

                            if (!customParamsObj[childNode._id]) {
                                customParamsObj[childNode._id] = {
                                    title: childNode.title,
                                    inputArg: {}
                                }
                            }

                            let argKeys = Object.keys(inputArgClone);
                            let lastLevelDataType = Object.prototype.toString.call(lastLevelData);
                            if (argKeys.length === 1 && lastLevelDataType !== '[object Object]') {
                                //只有一个参数，且不是对象，就不用匹配名称
                                if (this.checkArgType(inputArgClone[argKeys[0]].type, lastLevelDataType)) {
                                    //覆盖值
                                    inputArgClone[argKeys[0]].value.value = lastLevelData;

                                    //上一层的数据结果，类型符合参数要求
                                    customParamsObj[childNode._id].inputArg = inputArgClone;
                                } else {
                                    message.warning("联动参数类型不符合，请检查");
                                }
                            } else if (argKeys.length >= 1 && lastLevelDataType === "[object Object]") {
                                //存在多个参数，需要通过参数名称或字段匹配
                                argKeys.forEach((eachParamKey) => {
                                    let v;
                                    if (lastLevelData.hasOwnProperty(inputArgClone[eachParamKey].name)) {
                                        v = lastLevelData[inputArgClone[eachParamKey].name];
                                    } else if (lastLevelData.hasOwnProperty(inputArgClone[eachParamKey].field)) {
                                        v = lastLevelData[inputArgClone[eachParamKey].field];
                                    }

                                    if (v !== undefined) {
                                        if (this.checkArgType(inputArgClone[eachParamKey].type, Object.prototype.toString.call(v))) {
                                            //类型符合要求
                                            inputArgClone[eachParamKey].value.value = v;
                                        } else {
                                            message.warning("联动参数类型不符合，请检查");
                                        }
                                    }
                                })
                                customParamsObj[childNode._id].inputArg = inputArgClone;
                            }
                        }

                        let resultData = await this.getDataByNodeDetail(childNode, extraMsg).catch((e) => {
                            err = e
                        });

                        if (err) {
                            message.error(err);
                            reject(err)
                            break;
                        } else {
                            lastLevelData = resultData.tranData;
                        }
                    }

                    let tranDealData = lastLevelData;
                    if (selectNodeDetail.format) {
                        //需要转换
                        tranDealData = this.changeDataAction(selectNodeDetail.format, tranDealData)
                    }
                    resolve({
                        originData: lastLevelData,
                        tranData: tranDealData
                    })
                    break;
            }
        })
    }

    //根据单个配置获取  独立 数据
    async getDataByBindConfig(config, limitStep) {
        return new Promise((resolve, reject) => {
            if (!config) {
                reject("未传入配置项！");
                return;
            }

            if (!config.dataType) {
                resolve({
                    originData: "<未绑定数据>",
                    tranData: "<未绑定数据>",
                })
                return;
            }

            switch (config.dataType) {
                case 1: //查询元数据
                    let param = {
                        queryCode: config.configs.queryCode,
                        inputParams: {inputArg: config.configs.inputArg},
                        _title: config.title,
                        range: 0,
                        rangeId: null,
                        ...config.configs.apiParams
                    };

                    //修正范围
                    if(param.range && !param.rangeId){
                        //没有指定具体的id，需要动态传入
                        switch (param.range){
                            case 3:
                                //需要当前执行单元
                                if(param.execUnitId){
                                    param.rangeId = param.execUnitId;
                                }
                                break;
                            case 4:
                                //需要当前空间
                                if(param.spaceId){
                                    param.rangeId = param.spaceId;
                                }
                                break;
                        }
                    }

                    if (config.configs.useCache && config.configs.codeUniKey) {
                        //关联参数再计算一遍md5
                        param.cacheKey = md5(config.configs.codeUniKey + JSON.stringify(config.configs.inputArg));

                        if (!config.configs.expireMagnification || !config.configs.expireValue) {
                            reject("缓存配置参数不完整，拒绝请求");
                            return;
                        }

                        param.queryExpire = config.configs.expireValue * config.configs.expireMagnification;
                    }

                    if (config.configs.useAfterCode && config.configs.afterCode) {
                        param.afterCode = config.configs.afterCode;
                    }

                    console.log("this.selectSource",config.configs.selectSource)

                    let path;
                    if (config.configs.selectSource === "global") {
                        message.warning("后台项目不支持全局数据查询")
                        path = "";
                    }else if (config.configs.selectSource === "robot") {
                        message.warning("后台项目不支持数字人记忆查询")
                        path = "";
                    } else if (config.configs.selectSource === "resource") {
                        message.warning("后台项目不支持资源查询")
                        path = "";
                    } else if (config.configs.selectSource === "plan") {
                        message.warning("后台项目不支持计划查询")
                        path = "";
                    } else if (config.configs.selectSource === "device") {
                        message.warning("后台项目不支持数字人查询")
                        path = "";
                    } else if (config.configs.selectSource === "share") {
                        message.warning("后台项目不支持共享数据查询")
                        path = "/user/metadataTable/getSharedTableData";
                    } else if (config.configs.selectSource==="unit") {
                        message.warning("后台项目不支持输入输出数据查询")
                        path = "";
                    }else {
                        message.warning("错误的类型")
                        path = "";
                    }

                    HttpTool.post(path, (code, msg, data, option) => {

                        console.log(path,code, msg, data)
                        let tranData = this.dealTran(config.tranArr, data, limitStep);

                        resolve({
                            originData: data,
                            tranData: tranData
                        })
                    }, (code, msg) => {
                        reject(msg);
                    },param);
                    break;
                case 2: //查询接口数据
                    if (!config.configs?.url) {
                        reject("配置不完整，无法请求数据");
                        return;
                    }

                    let userSetParams = {};
                    Object.values(config.configs.configs).forEach((item) => {
                        if (item.value.hasOwnProperty("value")) {
                            userSetParams[item.field] = item.value.value;
                        } else {
                            userSetParams[item.field] = item.value.defaultValue;
                        }
                    })
                    let paramForBuilt = Object.assign(config.configs.defaultConfigs, userSetParams);

                    HttpTool.post(config.configs.url, (code, msg, data, option) => {

                        let tranData = this.dealTran(config.tranArr, data, limitStep);

                        resolve({
                            originData: data,
                            tranData: tranData
                        })
                    }, (code, msg) => {
                        reject(msg);
                    },paramForBuilt);
                    break;
                case 3: //设置的值
                    if (config.configs.jsonStr) {
                        let data;
                        try {
                            let parseStr = `data = ${config.configs.jsonStr}`;
                            eval(parseStr);
                        } catch (e) {
                            console.error(e)
                            reject("填写内容解析出错，请检查");
                            return;
                        }
                        let tranData = this.dealTran(config.tranArr, data, limitStep);

                        resolve({
                            originData: data,
                            tranData: tranData
                        })
                        return;
                    } else if (config.configs.setItems) {
                        reject("暂不支持该方式配置数据");
                        return;
                    }
                    break;
                case 4: //MongoDB数据
                    message.warning("未完成...");
                    reject("不支持的模式");
                    break;
                case 5: //从参数中收集值
                    let injectData = {};
                    Object.values(config.configs.inputArg).forEach((eachParam) => {
                        if (eachParam.value.hasOwnProperty("value")) {
                            injectData[eachParam.field] = eachParam.value.value;
                        } else if (eachParam.value.hasOwnProperty("defaultValue")) {
                            injectData[eachParam.field] = eachParam.value.defaultValue;
                        } else {
                            injectData[eachParam.field] = "<参数未注入数据>";
                        }

                    })
                    let tranData = this.dealTran(config.tranArr, injectData, limitStep);

                    resolve({
                        originData: injectData,
                        tranData: tranData
                    })
                    return;
                case 6: //数据库直查
                    if (!config.configs?.formData) {
                        reject("配置不完整，无法请求数据");
                        return;
                    }

                    HttpTool.post(APILXD.executeDbQuery, (code, msg, data, option) => {

                        let tranData = this.dealTran(config.tranArr, data, limitStep);

                        resolve({
                            originData: data,
                            tranData: tranData
                        })
                    }, (code, msg) => {
                        reject(msg);
                    }, config.configs?.formData);

                    break;

            }
        })
    }

    //转换数据
    dealTran(tranArr, originData, limitStep) {
        let tranData = _.cloneDeep(originData);
        let len = tranArr?.length;
        if (len) {
            if (limitStep === undefined || limitStep > len) {
                limitStep = len
            }

            //开始转换
            for (let i = 0; i < limitStep; i++) {
                let tranItem = tranArr[i];
                try {
                    switch (tranItem.tranType) {
                        //键名转换
                        case 1:
                            this.changeKeyAction(tranItem.tranConfig, tranData)
                            break;
                        //自定义代码转换
                        case 2:
                            tranData = this.changeDataAction(tranItem.tranConfig, tranData)
                            break;
                        //值转换：时间戳 变 日期字符串
                        case 3:
                            tranData = this.changeValueForTime(tranItem.tranConfig, tranData)
                            break;
                        //值转换：时间戳 变 持续时长
                        case 4:
                            tranData = this.changeValueForDuration(tranItem.tranConfig, tranData)
                            break;
                    }
                } catch (e) {
                    tranData = `数据转换第 ${i + 1} 步：${tranItem.title} 出错了：` + e.toString()
                }
            }
        }

        return tranData;
    }

    //对数据进行键的修改，并删除不需要的键
    changeKeyAction(keyList, tranData) {
        //为了方便对比，将配置数组改成对象结构
        let keyTranMap = {};
        keyList?.forEach((keyTranItem) => {
            keyTranMap[keyTranItem.fullStr] = keyTranItem
        })

        console.log("键转换对象", keyTranMap);
        let keyTranAction = (needDealData, parentKey) => {
            let prototypeStr = Object.prototype.toString.call(needDealData);
            if (prototypeStr === '[object Object]') {
                //对象，替换键
                Object.keys(needDealData).forEach((eachKey) => {
                    let currKey = parentKey + (parentKey ? "." : "") + eachKey;
                    if (keyTranMap[currKey]) {
                        //先处理值内容
                        keyTranAction(needDealData[eachKey], currKey)
                        //替换key
                        needDealData[keyTranMap[currKey].tranStr] = needDealData[eachKey];

                        if (keyTranMap[currKey].tranStr !== eachKey) {
                            //删除老的key
                            delete needDealData[eachKey];
                        }
                    } else {
                        //删除老的key
                        delete needDealData[eachKey];
                    }
                })
            } else if (prototypeStr === '[object Array]') {
                //数组
                needDealData.forEach((eachItem) => {
                    keyTranAction(eachItem, parentKey)
                })
            } else {
                //其它，不处理
            }
        }

        keyTranAction(tranData, "")
    }

    //对数据进行值的修改：时间戳转换
    changeValueForTime(keyList, tranData) {
        //为了方便对比，将配置数组改成对象结构
        let keyTranMap = {};
        keyList?.forEach((keyTranItem) => {
            keyTranMap[keyTranItem.fullStr] = keyTranItem
        })

        console.log("转换对象", keyTranMap);
        let keyTranAction = (needDealData, parentKey) => {
            let prototypeStr = Object.prototype.toString.call(needDealData);

            if (keyTranMap[parentKey] && prototypeStr !== "[object Array]") {
                //需要对这个值做处理
                if (prototypeStr === "[object Number]") {
                    return moment(needDealData).format(keyTranMap[parentKey].tranStr)
                } else {
                    return "--";
                }
            } else {
                //不匹配，继续往下找
                if (prototypeStr === '[object Object]') {
                    //对象
                    Object.keys(needDealData).forEach((eachKey) => {
                        let currKey = parentKey + (parentKey ? "." : "") + eachKey;

                        needDealData[eachKey] = keyTranAction(needDealData[eachKey], currKey);
                    })
                } else if (prototypeStr === '[object Array]') {
                    //数组
                    needDealData = needDealData.map((eachItem) => {
                        return keyTranAction(eachItem, parentKey)
                    })
                } else {
                    //其它，不处理
                }
                return needDealData
            }
        }

        return keyTranAction(tranData, "")
    }

    //对数据进行值的修改：时间戳 转 持续时长
    changeValueForDuration(keyList, tranData) {
        //为了方便对比，将配置数组改成对象结构
        let keyTranMap = {};
        keyList?.forEach((keyTranItem) => {
            keyTranMap[keyTranItem.fullStr] = keyTranItem
        })

        console.log("转换对象", keyTranMap);
        let keyTranAction = (needDealData, parentKey) => {
            let prototypeStr = Object.prototype.toString.call(needDealData);

            if (keyTranMap[parentKey] && prototypeStr !== "[object Array]") {
                //需要对这个值做处理
                if (prototypeStr === "[object Number]") {
                    if (!keyTranMap[parentKey].tranSet?.length) {
                        return "未勾选单位"
                    }

                    let durationTxt = "";
                    let year = 0,
                        month = 0,
                        day = 0,
                        hour = 0,
                        minute = 0,
                        second = 0;

                    if (keyTranMap[parentKey].tranSet.includes("Y")) {
                        //365 * 24 * 60 * 60 * 1000
                        year = Math.floor(needDealData / 31536000000)
                    }

                    if (keyTranMap[parentKey].tranSet.includes("M")) {
                        //30 * 24 * 60 * 60 * 1000
                        month = Math.floor((needDealData - year * 31536000000) / 2592000000)
                    }

                    if (keyTranMap[parentKey].tranSet.includes("D")) {
                        //24 * 60 * 60 * 1000
                        day = Math.floor((needDealData - year * 31536000000 - month * 2592000000) / 86400000)
                    }

                    if (keyTranMap[parentKey].tranSet.includes("H")) {
                        //60 * 60 * 1000
                        hour = Math.floor((needDealData - year * 31536000000 - month * 2592000000 - day * 86400000) / 3600000)
                    }

                    if (keyTranMap[parentKey].tranSet.includes("m")) {
                        //60 * 1000
                        minute = Math.floor((needDealData - year * 31536000000 - month * 2592000000 - day * 86400000 - hour * 3600000) / 60000)
                    }

                    if (keyTranMap[parentKey].tranSet.includes("s")) {
                        //1000
                        second = Math.floor((needDealData - year * 31536000000 - month * 2592000000 - day * 86400000 - hour * 3600000 - minute * 60000) / 1000)
                    }

                    durationTxt = `${year ? year + "年" : ""}${month ? month + "月" : ""}${day ? day + "天" : ""}${hour ? hour + "时" : ""}${minute ? minute + "分" : ""}${second ? second + "秒" : ""}`

                    if (durationTxt === "") {
                        //勾选的时间都不满足
                        if (keyTranMap[parentKey].tranSet.includes("s")) {
                            return "0秒"
                        }
                        if (keyTranMap[parentKey].tranSet.includes("m")) {
                            return "0分"
                        }
                        if (keyTranMap[parentKey].tranSet.includes("H")) {
                            return "0时"
                        }
                        if (keyTranMap[parentKey].tranSet.includes("D")) {
                            return "0天"
                        }
                        if (keyTranMap[parentKey].tranSet.includes("M")) {
                            return "0月"
                        }
                        if (keyTranMap[parentKey].tranSet.includes("Y")) {
                            return "0年"
                        }
                        return "0单位时间"
                    }

                    return durationTxt;
                } else {
                    return "--";
                }
            } else {
                //不匹配，继续往下找
                if (prototypeStr === '[object Object]') {
                    //对象
                    Object.keys(needDealData).forEach((eachKey) => {
                        let currKey = parentKey + (parentKey ? "." : "") + eachKey;

                        needDealData[eachKey] = keyTranAction(needDealData[eachKey], currKey);
                    })
                } else if (prototypeStr === '[object Array]') {
                    //数组
                    needDealData = needDealData.map((eachItem) => {
                        return keyTranAction(eachItem, parentKey)
                    })
                } else {
                    //其它，不处理
                }
                return needDealData
            }
        }

        return keyTranAction(tranData, "")
    }

    //通过自定义代码对数据进行处理
    changeDataAction(codeStr, tranData) {
        try {
            let func;
            let txt = `func = ${codeStr}`;
            eval(txt);
            return func(tranData);
        } catch (e) {
            console.error(e);
            return `<转换方法出错：${e.toString().slice(0, 100)}...>`;
        }
    }

    //提取下拉key
    getOriginKeyListByData(data) {
        if (!data || typeof data !== "object") {
            message.warning("当前数据中没有可提取的键。");
            return [];
        }

        let keyMap = new Map();

        let dealAction = (data, parentKey) => {
            let prototypeStr = Object.prototype.toString.call(data);
            if (prototypeStr === '[object Object]') {
                //对象，提取键
                Object.keys(data).forEach((eachKey) => {
                    let currKey = parentKey + (parentKey ? "." : "") + eachKey;
                    if (!keyMap.has(currKey)) {
                        keyMap.set(currKey, true)
                    }
                    dealAction(data[eachKey], currKey)
                })
            } else if (prototypeStr === '[object Array]') {
                //数组
                data.forEach((eachItem) => {
                    dealAction(eachItem, parentKey)
                })
            } else {
                //其它，不处理
            }
        }

        dealAction(data, "");
        // console.log(keyMap);
        return Array.from(keyMap.keys());
    }

    //提取独立数据集参数 （暂时只有1，2，5存在参数）
    getInputArgSetFromNodeMsg(nodeMsg) {
        if (!nodeMsg || nodeMsg.type !== 1) {
            return {};
        }

        let inputArg = {};
        switch (nodeMsg.dataType) {
            case 1:
                //元数据类型
                inputArg = nodeMsg.configs?.inputArg;
                break;
            case 2:
                //自定义接口类型
                inputArg = nodeMsg.configs?.configs;
                break;
            case 5:
                //从参数中收集值
                inputArg = nodeMsg.configs?.inputArg;
                break;
        }

        //避免修改原始结构
        return _.cloneDeep(inputArg);
    }

    //判断参数类型是否符合要求
    checkArgType(needType, realTypeStr) {
        let checkResult = false;
        switch (needType) {
            case "String":
                checkResult = realTypeStr === '[object String]';
                break;
            case "Number":
                checkResult = realTypeStr === '[object Number]';
                break;
            case "Boolean":
                checkResult = realTypeStr === '[object Boolean]';
                break;
            case "Object":
                checkResult = realTypeStr === '[object Object]';
                break;
            case "Array_String":
            case "Array_Number":
            case "Array_Object":
                checkResult = realTypeStr === '[object Array]';
                break;
        }

        return checkResult;
    }
}

export default DataGetHelp;