import React, { useEffect, useRef, useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./DigitalHumanOnlineOfflineRatioPie.less";
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts'

const DefaultFromArray: { [key: string]: string } = {
  'offline': '离线',
  'online': '在线'
}

const DigitalHumanOnlineOfflineRatioPie: React.FC<any> = (props) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const [errorMsg, setErrorMsg] = useState(null)
  const [dataSource, setDataSource] = useState<Array<{ name: string, value: number }>>([])

  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current, "dark", {
        locale: "ZH"
      });
    }
    return () => {
      chartInstance.current?.dispose();
    };
  }, []);

  useEffect(() => {
    chartInstance.current?.resize()
  }, [props.styleUpdate])

  useEffect(()=> {
    if (dataSource) {
      chartInstance.current?.setOption(getOption())
    }
  }, [dataSource])

  // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}digitalHuman/onlineToOfflineRatio`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate],
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       setDataSource(Object.keys(res?.data)?.map(key => ({
  //         name: DefaultFromArray[key],
  //         value: res?.data[key]
  //       })) || [])
  //     } else {
  //       setErrorMsg(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })

  const getOption = (): echarts.EChartsOption => {
    return {
      backgroundColor: 'transparent',
      tooltip: {
        trigger: 'item',
      },
      legend: {
        textStyle: {
          color: '#fff', // 图例文字颜色
        },
      },
      series: [
        {
          type: 'pie',
          avoidLabelOverlap: false,
          radius: '60%',
          emphasis: {
            label: {
              show: true,
              fontSize: 18,
              fontWeight: 'bold',
              color: '#fff'
            }
          },
          data: dataSource
        }
      ]
    };
  };

  return <div className={styles.outer + " swiper-no-swiping"} >
    {
      errorMsg
        ? <div className={styles.errorMsg}>
          {errorMsg}
        </div>
        :<div
          ref={chartRef}
          style={{ width: '100%', height: '100%', marginTop: 16 }}
        >
        </div>
    }
  </div>
}


export default DigitalHumanOnlineOfflineRatioPie