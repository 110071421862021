import React, { useRef, useEffect, useState } from 'react'
import styles from './DigitalHumanAreaPieAna.less'
import { request } from '../../../../../../tool/http'

function DigitalHumanAreaPieAna(props) {
  //只有一个展示内容，不需要勾选
  // const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || ["area"])
  const chartRef = useRef(null)
  const chartInstance = useRef(null);
  const [errorMsg, setErrorMsg] = useState(null)

  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/genderRatio`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
      }
    }).then((res) => {
      if (res.code === 200) {
        showValue(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }

  useEffect(() => {
    getBusinessData()
  }, [props.dataUpdate])

  useEffect(() => {
    chartInstance.current?.resize()
  }, [props.styleUpdate])

  // 初始化图表
  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current,"dark",{
        locale: "ZH"
      });
    }
    return () => {
      // 清理图表实例
      chartInstance.current?.dispose();
    };
  }, []);

  //绘制数据
  const showValue=(data)=>{
    let option = {
      backgroundColor: "transparent",
      color: [ '#ffbd2b', '#bb8cf9'],
      legend: {
        show: true,
      },
      tooltip: {
        show: true,
        trigger: "item",
      },
      dataset: {
        dimensions: ["label","value"],
        source: []
      },
      series: [
        {
          type: 'pie',
          name: "性别占比",
          label: {
            show: true, // 显示标签
            position: 'inside', // 标签的位置，'inside'表示在扇区内部
            color: '#fff', // 文字的颜色
            formatter: function(params) {
              return `${Math.round(params.percent)}%`; // 使用 Math.round 来取整
            },
          },
          itemStyle: {
            borderRadius: 5, // 所有角的圆角大小
          }
        },
      ]
    }

    if(data){
      //手动写，固定顺序
      let source = [
        {
          label: "男",
          value: 0,
        },
        {
          label: "女",
          value: 0,
        }
      ]

        data.forEach((item)=>{
        if(item.gender === "Male"){
          source[0].value = item.sum;
        }else if(item.gender === "Female"){
          source[1].value = item.sum;
        }
      })
      option.dataset.source = source

    }

    //渲染
    console.log("最终渲染option",option)
    chartInstance.current?.setOption(option)
  }

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : null
      }
      <div
        ref={chartRef}
        className={styles.chartBox}
      >
      </div>
    </div>

  )
}
export default DigitalHumanAreaPieAna