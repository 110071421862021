import React from "react";
import {Space} from "antd";
import AllContentMap,{ViewContentArray,ParameterContentArray,WidgetContentArray,SystemComponentContentArray} from "../../dashboard/contentView/AllContentDefinition";

class DataDefinitionFile {
    constructor(props) {
    }


    /**
     * 获取配置项
     * @param type  类型
     * @param extendData  额外的参数
     * @return *
     */
    getViewSetItems(type,extendData){
        let matchContent = AllContentMap[type];

        if(!matchContent || !matchContent.setFile?.getSetItems){
            return null
        }

        return matchContent.setFile.getSetItems(extendData)
    }

    /**
     * 获取默认值
     * @param type  类型
     * @return *
     */
    getDefaultViewParams(type){
        let matchContent = AllContentMap[type];

        if(!matchContent || !matchContent.setFile?.getDefaultValues){
            return {}
        }

        return matchContent.setFile.getDefaultValues()
    }

    /**
     * 获取视图的数据示例代码  (组件、部件和参数容器不需要示例代码)
     * @param type  类型
     * @return *
     */
    getViewDemoCode(type){
        let matchContent = AllContentMap[type];

        if(!matchContent || !matchContent.setFile?.getDemoCode){
            return ""
        }

        if(matchContent.contentMode !== "view"){
            return ""
        }

        return matchContent.setFile.getDemoCode()
    }

    /**
     * 获取系统组件的参数配置
     * @param type  类型
     * @return *
     */
    getInputArgsConfig(type){
        let matchContent = AllContentMap[type];

        if(!matchContent || !matchContent.setFile?.getInputArg){
            return {}
        }

        return matchContent.setFile.getInputArg()
    }

    /**
     * 获取类型图标与名称
     * @param type
     * @return {JSX.Element}
     */
    getChartTypeIcon(type) {
        if(!type){
            return <span>未选择</span>
        }

        let matchContent = AllContentMap[type];

        if(!matchContent){
            return null
        }

        return <Space>
            {matchContent.icon}
            <span>{matchContent.title}</span>
        </Space>
    }

    //类型选择的下拉数据
    getSelectView(contentMode,usage){
        let options = [];
        switch (contentMode){
            case "view": options = ViewContentArray;break;
            case "widget": options = WidgetContentArray;break;
            case "parameter": options = ParameterContentArray;break;
            case "systemComponent":
                options = SystemComponentContentArray;
                if(usage){
                    options = options.map((eachOp)=>{
                        eachOp.children = eachOp.children.filter((chartItem)=>{
                            return chartItem.usageArray.includes(usage)
                        })
                        return eachOp
                    }).filter((eachOp)=>{
                        return eachOp.children?.length
                    })
                }

                break;
        }

        return options;
    }

    //匹配参数内容真实的参数值类型
    getRealParameterType(parameterBoxType){
        let matchContent = AllContentMap[parameterBoxType];

        return matchContent?.valueType;
    }

    //可接收外部传值的参数组件
    ifParameterAllowSetValue(parameterBoxType){
        let matchContent = AllContentMap[parameterBoxType];

        return !!matchContent?.allowSetValue;
    }

    //参数有多种类型，比如网页(url)，识别出基础数据类型
    getBaseTypeFromArgType(argType){
        switch (argType){
            case "String":
            case "Select":
            case "By":
            case "Url":
            case "File": return "String";break;
            case "Number": return "Number";break;
            case "Boolean": return "Boolean";break;
            case "Multiple":
            case "Array_String": return "Array_String";break;
            case "Array_Number": return "Array_Number";break;
        }
    }
}

export default new DataDefinitionFile();