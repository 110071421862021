import React, { useEffect, useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./DigitalHumanDevicePie.less";
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts'
import { Card, Select, Statistic } from 'antd';

const DigitalHumanDevicePie: React.FC<any> = (props) => {
  console.error(props)
  const [errorMsg, setErrorMsg] = useState(null)
  const [errorMsg1, setErrorMsg1] = useState(null)
  const [status, setStatus] = useState('1,2,3,4')
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<Array<{ name: string, value: number }>>([])

  useEffect(() => {
    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('deviceTotal')) {
      runCount()
    }

    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('deviceTypeRatio')) {
      runDeviceRato()
    }
  }, [props.dataUpdate, status])

  // const { loading, run: runCount } = useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}robot/getRobotCount`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     status: status.split(',').map(Number)
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate, status],
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       setTotal(res?.data || 0)
  //     } else {
  //       setErrorMsg(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })

  // const { loading: loadingPie, run: runDeviceRato } = useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}robot/robotTerminalRatio`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     status: status.split(',').map(Number),
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate, status],
    
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       //转化数据成需要的格式
  //       setDataSource((res?.data || [])?.map((item: { count: number, name: string }) => ({
  //         name: item?.name,
  //         value: item?.count
  //       })) || [])
  //     } else {
  //       setErrorMsg1(res?.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg1(err.toString())
  //   }
  // })

  const getSeries = () => {
    return [
      {
        type: 'pie',
        avoidLabelOverlap: false,
        gap: 0,
        radius: '60%',
        emphasis: {
          label: {
            show: true,
            fontSize: 18,
            fontWeight: 'bold'
          }
        },
        label: {
          color: '#fff'
        },
        data: dataSource
      }
    ]
  }

  const getOption = (): echarts.EChartsOption => {
    return {
      tooltip: {
        trigger: 'item',
      },
      legend: {
        textStyle: {
          color: '#fff', // 图例文字颜色
        },
      },
      series: getSeries()
    };
  };

  return <div className={styles.outer + " swiper-no-swiping"}>
    <div className={styles.controlBar}>
      <Select
        style={{ width: "120px" }}
        value={status}
        onChange={(status) => {
          setStatus(status)
        }}
        options={[
          { label: "全部", value: '1,2,3,4' },
          { label: "在线", value: '2,3' },
        ]}
      />
    </div>
    {props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('deviceTotal')
      && <div className={styles.charBox1}>
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Card bordered hoverable className={styles.card}>
              <Statistic
                title="工作设备总数"
                value={total}
                precision={0}
              />
            </Card>
        }

      </div>
    }
    {
      props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('deviceTypeRatio')
      && <div className={styles.chartBox}>
        {
          errorMsg1
            ? <div className={styles.errorMsg}>
              {errorMsg1}
            </div>
            : <EChartsReact style={{ width: '100%', height: '100%' }} option={getOption()} />
        }
      </div>
    }

  </div>
}


export default DigitalHumanDevicePie