import React, { useEffect, useRef, useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./DigitalHumanLoadRate.less";
import { Card, Flex, Select, Statistic } from 'antd';
import * as echarts from 'echarts'
import LiquidFill from '../../view/liquidfill/LiquidFill';


type ResponeItem = {
  _id: string,
  busyCount: number;
  onlineCount: number;
}

type DataSource = {
  onlineTotal: number;
  workTotal: number;
  loadRate: number
}

const DigitalHumanLoadRate: React.FC<any> = (props) => {
  console.error(props)
  const chartRef = useRef<HTMLDivElement>(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [time, setTime] = useState(1)
  const [dataSource, setDataSource] = useState<DataSource>()

  props.contentMsg.dataConfig.contentParams.totalField = 'onlineTotal'
  props.contentMsg.dataConfig.contentParams.valueField = 'workTotal'

  // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}digitalHuman/digitalHumanLoadRate`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     time: time
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate, time],
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       // 将数据格式化，可直接使用的数据字段  分钟时长 转化为 分钟时长
  //       const workTotal = res.data?.reduce((total: number, item: ResponeItem) => total + item?.busyCount, 0) / 60
  //       const onlineTotal = res.data?.reduce((total: number, item: ResponeItem) => total + item?.onlineCount, 0) / 60

  //       setDataSource({
  //         onlineTotal,
  //         workTotal,
  //         loadRate: workTotal / onlineTotal,
  //       })

  //     } else {
  //       setErrorMsg(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })

  useEffect(() => {
    if (dataSource) {
      chartRef.current?.showValue(dataSource)
    }
  }, [dataSource])

  return <div className={styles.outer}>
    <div className={styles.controlBar + " swiper-no-swiping"}>
      <Select
        style={{ width: "120px" }}
        value={time}
        onChange={(time) => {
          setTime(time)
        }}
        options={[
          { label: "今日", value: 1 },
          { label: "7日", value: 7 },
          { label: "15日", value: 15 },
          { label: "30日", value: 30 },
        ]}
      />
    </div>
    <div className={styles.card}>
      <div className={styles.title}>数字人总数</div>
      <div className={styles.charBox}>
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <LiquidFill ref={chartRef} {...props}></LiquidFill>
        }
      </div>
      <Flex justify='space-between'>
        <Statistic
          title="在线总时长"
          value={(dataSource?.onlineTotal || 0).toFixed(2)}
          suffix={<span className={styles.timeSuffix}>小时</span>}
          precision={0}
        />

        <Statistic
          title="工作总时长"
          value={(dataSource?.workTotal || 0).toFixed(2)}
          suffix={<span className={styles.timeSuffix}>小时</span>}
          precision={0}
        />
      </Flex>
    </div>
  </div>
}


export default DigitalHumanLoadRate