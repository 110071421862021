import React, { useRef, useEffect, useState } from 'react'
import styles from './DigitalHumanOnlineTimeRatio.less'
import { request } from '../../../../../../tool/http'
import {Select} from "antd"
import TimeHelp from "../../../../../../tool/TimeHelp";

function DigitalHumanOnlineTimeRatio(props) {
  //只有一个展示内容，不需要勾选
  const chartRef = useRef(null)
  const chartInstance = useRef(null);
  const [errorMsg, setErrorMsg] = useState(null)
  const [time, setTime] = useState(1) //1.今天 7.七天内 15.十五天内 30.一个月内
  const [countMsg, setCountMsg] = useState(null)

  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/onlineTimeRatio`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time: time,
      }
    }).then((res) => {
      if (res.code === 200) {
        showValue(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }

  useEffect(() => {
    getBusinessData()
  }, [props.dataUpdate,time])

  useEffect(() => {
    chartInstance.current?.resize()
  }, [props.styleUpdate])


  // 初始化图表
  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current,"dark",{
        locale: "ZH"
      });
    }
    return () => {
      // 清理图表实例
      chartInstance.current?.dispose();
    };
  }, []);

  //绘制数据
  const showValue=(data)=>{
    let option = {
      grid: {
        left: '10',
        right: '10',
        bottom: '10',
        top: '30',
        containLabel: true
      },
      backgroundColor: "transparent",
      color: [ '#46FEA5', '#FFA057','#9EB1FF'],
      legend: {
        show: true,
        // type: "plain",  //plain  scroll
        // position: "top",
      },
      tooltip: {
        show: true,
        trigger: "axis",
      },
      xAxis: {
        show: true,
        type: 'category'
      },
      yAxis: {
        show: true,
        type: "value",
        minInterval: 1,
        axisLabel: {
          formatter: `{value}分钟`
        }
      },
      dataset: {
        dimensions: ["时长分布","平均在线","平均离线","平均忙碌"],
        source: []
      },
      series: [
        {
          type: 'line',
          name: "平均在线",
          // label: {
          //   show: true, // 显示标签
          //   color: '#fff', // 文字的颜色
          //   // formatter: function(params) {
          //   //   return `${Math.round(params.percent)}%`; // 使用 Math.round 来取整
          //   // },
          // },
        },
        {
          type: 'line',
          name: "平均离线",
        },
        {
          type: 'line',
          name: "平均忙碌",
        }
      ]
    }

    let source = [];
    let countMsg = {
      totalOnline: 0,
      totalOffline: 0,
      totalBusy: 0,
      totalNum: 0,
    }
    if(data){
      data.forEach((item)=>{

        if(item.count>0){
          countMsg.totalOnline += item.avgOnline * item.count;
          countMsg.totalOffline += item.avgOffline * item.count;
          countMsg.totalBusy += item.avgBusy * item.count;

          countMsg.totalNum += item.count;
        }

        source.push([
          item.time,
          item.avgOnline,
          item.avgOffline,
          item.avgBusy,
        ])
      })

      option.dataset.source = source;

      setCountMsg(countMsg)
    }

    //渲染
    console.log("最终渲染option",option)
    chartInstance.current?.setOption(option)
  }

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : null
      }
      <div className={styles.controlBar}>
        <Select
          style={{width:"90px"}}
          value={time}
          onChange={(v)=>{
            setTime(v)
          }}
          options={[
            {label: "今日",value:1},
            {label: "7日",value:7},
            {label: "15日",value:15},
            {label: "30日",value:30},
          ]}
        />
      </div>
      <div className={styles.countMsgBox}>
        {
          props.contentMsg?.dataConfig?.contentParams?.typeShowSel?.includes("onlineMsg")
            ?<div
              className={styles.topMsgBox}
              style={{
                backgroundColor: "#46FEA5",
              }}
            >
              <div style={{color: "#333"}}>平均在线时长</div>
              <div style={{color: "#333"}}>{countMsg?TimeHelp.getTime(countMsg.totalOnline/countMsg.totalNum*60000,false,true):"-"}</div>
            </div>
            :null
        }
        {
          props.contentMsg?.dataConfig?.contentParams?.typeShowSel?.includes("offlineMsg")
            ?<div
              className={styles.topMsgBox}
              style={{
                backgroundColor: "#FFA057"
              }}
            >
              <div>平均离线时长</div>
              <div>{countMsg?TimeHelp.getTime(countMsg.totalOffline/countMsg.totalNum*60000,false,true):"-"}</div>
            </div>
            :null
        }
        {
          props.contentMsg?.dataConfig?.contentParams?.typeShowSel?.includes("busyMsg")
            ?<div
              className={styles.topMsgBox}
              style={{
                backgroundColor: "#9EB1FF"
              }}
            >
              <div>平均忙碌时长</div>
              <div>{countMsg?TimeHelp.getTime(countMsg.totalBusy/countMsg.totalNum*60000,false,true):"-"}</div>
            </div>
            :null
        }
      </div>
      <div
        className={props.contentMsg?.dataConfig?.contentParams?.typeShowSel?.includes("totalChart")?styles.chartOuter:styles.chartOuterHide}
      >
        <div
          ref={chartRef}
          className={styles.chartBox}
        >
        </div>
      </div>
    </div>

  )
}
export default DigitalHumanOnlineTimeRatio