import { useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./PositionTaskCount.less";
import { Select } from 'antd';
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts'

type BarData = {
    name: string;
    type: string;
    sum: number;
}

const DefaultKeyConfigs = {
    1: '未开始',
    2: '进行中',
    3: '终止',
    4: '成功',
}

const colors = ['#5470C6', '#91CC75', '#EE6666'];

const PositionLogCount: React.FC<any> = (props) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const [dataSource, setDataSource] = useState<Array<BarData>>([])
    const [time, setTime] = useState<number>(30)
    const [type, setType] = useState<number>(1)

    // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}execUnit/getPositionTaskCount`, {
    //     data: {
    //         execUnitId: [props.contentMsg?.proConfig?.usageParams?.businessId],
    //         time: time,
    //         type: type,
    //     }
    // }), {
    //     formatResult(res) {
    //         return res
    //     },
    //     refreshDeps: [props.dataUpdate, time, type],
    //     onSuccess: (res) => {
    //         if (res.code === 200) {
    //             setDataSource(res?.data || [])
    //         } else {
    //             setErrorMsg(res.message)
    //         }
    //     },
    //     onError(err: any) {
    //         setErrorMsg(err.toString())
    //     }
    // })

    const getSeriesKeys = () => {
        let key_datas: Array<string> = []
        dataSource.forEach(item => {
            key_datas.push(item?.name)
        })
        return key_datas
    }

    const getSeriesValuesData = (key: number) => {
        let key_datas: Array<number> = []
        dataSource.forEach((item: any) => {
            item.event && item.event.forEach(data => {
                if (data?.status === Number(key)) {
                    key_datas.push(data?.sum || 0)
                }
            })
        })
        return key_datas
    }

    const getSeriesTotalValuesData = () => {
        let datas: Array<number> = []
        dataSource.forEach(item => {
            let total = 0
            item.event && item.event.forEach(data => {
                total = total + (data?.sum || 0)
            })
            datas.push(total)
        })
        return datas
    }

    const getSeries = () => {
        let seriesDatas: { name: any; type: string; yAxisIndex?: number, data: number[]; }[] = []
        Object.keys(DefaultKeyConfigs).forEach(key => {
            seriesDatas.push({
                name: DefaultKeyConfigs[key],
                type: 'bar',
                data: getSeriesValuesData(key),
            })
        })

        // 总量
        seriesDatas.push({
            name: '总量',
            type: 'line',
            yAxisIndex: 1,
            data: getSeriesTotalValuesData(),
        })

        return seriesDatas
    }
    const getOption = (): echarts.EChartsOption => {
        return {
            color: colors,
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                }
            },
            legend: {
                textStyle: {
                    color: '#fff', // 图例文字颜色
                },
                data: [...Object.values(DefaultKeyConfigs), '总量'],
            },
            xAxis: {
                type: 'category',
                data: getSeriesKeys(),
                axisPointer: {
                    type: 'shadow',
                }
            },
            yAxis: [
                {
                    type: 'value',
                    min: 0,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: colors[0]
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    minorSplitLine: {
                        show: false
                    }
                },
                {
                    type: 'value',
                    name: '总量',
                    min: 0,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: colors[1]
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    minorSplitLine: {
                        show: false
                    }
                }
            ],
            series: getSeries()
        };
    };

    return <div className={styles.outer}>
        <div className={styles.controlBar + " swiper-no-swiping"}>
            <Select
                style={{ width: "90px" }}
                value={time}
                onChange={(v) => {
                    setTime(v)
                }}
                options={[
                    { label: "30日", value: 30 },
                    { label: "15日", value: 15 },
                    { label: "7日", value: 7 },
                ]}
            />
            <Select
                style={{ width: "120px" }}
                value={type}
                onChange={(v) => {
                    setType(v)
                }}
                options={[
                    { label: "全部任务", value: 1 },
                    { label: "单次任务", value: 2 },
                    { label: "周期任务", value: 3 },
                ]}
            />
        </div>
        <div className={styles.chartBox + " swiper-no-swiping"}>
            {
                errorMsg
                    ? <div className={styles.errorMsg}>
                        {errorMsg}
                    </div>
                    : <EChartsReact style={{ width: '100%', height: '100%' }} option={getOption()} />
            }
        </div>
    </div>
}

export default PositionLogCount