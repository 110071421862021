import React, {useEffect, useState} from 'react';
import { request } from '../../../../../../tool/http'
import styles from "./PutDatgaSourceMsg.less";
import {Select,Table,Tabs} from "antd";

export default function PutDataSourceMsg(props) {
  const [errorMsg, setErrorMsg] = useState(null)
  const [order,setOrder] = useState("sum")
  const [type,setType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel[0] || "input")
  const [data,setData] = useState(null)

  useEffect(() => {
    getBusinessData()
  }, [order,type,props.dataUpdate])

  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    request(`${props.contentMsg?.proConfig?.apiPrefix}/execUnit/getIoDataSourceCount`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        type: type, //从自定义配置里取参
        order: order,
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }

  //表格列配置
  const columns = [
    {
      title: '数据来源',
      dataIndex: 'from',
      key: 'from',
      render: (text)=>{
        return {
          1:"API",
          2:"手动",
          3:"执行单元输入",
          4:"模块",
          5:"AI生成",
          6:"工作表",
          7:"触发器",
          8:"周期任务",
        }[text]
      }
    },
    {
      title: '条数',
      dataIndex: 'sum',
      key: 'sum',
    },
    {
      title: '使用次数',
      dataIndex: 'count',
      key: 'count',
    },
  ]

  let items = [];
  if(props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes("input")){
    items.push({
      key: 'input',
      label: '输入',
      children: (
        <div className={styles.tabPaneBox}>
          {
            errorMsg
              ? <div className={styles.errorMsg}>
                {errorMsg}
              </div>
              :<Table
                size={"small"}
                bordered={true}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
          }
        </div>
      ),
    })
  }
  if(props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes("output")){
    items.push({
      key: 'output',
      label: '输出',
      children: (
        <div className={styles.tabPaneBox}>
          {
            errorMsg
              ? <div className={styles.errorMsg}>
                {errorMsg}
              </div>
              :<Table
                size={"small"}
                bordered={true}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
          }
        </div>
      ),
    })
  }

  return <div
    className={styles.outer + " swiper-no-swiping"}
  >
    <Tabs
      className={styles.tabsBox}
      destroyInactiveTabPane={true}
      activeKey={type}
      onChange={(key) => {
        setType(key)
      }}
      tabBarExtraContent={<Select
        style={{width:"120px"}}
        value={order}
        onChange={(v)=>{
          setOrder(v)
        }}
        options={[
          {label: "按条数排序",value:"sum"},
          {label: "按使用次数",value:"count"},
        ]}
      />}
      items={items}
    />
  </div>
}