import React from "react";
import {Input,Space,Button} from "antd";
import styles from "./SelectOptionMake.less";
import {MinusCircleOutlined,PlusCircleOutlined} from "@ant-design/icons";

export default class SelectOptionMake extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value || []
        }
    }

    render(){
        let {value} = this.state;
        return <Space
            direction={"vertical"}
            style={{width: "100%"}}
        >
            {
                value?.map((item,index)=>{
                    return <div
                        className={styles.itemBox}
                        key={"ss_" + value.label + index}
                    >
                        <MinusCircleOutlined
                            style={{color: "red",fontSize: "16px"}}
                            onClick={()=>{
                                _.remove(value,(ti,n)=>{
                                    return n === index
                                })
                                this.setState({
                                    value: value
                                },()=>{
                                    this.props.onChange?.([].concat(value));
                                })
                            }}
                        />
                        <div
                            className={styles.itemValue}
                        >
                            <Input
                                addonBefore={"名"}
                                maxLength={30}
                                placeholder={"设置选项名"}
                                value={item.label}
                                onChange={(e)=>{
                                    item.label = e.target.value;
                                    this.setState({
                                        value: value
                                    },()=>{
                                        this.props.onChange?.([].concat(value));
                                    })
                                }}
                            />
                            <Input
                                addonBefore={"值"}
                                maxLength={50}
                                placeholder={"设置选项值"}
                                value={item.value}
                                onChange={(e)=>{
                                    item.value = e.target.value;
                                    this.setState({
                                        value: value
                                    },()=>{
                                        this.props.onChange?.([].concat(value));
                                    })
                                }}
                            />
                        </div>
                    </div>
                })
            }
            <Button
                type={"link"}
                onClick={()=>{
                    value.push({
                        label: "",
                        value: null
                    });
                    this.setState({
                        value: value
                    },()=>{
                        this.props.onChange?.([].concat(value));
                    })
                }}
            >
                <span style={{color: "limegreen"}}>
                    <PlusCircleOutlined />
                    &nbsp;&nbsp;
                    新增映射选项</span>
            </Button>
        </Space>
    }
}