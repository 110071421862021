import React, { memo, useEffect, useState } from 'react'
import styles from './digitalHumanOnlineAnalysisOne.less'
import { Flex, Select, Statistic } from 'antd'
import { request } from '../../../../../../tool/http'
// import HumanLineChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanLineChart/humanLineChart'


function DigitalHumanOnlineAnalysisOne(props: any) {
  // const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1])
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(30)
  const [data, setData] = useState<any>([])
  const [option, setOption] = useState<any>({})
  const [averageNumber, setAverageNumber] = useState(0)
  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg('')
    request(`${props.contentMsg?.proConfig?.apiPrefix}digitalHuman/digitalHumanOnlines`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }
  useEffect(() => {
    getBusinessData()
  }, [showType, time, props.dataUpdate])

  //获取图表数据
  const getOption = () => {
    let xAxis = [
      {
        color: '#EE6666',
        chartTitle: '在线人数',
        //角度调整
        axisLabel: {
          rotate: 30
        },
        data: data.map((item: any) => item._id)
      }
    ]

    let series = [{
      type: props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanOnlineAnalysisTwo' ? 'bar' : 'line', //这里判断是折线状还是柱状图
      name: '在线人数',
      data: data.map((item: any) => item.count),
      color: '#EE6666',
      areaColor: 'rgba(238, 102, 102,0.2)',
      label: {
        show: props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanOnlineAnalysisThree' ? false : true,
        //修改折线图上的label颜色
        color: '#EE6666',
        position: 'top',
      }
    }]
    let option = {
      polar: props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanOnlineAnalysisThree',
      xAxis,
      series
    }
    setOption(option)
  }
  // 计算data里count的平均值
  const getAverage = (data: any) => {
    let sum = 0
    data.forEach((item: any) => {
      sum += item.count
    })
    //取整
    return Math.floor(sum / data.length)
  }
  useEffect(() => {
    console.log('data====', data)
    if (data.length > 0) {
      getOption()
      setAverageNumber(getAverage(data))
    }
  }, [data])

  return (
    <div className={styles.outer}>
      <div
        className={styles.chartBox + " swiper-no-swiping"}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex>
                {showType.includes(1) && <Flex className={styles.averageQuantityBulk} justify='center' align='center'>
                  <Statistic title="平均每天可用数字人" value={averageNumber} suffix="人" />
                </Flex>}
                <Flex justify='end' flex={1} className={" swiper-no-swiping"}>
                  <Select
                    style={{ width: "120px" }}
                    value={time}
                    onChange={(v) => {
                      setTime(v)
                    }}
                    options={[
                      { label: "30日", value: 30 },
                      { label: "15日", value: 15 },
                      { label: "7日", value: 7 },
                    ]}
                  >
                  </Select>
                </Flex>
              </Flex>

              {/* {showType.includes(2) && <HumanLineChart option={option}></HumanLineChart>} */}
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(DigitalHumanOnlineAnalysisOne)