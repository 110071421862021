import React, { useEffect, useState } from 'react';
import { request, useRequest } from "umi";
import styles from "./DigitalHumanDataSourceBar.less";
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts'
import { Card, Statistic } from 'antd';

const DigitalHumanDataSourcePie: React.FC<any> = (props) => {
  console.error(props)
  const [errorMsg, setErrorMsg] = useState(null)
  const [errorMsg1, setErrorMsg1] = useState(null)
  const [digitalHumanTotal, setDigitalHumanTotal] = useState()
  const [dataSource, setDataSource] = useState<{
    "offline": number,
    "idle": number,
    "work": number,
    "shutDown": number,
    'online': number
  }>()

  // useEffect(() => {
  //   if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('digitalHumanTotal')) {
  //     runTotalBusniess()
  //   }

  //   if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('digitalHumanStatus')) {
  //     runStatusRatio()
  //   }
  // }, [props.dataUpdate, JSON.stringify(props.contentMsg?.dataConfig?.contentParams?.typeShowSel)])

  // const { run: runTotalBusniess } = useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}digitalHuman/getDigitalHumanCount`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     status: status.split(',').map(Number)
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   manual: true,
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       setDigitalHumanTotal(res?.data || 0)
  //     } else {
  //       setErrorMsg(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })

  // const { run: runStatusRatio } = useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}digitalHuman/statusRatio`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   manual: true,
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       setErrorMsg1(null)
  //       //转化数据成需要的格式
  //       setDataSource({ ...res.data, online: res?.data?.work + res?.data?.idle || 0 })
  //     } else {
  //       setErrorMsg1(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg1(err.toString())
  //   }
  // })

  const labelOption = {
    show: true,
    position: 'top',
    color: "#fff"
  }

  const getOption = (): echarts.EChartsOption => {
    return {
      grid: {
        left: '5%',
        right: '5%',
        bottom: '3%',
        containLabel: true
      },
      legend: {
        textStyle: {
          color: '#fff'
        }
      },
      xAxis: { type: 'category' },
      yAxis: [
        {
          splitLine: {
            show: false
          }
        }
      ],
      dataset: {
        source: [
          ['product', '在线', '离线', '停工', '空闲', '忙碌'],
          ['在线状态人数及比例', null, null, null, dataSource?.idle, dataSource?.work],
          ['在线离线人数比例', dataSource?.online, dataSource?.offline, dataSource?.shutDown],
        ]
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
      },
      series: [{
        type: 'bar',
        barGap: 0,
        label: labelOption,
        emphasis: {
          label: {
            show: true,
            position: 'inside',  // 中间显示百分比
            formatter: function (params) {
              // 假设总数为 500，这里可以根据实际总和来计算百分比
              var total = dataSource?.online + dataSource?.offline + dataSource?.shutDown;
              var percent = ((dataSource?.online / total) * 100).toFixed(1);
              return percent + '%';  // 返回百分比
            },
            fontSize: 12,
            color: '#fff'  // 设置为白色字体显示在柱子中
          }
        },
      },
      {
        type: 'bar',
        barGap: 0,
        label: labelOption,
        emphasis: {
          label: {
            show: true,
            position: 'inside',  // 中间显示百分比
            formatter: function (params) {
              // 假设总数为 500，这里可以根据实际总和来计算百分比
              var total = dataSource?.online + dataSource?.offline + dataSource?.shutDown;
              var percent = ((dataSource?.offline / total) * 100).toFixed(1);
              return percent + '%';  // 返回百分比
            },
            fontSize: 12,
            color: '#fff'  // 设置为白色字体显示在柱子中
          }
        },
      },
      {
        type: 'bar',
        label: labelOption,
        emphasis: {
          label: {
            show: true,
            position: 'inside',  // 中间显示百分比
            formatter: function (params) {
              // 假设总数为 500，这里可以根据实际总和来计算百分比
              var total = dataSource?.online + dataSource?.offline + dataSource?.shutDown;
              var percent = ((dataSource?.shutDown / total) * 100).toFixed(1);
              return percent + '%';  // 返回百分比
            },
            fontSize: 12,
            color: '#fff'  // 设置为白色字体显示在柱子中
          }
        },
      },
      {
        type: 'bar',
        label: labelOption,
        emphasis: {
          label: {
            show: true,
            position: 'inside',  // 中间显示百分比
            formatter: function (params) {
              // 假设总数为 500，这里可以根据实际总和来计算百分比
              var total = dataSource?.online + dataSource?.offline;
              var percent = ((dataSource?.online / total) * 100).toFixed(1);
              return percent + '%';  // 返回百分比
            },
            fontSize: 12,
            color: '#fff'  // 设置为白色字体显示在柱子中
          }
        },
      },
      {
        type: 'bar',
        label: labelOption,
        emphasis: {
          label: {
            show: true,
            position: 'inside',  // 中间显示百分比
            formatter: function (params) {
              // 假设总数为 500，这里可以根据实际总和来计算百分比
              var total = dataSource?.online + dataSource?.offline;
              var percent = ((dataSource?.offline / total) * 100).toFixed(1);
              return percent + '%';  // 返回百分比
            },
            fontSize: 12,
            color: '#fff'  // 设置为白色字体显示在柱子中
          }
        },
      }]
    };
  };

  return <div className={styles.outer + " swiper-no-swiping"}>
    {
      props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('digitalHumanTotal')
      && <div className={styles.charBox1}>
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Card bordered hoverable className={styles.card}>
              <Statistic
                title="数字人总数"
                value={digitalHumanTotal}
                suffix="台"
                precision={0}
              />
            </Card>
        }
      </div>
    }
    {
      props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('digitalHumanStatus')
      && <div className={styles.chartBox}>
        {
          errorMsg1
            ? <div className={styles.errorMsg}>
              {errorMsg1}
            </div>
            : <EChartsReact style={{ width: '100%', height: '100%' }} option={getOption()} />
        }
      </div>
    }
  </div>
}


export default DigitalHumanDataSourcePie