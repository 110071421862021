import { Form, Divider, Checkbox } from "antd";

//与图表展示方式有关的配置
function getSetItems() {
    return <>
        <Divider orientation="left">
            展示方式配置
        </Divider>

        <Form.Item
            name={"ranking"}
            label={"展示数据"}
            rules={[{ required: true }]}
        >
            <Checkbox.Group>
                <Checkbox value={"ranking"} >执行单元引用次数排行</Checkbox>
            </Checkbox.Group>
        </Form.Item>
    </>
}
//getSetItems配置内容的默认值
function getDefaultValues() {
    return {
    }
}

//可由外部传入的参数定义
function getInputArg() {
    return {
        "size": {
            key: "size",
            name: "显示条数",
            descs: "显示条数",
            type: "Select",
            value: {
                value: "5条",
                defaultValue: "5条"
            },
            list: ["5条", "10条"]
        },
    }
}
export default {
    getSetItems,
    getDefaultValues,
    getInputArg
}