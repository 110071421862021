import React, { memo, useEffect, useState } from 'react'
import styles from './digitalPersonLongOffline.less'
import { Flex, Select, Statistic } from 'antd'
import { request } from '../../../../../../tool/http'
// import HumanLineChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanLineChart/humanLineChart'
// import HumanPieChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanPieChart/humanPieChart'


function DigitalPersonLongOffline(props: any) {
  // console.log('每天的人数---展示', props)
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  //判断是柱状图还是饼图
  let isPieChart = props?.contentMsg?.dataConfig?.contentType === 'system_digitalPersonLongOfflineTwo'
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(30)
  const [data, setData] = useState<any>([])
  const [option, setOption] = useState<any>({})
  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg('')
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/digitalHumanOfflineTimeRate`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }
  useEffect(() => {
    getBusinessData()
  }, [showType, time, props.dataUpdate])

  //获取图表数据
  const getOption = () => {

    let xAxis = [
      {
        color: '#f9896b',
        chartTitle: '人数',
        data: data.map((item: any) => item._id)
      }
    ]

    let series = [{
      type: 'bar', //这里判断是折线状还是柱状图
      name: '人数',
      data: data.map((item: any) => item.count),
      color: '#f9896b',
    }]

    let pieSeries = [{
      type: 'pie',
      name: '人数',
      radius: '70%',
      center: ['50%', '50%'],
      data: data.map((item: any) => {
        return {
          name: '离线' + item._id + '天',
          value: item.count
        }
      }),
    }]

    let option = {
      xAxis,
      series: isPieChart ? pieSeries : series
    }
    setOption(option)
  }
  useEffect(() => {
    // console.log('长时间离线的数字人---', data)
    if (data.length > 0) {
      getOption()
    }
  }, [data])

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      <div
        className={styles.chartBox}
      >
        {/* {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              {showType.includes(1) && isPieChart ? <HumanPieChart option={option}></HumanPieChart> : <HumanLineChart option={option}></HumanLineChart>
              }
            </Flex>
        } */}
      </div>
    </div>

  )
}
export default memo(DigitalPersonLongOffline)