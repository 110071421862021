import { Form, Divider, Checkbox, Input } from "antd";
import ColorPicker from "../../../formDiyItem/ColorPicker";

//与图表展示方式有关的配置
function getSetItems() {
    return <>
        <Divider orientation="left">
            展示方式配置
        </Divider>

        <Form.Item
            name={"typesSel"}
            label={"展示数据"}
            rules={[{ required: true }]}
        >
            <Checkbox.Group>
                <Checkbox value={"unit"}>执行单元数据</Checkbox>
            </Checkbox.Group>
        </Form.Item>

        <Form.Item
            name={"cardBgSel"}
            label={"卡片背景色"}
        >
            <ColorPicker />
        </Form.Item>

        <Form.Item
            name={"totalFontSize"}
            label={"总数字体大小"}
        >
            <Input />
        </Form.Item>
        <Form.Item
            name={"totalFontColor"}
            label={"总数字体颜色"}
        >
            <ColorPicker />
        </Form.Item>
    </>
}
//getSetItems配置内容的默认值
function getDefaultValues() {

    return {
        typesSel: ['unit'],
        cardBgSel: 'linear-gradient(135deg,rgba(246, 198, 249, 0.4) 0%,rgba(164, 164, 218, 0.4) 100%)',
        totalFontSize: 24
    }
}

//可由外部传入的参数定义
function getInputArg() {
    return {
        "count": {
            key: "count",
            name: "查询结果数量",
            descs: "不传则查询全部",
            type: "Number",
            required: false,    //可以设置此项非必填
            value: {
                value: null,
                defaultValue: null
            },
        }
    }
}
export default {
    getSetItems,
    getDefaultValues,
    getInputArg
}