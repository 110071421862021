import React, { memo, useEffect, useState } from 'react'
import styles from './taskLogProportion.less'
import { Flex, Select, Statistic } from 'antd'
import { request } from '../../../../../../tool/http'
// import TaskSelectTwo from '@/pages/containers/dashboard/contentView/view/taskSelectTwo/taskSelectTwo'
// import HumanPieChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanPieChart/humanPieChart'
function TaskLogProportion(props: any) {
  // console.log('每天的在线人数---展示', props)
  const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1])
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(1)
  const [data, setData] = useState<any>([])
  const [option, setOption] = useState<any>({})
  const [takeSelectType, setTakeSelectType] = useState<any>(0)
  const [takeSelect, setTakeSelect] = useState<any>([])
  //请求业务数据  
  const getBusinessData = () => {
    setErrorMsg('')
    // let taskVal = [] as any
    let objVal = {} as any
    objVal[takeSelectType === 2 ? 'triggerIds' : 'execUnitPlanIds'] = takeSelect || []
    //判断单次任务和周期任务的值

    request(`${props.contentMsg?.proConfig?.apiPrefix}/taskStatistics/getStatisticsLogs`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time,
        type: takeSelectType ? takeSelectType : '',
        ...objVal
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }
  // 使用 useEffect 监听 props.contentMsg?.dataConfig?.contentParams?.typeShowSel 的变化
  useEffect(() => {
    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel !== showType) {
      // 如果 props 发生了变化，更新本地状态并触发接口调用
      setShowType(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]);
    }
  }, [props.contentMsg?.dataConfig?.contentParams?.typeShowSel]); // 监听 props 中的这个值
  
  useEffect(() => {
    getBusinessData()
  }, [showType, time, props.dataUpdate, takeSelectType, takeSelect])

  //获取图表数据
  const getOption = () => {
    let dataFilter = [] as any[]
    //对应数据字段
    let dataKey: any = {
      distributeing: '分发',
      undistributed: '未分发'
    }
    Object.keys(dataKey).forEach((key: any) => {
      if (key === 'distributeing') {
        dataFilter.push({
          _id: dataKey[key],
          count: data?.running + data?.success + data?.fail + data?.noExec + data?.timeout
        })
      } else {
        dataFilter.push({
          _id: dataKey[key],
          count: data[key]
        })
      }
    })
    console.log('dataFilter++++=', dataFilter)
    let series = [{
      type: 'pie',
      name: '人数',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: 'transparent',
        borderWidth: 2
      },
      label: {
        show: true,
        //位置在里面
        formatter: ' {b} : {d}%',
      },
      data: dataFilter.map((item: any) => {
        return {
          name: item._id,
          value: item.count
        }
      }),
    }]
    let option = {
      series,
      tooltip: {
        formatter: '{b} : {c} '
      }
    }
    setOption(option)
  }
  useEffect(() => {
    console.log('日志分发占比', data)
    if (data) {
      getOption()
    }
  }, [data])

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      <div
        className={styles.chartBox}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex style={{ marginBottom: '16px' }}>
                <Flex justify='end' flex={1} className={" swiper-no-swiping"}>
                  <Select
                    style={{ width: "120px" }}
                    value={time}
                    onChange={(v) => {
                      setTime(v)
                    }}
                    options={[
                      { label: "今日", value: 1 },
                      { label: "30日", value: 30 },
                      { label: "15日", value: 15 },
                      { label: "7日", value: 7 },
                    ]}
                  >
                  </Select>
                  <Select style={{ width: "120px", marginLeft: '10px' }}
                    value={takeSelectType}
                    onChange={(v) => {
                      setTakeSelectType(v)
                    }}
                    options={[
                      { label: "全部任务", value: 0 },
                      { label: "单次任务", value: 2 },
                      { label: "周期任务", value: 3 },
                    ]} >

                  </Select>
                  {/* <TakeSelectOne
                    data={{
                      option: {
                        defaultValue: takeSelect
                      }
                    }}
                    businessId={[props.contentMsg?.proConfig?.usageParams?.businessId]}
                    businessType={props.contentMsg?.proConfig?.usageParams?.usageCode}
                    verification={(data, value) => {
                      setTakeSelect(value)
                      console.log(data, value)
                    }}
                  ></TakeSelectOne> */}
                  {/* {takeSelectType !== 0 && <TaskSelectTwo
                    data={{
                      option: {
                        defaultValue: null
                      }
                    }}
                    takeType={takeSelectType}
                    businessId={[props.contentMsg?.proConfig?.usageParams?.businessId]}
                    businessType={props.contentMsg?.proConfig?.usageParams?.usageCode}
                    verification={(data, value) => {
                      setTakeSelect(value)
                      console.log(data, value)
                    }}
                  >
                  </TaskSelectTwo>} */}

                </Flex>
              </Flex>

              {/* {showType.includes(1) && <HumanPieChart option={option}></HumanPieChart>} */}
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(TaskLogProportion)