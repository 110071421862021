import { useEffect, useRef, useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./PutDataSourceTime.less";
import { Button, Flex, Select, } from "antd";
import * as echarts from 'echarts'

const DefaultFromArray = {
  1: 'API',
  2: '手动',
  3: '执行单元输入',
  4: '模块',
  5: 'AI生成',
  6: '工作表',
  7: '触发器',
  8: '周期任务'
}

export default function PutDataSourceTime(props) {
  console.error(props)
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const [errorMsg, setErrorMsg] = useState(null)
  const [time, setTime] = useState(1)
  const [type, setType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel[0] || "input")
  const [from, setFrom] = useState<Array<string>>(Object.keys(DefaultFromArray))
  const [data, setData] = useState(null)
  const [hourTime, setHourTime] = useState<any>('')

  // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}execUnit/getIoDataInsertTimeCount`, {
  //   data: hourTime ? {
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     from: from,
  //     type: type,
  //     startTime: hourTime ? getTimestamps(hourTime).startTime : '',
  //     endTime: hourTime ? getTimestamps(hourTime).endTime : '',
  //   } : {
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     from: from,
  //     time: time,
  //     type: type,
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate, time, type, from, hourTime],
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       setErrorMsg(null)
  //       //转化数据成需要的格式
  //       setData(res?.data || [])
  //     } else {
  //       setErrorMsg(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })

  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current);
      // 注册点击事件监听器
      chartInstance.current.on('click', (params) => {
        if (params.componentType === 'xAxis') {
          setHourTime(params?.value)
        }
      });
    }
    return () => {
      chartInstance.current?.dispose();
    };
  }, []);

  useEffect(() => {
    if (data)
      setValue()
  }, [JSON.stringify(data)])

  //格式化转换
  const formatDate = (dateStr: any) => {
    const year = dateStr.slice(0, 4);  // 提取年份
    const month = dateStr.slice(4, 6); // 提取月份
    const day = dateStr.slice(6, 8);   // 提取日期
    return `${year}-${month}-${day}`;  // 返回格式化后的日期
  }
  const getTimestamps = (dateStr: string) => {
    // 提取年、月、日
    const year = parseInt(dateStr.slice(0, 4));
    const month = parseInt(dateStr.slice(4, 6)) - 1;  // 月份从 0 开始计数
    const day = parseInt(dateStr.slice(6, 8));

    // 创建这一天的开始时间：00:00:00
    const startDate = new Date(year, month, day, 0, 0, 0);
    const startTimeStamp = startDate.getTime();  // 开始时间的时间戳

    // 创建这一天的结束时间：23:59:59
    const endDate = new Date(year, month, day, 23, 59, 59);
    const endTimeStamp = endDate.getTime();  // 结束时间的时间戳

    return {
      startTime: startTimeStamp,
      endTime: endTimeStamp
    };
  }

  const getAxisValueData = () => {
    return data?.map((item: any) => item.addTime)
  }

  console.error(getAxisValueData())
  const getSeriesValuesData = (key: number) => {
    let key_datas: Array<number> = []
    data?.forEach((item: any) => {
      item?.events && item?.events?.forEach((data: any) => {
        if (data?.from === Number(key)) {
          key_datas.push(data?.sum || 0)
        }
      })
    })
    return key_datas
  }

  const getSeries = () => {
    let seriesDatas: { name: any; type: string; stack: string, data: number[]; }[] = []
    Object.keys(DefaultFromArray).forEach(key => {
      seriesDatas.push({
        name: DefaultFromArray[key],
        type: 'line',
        stack: 'Total',
        data: getSeriesValuesData(key),
      })
    })
    console.error(seriesDatas)
    return seriesDatas
  }

  const setValue = () => {
    chartInstance.current?.setOption(getOption())
  }

  const getOption = (): echarts.EChartsOption => ({
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      textStyle: {
        color: '#fff',
      },
      data: Object.values(DefaultFromArray)
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: getAxisValueData(),
      axisLabel: {
        rotate: 30
      },
      triggerEvent: (hourTime || time === 1) ? false : true,
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
    },
    series: getSeries()
  })

  return <div className={styles.outer + " swiper-no-swiping"}>
    <div className={styles.controlBar}>
      {!hourTime && <Select
        style={{ width: "90px" }}
        value={time}
        onChange={(v) => {
          setTime(v)
        }}
        options={[
          { label: "今日", value: 1 },
          { label: "7日", value: 7 },
          { label: "15日", value: 15 },
          { label: "30日", value: 30 },
        ]}
      />
      }
      <Select
        style={{ width: "120px" }}
        value={type}
        onChange={(v) => {
          setType(v)
        }}
        options={[
          { label: "输入", value: 'input' },
          { label: "输出", value: 'output' },
        ]}
      />
      {hourTime && <Flex align='center' gap={12}>
        <div>
          {formatDate(hourTime)}
        </div>
        <Button type='text' onClick={() => { setHourTime('') }}>取消选择</Button>
      </Flex>}
    </div>
    <div className={styles.chartBox}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : <div ref={chartRef} style={{ width: '100%', height: '100%', }}></div>
      }
    </div>
  </div>
}
