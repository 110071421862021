import React from "react";
import {
    Form,
    Divider,
    Switch,
    Select,
    Input,
    InputNumber,
    AutoComplete
} from "antd";
import CommonFormItems from "../../../CommonFormItems";

const Item = Form.Item;

function getSetItems(extendData = {}) {
    let opts = [];
    if (extendData?.bindData) {
        opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
    }
    return <>
        <Divider orientation="left">
            数据对接设置
        </Divider>
        <Item
            label={"小标题字段"}
            name={"titleField"}
            rules={[{required: false}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"主信息字段"}
            name={"descField"}
            rules={[{required: true}]}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"扩展信息字段"}
            name={"labelField"}
            rules={[{required: false}]}
            extra={"若无扩展信息，此项可不填"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"状态颜色字段"}
            name={"statusColorField"}
            rules={[{required: false}]}
            extra={"若打算使用默认颜色，此项可不填"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Item
            label={"默认展示位标记"}
            name={"markField"}
            rules={[{required: false}]}
            extra={"首屏滚动到哪一项位置。当启用动画时，此配置无效。"}
        >
            <AutoComplete
                placeholder={"请选择或填写对接字段"}
                options={opts}
            />
        </Item>
        <Divider orientation="left">
            排版配置
        </Divider>
        <Item
            label={"排列方式"}
            name={"mode"}
            rules={[{required: true}]}
        >
            <Select>
                <Select.Option value={"left"}>左对齐</Select.Option>
                <Select.Option value={"right"}>右对齐</Select.Option>
                <Select.Option value={"alternate"}>左右交替出现</Select.Option>
            </Select>
        </Item>
        <Item
            label={"倒序"}
            name={"reverse"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            label={"动画间隔时间"}
            name={"insertTime"}
            rules={[{required: true}]}
        >
            <InputNumber
                step={0.5}
                precision={1}
                min={1}
                max={10}
                addonAfter={"秒"}
            />
        </Item>
        <Item
            label={"启用动画"}
            name={"insertAction"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Divider orientation="left">
            小标题样式设置
        </Divider>
        {CommonFormItems.getFontSet("titleFontSet", true)}
        <Divider orientation="left">
            主信息样式设置
        </Divider>
        {CommonFormItems.getFontSet("descFontSet", true)}
        <Divider orientation="left">
            扩展信息样式设置
        </Divider>
        {CommonFormItems.getFontSet("labelFontSet", true)}
        <Divider orientation="left">
            图片展示样式设置
        </Divider>
        <Item
          label={"图片圆角"}
        >
            {CommonFormItems.distanceSet(["imgSet","radiusSet", "num"],["imgSet","radiusSet", "unit"],["px","rem","%"])}
        </Item>
        <Item
          label={"图片宽度"}
        >
            {CommonFormItems.distanceSet(["imgSet","widthSet", "num"], ["imgSet","widthSet", "unit"], ["px", "rem"])}
        </Item>
    </>
}

function getDefaultValues() {
    return {
        mode: "left",// 排列 left,right,alternate
        reverse: false,//   倒序
        insertAction: false,    //插入动画
        insertTime: 3.0,    //插入时间间隔
        labelField: null,
        titleField: null,
        descField: null,
        markField: null,
        statusColorField: null,
        labelFontSet: {
            color: "#843CFF",
            fontFamily: "default",
            fontSize: 14,
            sizeUnit: "rem", //px rem
            fontBold: false, //加粗
            fontItalic: false, //倾斜
        },
        titleFontSet: {
            color: "#ffa184",
            fontFamily: "default",
            fontSize: 16,
            sizeUnit: "rem", //px rem
            fontBold: false, //加粗
            fontItalic: false, //倾斜
        },
        descFontSet: {
            color: "#ffffff",
            fontFamily: "default",
            fontSize: 12,
            sizeUnit: "rem", //px rem
            fontBold: false, //加粗
            fontItalic: false, //倾斜
        },
        imgSet: {
            radiusSet: {
                num: 0,
                unit: "px"
            },
            widthSet: {
                width: 150,
                unit: "px"
            }
        }
    }
}

function getDemoCode() {
    return `
    以下示例中 title、desc、label、color、marked 均可在 数据对接设置 中修改替换成其它单词
    1.最简略的时间线数据
    [
        {
            title: "2022年第一期",
            desc: "发生了事件A",
        },
        {
            title: "2022年第二期",
            desc: "发生了事件B",
        },
        ...
    ]
    
    2.可携带扩展信息
    [
        {
            title: "2022年第一期",
            desc: "发生了事件A",
            label: "扩展内容123"
        },
        {
            title: "2022年第二期",
            desc: "发生了事件B",
            label: "扩展内容123"
        },
        ...
    ]
    
    3.传html字符串自定义展示格式
    [
        {
            title: "<span>中间<span style='color:red;font-size:18px'>醒目文字</span>演示</span>",
            desc: "<span style='text-decoration-line:line-through'>这段文字有删除线</span>",
            label: "<span style='border: 1px solid blue;background: yellow'>这段文字有边框和背景</span>",
        },
        {
            title: "普通字符串",
            desc: "普通字符串2",
            label: "<span style='font-style:italic'>自定义格式字符串</span>",
        },
        {
            title: "标题",
            desc: "描述",
            label: "扩展信息",
        },
        ...
    ]
    
    4.对每一项事件的小图标设置颜色
    [
        {
            title: "2022年第一期",
            desc: "发生了事件A",
            color: "#ff00ff",
        },
        {
            title: "2022年第二期",
            desc: "发生了事件B",
            color: "red",
        },
        ...
    ]
    
    5.将第三项设置为首屏展示顶部位置
    [
        {
            title: "2022年第一期",
            desc: "发生了事件A",
        },
        {
            title: "2022年第二期",
            desc: "发生了事件B",
        },
        {
            title: "2022年第三期",
            desc: "发生了事件C",
            marked: true,
        },
           {
            title: "2022年第四期",
            desc: "发生了事件D",
        },
        ...
    ]
    `
}

export default {
    getSetItems,
    getDefaultValues,
    getDemoCode
}