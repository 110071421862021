import React from "react";
import styles from "./AutoScrollCard.less";
import {Empty} from "antd";

export default class AutoScrollCard extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      flag: this.props.flag || false,
      rollTop: this.props.rollTop || 1,
      stop: false,
      tableY:this.props.tableY || 500,
      needScroll: false
    }


    this.timer = null;
    this.tempTop = 0
  }

  componentDidMount() {
    this.io = new IntersectionObserver((entries)=>{
      if (entries[0].intersectionRatio <= 0){
        this.tempTop = 0;
        this.scrollBox && (this.scrollBox.scrollTop = this.tempTop);
      }
    });
    this.checkIfNeedScroll();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.dataSource?.length !== this.props.dataSource?.length){
      this.checkIfNeedScroll()
    }
  }


  checkIfNeedScroll(){
    console.log("判断是否滚动")
    let container = this.scrollBox;
    let beforeBox = this.scrollBodyBefore;
    let scrollL = Number(beforeBox.clientHeight - container.clientHeight)
    if(this.state.flag && scrollL>=(this.props.limitDistance || 100)){
      if(!this.state.needScroll){
        this.setState({
          needScroll: true
        },()=>{
          this.io.observe(
            this.scrollBodyBefore
          );
          this.goScroll()
        })
      }
    }else{
      this.io.unobserve(this.scrollBodyBefore)
      this.timer && window.cancelAnimationFrame(this.timer);
      this.timer = null;
      this.setState({
        needScroll: false
      })
    }
  }

  componentWillUnmount() {
    this.timer && window.cancelAnimationFrame(this.timer)
    this.io && this.io.disconnect()
  }


  goScroll(){
    this.startTime = Date.now();
    let {rollTop} = this.state;
    let container = this.scrollBox;

    let callbackFn = ()=>{
      if (!this.state.stop) {
        let scrollL = Number(container.scrollHeight - container.clientHeight)
        //scrollTop 无法一次性加小于1的数值，用 tempTop 做累加中转
        this.tempTop += Number((Date.now() - this.startTime) /(this.props.scrollSpeed || 50) * rollTop)
        container.scrollTop = Math.ceil(this.tempTop);
        if (
          Math.ceil(container.scrollTop) >= scrollL
        ) {
          this.tempTop = 0;
          container.scrollTop = this.tempTop;
        }
      }
      this.startTime = Date.now();
      this.timer = window.requestAnimationFrame(callbackFn)
    }

    this.timer = window.requestAnimationFrame(callbackFn)
  }


  render(){
    let {needScroll} = this.state;
    let {dataSource} = this.props;
    return (
      <div
        className={styles.outer}
        onMouseOver={() => {
          this.setState({
            stop: true
          })
        }}
        onMouseOut={() => {
          this.setState({
            stop: false
          })
        }}
      >
        <div className={styles.body}>
          <div className={styles.bodyScroll}
               ref={ref=>this.scrollBox = ref}>
            {
              dataSource?.length || this.props.notShowEmptyMsg
                ?null
                :<div className={styles.emptyBox}>
                  <Empty
                    imageStyle={{ opacity: 0.3}}
                    description={<span style={{color: "#ffffff66"}}>暂无数据</span>}
                  />
                </div>
            }
            <div className={styles.bodyBefore} ref={ref=>this.scrollBodyBefore = ref}>
              {
                dataSource?.map((eachData,index)=>{
                  return this.props.renderRow?.(eachData,index)
                })
              }
            </div>
            <div className={styles.bodyAfter}
                 style={{
                   marginTop: this.props.scrollSpaceWithUnit || (this.props.scrollSpace? `-${this.props.scrollSpace}px`: 0),
                   opacity: needScroll? 1:0,
                   height: needScroll?"unset":0
                 }}
            >
              {
                dataSource?.map((eachData,index)=>{
                  return this.props.renderRow?.(eachData,index)
                })
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}