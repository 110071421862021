import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import { Button, message, Popover, Tooltip, Tag, Badge, Space } from "antd";
import TimeHelp from "../../../tool/TimeHelp.js";
import ModalBase from "../../../components/modalBase/index";
import less from "../accountManage/AccountManage.less";
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import LayoutCountrySelect from "../../../components/areaSelect/CountrySelectUseForLayout.js";
import CodeGetInput from "./CodeGetInput.js";
import HttpTool from "../../../tool/HttpTool.js";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

const ModalDelete = new ModalDel();
const MB = new ModalBase();

const StatusMap = {
  1: {
    title: "未绑定",
    value: 1,
    color: "gray",
  },
  2: {
    title: "绑定中",
    value: 2,
    color: "darkorange",
  },
  3: {
    title: "待机",
    value: 3,
    color: "dodgerblue",
  },
  4: {
    title: "绑定失败",
    value: 4,
    color: "red",
  },
  5: {
    title: "激活中",
    value: 5,
    color: "darkorange",
  },
  6: {
    title: "已激活",
    value: 6,
    color: "limegreen",
  },
  7: {
    title: "激活失败",
    value: 7,
    color: "red",
  },
  // 8: {
  //   title: "回收",
  //   value: 8,
  //   color: "red",
  // },
  9: {
    title: "回收中",
    value: 9,
    color: "red",
  },
}

const DeviceStatusMap = {
  1: {
    title: "离线",
    value: 1,
    color: "gray",
  },
  2: {
    title: "空闲中",
    value: 2,
    color: "limegreen",
  },
  3: {
    title: "忙碌中",
    value: 3,
    color: "red",
  },
}

class ESIMBlackList extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        columns: this.getTableColumns(),
        url: APILXD.esimGetESIMBlackList,
      },
      search: {
        seniorSearch: null,
        option: {
          hideSeniorBtn: true,
          openSenior: true,
          placeholder: '请输入手机号码/设备ID模糊搜索',
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            value.deviceId = value.keyword
            return true;
          },
        }
      },
      new: {
        defaultValue: '新建',
        action: () => {
          this.add();
        }
      },
      extraBox: () => {
        return null
      }
    }
  }

  getAddData() {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[\S\s]{0,50}$/,
      verMessage: "最多40个字符",
      required: true,
    };
    let props = {
      colCount: 1,
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 16, offset: 0 },
      },
      parameterArr: [ 
        {
          ...defaultOption,
          field: "deviceId",
          name: "手机号/设备ID",
          type: 'input',
          verMessage: "请输入格式正确的手机号/设备ID",
          option: {
            placeholder: "请填写手机号/设备ID",
            maxLength: "40",
          }
        },
      ],
    };
    return {
      props,
      otherParam: null
    }
  }

  add() {
    let addData = this.getAddData();
    MB.show(
      {
        title: "新建SIM",
        okTitle: "提交",
        closeTitle: "取消",
        
      },
      addData.props
      ,
      {
        url: APILXD.esimAddBlackList,
        otherParam: {

        },
        beforeSubmit: (param) => {
          console.log(param)

          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }
  /**
   *  列表数据配置
   */
  getTableColumns() {

    return [
      {
        title: '手机/设备ID',
        dataIndex: 'deviceId',
        key: 'deviceId',
        render: (_, record) => {

          return (
            <Space size={8}>
              <span>{record.deviceId}</span>
              {
                record.status
                  ? <Tag color={DeviceStatusMap[record?.status]?.color}>{DeviceStatusMap[record?.status]?.title}</Tag>
                  : null
              }
            </Space>
          )
        }
      },
      {
        title: '手机号',
        dataIndex: 'mobile',
        key: 'mobile',
      },
      {
        title: '添加时间',
        dataIndex: 'updateTime',
        key: 'updateTime',
        render: (text, record, index) => {
          return <div>{record?.updater?.addTime ? TimeHelp.getYMDHM(record?.updater?.addTime) : "-"}</div>
        }
      },
      {
        title: '操作人',
        dataIndex: 'updater',
        key: 'updater',
        render: (text, record, index) => {
          return <div>{record?.updater?.creater ? record?.updater?.creater : "-"}</div>
        }
      },
      {
        title: '操作',
        width: 300,
        render: (text, record, index) => {
          return (
            <div>
              <div
                className={less.btnDelete}
                onClick={() => {
                  ModalDelete.show({
                    title: "提示",
                    okTitle: "确定",
                    closeTitle: "取消",
                  },
                    {}
                    ,
                    {
                      otherParam: { _id: record._id },
                      content: "SIM列表",
                      url: APILXD.esimDelBlackList,
                      apiType: 'post',
                      ask: "确认将该设备移除",
                      tip: "手机/设备ID：" + record.deviceId ,
                      callBack: (state) => {
                        //删除成功回调
                        state === "success" && this._loadDataForNet();
                      }
                    });
                }}
              >移除
              </div>
              {
                record?.mobile &&  <div
                  className={less.btnDetail}
                  onClick={() => {
                    this.props.openTab({
                      path: 'ESIMMessageList',
                      title: '短信列表',
                      post: {
                        navPath: 'ESIMBlackList',
                        parent: this.props,
                        mobile: record.mobile,
                        status: record.state,
                        deviceStatus: record.status,
                      }
                    })
                  }}
                >短信
              </div>
              }
              
            </div>)
        }
      },
    ]
  }
}

module.exports = ESIMBlackList;