import React from "react";
import {
  Form,
  Divider,
  Radio,
  InputNumber,
  Switch,
} from "antd";
import CommonFormItems from "../../../CommonFormItems";
import CardItemSet from "./CardItemSet";

const Item = Form.Item;

function getSetItems(extendData = {}){
  let opts = [];
  if(extendData?.bindData){
    opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
  }

  return <>
    <Divider orientation="left">
      数据对接设置
    </Divider>
    <Item
      noStyle={true}
      name={["dataSetConfig"]}
      rules={[{required: true}]}
    >
      <CardItemSet
        options={opts}
      />
    </Item>
    <Divider orientation="left">
      布局设置
    </Divider>
    <Item
      label={"卡片间距"}
    >
      {CommonFormItems.distanceSet(["layoutSet", "cardGap","num"], ["layoutSet", "cardGap","unit"], ["px", "rem"])}
    </Item>
    <Item
      label={"卡片内边距"}
    >
      {CommonFormItems.getSimplePaddingSet("layoutSet")}
    </Item>
    <Item
      label={"内容每行间距"}
    >
      {CommonFormItems.distanceSet(["layoutSet", "contentGap","num"], ["layoutSet", "contentGap","unit"], ["px", "rem"])}
    </Item>
    <Divider orientation="left">
      卡片背景设置
    </Divider>
    {CommonFormItems.getSimpleBgSet("cardBgSet")}
    <Divider orientation="left">
      滚动配置
    </Divider>
    <Item
      label={"开启滚动"}
      name={["scrollSet","autoScroll"]}
      rules={[{required: true}]}
      valuePropName={"checked"}
      extra={"提示：内容过少不会有滚动效果"}
    >
      <Switch
        checkedChildren={"开"}
        unCheckedChildren={"关"}
      />
    </Item>
    <Item
      label={"滚动速度"}
      name={["scrollSet","scrollSpeed"]}
      rules={[{required: true}]}
    >
      <Radio.Group>
        <Radio value={70}>慢</Radio>
        <Radio value={50}>中等</Radio>
        <Radio value={25}>快</Radio>
        <Radio value={10}>极速</Radio>
      </Radio.Group>
    </Item>
  </>
}

function getDefaultValues(){
  return {
    dataSetConfig: null,
    layoutSet: {
      cardGap: {
        num: 10,
        unit: "px",
      },
      padding: {
        top: 10,
        topUnit: "px",//px rem
        left: 15,
        leftUnit: "px",
        right: 15,
        rightUnit: "px",
        bottom: 10,
        bottomUnit: "px",
      },
      contentGap: {
        num: 5,
        unit: "px",
      },
    },
    cardBgSet: {
      use: true,
      radiusSet: {
        num: 0,
        unit: "rem"
      }, //圆角
      opacity: 100,   //透明度
      modeSet: {
        mode: "svgBorder",  //背景类型  color  border  image   eachBorder  injectImage
        color: "#843cff",
        upload_imageUrl: null,
        borderSVGType: "type05",
        backgroundImgSize: "cover", //cover contain diy
        borderBgSet:{
          width: 2,
          type: "solid",  //solid dashed dotted
        },
        backgroundImgSizeDiy:{
          width: 100,
          widthUnit: "%",
          height: 100,
          heightUnit: "%",
        },
      },
    },
    scrollSet: {
      autoScroll: true,
      scrollSpeed: 50,
    }
  }
}

//推荐数据格式
function getDemoCode(){
  return `
   以下示例中 title、time、like、share 均可在 数据对接设置 中修改替换成其它单词
    [
        {
            title: "帖子名称1",
            time: "2024/06/07",
            like: "点赞：160",
            share: "分享：33",
        },
        {
            title: "帖子名称2",
            time: "2024/06/08",
            like: "点赞：77",
            share: "分享：60",
        },
        {
            title: "帖子名称3",
            time: "2024/06/09",
            like: "点赞：98",
            share: "分享：6",
        },
        ...
    ]
    `
}

export default {
  getSetItems,
  getDefaultValues,
  getDemoCode
}