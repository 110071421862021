import React, { memo, useEffect, useState } from 'react'
import styles from './distributionLogDuration.less'
import { Flex, Select, Statistic } from 'antd'
import { request } from '../../../../../../tool/http'
// import TaskSelectTwo from '@/pages/containers/dashboard/contentView/view/taskSelectTwo/taskSelectTwo'
// import HumanChartTwo from '../../view/showEchart/humanChartTwo/humanChartTwo'
// import HumanLineChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanLineChart/humanLineChart'
function DistributionLogDuration(props: any) {
  // console.log('每天的分发日志个数---展示', props)
  // const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1])
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(1)
  const [data, setData] = useState<any>([])
  const [option, setOption] = useState<any>({})
  const [takeSelectType, setTakeSelectType] = useState<any>(0)
  const [takeSelect, setTakeSelect] = useState<any>([])
  //平均时长
  const [avg, setAvg] = useState<any>(0)


  //将时间戳转换为分钟
  const convertTimestampToMinutes = (timestamp: any) => {
    return (timestamp / 1000 / 60).toFixed(2);
  };
  // 计算data里count的平均值
  const getAverage = (data: any) => {
    let sum = 0
    data.forEach((item: any) => {
      sum += item
    })
    //取整
    return Math.floor(sum / data.length)
  }
  //请求业务数据  
  const getBusinessData = () => {
    setErrorMsg('')
    // let taskVal = [] as any
    let objVal = {} as any
    // console.log('taskVal+++++', taskVal, objVal)
    objVal[takeSelectType === 2 ? 'triggerIds' : 'execUnitPlanIds'] = takeSelect || []
    //判断单次任务和周期任务的值

    request(`${props.contentMsg?.proConfig?.apiPrefix}/taskStatistics/getDistributionDuration`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time,
        type: takeSelectType ? takeSelectType : '',
        ...objVal,
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
        console.log('平均时长', getAverage(res.data.map((item: any) => item.durationSum)))
        setAvg(convertTimestampToMinutes(getAverage(res.data.map((item: any) => item.durationSum))))
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }
  useEffect(() => {
    getBusinessData()
  }, [showType, time, props.dataUpdate, takeSelectType, takeSelect])

  //获取图表数据
  const getOption = () => {
    let yAxis = [
      {
        color: '#5470C6',
        chartTitle: '分发日志个数',
        data: data.map((item: any) => item.key)
      }
    ]
    let series = [{
      type: props?.contentMsg?.dataConfig?.contentType === 'system_distributionLogDurationTwo' ? 'line' : 'bar', //这里判断是折线状还是柱状图
      name: '分发日志个数',
      data: data.map((item: any) => item.count),
      color: '#5470C6',
      // areaColor: 'rgba(238, 102, 102,0.2)'
    }]
    let option = {
      [props?.contentMsg?.dataConfig?.contentType === 'system_distributionLogDurationTwo' ? 'xAxis' : 'yAxis']: yAxis,
      series
    }
    setOption(option)
  }



  useEffect(() => {
    console.log('日志统计分布--', data)
    if (data.length > 0) {
      getOption()
    }
  }, [data])

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      <div
        className={styles.chartBox}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex style={{ marginBottom: '16px' }}>
                <Flex justify='end' flex={1} className={" swiper-no-swiping"}>
                  <Select
                    style={{ width: "120px" }}
                    value={time}
                    onChange={(v) => {
                      setTime(v)
                    }}
                    options={[
                      { label: "今日", value: 1 },
                      { label: "30日", value: 30 },
                      { label: "15日", value: 15 },
                      { label: "7日", value: 7 },
                    ]}
                  >
                  </Select>
                  <Select style={{ width: "120px", marginLeft: '10px' }}
                    value={takeSelectType}
                    onChange={(v) => {
                      setTakeSelectType(v)
                    }}
                    options={[
                      { label: "全部任务", value: 0 },
                      { label: "单次任务", value: 2 },
                      { label: "周期任务", value: 3 },
                    ]} >

                  </Select>
                  {takeSelectType !== 0 && <TaskSelectTwo
                    data={{
                      option: {
                        defaultValue: null
                      }
                    }}
                    takeType={takeSelectType}
                    businessId={[props.contentMsg?.proConfig?.usageParams?.businessId]}
                    businessType={props.contentMsg?.proConfig?.usageParams?.usageCode}
                    verification={(data, value) => {
                      setTakeSelect(value)
                      console.log(data, value)
                    }}
                  >
                  </TaskSelectTwo>}

                </Flex>
              </Flex>
              {showType.includes(1) && <Flex className={styles.averageQuantityBulk} justify='center' align='center'>
                <Statistic title="分发日志平均用时" value={avg} suffix="分钟" />
              </Flex>}


              {/* {showType.includes(2) &&
                props?.contentMsg?.dataConfig?.contentType === 'system_distributionLogDurationTwo' ?
                <HumanLineChart option={option}></HumanLineChart> :
                <HumanChartTwo option={option}></HumanChartTwo>} */}
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(DistributionLogDuration)