import React, {Component} from 'react';
import ReactDom from "react-dom";
import styles from "../ShowEchart.less";
import {message} from "antd";
import CommonViewDiv from "../../../../CommonViewDiv";

const SliceLength = 500;//数据分段展示限制长度

export default class PieShow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            totalMsg: null,
        }
    }

    componentDidMount() {
        let theme = this.props.contentMsg?.styleConfig?.commonSet?.colorMode || "dark";
        let chartDom = ReactDom.findDOMNode(this.chartDom);
        this.myChart = echarts.init(chartDom, theme, {
            locale: "ZH"
        });
        if(this.props.contentMsg?.dataConfig?.contentParams?.clickActionSet?.use){
            this.myChart.on('click',  (params)=> {
                console.log("params",params)
                let txt = params[this.props.contentMsg?.dataConfig?.contentParams?.clickActionSet?.valueType];
                this.props?.broadcastData(txt)
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.styleUpdate !== nextProps.styleUpdate) {
            setTimeout(() => {
                this.refresh()
            }, 0)
        }
    }

    componentWillUnmount() {
        this.myChart && this.myChart.dispose()
    }

    refresh() {
        this.lastData && this.showValue(this.lastData);
    }

    //获取默认的
    getChartOption() {
        let {} = this.props.contentMsg;

        let option = {}

        //图例
        option.legend = this.props.contentMsg?.dataConfig?.contentParams?.legend;

        switch (this.props.contentMsg?.dataConfig?.contentParams?.legend?._position) {
            case "top":
                option.legend = Object.assign(option.legend, {
                    top: "5%",
                    left: "center",
                });
                break;
            case "bottom":
                option.legend = Object.assign(option.legend, {
                    bottom: "5%",
                    left: "center",
                });
                break;
            case "left":
                option.legend = Object.assign(option.legend, {
                    top: "center",
                    left: "5%",
                });
                break;
            case "right":
                option.legend = Object.assign(option.legend, {
                    top: "center",
                    right: "5%",
                });
                break;
            case "topLeft":
                option.legend = Object.assign(option.legend, {
                    top: "5%",
                    left: "5%",
                });
                break;
            case "topRight":
                option.legend = Object.assign(option.legend, {
                    top: "5%",
                    right: "5%",
                });
                break;
            case "bottomLeft":
                option.legend = Object.assign(option.legend, {
                    bottom: "5%",
                    left: "5%",
                });
                break;
            case "bottomRight":
                option.legend = Object.assign(option.legend, {
                    bottom: "5%",
                    right: "5%",
                });
                break;
            case "center":
                option.legend = Object.assign(option.legend, {
                    top: "center",
                    left: "center",
                });
                break;
        }

        //tooltip
        option.tooltip = this.props.contentMsg?.dataConfig?.contentParams?.tooltip;
        if (option.tooltip?._showPercentage) {
            // option.tooltip.formatter = '{c} ({d}%)'
            option.tooltip.formatter = (params) => {
                let color = option.tooltip?.textStyle?.color || "inherit";

                return `<div style="color: ${color};display: flex;justify-content: space-between;gap: 15px">
                                <span>
                                   ${params.marker}
                                   <span>${params?.name}</span>
                                </span>
                                <span>
                                   <span style="font-weight: bold">${params.value}</span>
                                   <span> (${params.percent}%)</span>
                                </span>
                    </div>`
            }
        }

        //颜色
        option.backgroundColor = this.props.contentMsg?.dataConfig?.contentParams?.theme?.backgroundColor;
        if (this.props.contentMsg?.dataConfig?.contentParams?.theme?.color) {
            option.color = this.props.contentMsg?.dataConfig?.contentParams?.theme?.color.map((eachColor) => {
                if (typeof eachColor === 'string' || !eachColor) {
                    return eachColor
                } else {
                    let obj = {
                        type: eachColor.type,
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0, color: eachColor.colorStops[0] // 0% 处的颜色
                        }, {
                            offset: 1, color: eachColor.colorStops[1] // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    };
                    if (eachColor.direction === "horizontal") {
                        obj.x2 = 1;
                    } else {
                        obj.y2 = 1;
                    }

                    return obj;
                }
            });
        }

        // this.lastOption = option;
        return option;
    }

    //数据展示
    async showValue(data) {
        this.lastData = _.cloneDeep(data);
        // console.log("看啊看数据",data)
        let {styleConfig, dataConfig} = this.props.contentMsg;

        let limitNum = dataConfig?.contentParams?.limitNum || SliceLength;

        if (data?.length > limitNum) {
            this.setState({
                error: `数据量超过${limitNum}条，不予以展示，请优化数据或修改配置`
            })
            return;
        }

        //另一种格式的数据的判断
        if (dataConfig?.contentParams?.dataSetConfig?.labelField) {
            if (data?.[dataConfig?.contentParams?.dataSetConfig?.labelField]?.length > limitNum) {
                this.setState({
                    error: `数据量超过${limitNum}条，不予以展示，请优化数据或修改配置`
                })
                return;
            }
        }


        let option = this.getChartOption();

        if (dataConfig?.contentParams) {
            let seriesItem = {
                type: "pie",
            };

            //饼图特殊配置
            //是否为南丁格尔图
            switch (dataConfig?.contentParams?.pieSpecial?._pieType) {
                case "normal":
                    break;
                case "radius":
                    seriesItem.roseType = "radius";
                    break;
                case "area":
                    seriesItem.roseType = "area";
                    break;
            }

            //顺时针
            if (dataConfig?.contentParams?.pieSpecial?._clockwise === "counterclockwise") {
                seriesItem.clockwise = false;
            }

            //圆心
            seriesItem.center = [dataConfig?.contentParams?.pieSpecial?._centerSetX || "50%", dataConfig?.contentParams?.pieSpecial?._centerSetY || "50%"]

            //展示所有标签
            if (dataConfig?.contentParams?.pieSpecial?._showAllLabel) {
                seriesItem.minShowLabelAngle = 0;
            } else {
                seriesItem.minShowLabelAngle = 3.6;
            }

            //视觉引导线
            switch (dataConfig?.contentParams?.pieSpecial?._labelLine) {
                case "hide":
                    seriesItem.labelLine = {
                        show: false
                    };
                    break;
                case "line":
                    seriesItem.labelLine = {
                        show: true
                    };
                    break;
                case "curve":
                    seriesItem.labelLine = {
                        show: true,
                        smooth: true
                    };
                    break;
            }

            //半径
            seriesItem.radius = [(dataConfig?.contentParams?.pieSpecial?._radiusInner || 0) + "%", (dataConfig?.contentParams?.pieSpecial?._radiusOuter || 0) + "%"]

            //标签
            if (dataConfig?.contentParams?.label) {
                seriesItem.label = {
                    show: dataConfig?.contentParams?.label.show,
                    color: dataConfig?.contentParams?.label.color || "inherit",
                    position: dataConfig?.contentParams?.label.position,
                }

                switch (dataConfig?.contentParams?.label._showPlan) {
                    case "normal":
                        break;
                    case "pop":
                        seriesItem.label.padding = 10;
                        seriesItem.label.backgroundColor = "rgba(255, 255, 255, 0.3)";
                        seriesItem.label.borderRadius = 100;
                        break;
                    case "popDark":
                        seriesItem.label.padding = 10;
                        seriesItem.label.backgroundColor = "rgba(0, 0, 0, 0.3)";
                        seriesItem.label.borderRadius = 100;
                        break;
                }
            }

            //扇区样式
            if (dataConfig?.contentParams?.itemStyle) {
                seriesItem.itemStyle = {
                    borderWidth: dataConfig.contentParams.itemStyle.borderWidth,
                    borderColor: dataConfig.contentParams.itemStyle.borderColor,
                    borderType: dataConfig.contentParams.itemStyle.borderType,
                    shadowBlur: dataConfig.contentParams.itemStyle.shadowBlur,
                    shadowColor: dataConfig.contentParams.itemStyle.shadowColor,
                    borderRadius: [(dataConfig.contentParams.itemStyle._borderRadiusInner || 0) + "%", (dataConfig.contentParams.itemStyle._borderRadiusOuter || 0) + "%"]
                }
            }

            //高亮配置
            seriesItem.emphasis = {}

            //高亮下的标签
            if (dataConfig?.contentParams?.label?._emphasisSel?.length) {
                //先继承基础值
                seriesItem.emphasis.label = _.cloneDeep(seriesItem.label);

                if (dataConfig?.contentParams?.label?._emphasisSel?.includes("color")) {
                    seriesItem.emphasis.label.color = dataConfig?.contentParams?.label?._emphasis?.color || "inherit";
                }

                if (dataConfig?.contentParams?.label?._emphasisSel?.includes("show")) {
                    if (dataConfig?.contentParams?.label?._emphasis?.show) {
                        seriesItem.emphasis.label.show = true;
                    } else {
                        seriesItem.emphasis.label.show = false;
                    }
                }
            }

            //根据数据和配置填充其它属性
            option.series = [];

            let pieColorMap = {};
            if(dataConfig?.contentParams?.dataSetConfig?.pieSetArr?.length){
                dataConfig?.contentParams?.dataSetConfig?.pieSetArr.forEach((setItem)=>{
                    if(setItem.field && setItem.extraStyle?.color){
                        pieColorMap[setItem.field] = setItem.extraStyle.color;
                    }
                })
            }

            if (dataConfig?.contentParams?.dataSetConfig?.labelField) {

                let fillData = [];
                if(Array.isArray(data)){
                    data.forEach((eachDataItem)=>{
                        let fillItem = {
                            name: eachDataItem[dataConfig.contentParams.dataSetConfig.labelField],
                            value: eachDataItem[dataConfig.contentParams.dataSetConfig.valueField],
                        }

                        if(pieColorMap[eachDataItem[dataConfig.contentParams.dataSetConfig.labelField]]){
                            fillItem.itemStyle = {
                                color: pieColorMap[eachDataItem[dataConfig.contentParams.dataSetConfig.labelField]]
                            }
                        }

                        fillData.push(fillItem)
                    })
                }else if(Array.isArray(data?.[dataConfig.contentParams.dataSetConfig.labelField])){
                    //另一种数据结构
                    data[dataConfig.contentParams.dataSetConfig.labelField]?.forEach((eachField,index)=>{
                        let fillItem = {
                            name: eachField,
                            value: data[dataConfig.contentParams.dataSetConfig.valueField]?.[index],
                        }

                        if(pieColorMap[eachField]){
                            fillItem.itemStyle = {
                                color: pieColorMap[eachField]
                            }
                        }

                        fillData.push(fillItem)
                    })
                }

                let newSeriesItem = _.cloneDeep(seriesItem);

                //需要用到field的特殊配置先修改好
                if (dataConfig?.contentParams?.label?.show) {
                    if (dataConfig?.contentParams?.label._beforeTxt || dataConfig?.contentParams?.label._afterTxt || dataConfig?.contentParams?.label._showPercentage || dataConfig?.contentParams?.label._showRealNum) {
                        //特殊格式
                        newSeriesItem.label.formatter = `${dataConfig?.contentParams?.label._beforeTxt}{b}${dataConfig?.contentParams?.label._afterTxt}${dataConfig?.contentParams?.label._showRealNum?'\n{c}':""}${dataConfig?.contentParams?.label._showPercentage?'\n({d}%)':""}`
                    }
                }

                newSeriesItem.data = fillData;
                option.series.push(newSeriesItem)
            }
        }


        //自定义转换数据和配置项
        if (dataConfig.formatOptionCode) {
            try {
                let func;
                let txt = `func = ${dataConfig.formatOptionCode}`;
                eval(txt);
                option = await func(option, data);
            } catch (e) {
                console.error(e);
                message.error("Echarts配置项转换出错");
            }
        }

        console.log("option", option)

        if (this.myChart) {
            this.myChart.clear();
            this.myChart.resize();
            this.myChart.setOption(option);
        }

        let totalMsg = null;
        if (dataConfig?.contentParams?.totalMsg?.show) {
            totalMsg = 0;
            if (Array.isArray(data)) {
                data.forEach((eachItem) => {
                    totalMsg += (eachItem[dataConfig.contentParams.dataSetConfig.valueField] || 0)
                })
            } else if (data?.[dataConfig.contentParams.dataSetConfig.valueField] && Array.isArray(data?.[dataConfig.contentParams.dataSetConfig.valueField])) {
                data[dataConfig.contentParams.dataSetConfig.valueField].forEach((eachV) => {
                    totalMsg += (eachV || 0)
                })
            }

            if (!isNaN(Number(totalMsg)) && dataConfig?.contentParams?.totalMsg.tranBigNum) {
                //是数字,且开启了处理
                totalMsg = this.tranNumber(totalMsg, dataConfig?.contentParams?.totalMsg.bigNumPoint)
            }
        }

        this.setState({
            error: null,
            totalMsg: totalMsg
        })
    }

    // num接收的数字,point保留数字的第几位
    tranNumber(num, point = 0) {
        // 将数字转换为字符串,然后通过split方法用.分隔,取到第0个
        let numStr = num.toString().split('.')[0]
        if (numStr.length < 6) { // 判断数字有多长,如果小于6,,表示10万以内的数字,让其直接显示
            // console.log(numStr);
            return numStr;
        } else if (numStr.length >= 6 && numStr.length <= 8) { // 如果数字大于6位,小于8位,让其数字后面加单位万
            let decimal = numStr.substring(numStr.length - 4, numStr.length - 4 + point)
            // console.log(decimal);
            // 由千位,百位组成的一个数字
            return parseFloat(parseInt(num / 10000) + '.' + decimal) + '万'
        } else if (numStr.length > 8) { // 如果数字大于8位,让其数字后面加单位亿
            let decimal = numStr.substring(numStr.length - 8, numStr.length - 8 + point);
            // console.log(decimal);
            return parseFloat(parseInt(num / 100000000) + '.' + decimal) + '亿'
        }
    }

    render() {
        let {error, totalMsg} = this.state;
        let {contentMsg} = this.props;
        return <div className={styles.chartBox}>
            <div
              ref={(ref) => {
                  this.chartDom = ref
              }}
              style={{
                  // height: window.innerHeight - 200
              }}
              className={styles.chartDom + " swiper-no-swiping"}
            />
            {
                totalMsg !== null && totalMsg !== undefined
                  ? <div
                    className={styles.totalMsg}
                    style={{
                        top: (contentMsg?.dataConfig?.contentParams?.totalMsg?._centerSetY || 0) + "%",
                        left: (contentMsg?.dataConfig?.contentParams?.totalMsg?._centerSetX || 0) + "%",
                        alignItems: `${contentMsg?.dataConfig?.contentParams?.totalMsg?.alignItems}`
                    }}
                  >
                      {
                          contentMsg?.dataConfig?.contentParams.totalMsg.beforeTextSet?.text
                            ? CommonViewDiv.getText(contentMsg?.dataConfig?.contentParams.totalMsg.beforeTextSet, contentMsg?.dataConfig?.contentParams.totalMsg.beforeTextSet?.text)
                            : null
                      }
                      <div
                        style={{
                            marginLeft: window.changeRemNum(contentMsg?.dataConfig?.contentParams.totalMsg.marginLeft),
                            marginRight: window.changeRemNum(contentMsg?.dataConfig?.contentParams.totalMsg.marginRight),
                        }}
                      >{CommonViewDiv.getText(contentMsg?.dataConfig?.contentParams?.totalMsg.centerTextSet, totalMsg)}</div>
                      {
                          contentMsg?.dataConfig?.contentParams.totalMsg.afterTextSet?.text
                            ? CommonViewDiv.getText(contentMsg?.dataConfig?.contentParams.totalMsg.afterTextSet, contentMsg?.dataConfig?.contentParams.totalMsg.afterTextSet?.text)
                            : null
                      }
                  </div>
                  : null
            }
            {
                error
                  ? <div className={styles.errorMsg}>{error}</div>
                  : null
            }
        </div>
    }
}