import React, { useEffect, useRef, useState } from 'react';
// import { request, useRequest } from "umi";
import * as echarts from 'echarts';
import styles from "./DigitalHumanUnitWorkTime.less";
import { Select } from 'antd';
import _ from 'lodash';

type DataSourceItem = {
  execUnitId: string;
  name: string;
  busyTime: number
}

const DigitalHumanUnitWorkTime: React.FC<any> = (props) => {
  console.error(props)

  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [time, setTime] = useState(1)
  const [dataSource, setDataSource] = useState<Array<DataSourceItem>>([])

  useEffect(() => {
    chartInstance.current?.resize()
  }, [props.styleUpdate])

  // 初始化图表
  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current, "dark", {
        locale: "ZH"
      });
    }
    return () => {
      // 清理图表实例
      chartInstance.current?.dispose();
    };
  }, []);

  useEffect(() => {
    if (dataSource)
      setOption()
  }, [dataSource])

  // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}digitalHuman/digitalHumanExecUnitWorkTimeRate`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     time: time
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate, time],
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       // 将数据格式化，可直接使用的数据字段  分钟时长 转化为 小时时长
  //       setErrorMsg(null)
  //       setDataSource(res?.data?.map((item: DataSourceItem) => ({
  //         busyTime: Math.round(item.busyTime * 100 / 60) / 100,
  //         name: item?.name,
  //         value: Math.round(item.busyTime * 100 / 60) / 100,
  //       })))
  //     } else {
  //       setErrorMsg(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })

  const isLargeData = () => {
    return dataSource.length > 20
  }

  const setOption = () => {

    const config = props.contentMsg?.dataConfig?.contentType === 'system_DigitalHumanUnitWorkTime' ? {
      backgroundColor: "transparent",
      tooltip: {
        trigger: 'item',
      },
      grid: {
        top: 0,
        left: '3%',
        right: '4%',
        bottom: isLargeData() ? '10%' : '5%',  // 这里增加了 bottom 值以确保图例不被遮挡
      },
      legend: {
        type: 'scroll', // 让图例支持滚动
        orient: 'horizontal',
        top: 'bottom', // 将图例放置于图表底部
        itemWidth: 14, // 调整图例标记的宽度
        itemHeight: 14, // 调整图例标记的高度
        textStyle: {
          fontSize: 12, // 缩小字体
        },
        pageIconColor: '#3398DB', // 自定义滚动按钮的颜色
        pageTextStyle: {
          fontSize: 12, // 自定义分页文本样式
        },
      },
      series: [
        {
          name: '工作时长 (小时)',
          type: 'pie',
          radius: isLargeData() ? ['30%', '55%'] : ['30%', '65%'],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 8,
          },
          label: {
            show: true,
            formatter: '{b}: {c} 小时',
            fontSize: isLargeData() ? 10 : 12,
          },
          labelLayout: {
            hideOverlap: true,
          },
          data: dataSource,
        },
      ],
    } : {
      backgroundColor: "transparent",
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: dataSource.map(item => item.name), // Set categories to execution unit names
        axisLabel: {
          rotate: 45, // Rotate labels if needed for better fit
          interval: 2, // Display all labels
        },
      },
      yAxis: {
        type: 'value',
        name: '工作时长 (小时)',
      },
      dataZoom: dataSource.length > 20 ? [
        {
          type: 'slider', // Slider for zooming and scrolling
          show: true,
          xAxisIndex: [0], // Apply zoom to the x-axis
          start: 0, // Initially start at the beginning of the axis
          end: dataSource.length > 20 ? (20 / dataSource.length) * 100 : 100, // Show first 10 items initially
          height: 15, // Adjust the height of the scroll bar (smaller than default)
          bottom: 10, // Position it closer to the chart for a compact look
          handleSize: '80%', // Control the size of the handle for zooming
        },
        {
          type: 'inside', // Enable scrolling by mouse or touch inside the chart
          xAxisIndex: [0], // Apply zoom to the x-axis
          start: 0,
          end: dataSource.length > 20 ? (20 / dataSource.length) * 100 : 100,
        },
      ] : [],
      series: [
        {
          name: 'Busy Time',
          type: 'bar',
          data: dataSource.map(item => item.busyTime),
          itemStyle: {
            color: '#3398DB',
          },
          label: {
            show: true,
            position: 'top',
            formatter: '{c} 小时',
          },
        },
      ],
    };

    chartInstance.current?.setOption(config)
  }

  return <div className={styles.outer}>
    <div className={styles.controlBar + " swiper-no-swiping"}>
      <Select
        style={{ width: "120px" }}
        value={time}
        onChange={(time) => {
          setTime(time)
        }}
        options={[
          { label: "今日", value: 1 },
          { label: "7日", value: 7 },
          { label: "15日", value: 15 },
          { label: "30日", value: 30 },
        ]}
      />
    </div>
    <div className={styles.chartBox + " swiper-no-swiping"}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : <div ref={chartRef} className={styles.chartBox}></div>
      }
    </div>
  </div>
}


export default DigitalHumanUnitWorkTime