import React, { memo, useEffect, useState } from 'react';
import { request } from '../../../../../../tool/http'
import styles from "./distributionLogDigitalManRanking.less";
import { Flex, Select, Statistic, Table, Tabs } from "antd";

function TaskList(props: any) {
  //表格列配置类型
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || ['total']
  const [errorMsg, setErrorMsg] = useState(null)
  const [tabType, setTabType] = useState(showType[0] || 0)
  const [statusType, setStatusType] = useState(0)
  const [data, setData] = useState(null)
  const [size, setSize] = useState(10)
  const [type, setType] = useState(0)
  //总数量
  const [total, setTotal] = useState<any>(0)

  //计算总数
  const getSum = (arr: any) => {
    let sum = 0
    arr.forEach((item: any) => {
      sum += item
    })
    return sum
  }
  //将毫秒转换为分钟
  const formatDuration = (duration: number) => {
    let minute = Math.floor(duration / 60000)
    // let second = Math.floor((duration % 60000) / 1000)
    return minute + "分"
  }
  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    let data = {
      businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
      businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
      size,
      type,
      order: tabType,
      group: 'digitalHumanId'
    }
    if (!(type ?? '')) delete data.type
    request(`${props.contentMsg?.proConfig?.apiPrefix}taskStatistics/getRanking`, {
      data
    }).then((res) => {
      if (res.code === 200) {
        console.log('数字人日志分发----', res.data)
        setData(res.data.map((item: any) => {
          return {
            ...item,
            key: tabType + item.digitalHumanId
          }
        }))
        setTotal(getSum(res.data.map((item: any) => item.total)))
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }


  useEffect(() => {
    getBusinessData()
  }, [tabType, props.dataUpdate, type, size])

  useEffect(() => {
    //判断tab类型是否在配置showType中且选中的值不相等，则默认选中第一个
    if (showType[0] !== tabType && !showType.includes(tabType)) {
      setTabType(showType[0])
    }
  }, [showType])

  //表格列配置
  const columns = [
    {
      title: '排名',
      dataIndex: 'index',
      key: tabType + 'index',
      render: (text: number, record: any, index: number) => {
        return <div>{index + 1}</div>
      }
    },
    {
      title: '数字人名称',
      dataIndex: 'name',
      key: tabType + 'name',
    },
    {
      title: '分发日志完成数量',
      dataIndex: 'success',
      key: tabType + 'success',
    },
    {
      title: '总用时',
      dataIndex: 'duration',
      key: tabType + 'duration',
      render: (text: number) => {
        return <div>{formatDuration(text)}</div>
      }
    },
  ]

  let items = [];
  if (showType.includes("total")) {
    items.push({
      key: 'total',
      label: '数量排名',
      children: (
        <div className={styles.tabPaneBox}>
          {
            errorMsg
              ? <div className={styles.errorMsg}>
                {errorMsg}
              </div>
              : <Table
                size={"small"}
                bordered={true}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
          }
        </div>
      ),
    })
  }

  if (showType.includes("duration")) {
    items.push({
      key: 'duration',
      label: '用时排名',
      children: (
        <div className={styles.tabPaneBox}>
          {
            errorMsg
              ? <div className={styles.errorMsg}>
                {errorMsg}
              </div>
              : <Table
                size={"small"}
                bordered={true}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
          }
        </div>
      ),
    })
  }

  return <div
    className={styles.outer + " swiper-no-swiping"}
  >
    <Tabs
      className={styles.tabsBox}
      destroyInactiveTabPane={true}
      activeKey={tabType}
      onChange={(key) => {
        setTabType(key)
      }}
      tabBarExtraContent={
        <Flex gap={8} align='center'>
          <Flex align='center' gap={16}>
            <div>
              日志数量
            </div>
            <Statistic value={total} />
          </Flex>
          <Select
            style={{ width: "120px" }}
            value={type}
            onChange={(v) => {
              setType(v)
            }}
            options={[
              { label: "全部", value: 0 },
              { label: "单次任务", value: 2 },
              { label: "周期任务", value: 3 },
            ]}
          />
          <Select
            style={{ width: "120px" }}
            value={size}
            onChange={(v) => {
              setSize(v)
            }}
            options={[
              { label: "10条", value: 10 },
              { label: "5条", value: 5 },
              { label: "20条", value: 20 },
            ]}
          />
        </Flex>
      }
      items={items}
    />
  </div>
}
export default memo(TaskList)