import { useEffect, useState } from 'react';
import { request } from '../../../../../../tool/http'
import styles from "./PositionDigitalHumanCount.less";
// import { Column } from '@ant-design/charts'

const DefaultStatus: { [key: number]: string } = {
    1: '缺勤',
    2: '空闲',
    3: '工作中',
    4: '停工'
}

type Data = {
    name: string;
    events: Array<{ status: number; sum: number }>
}

type ColumnData = {
    name: string;
    type: string;
    sum: number;
}

const PositionDigitalHumanCount: React.FC<any> = (props) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const [dataSource, setDataSource] = useState<Array<ColumnData>>([])

    useEffect(() => {
        getBusinessData()
    }, [props.contentMsg?.dataConfig?.contentParams?.type])

    //请求业务数据
    const getBusinessData = () => {
        request(`${props.contentMsg?.proConfig?.apiPrefix}/execUnit/getPositionHumanCount`, {
            data: {
                businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
                execUnitId: [props.contentMsg?.proConfig?.usageParams?.businessId],
            }
        }).then((res) => {
            if (res.code === 200) {
                //转化数据成需要的格式
                let needData: Array<ColumnData> = [];
                res.data?.forEach((eachItem: Data) => {
                    eachItem.events?.forEach((eachEvent) => {
                        needData.push({
                            name: eachItem.name,
                            type: DefaultStatus[eachEvent.status],
                            sum: eachEvent.sum,
                        })
                    })
                })
                setDataSource(needData)
            } else {
                setErrorMsg(res.message)
            }
        }).catch((err) => {
            setErrorMsg(err.toString())
        })
    }

    const config = {
        theme: 'dark',
        data: dataSource,
        xField: 'name',
        yField: 'sum',
        colorField: 'type',
        stack: true,
        sort: {
            reverse: true,
            by: 'y',
        },
        axis: {
            y: { labelFormatter: '~s' },
            x: {
                style: {
                    labelTransform: 'rotate(0)',
                },
            },
        },
    };

    return <div className={styles.outer}>
        {/* {
            errorMsg
                ? <div className={styles.errorMsg}>
                    {errorMsg}
                </div>
                : <Column autoFit={true} {...config} />
        } */}
    </div>
}

export default PositionDigitalHumanCount