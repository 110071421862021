import { Form, Divider, Checkbox, Radio, Switch, Select, InputNumber, Input } from "antd";
import LiquidFillSet from '../../view/liquidfill/LiquidFillSet';
import CommonFormItems from "../../../CommonFormItems";
import ColorArray from "../../../formDiyItem/ColorArray";
import ColorPicker from "../../../formDiyItem/ColorPicker";

const Item = Form.Item;
//与图表展示方式有关的配置
function getSetItems() {
    return <>
        <Divider orientation="left">
            展示方式配置
        </Divider>
        <Form.Item
            name={"typeShowSel"}
            label={"展示数据"}
            rules={[{ required: true }]}
        >
            <Checkbox.Group
                options={[
                    { label: "数字人负载率", value: "digitalHumanLoadRate" },
                    { label: "在线总时长", value: "digitalHumanOnlineTotalTime" },
                    { label: "工作总时长", value: "digitalHumanWorkTotalTime" },
                ]}
            />
        </Form.Item>

        <Divider orientation="left">
            水波样式配置
        </Divider>
        <Item
            label={"波浪高度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("amplitude",{
                min: 0,
                max: 100,
                step: 1,
                addonAfter: "%"
            })}
        </Item>
        <Item
            label={"波浪宽度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("waveLength",{
                min: 0,
                max: 100,
                step: 1,
                addonAfter: "%"
            })}
        </Item>
        <Item
            label={"开启动画"}
            name={"waveAnimation"}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            name={["direction"]}
            label={"波动方向"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="left">向左</Radio>
                <Radio value="right">向右</Radio>
            </Radio.Group>
        </Item>
        <Item
            label={"波浪颜色梯度"}
            name={"colorArray"}
            rules={[{required: true}]}
        >
            <ColorArray
                max={10}
            />
        </Item>
        <Item
            label={"波浪不透明度"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["waveStyle","opacity"], {
                min: 0,
                max: 100
            })}
        </Item>
        <Item
            label={"波浪阴影"}
        >
            {CommonFormItems.getShadowSet(["waveStyle","shadowSet"])}
        </Item>
        <Divider orientation="left">
            轮廓样式配置
        </Divider>
        <Item
            label={"轮廓形状"}
            name={"shape"}
            rules={[{required: true}]}
        >
            <Select>
                <Select.Option value={"circle"}>圆形</Select.Option>
                <Select.Option value={"rect"}>矩形</Select.Option>
                <Select.Option value={"roundRect"}>圆角矩形</Select.Option>
                <Select.Option value={"triangle"}>三角形</Select.Option>
                <Select.Option value={"diamond"}>菱形</Select.Option>
                <Select.Option value={"pin"}>坐标</Select.Option>
                <Select.Option value={"arrow"}>箭头</Select.Option>
                <Select.Option value={"mapleLeaf"}>枫叶</Select.Option>
                <Select.Option value={"tree"}>树</Select.Option>
                <Select.Option value={"rocket"}>火箭</Select.Option>
                <Select.Option value={"cetacean"}>鲸鱼</Select.Option>
                <Select.Option value={"rabbit"}>兔子</Select.Option>
                <Select.Option value={"snail"}>蜗牛</Select.Option>
                <Select.Option value={"container"}>铺满容器内容</Select.Option>
            </Select>
        </Item>
        <Item
            label={"轮廓占容器比例"}
        >
            {CommonFormItems.getSliderAndInputItemGroup("radius",{
                min: 20,
                max: 100,
                step: 1,
                addonAfter: "%"
            })}
        </Item>
        <Item
            label={"轮廓底色"}
            name={["backgroundSet","color"]}
        >
            <ColorPicker
            />
        </Item>
        <Item
            label={"内边距"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["outline","borderDistance"],{
                min: 0,
                max: 50,
                step: 1
            })}
        </Item>
        <Item
            label={"轮廓描边"}
            name={["outline","show"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"显示"}
                unCheckedChildren={"隐藏"}
            />
        </Item>
        <Item
            label={"轮廓颜色"}
            name={["outline","lineStyle","borderColor"]}
        >
            <ColorPicker
            />
        </Item>
        <Item
            label={"轮廓粗细"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["outline","lineStyle","borderWidth"],{
                min: 0,
                max: 20,
                step: 1
            })}
        </Item>
        <Item
            label={"轮廓阴影"}
        >
            {CommonFormItems.getShadowSet(["outline","lineStyle","shadowSet"])}
        </Item>
        <Divider orientation="left">
            内部文本样式设置
        </Divider>
        <Item
            label={"显示文案"}
            name={["label","show"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            name={["label","showType"]}
            label={"数据值"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value={1}>百分比</Radio>
                <Radio value={2}>具体数值</Radio>
                <Radio value={3}>隐藏</Radio>
            </Radio.Group>
        </Item>
        <Item
            noStyle={true}
            shouldUpdate={(prevValues, currentValues) => prevValues.label.showType !== currentValues.label.showType}
        >
            {({getFieldValue})=>{
                let showType = getFieldValue(["label","showType"]);
                return <>
                    <Item
                        label={"精度"}
                        name={["label","precision"]}
                        rules={[{required: true}]}
                        hidden={showType !== 1}
                    >
                        <InputNumber
                            min={0}
                            max={4}
                            step={1}
                        />
                    </Item>
                    <Item
                        label={"处理大数字"}
                        name={["label","bigNumber"]}
                        rules={[{required: true}]}
                        extra={"当数量超过1万时，以W为单位展示"}
                        hidden={showType !== 2}
                        valuePropName={"checked"}
                    >
                        <Switch
                            checkedChildren={"是"}
                            unCheckedChildren={"否"}
                        />
                    </Item>
                </>
            }}
        </Item>
        <Item
            label={"前缀文案"}
            extra={"若不填，则不显示"}
            name={["label","beforeText"]}
        >
            <Input
                maxLength={"50"}
            />
        </Item>
        <Item
            label={"后缀文案"}
            extra={"若不填，则不显示"}
            name={["label","afterText"]}
        >
            <Input
                maxLength={"50"}
            />
        </Item>
        <Item
            label={"文字上浮色"}
            extra={"当文字没有被水波没过时的颜色"}
            name={["label","color"]}
        >
            <ColorPicker
            />
        </Item>
        <Item
            label={"文字下沉色"}
            extra={"当文字被水波没过时的颜色"}
            name={["label","insideColor"]}
        >
            <ColorPicker
            />
        </Item>
        <Item
            label={"字体类型"}
            name={["label","fontFamily"]}
        >
            {CommonFormItems.getFontFamilySelect()}
        </Item>
        <Item
            label={"字体大小"}
        >
            {CommonFormItems.getSliderAndInputItemGroup(["label","fontSize"],{
                min: 10,
                max: 150,
                step: 1,
                addonAfter: "rem"
            })}
        </Item>
        <Item
            label={"加粗"}
            name={["label","fontWeightBold"]}
            rules={[{required: true}]}
            valuePropName={"checked"}
        >
            <Switch
                checkedChildren={"是"}
                unCheckedChildren={"否"}
            />
        </Item>
        <Item
            name={["label","position"]}
            label={"位置"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="inside">内部</Radio>
                <Radio value="top">靠上</Radio>
                <Radio value="bottom">靠下</Radio>
                <Radio value="left">靠左</Radio>
                <Radio value="right">靠右</Radio>
            </Radio.Group>
        </Item>
        <Item
            name={["label","align"]}
            label={"水平对齐"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="left">左对齐</Radio>
                <Radio value="center">居中</Radio>
                <Radio value="right">右对齐</Radio>
            </Radio.Group>
        </Item>
        <Item
            name={["label","baseline"]}
            label={"垂直对齐"}
            rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="top">顶部对齐</Radio>
                <Radio value="middle">居中</Radio>
                <Radio value="bottom">底部对齐</Radio>
            </Radio.Group>
        </Item>
    </>
}
//getSetItems配置内容的默认值
function getDefaultValues() {
    let LiquidFillValues = LiquidFillSet.getDefaultValues();
    return {
        typeShowSel: ["digitalHumanLoadRate", "digitalHumanOnlineTotalTime", "digitalHumanWorkTotalTime"],
        ...LiquidFillValues
    }
}

//可由外部传入的参数定义
function getInputArg() {
    //不需要额外的查询参数传入，全部组件内部处理，返回空对象
    return {}
}
export default {
    getSetItems,
    getDefaultValues,
    getInputArg
}