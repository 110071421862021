import React, { useRef, useEffect, useState } from 'react'
import styles from './DigitalHumanAvgMaxTimeRatio.less'
import { request } from '../../../../../../tool/http'
import { Select } from "antd"
import TimeHelp from "../../../../../../tool/TimeHelp";

function DigitalHumanAvgMaxTimeRatio(props) {
  //只有一个展示内容，不需要勾选
  const chartRef = useRef(null)
  const chartInstance = useRef(null);
  const [errorMsg, setErrorMsg] = useState(null)
  const [time, setTime] = useState(1) //1.今天 7.七天内 15.十五天内 30.一个月内
  const [countMsg, setCountMsg] = useState(null)


  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/avgMaxOnlineTimeRatio`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time: time,
      }
    }).then((res) => {
      if (res.code === 200) {
        showValue(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }

  useEffect(() => {
    getBusinessData()
  }, [props.dataUpdate, time])

  useEffect(() => {
    chartInstance.current?.resize()
  }, [props.styleUpdate])


  // 初始化图表
  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current, "dark", {
        locale: "ZH"
      });
      // 注册点击事件监听器
      chartInstance.current.on('click', (params) => {
        if (params.componentType === 'xAxis') {
          if (props.onClickXAxis) {
            props.onClickXAxis(params); // 调用传入的点击回调
          }
        }
      });
      
    }

    return () => {
      // 清理图表实例
      chartInstance.current?.dispose();
    };
  }, []);

  //绘制数据
  const showValue = (data) => {
    let option = {
      grid: {
        left: '10',
        right: '10',
        bottom: '10',
        top: '30',
        containLabel: true
      },
      backgroundColor: "transparent",
      color: ['#46FEA5', '#FFA057', '#9EB1FF', '#009d51', '#cf5900', '#0020ab', '#bee2d4', '#e2ccbe', '#c2c9d9'],
      legend: {
        show: true,
        // type: "plain",  //plain  scroll
        // position: "top",
      },
      tooltip: {
        show: true,
        trigger: "axis",
      },
      xAxis: {
        show: true,
        type: 'category',
        triggerEvent: (hourTime || time === 1) ? false : true,
      },
      yAxis: {
        show: true,
        type: "value",
        minInterval: 1,
        axisLabel: {
          formatter: `{value}分钟`
        }
      },
      dataset: {
        dimensions: ["时长分布", "平均空闲", "平均离线", "平均忙碌", "最高空闲", "最高离线", "最高忙碌", "最低空闲", "最低离线", "最低忙碌"],
        source: []
      },
      series: [
        {
          type: 'line',
          name: "平均空闲",
          symbol: 'circle',
        },
        {
          type: 'line',
          name: "平均离线",
          symbol: 'circle',
        },
        {
          type: 'line',
          name: "平均忙碌",
          symbol: 'circle',
        },
        {
          type: 'line',
          name: "最高空闲",
          symbol: 'rect',
        },
        {
          type: 'line',
          name: "最高离线",
          symbol: 'rect',
        },
        {
          type: 'line',
          name: "最高忙碌",
          symbol: 'rect',
        },
        {
          type: 'line',
          name: "最低空闲",
          symbol: 'diamond',
        },
        {
          type: 'line',
          name: "最低离线",
          symbol: 'diamond',
        },
        {
          type: 'line',
          name: "最底忙碌",
          symbol: 'diamond',
        }
      ]
    }

    let source = [];
    let countMsg = {
      totalFree: 0,
      totalOffline: 0,
      totalBusy: 0,
      totalNum: 0,
    }
    if (data) {
      data.forEach((item) => {

        if (item.count > 0) {
          countMsg.totalFree += item.avgFreeSum * item.count;
          countMsg.totalOffline += item.avgOffLine * item.count;
          countMsg.totalBusy += item.avgBusySum * item.count;

          countMsg.totalNum += item.count;
        }

        source.push([
          item._id,
          item.avgFreeSum,
          item.avgOffLine,
          item.avgBusySum,
          item.maxFreeSum,
          item.maxOffLine,
          item.maxBusySum,
          item.minFreeSum,
          item.minOffLine,
          item.minBusySum,
        ])
      })

      option.dataset.source = source;

      setCountMsg(countMsg)
    }

    //渲染
    console.log("最终渲染option", option)
    chartInstance.current?.setOption(option)
  }

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : null
      }
      <div className={styles.controlBar}>
        <Select
          style={{ width: "90px" }}
          value={time}
          onChange={(v) => {
            setTime(v)
          }}
          options={[
            { label: "今日", value: 1 },
            { label: "7日", value: 7 },
            { label: "15日", value: 15 },
            { label: "30日", value: 30 },
          ]}
        />
      </div>
      <div className={styles.countMsgBox}>
        {
          props.contentMsg?.dataConfig?.contentParams?.typeShowSel?.includes("onlineMsg")
            ? <div
              className={styles.topMsgBox}
              style={{
                backgroundColor: "#46FEA5",
              }}
            >
              <div style={{ color: "#333" }}>平均空闲时长</div>
              <div style={{ color: "#333" }}>{countMsg ? TimeHelp.getTime(countMsg.totalFree / countMsg.totalNum * 60000, false, true) : "-"}</div>
            </div>
            : null
        }
        {
          props.contentMsg?.dataConfig?.contentParams?.typeShowSel?.includes("offlineMsg")
            ? <div
              className={styles.topMsgBox}
              style={{
                backgroundColor: "#FFA057"
              }}
            >
              <div>平均离线时长</div>
              <div>{countMsg ? TimeHelp.getTime(countMsg.totalOffline / countMsg.totalNum * 60000, false, true) : "-"}</div>
            </div>
            : null
        }
        {
          props.contentMsg?.dataConfig?.contentParams?.typeShowSel?.includes("busyMsg")
            ? <div
              className={styles.topMsgBox}
              style={{
                backgroundColor: "#9EB1FF"
              }}
            >
              <div>平均忙碌时长</div>
              <div>{countMsg ? TimeHelp.getTime(countMsg.totalBusy / countMsg.totalNum * 60000, false, true) : "-"}</div>
            </div>
            : null
        }
      </div>
      <div
        className={props.contentMsg?.dataConfig?.contentParams?.typeShowSel?.includes("totalChart") ? styles.chartOuter : styles.chartOuterHide}
      >
        <div
          ref={chartRef}
          className={styles.chartBox}
        >
        </div>
      </div>
    </div>

  )
}
export default DigitalHumanAvgMaxTimeRatio