import React, { memo, useEffect, useState } from 'react'
import styles from './digitalHumanGoOnline.less'
import { Flex, Select } from 'antd'
import { request } from '../../../../../../tool/http'
// import HumanLineChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanLineChart/humanLineChart'


function DigitalHumanGoOnline(props: any) {
  // console.log('每天的上线人数---展示', props)
  // const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1])
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(1)
  const [data, setData] = useState<any>([])
  const [option, setOption] = useState<any>({})
  const [timeType, setTimeType] = useState(1)
  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg('')
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/digitalHumanOnlineTimeRate`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time,
        status: timeType
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }
  useEffect(() => {
    getBusinessData()
  }, [showType, time, timeType, props.dataUpdate])

  //获取图表数据
  const getOption = () => {
    let xAxis = [
      {
        color: '#BD',
        chartTitle: '上线人数',
        data: data.map((item: any) => item.time + '点')
      }
    ]
    let series = [{
      type: props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanGoOnlineTwo' ? 'bar' : 'line', //这里判断是折线状还是柱状图
      name: '上线人数',
      data: data.map((item: any) => item.count),
      color: '#BD3223',
      label: {
        show: true,
        position: 'top',
        color: '#BD3223',
      }
      // areaColor: 'rgba(238, 102, 102,0.2)'
    }]
    let option = {
      polar: props?.contentMsg?.dataConfig?.contentType === 'system_digitalHumanGoOnline',
      xAxis,
      series
    }
    setOption(option)
  }
  useEffect(() => {
    // console.log('数字人在线时长分布--', data)
    if (data.length > 0) {
      getOption()
    }
  }, [data])

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      <div
        className={styles.chartBox}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex style={{ marginBottom: '16px' }}>
                <Flex justify='end' flex={1} className={" swiper-no-swiping"}>
                  <Select
                    style={{ width: "120px" }}
                    value={timeType}
                    onChange={(v) => {
                      setTimeType(v)
                    }}
                    options={[
                      { label: "北京时间", value: 1 },
                      { label: "当地时间", value: 2 },
                    ]}
                  >
                  </Select>
                  <Select
                    style={{ width: "120px", marginLeft: "10px" }}
                    value={time}
                    onChange={(v) => {
                      setTime(v)
                    }}
                    options={[
                      { label: "今日", value: 1 },
                      { label: "30日", value: 30 },
                      { label: "15日", value: 15 },
                      { label: "7日", value: 7 },
                    ]}
                  >
                  </Select>
                </Flex>
              </Flex>

              {/* {showType.includes(1) && <HumanLineChart option={option}></HumanLineChart>} */}
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(DigitalHumanGoOnline)