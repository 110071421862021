import React from "react";
import {
    Form,
    Divider,
    Radio
} from "antd";
// import PieSet from "@/pages/containers/dashboard/contentView/view/showEchart/pie/PieSet";

//与图表展示方式有关的配置
function getSetItems(){
    return <>
        <Divider orientation="left">
            展示方式配置
        </Divider>
        <Form.Item
          name={"type"}
          label={"查看数据类型"}
          rules={[{required: true}]}
        >
            <Radio.Group>
                <Radio value="input">输入数据</Radio>
                <Radio value="output">输出数据</Radio>
            </Radio.Group>
        </Form.Item>
        {PieSet.getSetItems({
            hideDataSetConfig: true
        })}
    </>
}
//getSetItems配置内容的默认值
function getDefaultValues(){
    // let peiValues = PieSet.getDefaultValues();

    return {
        type: "input",
    }
}

//可由外部传入的参数定义
function getInputArg(){
    return {
        "order": {
            key: "order",
            name: "排序字段",
            descs: "按照什么排序",
            type: "Select",
            value: {
                value: "按条数",
                defaultValue: "按条数"
            },
            list: ["按条数","按次数"]
        },
        "count": {
            key: "count",
            name: "查询结果数量",
            descs: "不传则查询全部",
            type: "Number",
            required: false,    //可以设置此项非必填
            value: {
                value: null,
                defaultValue: null
            },
        }
    }
}
export default {
    getSetItems,
    getDefaultValues,
    getInputArg
}