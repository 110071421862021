import React from "react";
import {message,AutoComplete,Row,Col,Space,Button,Select} from "antd";
import {MinusCircleOutlined,PlusOutlined,LinkOutlined} from "@ant-design/icons";
import less from "./ScrollCard.less";
import classNames from "classnames";
import ColorPicker from "../../../formDiyItem/ColorPicker";

export default class CardItemSet extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            dataSetConfig: this.props.value || {
                itemArr: [],
            },
        }
    }

    configChanged(){
        this.props.onChange?.(this.state.dataSetConfig);
    }

    render(){
        let {dataSetConfig} = this.state;

        return <div>
            {
                dataSetConfig?.itemArr?.map((eachLineSet,index)=>{
                    eachLineSet.index = index;
                    return <Row
                        key={"la" + index}
                        className={classNames({
                            [less.tabLine]: true,
                        })}
                    >
                        <Col span={4}>
                            内容{index + 1}：</Col>
                        <Col span={12}>
                            <AutoComplete
                                size={"small"}
                                style={{width: "100%"}}
                                value={eachLineSet.field}
                                placeholder={"请选择或填写对接字段"}
                                options={this.props.options}
                                onChange={(v)=>{
                                    eachLineSet.field = v;
                                    this.setState({},()=>{
                                        this.configChanged()
                                    })
                                }}
                            />
                        </Col>
                        <Col span={6}>
                            <Space>
                                <MinusCircleOutlined
                                  style={{
                                      color: "red"
                                  }}
                                  onClick={()=>{
                                      this.deleteLine(index)
                                  }}
                                />
                                <ColorPicker
                                  value={eachLineSet.extraStyle?.color}
                                  hideRecommendBtn={true}
                                  onChange={(v)=>{
                                      if(!eachLineSet.extraStyle){
                                          eachLineSet.extraStyle = {};
                                      }
                                      eachLineSet.extraStyle.color = v;

                                      this.setState({
                                          dataSetConfig: dataSetConfig
                                      },()=>{
                                          this.configChanged()
                                      })
                                  }}
                                />
                            </Space>
                        </Col>
                        <Col span={12}>
                            <Space>
                                占宽:
                                <Select
                                  value={eachLineSet.extraStyle?.span}
                                  style={{width: "80px"}}
                                  onChange={(v)=>{
                                      if(!eachLineSet.extraStyle){
                                          eachLineSet.extraStyle = {};
                                      }
                                      eachLineSet.extraStyle.span = v;

                                      this.setState({
                                          dataSetConfig: dataSetConfig
                                      },()=>{
                                          this.configChanged()
                                      })
                                  }}
                                  options={[
                                      {label: "1/24",value: 1},
                                      {label: "2/24",value: 2},
                                      {label: "3/24",value: 3},
                                      {label: "4/24",value: 4},
                                      {label: "5/24",value: 5},
                                      {label: "6/24",value: 6},
                                      {label: "7/24",value: 7},
                                      {label: "8/24",value: 8},
                                      {label: "9/24",value: 9},
                                      {label: "10/24",value: 10},
                                      {label: "11/24",value: 11},
                                      {label: "12/24",value: 12},
                                      {label: "13/24",value: 13},
                                      {label: "14/24",value: 14},
                                      {label: "15/24",value: 15},
                                      {label: "16/24",value: 16},
                                      {label: "17/24",value: 17},
                                      {label: "18/24",value: 18},
                                      {label: "19/24",value: 19},
                                      {label: "20/24",value: 20},
                                      {label: "21/24",value: 21},
                                      {label: "22/24",value: 22},
                                      {label: "23/24",value: 23},
                                      {label: "24/24",value: 24},
                                  ]}
                                />
                            </Space>
                        </Col>
                        <Col span={12}>
                            <Space>
                                字号:
                                <Select
                                  value={eachLineSet.extraStyle?.fontSize}
                                  style={{width: "80px"}}
                                  onChange={(v)=>{
                                      if(!eachLineSet.extraStyle){
                                          eachLineSet.extraStyle = {};
                                      }
                                      eachLineSet.extraStyle.fontSize = v;

                                      this.setState({
                                          dataSetConfig: dataSetConfig
                                      },()=>{
                                          this.configChanged()
                                      })
                                  }}
                                  options={[
                                      {label: "12px",value: 12},
                                      {label: "14px",value: 14},
                                      {label: "16px",value: 16},
                                      {label: "18px",value: 18},
                                      {label: "20px",value: 20},
                                      {label: "24px",value: 24},
                                      {label: "30px",value: 30},
                                      {label: "50px",value: 50},
                                  ]}
                                />
                            </Space>
                        </Col>
                    </Row>
                })
            }
            <Row>
                <Col span={6} offset={4}>
                    <Button
                        icon={<LinkOutlined />}
                        type={"link"}
                        size={"small"}
                        onClick={()=>{
                            this.addAll()
                        }}
                    >
                        自动提取
                    </Button>
                </Col>
                <Col span={6} offset={4}>
                    <Button
                        icon={<PlusOutlined />}
                        style={{color: "limegreen"}}
                        type={"link"}
                        size={"small"}
                        onClick={()=>{
                            this.addOneLineData()
                        }}
                    >
                        增加一个系列
                    </Button>
                </Col>
            </Row>
        </div>
    }


    addOneLineData(){
        let {dataSetConfig} = this.state;

        if(!dataSetConfig.itemArr){
            dataSetConfig.itemArr = [];
        }

        dataSetConfig.itemArr.push({
            field: null,
            extraStyle: {
                color: "#ffffff",
                span: 6,
                fontSize: 14,
            },
        })

        this.setState({})
    }

    deleteLine(index){
        let {dataSetConfig,listChange} = this.state;

        _.remove(dataSetConfig.itemArr,(ti,n)=>{
            return n === index
        })

        this.setState({listChange: listChange + 1},()=>{
            this.configChanged()
        })
    }

    addAll(){
        let {dataSetConfig} = this.state;

        if(!dataSetConfig.itemArr){
            dataSetConfig.itemArr = [];
        }

        let has = [];
        dataSetConfig?.itemArr?.forEach((lineSet)=>{
            has.push(lineSet.field);
        })
        this.props.options?.forEach((opt)=>{
            if(!has.includes(opt.value)){
                dataSetConfig.itemArr.push({
                    field: opt.value,
                    extraStyle: {
                        color: "#ffffff",
                        span: 6,
                        fontSize: 14,
                    },
                })
            }
        })

        this.setState({},()=>{
            this.configChanged()
        })
    }
}