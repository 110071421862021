import React from "react";
import styles from "./DataDisposition.less";
import DataDefinitionFile from "./DataDefinitionFile";
import {Divider,Popover} from "antd";

export default class ViewTypeSel extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            type: this.props.type || null,
        }

        this.options = DataDefinitionFile.getSelectView(this.props.contentMode,this.props.usage);
    }

    render(){
        let {type} = this.state;

        return <div>
            {this.options?.map((eachGroup,index)=>{
                return <div
                    key={"index_" + index}
                >
                    <Divider orientation="left">
                        <div className={styles.groupName}>{eachGroup.groupName}</div>
                    </Divider>
                    <div className={styles.eachGroupBox}>
                        {
                            eachGroup.children?.map((eachView)=>{
                                let view = <div
                                  key={eachView.type}
                                  className={type === eachView.type?styles.viewItemSel: styles.viewItem}
                                  onClick={()=>{
                                      // this.setState({
                                      //     type: eachView.type
                                      // },()=>{
                                      //
                                      // })
                                      this.props.onChange?.(eachView)
                                  }}
                                >
                                    {
                                        eachView.cover
                                          ?<div className={styles.viewLogo}
                                                style={{backgroundImage: `url(${eachView.cover})`}}
                                          ></div>
                                          :<div className={styles.viewLogo}>{eachView.title}</div>
                                    }
                                    {eachView.title}
                                </div>

                                if(this.props.needPreview){
                                    return <Popover
                                      title={eachView.title}
                                      content={<div className={styles.viewLogo}
                                                    style={{
                                                        width: "250px",
                                                        height: "200px",
                                                        backgroundImage: `url(${eachView.cover})`
                                                    }}
                                      ></div>}
                                    >
                                        {view}
                                    </Popover>
                                }else{
                                    return view;
                                }
                            })
                        }
                    </div>
                </div>
            })}
        </div>
    }
}