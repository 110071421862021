import React, { useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./DigitalHumanWorkTimeRate.less";
import { Flex, Select } from 'antd';

type ResponeItem = {
  _id: string,
  busyCount: number;
  onlineCount: number;
}

type DataSource = {
  onlineTotal: number;
  workTotal: number;
  loadRate: number
}

const DigitalHumanWorkTimeRate: React.FC<any> = (props) => {
  console.error(props)
  const [errorMsg, setErrorMsg] = useState(null)
  const [time, setTime] = useState(1)
  const [dataSource, setDataSource] = useState<DataSource>()

  // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}digitalHuman/digitalHumanLoadRate`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     time: time
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate, time],
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       // 将数据格式化，可直接使用的数据字段  分钟时长 转化为 分钟时长
  //       const workTotal = Number((res.data?.reduce((total: number, item: ResponeItem) => total + item?.busyCount, 0) / 60).toFixed(2))
  //       const onlineTotal = Number((res.data?.reduce((total: number, item: ResponeItem) => total + item?.onlineCount, 0) / 60).toFixed(2))
  //       setDataSource({
  //         onlineTotal,
  //         workTotal,
  //         loadRate: workTotal * 100 / onlineTotal,
  //       })
  //     } else {
  //       setErrorMsg(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })

  return <div className={styles.outer + " swiper-no-swiping"}>
    <div className={styles.controlBar}>
      <Select
        style={{ width: "120px" }}
        value={time}
        onChange={(time) => {
          setTime(time)
        }}
        options={[
          { label: "今日", value: 1 },
          { label: "7日", value: 7 },
          { label: "15日", value: 15 },
          { label: "30日", value: 30 },
        ]}
      />
    </div>
    <div className={styles.chartBox}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : <div
            className={styles.chartBox}
          >
            {
              dataSource ?
                <Flex>
                  <div className={styles.worktimelabel}>工作时长：{dataSource.workTotal}小时 </div>
                  <Flex className={styles.onlineBar} style={{
                    backgroundColor: dataSource?.onlineTotal > 0 ? (props?.contentMsg?.dataConfig?.contentParams?.typeOnlineSelColor ? props?.contentMsg?.dataConfig?.contentParams?.typeOnlineSelColor : 'blue') : 'gray'
                  }}>
                    <div className={styles.workBar} style={{
                      width: `${dataSource?.loadRate}%`,
                      backgroundColor: dataSource.workTotal > 0 ? (props?.contentMsg?.dataConfig?.contentParams?.typeWorkSelColor ? props?.contentMsg?.dataConfig?.contentParams?.typeWorkSelColor : 'orange') : 'transparent'
                    }}>
                      <div className={styles.worktime}>{dataSource.loadRate > 0 ? (dataSource?.loadRate).toFixed(2) + '%' : ''}</div>
                    </div>
                  </Flex>

                  <Flex align='center'>
                    <div className={styles.divider} style={{ backgroundColor: dataSource?.onlineTotal > 0 ? 'blue' : 'gray' }} />
                    <span className={styles.onlinetime}>在线时长：{dataSource.onlineTotal}小时</span>
                  </Flex>
                </Flex>
                : null
            }
          </div>
      }
    </div>
  </div>
}

export default DigitalHumanWorkTimeRate