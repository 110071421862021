import React, { useEffect, useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./DigitalHumanDeviceBar.less";
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts'
import { Card, Select, Statistic } from 'antd';

interface DefaultAxisTextType {
  [key: string]: string;
  '1,2,3,4': string;
  '2,3': string;
}

const DefaultAxisText: DefaultAxisTextType = {
  '1,2,3,4': '全部工作设备类型占比',
  '2,3': '在线工作设备类型占比'
}

const DigitalHumanDeviceBar: React.FC<any> = (props) => {
  console.error(props)
  const [errorMsg, setErrorMsg] = useState(null)
  const [errorMsg1, setErrorMsg1] = useState(null)
  const [status, setStatus] = useState('1,2,3,4')
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<Array<{ name: string, value: number }>>([])

  useEffect(()=> {
    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('deviceTotal')) {
      runTotalBusniess()
      
    }

    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('deviceRatio')) {
      runDeviceRato()
    }
  }, [props.dataUpdate, status])

  // const { loading, run: runTotalBusniess } = useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}robot/getRobotCount`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     status: status.split(',').map(Number)
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate, status],
  //   manual: true,
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       setTotal(res?.data || 0)
  //     } else {
  //       setErrorMsg(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })

  // const { loading: loadingPie, run: runDeviceRato } = useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}robot/robotTerminalRatio`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,
  //     status: status.split(',').map(Number),
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate, status],
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       //转化数据成需要的格式
  //       setDataSource((res?.data || [])?.map((item: { count: number, name: string }) => ({
  //         name: item?.name,
  //         value: item?.count,
  //         count: item?.count
  //       })) || [])
  //     } else {
  //       setErrorMsg1(res?.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg1(err.toString())
  //   }
  // })

  const getSeries = () => {
    if (dataSource?.length === 0) {
      return []
    }

    return dataSource?.map((item: any) => {
      return {
        value: item?.count,
        ...item,
        type: 'bar',
        barGap: 0,
        label: {
          show: true,
          position: 'top',
          distance: 15,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 0,
          formatter: '{c}',
          fontSize: 12,
          color: '#fff',
          rich: {
            name: {}
          },
        },
        emphasis: {
          focus: 'series'
        },
        data: [item?.count || 0]
      }
    })  
  }

  const getOption = (): echarts.EChartsOption => {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {
        textStyle: {
          color: '#fff', // 图例文字颜色
        },
        data: dataSource?.map(item => item.name)
      },
      xAxis: [
        {
          type: 'category',
          axisTick: { show: false },
          data: [DefaultAxisText[status]]
        }
      ],
      yAxis: [
        {
          type: 'value',
          splitLine: {
            show: false
          }
        }
      ],
      series: getSeries()
    };
  };

  return <div className={styles.outer + " swiper-no-swiping"}>
    <div className={styles.controlBar}>
      <Select
        style={{ width: "120px" }}
        value={status}
        onChange={(status) => {
          setStatus(status)
        }}
        options={[
          { label: "全部", value: '1,2,3,4' },
          { label: "在线", value: '2,3' },
        ]}
      />
    </div>
    {
      props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('deviceTotal')
      && <div className={styles.charBox1}>
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Card bordered hoverable className={styles.card}>
              <Statistic
                title="工作设备总数"
                value={total}
                suffix="台"
                precision={0}
              />
            </Card>
        }
      </div>
    }
    {
      props.contentMsg?.dataConfig?.contentParams?.typeShowSel.includes('deviceRatio')
      && <div className={styles.chartBox}>
        {
          errorMsg1
            ? <div className={styles.errorMsg}>
              {errorMsg1}
            </div>
            : <EChartsReact style={{ width: '100%', height: '100%' }} option={getOption()} />
        }
      </div>
    }
  </div>
}


export default DigitalHumanDeviceBar