import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import { Modal, message, Tag } from "antd";
import TimeHelp from "../../../tool/TimeHelp";
import less from "../accountManage/AccountManage.less";
import HttpTool from "../../../tool/HttpTool";

class ESIMRecycleList extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        columns: this.getTableColumns(),
        url: APILXD.esimGetRecycleEsimList,
      },
      search: {
        option: {
          openSenior: true,
          placeholder: '请输入手机号码模糊搜索',
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            value.mobile = value.keyword
            return true;
          },
        }
      },
    }
  }


  back(data) {
    Modal.confirm({
      title: "提示",
      content: "还原后回归至未绑定状态，是否继续？",
      okText: "继续",
      onOk: () => {
        HttpTool.post(data?.type === 1 ? APILXD.esimPrivateRestore : APILXD.esimRestore, (code, msg, json) => {
          message.success(msg);
          this._loadDataForNet();
        }, (code, msg) => {
          message.error(msg)
        }, {
          _id: data._id
        })
      }
    })
  }

  /**
   *  列表数据配置
   */
  getTableColumns() {
    return [
      {
        title: '手机号',
        dataIndex: 'mobile',
        key: 'mobile',
      },
      {
        title: '国家',
        dataIndex: 'country',
        key: 'country',
        render: (text, record, index) => {
          return <div>{record.country && record.country.countryName || "-"}</div>
        }
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        render: (text, record, index) => {
          return <div>{record.type === 1 ? <Tag color="blue">共享</Tag> : <Tag color="green">独享</Tag>}</div> || "-"
        }
      },
      {
        title: '有效期',
        dataIndex: 'expireTime',
        key: 'expireTime',
        render: (text, record, index) => {
          if (record.expireTime) {
            let nowTime = Date.now();

            return <div>
              <div>
                {
                  record.expireTime < nowTime
                    ? <span style={{ color: "red" }}>停机</span>
                    : (
                      record.expireTime - nowTime > 86400000
                        ? <span>{Math.floor((record.expireTime - nowTime) / 86400000)}天</span>
                        : <span>{TimeHelp.getTimeDHM(record.expireTime - nowTime)}</span>
                    )
                }
              </div>
              <div style={{ opacity: 0.5 }}>{record.expireTime ? TimeHelp.getYMD(record.expireTime) : "-"}</div>
            </div>
          } else {
            return "无限期"
          }
        }
      },
      {
        title: '回收时间',
        dataIndex: 'recycleTime',
        key: 'recycleTime',
        render: (text, record, index) => {
          return <div>{record.recycleTime ? TimeHelp.getYMDHM(record.recycleTime) : "-"}</div>
        }
      },
      {
        title: '操作',
        width: 120,
        render: (text, record, index) => {
          return (
            <div>
              {
                this.powerConfig.Edit
                  ? (<div
                    className={less.btnEdit}
                    onClick={() => {
                      this.back(record)
                    }}
                  >还原
                  </div>)
                  : null
              }
            </div>)
        }
      },
    ]
  }
}

module.exports = ESIMRecycleList;