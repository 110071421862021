import React from 'react';
import ListPage from '../../base/ListPage.js';
import APILXD from '../../../http/APILXD.js';
import { message, Tag, Badge, Space } from "antd";
import TimeHelp from "../../../tool/TimeHelp";
import ModalBase from "../../../components/modalBase/index";
import less from "../accountManage/AccountManage.less";
import ModalDel from '../../../components/modalDelete/ModalDelete.js';
import LayoutCountrySelect from "../../../components/areaSelect/CountrySelectUseForLayout";
import CodeGetInput from "./CodeGetInput";
import HttpTool from "../../../tool/HttpTool";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

const ModalDelete = new ModalDel();
const MB = new ModalBase();

const StatusMap = {
  1: {
    title: "未绑定",
    value: 1,
    color: "gray",
  },
  2: {
    title: "绑定中",
    value: 2,
    color: "darkorange",
  },
  3: {
    title: "待机",
    value: 3,
    color: "dodgerblue",
  },
  4: {
    title: "绑定失败",
    value: 4,
    color: "red",
  },
  5: {
    title: "激活中",
    value: 5,
    color: "darkorange",
  },
  6: {
    title: "已激活",
    value: 6,
    color: "limegreen",
  },
  7: {
    title: "激活失败",
    value: 7,
    color: "red",
  },
  // 8: {
  //   title: "回收",
  //   value: 8,
  //   color: "red",
  // },
  9: {
    title: "回收中",
    value: 9,
    color: "red",
  },
}

const DeviceStatusMap = {
  1: {
    title: "离线",
    value: 1,
    color: "gray",
  },
  2: {
    title: "空闲中",
    value: 2,
    color: "limegreen",
  },
  3: {
    title: "忙碌中",
    value: 3,
    color: "red",
  },
}

class ESIMList extends ListPage {
  constructor(props) {
    super(props);
  }

  base_getListConfig() {
    return {
      table: {
        columns: this.getTableColumns(),
        url: APILXD.esimGetPirvateESIMList,
      },
      search: {
        seniorSearch: this.getSeniorSearch(),
        option: {
          hideSeniorBtn: true,
          openSenior: true,
          placeholder: '请输入手机号码模糊搜索',
          reg: /^[\s\S]*$/,
          beforeSubmit: (value) => {
            value.mobile = value.keyword
            return true;
          },
        }
      },
      new: {
        defaultValue: '新建',
        action: () => {
          this.add();
        }
      },
      extraBox: () => {
        return <StatisCard />
      }
    }
  }

  getSeniorSearch() {
    return {
      colCount: 3,
      formItemLayout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18, offset: 0 },
      },
      parameterArr: [
        {
          field: "deviceId",
          name: "手机/设备ID",
          type: 'input',
          reg: /^[\S\s]{0,50}$/,
          verMessage: "请输入设备ID",
          option: {
            placeholder: "填写手机/设备ID模糊搜索",
          }
        },
        {
          field: "status",
          name: "状态",
          type: 'select',
          selectType: 'value',
          reg: (v) => {
            return true;
          },
          option: {
            allowClear: true,
          },
          data: Object.values(StatusMap).map((item) => {
            return {
              title: <Tag color={item.color}>{item.title}</Tag>,
              value: item.value
            }
          })
        },
        {
          field: "platforms",
          name: "使用情况",
          type: 'selectAll',
          selectType: 'value',
          required: false,
          ver: true,
          verMessage: "请选择使用平台",
          reg: (v) => {
            return true
          },
          apiConfig: {
            url: APILXD.getPlatformList,
            fillObject: (json) => {
              let list = [];
              for (let code in json) {
                list.push({
                  title: json[code],
                  value: code
                });
              }

              return list;
            }
          },
          data: [],
          option: {
            mode: "multiple",
            allowClear: true,
            placeholder: "请选择使用平台",
            dropdownMatchSelectWidth: false,
            showSearch: true,
            onSearch: () => { },
            filterOption: (input, option) => {
              return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            optionFilterProp: "children",
            defaultValue: []
          }
        },
      ],
    }
  }

  getAddData() {
    let defaultOption = {
      type: "input",
      ver: true,
      reg: /^[\S\s]{0,50}$/,
      verMessage: "最多50个字符",
      required: true,
    };
    let props = {
      colCount: 1,
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 16, offset: 0 },
      },
      parameterArr: [
        {
          ...defaultOption,
          field: "activate",
          name: "激活码",
          type: 'diy',
          component: CodeGetInput,
          reg: (v) => {
            return !!v;
          },
          verMessage: "请输入激活码",
          option: {
            placeholder: "请填写激活码",
            maxLength: "100",
          }
        },
        {
          ...defaultOption,
          field: "country",
          name: "地区与手机区号",
          type: 'diy',
          // col: 1/3,
          // formItemLayout: {
          //   labelCol: { span: 12 },
          //   wrapperCol: { span: 12, offset: 0 },
          // },
          component: LayoutCountrySelect,
          reg: (v) => {
            return !!v;
          },
          verMessage: "请选择地区与手机区号",
          option: {
          },
        },
        {
          ...defaultOption,
          field: "mobile",
          name: "手机号",
          type: 'input',
          reg: /^[1-9][0-9]{5,12}$/,
          verMessage: "请输入格式正确的手机号，无需填写区号",
          // col: 2/3,
          // formItemLayout: {
          //   labelCol: { span: 6 },
          //   wrapperCol: { span: 12, offset: 0 },
          // },
          option: {
            placeholder: "请填写手机号，无需填写区号",
            maxLength: "20",
          }
        },
        {
          verMessage: "请输入绑定手机/设备ID",
          field: "preBindDeviceId",
          name: "预绑定设备ID",
          type: 'Input',
          extra: "不填写绑定设备ID，则随机分配设备",
          required: false,
          reg: (v) => {
            return true
          },
          option: {
            placeholder: '请输入手机/设备ID',
            defaultValue: undefined
          }
        },
        {
          ...defaultOption,
          field: "expireTimeSet",
          name: "过期日期",
          type: 'DatePicker',
          extra: "不选择日期，则代表无限期",
          required: false,
          reg: (v) => {
            return true
          },
          option: {
            style: { width: 300 },
            placeholder: '选择过期的日期',
            format: "YYYY-MM-DD 00:00:00",
            disabledDate: (value) => {
              if (!value) {
                return false;
              }
              let nowDate = new Date();
              return value.valueOf() < nowDate.valueOf() - 86400000;
            },
          }
        },
      ],
    };
    return {
      props,
      otherParam: null
    }
  }

  add() {
    let addData = this.getAddData();
    MB.show(
      {
        title: "新建独享ESIM",
        okTitle: "提交",
        closeTitle: "取消",
      },
      addData.props
      ,
      {
        url: APILXD.esimPrivateInsert,
        otherParam: {

        },
        beforeSubmit: (param) => {
          console.log(param)
          if (param.expireTimeSet) {
            param.expireTime = param.expireTimeSet.valueOf()
          } else {
            param.expireTime = 0;
          }

          if (param.country?.phoneCode) {
            param.mobile = param.country.phoneCode + param.mobile
          }
          delete param.expireTimeSet;

          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }

  editTime(data) {
    MB.show(
      {
        title: "编辑过期时间",
        okTitle: "提交",
        closeTitle: "取消",
      },
      {
        colCount: 1,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 16, offset: 0 },
        },
        parameterArr: [
          {
            verMessage: "最多50个字符",
            field: "expireTimeSet",
            name: "过期日期",
            type: 'DatePicker',
            required: false,
            reg: (v) => {
              return true
            },
            extra: "不选择日期，则代表无限期",
            option: {
              style: { width: 300 },
              placeholder: '选择过期的日期',
              format: "YYYY-MM-DD 00:00:00",
              defaultValue: data?.expireTime ? dayjs(data.expireTime) : undefined,
              // defaultValue:undefined,
              disabledDate: (value) => {
                if (!value) {
                  return false;
                }
                let nowDate = new Date();
                return value.valueOf() < nowDate.valueOf() - 86400000;
              },
            }
          },
          {
            field: "usePlatform",
            name: "正在使用的平台",
            type: 'string',
            data: [],
            option: {
              defaultValue: data.usePlatform ? Object.values(data.usePlatform).join(",") : "无"
            }
          },
          {
            field: "usePlatform",
            name: "新增使用平台",
            type: 'selectAll',
            selectType: 'value',
            required: false,
            ver: true,
            verMessage: "请选择新增平台",
            reg: (v) => {
              return true
            },
            apiConfig: {
              url: APILXD.getPlatformList,
              fillObject: (json) => {
                let list = [];
                for (let code in json) {
                  if (data.usePlatform && data.usePlatform[code]) {
                    continue
                  }
                  list.push({
                    title: json[code],
                    value: code
                  });
                }

                return list;
              }
            },
            data: [],
            option: {
              mode: "multiple",
              allowClear: true,
              placeholder: "请选择新增平台",
              dropdownMatchSelectWidth: false,
              showSearch: true,
              onSearch: () => { },
              filterOption: (input, option) => {
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              },
              optionFilterProp: "children",
              defaultValue: []
            }
          },
        ],
      }
      ,
      {
        url: APILXD.esimPrivateUpdate,
        otherParam: {
          _id: data._id
        },
        beforeSubmit: (param) => {
          console.log(param)
          if (param.expireTimeSet) {
            param.expireTime = param.expireTimeSet.valueOf()
          } else {
            param.expireTime = 0;
          }
          delete param.expireTimeSet;

          param.usePlatform = param.usePlatform.map((o) => {
            return typeof o === "string" ? o : o.key
          })

          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }


  preBind(record) {
    MB.show(
      {
        title: "绑定设备",
        okTitle: "提交",
        closeTitle: "取消",
      },
      {
        colCount: 1,
        formItemLayout: {
          labelCol: { span: 4 },
          wrapperCol: { span: 16, offset: 0 },
        },
        parameterArr: [
          {
            verMessage: "请输入手机/设备ID",
            field: "preBindDeviceId",
            name: "绑定设备ID",
            type: 'Input',
            required: true,
            reg: (v) => {
              return !!v
            },
            option: {
              placeholder: '请输入手机/设备ID',
              defaultValue: record.preBindDeviceId || undefined
            }
          },
        ],
      }
      ,
      {
        url: APILXD.esimPrivatePreBind,
        otherParam: {
          _id: record._id
        },
        beforeSubmit: (param) => {
          console.log(param)

          return true;
        },
        callBack: (state, msg) => {
          //添加成功回调
          state == 'success' && this._loadDataForNet();
        }
      });
  }

  /**
   *  列表数据配置
   */
  getTableColumns() {
    const getTips = (recycleCount) => {
      switch (recycleCount) {
        case 0:
          return "回收后进入回收站，可能不可还原，请谨慎操作！";
        case 1:
        case 2:
          return `此号码已回收${recycleCount}次，回收后可能不可还原，请谨慎操作！`;
        default:
          return (
            <span style={{ color: recycleCount >= 5 ? 'red' : '#FFD700' }}>
              此号码已回收{recycleCount}次，回收后可能不可还原，请谨慎操作！
            </span>
          );
      }
    }

    return [
      {
        title: '手机号',
        dataIndex: 'mobile',
        key: 'mobile',
      },
      {
        title: '国家或地区',
        dataIndex: 'country',
        key: 'country',
        render: (text, record, index) => {
          return <div>{record.country && record.country.countryName || "-"}</div>
        }
      },
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => {
          if (!StatusMap[text]) {
            return "-"
          }
          // 当设备离线时  状态标签背景全部置灰
          return <Tag color={record?.deviceStatus !== 1 ? StatusMap[text].color : 'gray'}>{StatusMap[text].title}</Tag>
        }
      },
      {
        title: '有效期',
        dataIndex: 'expireTime',
        key: 'expireTime',
        render: (text, record, index) => {
          if (record.expireTime) {
            let nowTime = Date.now();

            return <div>
              <div>
                {
                  record.expireTime < nowTime
                    ? <span style={{ color: "red" }}>停机</span>
                    : (
                      record.expireTime - nowTime > 86400000
                        ? <span>剩余{Math.floor((record.expireTime - nowTime) / 86400000)}天</span>
                        : <span>{TimeHelp.getTimeDHM(record.expireTime - nowTime)}</span>
                    )
                }
              </div>
              <div style={{ opacity: 0.5 }}>{record.expireTime ? TimeHelp.getYMD(record.expireTime) : "-"}</div>
            </div>
          } else {
            return "无限期"
          }
        }
      },
      {
        title: '手机/设备ID',
        dataIndex: 'deviceId',
        key: 'deviceId',
        render: (_, record) => {

          return (
            <Space size={8}>
              <span>{record.deviceId}</span>
              {
                record.deviceId
                  ? (record?.blackList ? <Tag color='#f50'>黑名单</Tag> : <Tag color={DeviceStatusMap[record?.deviceStatus]?.color}>{DeviceStatusMap[record?.deviceStatus]?.title}</Tag>)
                  : null
              }
            </Space>
          )
        }
      },
      {
        title: '使用情况',
        dataIndex: 'usePlatform',
        key: 'usePlatform',
        render: (text, record) => {
          if (!record.usePlatform) {
            return "-"
          }

          return Object.keys(record.usePlatform).map((platKey) => {
            return <Badge status={"processing"} text={record.usePlatform[platKey]} style={{ marginRight: "5px" }} />
          })
        }
      },
      {
        title: '更新时间',
        dataIndex: 'updateTime',
        key: 'updateTime',
        render: (text, record, index) => {
          return <div>{record.updateTime ? TimeHelp.getYMDHM(record.updateTime) : "-"}</div>
        }
      },
      {
        title: '操作',
        width: 300,
        render: (text, record, index) => {
          return (
            <Space>
              <div style={{
                opacity: record?.blackList ? 0.4 : 1,
                pointerEvents: record?.blackList ? 'none' : 'auto',
                cursor: record?.blackList ? 'not-allowed' : 'pointer'
              }}>
                <div
                  className={less.btnDetail}
                  onClick={() => {
                    this.props.openTab({
                      path: 'ESIMMessageList',
                      title: '短信列表',
                      post: {
                        navPath: 'ESIMMessageList',
                        parent: this.props,
                        mobile: record.mobile,
                        status: record.status,
                        deviceStatus: record?.deviceStatus
                      }
                    })
                  }}
                >短信
                </div>
                {
                  this.powerConfig.Edit
                    ? (<div
                      className={less.btnEdit}
                      onClick={() => {
                        this.preBind(record)
                      }}
                    >
                      {record.preBindDeviceId ? "修改绑定" : <span style={{ padding: "0 15px" }}>绑定</span>}
                    </div>)
                    : null
                }
                {
                  this.powerConfig.Edit
                    ? (<div
                      className={less.btnEdit}
                      onClick={() => {
                        this.editTime(record)
                      }}
                    >编辑
                    </div>)
                    : null
                }
              </div>
              {
                this.powerConfig.Delete && ![2, 5, 6].includes(record.status)//绑定中、激活中和已激活的手机号不允许回收
                  ? (<div
                    className={less.btnDelete}
                    onClick={() => {
                      ModalDelete.show({
                        title: "提示",
                        okTitle: "确定",
                        closeTitle: "取消",
                      },
                        {}
                        ,
                        {
                          otherParam: { _id: record._id },
                          content: record.mobile,
                          url: APILXD.esimPrivateRecycle,
                          apiType: 'post',
                          ask: "是否回收",
                          tip: getTips(record?.recycleCount || 0),
                          callBack: (state) => {
                            //删除成功回调
                            state === "success" && this._loadDataForNet();
                          }
                        });
                    }}
                  >回收
                  </div>)
                  : null
              }
            </Space>
          )
        }
      },
    ]
  }
}

class StatisCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msgObj: null
    }
  }

  componentDidMount() {
    this.getMsg()
  }

  getMsg() {
    HttpTool.post(APILXD.esimPrivateDeviceStatistics, (code, msg, json) => {
      this.setState({
        msgObj: json
      })
    }, (code, msg) => {
      message.error(msg)
    }, {

    })
  }

  render() {
    let { msgObj } = this.state;

    return <div style={{ float: "left" }}>
      <span>手机剩余：{msgObj && msgObj.cellphone} 台，</span>
      <span>可存：{msgObj && msgObj.storeNumber} 个号，</span>
      <span>已存：{msgObj && msgObj.storedNumber} 个号</span>
    </div>
  }
}

module.exports = ESIMList;