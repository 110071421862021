import React, { memo, useEffect, useState } from 'react'
import styles from './taskLogDistribution.less'
import { Button, Flex, Select, Statistic } from 'antd'
import { request } from '../../../../../../tool/http'

// import TaskSelectTwo from '@/pages/containers/dashboard/contentView/view/taskSelectTwo/taskSelectTwo'
// import HumanLineChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanLineChart/humanLineChart'
function TaskLogDistribution(props: any) {
  const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1])
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(1)
  const [data, setData] = useState<any>([])
  const [option, setOption] = useState<any>({})
  const [takeSelectType, setTakeSelectType] = useState<any>(0)
  const [takeSelect, setTakeSelect] = useState<any>([])
  //总数量
  const [total, setTotal] = useState<any>(0)
  //
  const [groupType, setGroupType] = useState<any>('hour')
  const [hourTime, setHourTime] = useState<any>('')
  // const [startTime, setStartTime] = useState<any>(0)
  // const [endTime, setEndTime] = useState<any>(0)

  //计算总数
  const getSum = (arr: any) => {
    let sum = 0
    arr.forEach((item: any) => {
      sum += item
    })
    return sum
  }

  //格式化转换
  const formatDate = (dateStr: any) => {
    const year = dateStr.slice(0, 4);  // 提取年份
    const month = dateStr.slice(4, 6); // 提取月份
    const day = dateStr.slice(6, 8);   // 提取日期
    return `${year}-${month}-${day}`;  // 返回格式化后的日期
  }
  const getTimestamps = (dateStr) => {
    // 提取年、月、日
    const year = parseInt(dateStr.slice(0, 4));
    const month = parseInt(dateStr.slice(4, 6)) - 1;  // 月份从 0 开始计数
    const day = parseInt(dateStr.slice(6, 8));

    // 创建这一天的开始时间：00:00:00
    const startDate = new Date(year, month, day, 0, 0, 0);
    const startTimeStamp = startDate.getTime();  // 开始时间的时间戳

    // 创建这一天的结束时间：23:59:59
    const endDate = new Date(year, month, day, 23, 59, 59);
    const endTimeStamp = endDate.getTime();  // 结束时间的时间戳

    return {
      startTime: startTimeStamp,
      endTime: endTimeStamp
    };
  }

  //请求业务数据  
  const getBusinessData = () => {
    setErrorMsg('')
    // let taskVal = [] as any
    let objVal = {} as any
    // console.log('taskVal+++++', taskVal, objVal)
    objVal[takeSelectType === 2 ? 'triggerIds' : 'execUnitPlanIds'] = takeSelect || []
    //判断单次任务和周期任务的值
    // let { startTime, endTime } = getTimestamps(formatDate(data[0].day))
    request(`${props.contentMsg?.proConfig?.apiPrefix}/taskStatistics/getStatus`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time,
        type: takeSelectType ? takeSelectType : '',
        ...objVal,
        group: time === 1 ? 'hour' : 'day',
        day: hourTime || '',
        // startTime,
        // endTime
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
        setTotal(getSum(res.data.map((item: any) => item.total)))
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }
  // 使用 useEffect 监听 props.contentMsg?.dataConfig?.contentParams?.typeShowSel 的变化
  useEffect(() => {
    if (props.contentMsg?.dataConfig?.contentParams?.typeShowSel !== showType) {
      // 如果 props 发生了变化，更新本地状态并触发接口调用
      setShowType(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]);
    }
  }, [props.contentMsg?.dataConfig?.contentParams?.typeShowSel]); // 监听 props 中的这个值˝

  useEffect(() => {
    getBusinessData()
  }, [showType, time, props.dataUpdate, takeSelectType, takeSelect])

  useEffect(() => {
    setTime(1)
  }, [hourTime])

  //获取图表数据
  const getOption = () => {
    let xAxis = [
      {
        //角度调整
        axisLabel: {
          rotate: 45
        },
        data: data.map((item: any) => item.day || item.hour.slice(8) + '时'),
        triggerEvent: hourTime ? false : true,
      }
    ]
    let dataFilter = [] as any[]
    //对应数据字段
    let dataKey: any = {
      running: '进行中',
      success: '成功',
      fail: '失败',
      noExec: '未执行',
      timeout: '超时',
      undistributed: '未分发'
    }
    Object.keys(dataKey).forEach((key: any) => {
      if (dataKey[key]) {
        dataFilter.push({
          _id: dataKey[key],
          count: data.map((item: any) => item[key] || 0)
        })
      }
    })
    let series = dataFilter.map((item: any) => {
      return {
        name: item._id,
        type: props?.contentMsg?.dataConfig?.contentType === 'system_taskLogDistributionTwo' ? 'bar' : 'line',
        stack: props?.contentMsg?.dataConfig?.contentType === 'system_taskLogDistributionTwo' ? 'total' : false,
        xAxisIndex: 0,
        data: item.count,
      }
    })
    let option = {
      xAxis,
      series,
    }
    setOption(option)
  }

  useEffect(() => {
    console.log('日志统计分布1111--', data)
    if (data) {
      getOption()
    }
  }, [data])

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      <div
        className={styles.chartBox}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex style={{ marginBottom: '16px' }} align='center'>
                <Flex align='center' gap={16}>
                  <div>
                    日志数量
                  </div>
                  <Statistic value={total} />
                </Flex>
                <Flex justify='end' flex={1} gap={12} className={" swiper-no-swiping"}>
                  {!hourTime && <Select
                    style={{ width: "120px" }}
                    value={time}
                    onChange={(v) => {
                      setTime(v)
                    }}
                    options={[
                      { label: "今日", value: 1 },
                      { label: "30日", value: 30 },
                      { label: "15日", value: 15 },
                      { label: "7日", value: 7 },
                    ]}
                  >
                  </Select>}
                  <Select style={{ width: "120px" }}
                    value={takeSelectType}
                    onChange={(v) => {
                      setTakeSelectType(v)
                    }}
                    options={[
                      { label: "全部任务", value: 0 },
                      { label: "单次任务", value: 2 },
                      { label: "周期任务", value: 3 },
                    ]} >

                  </Select>
                  {/* {takeSelectType !== 0 && <TaskSelectTwo
                    data={{
                      option: {
                        defaultValue: null
                      }
                    }}
                    takeType={takeSelectType}
                    businessId={[props.contentMsg?.proConfig?.usageParams?.businessId]}
                    businessType={props.contentMsg?.proConfig?.usageParams?.usageCode}
                    verification={(data, value) => {
                      setTakeSelect(value)
                      console.log(data, value)
                    }}
                  >
                  </TaskSelectTwo>} */}
                  {/* 判断hourTime是否为空，展示时间和一个取消按钮 */}
                  {hourTime && <Flex align='center' gap={12}>
                    <div>
                      {formatDate(hourTime)}
                    </div>
                    <Button type='text' onClick={() => { setHourTime('') }}>取消选择</Button>
                  </Flex>}
                </Flex>
              </Flex>

              {/* {showType.includes(1) && <HumanLineChart option={option} onClickXAxis={(params: any) => {
                console.log('params--', params)
                setHourTime(params?.value)
              }}></HumanLineChart>} */}
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(TaskLogDistribution)