import React, { memo, useEffect, useState } from 'react'
import styles from './taskLogStatisticsTwo.less'
import { Flex, Select, Statistic } from 'antd'
import { request } from '../../../../../../tool/http'
// import HumanLineChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanLineChart/humanLineChart'


function TaskLogStatisticsTwo(props: any) {
  // console.log('每天的在线人数---展示', props)
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  const [errorMsg, setErrorMsg] = useState('')
  const [time, setTime] = useState(30)
  const [data, setData] = useState<any>([])
  const [dataFilter, setDataFilter] = useState<any>([])
  const [option, setOption] = useState<any>({})
  const [sum, setSum] = useState(0)
  const [takeSelectType, setTakeSelectType] = useState(0)
  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg('')
    request(`${props.contentMsg?.proConfig?.apiPrefix}taskStatistics/getStatisticsLogs`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        time,
        type: takeSelectType ? takeSelectType : '',
      }
    }).then((res) => {
      if (res.code === 200) {
        setData(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }
  useEffect(() => {
    getBusinessData()
  }, [showType, time, props.dataUpdate, takeSelectType])

  //获取图表数据
  const getOption = () => {
    let dataFilter = [] as any[]
    //对应数据字段
    let dataKey: any = {
      running: '进行中',
      success: '成功',
      fail: '失败',
      noExec: '未执行',
      timeout: '超时',
      undistributed: '未分发'
    }
    Object.keys(data).forEach((key: any) => {
      if (dataKey[key]) {
        dataFilter.push({
          _id: dataKey[key],
          count: data[key]
        })
      }
    })
    setDataFilter(dataFilter)
    let xAxis = [
      {
        color: '#5470C6',
        chartTitle: '在线人数',
        data: dataFilter.map((item: any) => item._id)
      }
    ]

    let series = [{
      type: 'bar', //这里判断是折线状还是柱状图
      name: '数量',
      data: dataFilter.map((item: any) => item.count),
      color: '#5470C6',
    }]
    let option = {
      xAxis,
      series
    }
    setOption(option)
  }
  // 计算data里总数
  const getSum = (data: any) => {
    let sum = 0
    data.forEach((item: any) => {
      sum += item.count
    })
    //取整
    return Math.floor(sum)
  }
  useEffect(() => {
    console.log('data====', data)
    if (data) {
      getOption()
    }
  }, [data])
  useEffect(() => {
    setSum(getSum(dataFilter))
  }, [dataFilter])

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      <div
        className={styles.chartBox}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              <Flex>
                {showType.includes(1) && <Flex className={styles.averageQuantityBulk} justify='center' align='center'>
                  <Statistic title="日志总量" value={sum} />
                </Flex>}
                <Flex justify='end' flex={1} className={" swiper-no-swiping"}>
                  <Select
                    style={{ width: "120px" }}
                    value={time}
                    onChange={(v) => {
                      setTime(v)
                    }}
                    options={[
                      { label: "今日", value: 1 },
                      { label: "30日", value: 30 },
                      { label: "15日", value: 15 },
                      { label: "7日", value: 7 },
                    ]}
                  >
                  </Select>
                  <Select style={{ width: "120px", marginLeft: '10px' }}
                    value={takeSelectType}
                    onChange={(v) => {
                      setTakeSelectType(v)
                    }}
                    options={[
                      { label: "全部任务", value: 0 },
                      { label: "单次任务", value: 2 },
                      { label: "周期任务", value: 3 },
                    ]} >

                  </Select>
                </Flex>
              </Flex>

              {/* {showType.includes(2) && <HumanLineChart option={option}></HumanLineChart>} */}
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(TaskLogStatisticsTwo)