import React, { useRef, useEffect, useState } from 'react'
import styles from './DigitalHumanAreaMap.less'
import { request } from '../../../../../../tool/http'
import AreaMapShow from "./AreaMapShow";
import {Checkbox,message} from "antd";

function DigitalHumanAreaMap(props) {
  const mapRef = useRef(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [status, setStatus] = useState([1,2])

  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/countryRatio`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        status: status
      }
    }).then((res) => {
      if (res.code === 200) {
        showValue(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }

  useEffect(() => {
    getBusinessData()
  }, [props.dataUpdate,status])

  useEffect(() => {

  }, [props.styleUpdate])


  //绘制数据
  const showValue=(data)=>{
    mapRef.current?.renderDataToMap({
      areaData: data,
      status: status
    })
  }

  return (
    <div className={styles.outer}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : null
      }
      <div className={styles.controlBar}>
        <Checkbox.Group
          value={status}
          options={[
            {label: "在线",value:1},
            {label: "离线",value:2},
          ]}
          onChange={(v)=>{
            if(!v?.length){
              message.info("无法取消勾选：至少查看一种状态")
              return;
            }
            setStatus(v)
          }}
        />
      </div>
      <div
        className={styles.chartBox + " swiper-no-swiping"}
      >
        <AreaMapShow
          ref={mapRef}
          mapLoadDelay={60000}
          style={{}}
        />
      </div>
    </div>

  )
}
export default DigitalHumanAreaMap