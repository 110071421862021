import React, {Component} from 'react';
import styles from "./ScrollCard.less";
import CommonViewDiv from "../../../CommonViewDiv";
import AutoScrollCard from "../../../../../../components/AutoScrollCard/AutoScrollCard";
import BackgroundBgDiv from "../../BackgroundBgDiv";
import {Row,Col} from "antd";

export default class ScrollCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listData: []
    }
  }


  showValue(value){
    this.setState({
      listData: value
    })
  }

  render(){
    let contentMsg = this.props.contentMsg;
    let {contentParams} = contentMsg.dataConfig;

    if(!contentParams){
      return null
    }

    let {listData} = this.state;

    if(!listData?.length){
      return null
    }

    if(!contentParams?.dataSetConfig?.itemArr?.length){
      return "数据对接未配置完整";
    }

    return <div
      className={styles.outerBox}
      ref={ref=>this.outerBox = ref}
    >
      <AutoScrollCard
        key={"a_" + contentParams.scrollSet?.autoScroll}
        flag={contentParams.scrollSet?.autoScroll}
        scrollSpaceWithUnit={window.changeRemNum(contentParams?.layoutSet?.cardGap)}
        scrollSpeed={contentParams.scrollSet?.scrollSpeed}
        dataSource={listData}
        renderRow={(eachData,index)=>{
          return <Row
            key={"key_" + index}
            className={styles.eachCardBox}
            style={{
              paddingTop: window.changeRemNum(contentParams?.layoutSet?.padding,"top","topUnit"),
              paddingBottom: window.changeRemNum(contentParams?.layoutSet?.padding,"bottom","bottomUnit"),
              paddingLeft: window.changeRemNum(contentParams?.layoutSet?.padding,"left","leftUnit"),
              paddingRight: window.changeRemNum(contentParams?.layoutSet?.padding,"right","rightUnit"),
              borderRadius: window.changeRemNum(contentParams?.cardBgSet?.radiusSet),
              marginBottom: window.changeRemNum(contentParams?.layoutSet?.cardGap),
              rowGap: window.changeRemNum(contentParams?.layoutSet?.contentGap),
            }}
          >
            {
              contentParams?.cardBgSet?.use
                ?<BackgroundBgDiv
                  zIndex={1}
                  backgroundSet={contentParams?.cardBgSet}
                />
                :null
            }
            {
              contentParams?.dataSetConfig?.itemArr.map((eachContentSet,index)=>{
                return <Col
                  key={"c_" + index}
                  span={eachContentSet.extraStyle?.span}
                  style={{
                    fontSize:eachContentSet.extraStyle?.fontSize + "px",
                    color: eachContentSet.extraStyle?.color,
                  }}
                >
                  {eachData[eachContentSet.field]}
                </Col>
              })
            }
          </Row>
        }}
      />
    </div>
  }
}