import React, { useRef, useEffect, useState } from 'react'
import styles from './DigitalHumanAreaAna.less'
import { request } from '../../../../../../tool/http'

function DigitalHumanAreaAna(props) {
  //只有一个展示内容，不需要勾选
  const chartRef = useRef(null)
  const chartInstance = useRef(null);
  const [errorMsg, setErrorMsg] = useState(null)

  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/countryRatio`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
      }
    }).then((res) => {
      if (res.code === 200) {
        showValue(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }

  useEffect(() => {
    getBusinessData()
  }, [props.dataUpdate])

  useEffect(() => {
    chartInstance.current?.resize()
  }, [props.styleUpdate])

  // 初始化图表
  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current,"dark",{
        locale: "ZH"
      });
    }
    return () => {
      // 清理图表实例
      chartInstance.current?.dispose();
    };
  }, []);

  //绘制数据
  const showValue=(data)=>{
    let option = {
      grid: {
        left: '10',
        right: '10',
        bottom: '10',
        top: '30',
        containLabel: true
      },
      backgroundColor: "transparent",
      color: [ '#2EAA37', '#E74242'],
      legend: {
        show: true,
        // type: "plain",  //plain  scroll
        // position: "top",
      },
      tooltip: {
        show: true,
        trigger: "axis",
        // triggerOn: "mousemove",
      },
      xAxis: {
        show: true,
        type: "value",
        minInterval: 1,
      },
      yAxis: {
        show: true,
        type: 'category'
      },
      dataset: {
        dimensions: ["地区","在线","离线"],
        source: []
      },
      series: [
        {
          type: 'bar',
          name: "在线",
          stack: "value",
        },
        {
          type: 'bar',
          name: "离线",
          stack: "value",
        }
      ]
    }

    let source = [];
    if(data){
      data.forEach((item)=>{
        let formatItem = {
          name: item._id,
          online: 0,
          offline: 0,
        }
        item.event.forEach((eventItem)=>{
          if(eventItem.status === 1){
            formatItem.online = eventItem.sum;
          }else if(eventItem.status === 2){
            formatItem.offline = eventItem.sum;
          }
        })

        source.push([
          formatItem.name,
          formatItem.online,
          formatItem.offline,
        ])
      })

      option.dataset.source = source
    }

    //渲染
    console.log("最终渲染option",option)
    chartInstance.current?.setOption(option)
  }

  return (
    <div className={styles.outer}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : null
      }
      <div
        ref={chartRef}
        className={styles.chartBox + " swiper-no-swiping"}
      >
      </div>
    </div>

  )
}
export default DigitalHumanAreaAna