import { useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./UnitsCount.less";
import { Card, Flex } from 'antd';

const UnitsCount: React.FC<any> = (props) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const [dataSource, setDataSource] = useState<number>(0)

    // const { loading } = useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}/execUnit/getExecUnitCount`, {
    //     data: {
    //         businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
    //         businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],//公司id
    //     }
    // }), {
    //     formatResult(res) {
    //         return res
    //     },
    //     refreshDeps: [props.dataUpdate],
    //     onSuccess: (res) => {
    //         if (res.code === 200) {
    //             //转化数据成需要的格式
    //             setDataSource(res?.data || 0)
    //         } else {
    //             setErrorMsg(res.message)
    //         }
    //     },
    //     onError(err: any) {
    //         setErrorMsg(err.toString())
    //     }
    // })

    return <div className={styles.outer}>
        {
            errorMsg
                ? <div className={styles.errorMsg}>
                    {errorMsg}
                </div>
                : <Card loading={loading} bordered={false} className={styles.cardBg} style={{ 
                    background: props?.contentMsg?.dataConfig?.contentParams?.cardBgSel 
                    || 'linear-gradient(135deg,rgba(246, 198, 249, 0.4) 0%,rgba(164, 164, 218, 0.4) 100%)'
                    }}>
                    <Flex style={{
                        fontSize: Number(props?.contentMsg?.dataConfig?.contentParams?.totalFontSize) || 24,
                        color: props?.contentMsg?.dataConfig?.contentParams?.totalFontColor,
                    }} align='center' justify='center'>
                        {dataSource} <span style={{ fontSize: 14, marginLeft: 12, color: '#fff' }}>个</span> </Flex>
                </Card>
        }
    </div>
}

export default UnitsCount