import React from "react";
import {
    Form,
    Input,
    Switch,
    Radio,
    Checkbox,
    Row,
    Col,
    InputNumber,
    Select,
    Slider
} from "antd";
import UploadFile from "../../../components/upLoad/UploadFile"
import ColorPicker from "./formDiyItem/ColorPicker";
import SVGBorderTypeSel from "./configSet/SVGBorderTypeSel";

const Item = Form.Item;
const Option = Select.Option;

//输入框 + 单位选择
function distanceSet(valueField, unitField, unitArray = ["px", "%"]) {
    return <Row>
        <Col span={12}>
            <Item
                noStyle
                name={valueField}
                style={{
                    display: 'inline-block',
                }}
            >
                <InputNumber
                    controls={false}
                    min={-1000}
                    max={1000}
                    step={1}
                    precision={0}
                />
            </Item>
        </Col>
        <Col span={12}>
            <Item
                noStyle
                name={unitField}
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{
                    display: 'inline-block',
                }}
            >
                <Select placeholder="选择单位"
                        style={{
                            width: "60px",
                            marginLeft: '8px',
                        }}
                >
                    {
                        unitArray.map((str) => {
                            return <Option
                                value={str}
                                key={str}
                            >{str}</Option>
                        })
                    }
                </Select>
            </Item>
        </Col>
    </Row>
}

//滑动和输入框通用
function getSliderAndInputItemGroup(field, options) {
    return <Row>
        <Col span={options?.addonAfter?16:18}>
            <Item
                noStyle
                name={field}
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{
                    display: "inline-block",
                }}
            >
                <Slider
                    min={0}
                    max={50}
                    step={1}
                    {...options}
                />
            </Item>
        </Col>
        <Col span={options?.addonAfter?8:6}>
            <Item
                noStyle
                name={field}
                rules={[
                    {
                        required: true,
                    },
                ]}
                style={{
                    display: "inline-block",
                }}
            >
                <InputNumber
                    style={{
                        width: options?.addonAfter?"70px":"60px"
                    }}
                    controls={false}
                    min={0}
                    max={50}
                    step={1}
                    precision={0}
                    {...options}
                />
            </Item>
        </Col>
    </Row>
}

//字体配置 （大小，粗细，颜色）
function getFontSet(parentKeyName,hideInput=false,hideColor=false){
    return <>
        {
            hideInput
                ?null
                :<Item
                    label={"文本"}
                    name={[].concat(parentKeyName).concat("text")}
                    rules={[{required: true}]}
                >
                    <Input
                        maxLength={50}
                    />
                </Item>
        }
        {
            hideColor
                ?null
                :<Item
                    label={"字体颜色"}
                    name={[].concat(parentKeyName).concat("color")}
                >
                    <ColorPicker/>
                </Item>
        }
        <Item
            label={"字体类型"}
            name={[].concat(parentKeyName).concat("fontFamily")}
        >
            {getFontFamilySelect()}
        </Item>
        <Item
            label={"字体大小"}
        >
            {distanceSet([].concat(parentKeyName).concat("fontSize"),[].concat(parentKeyName).concat("sizeUnit"),["px","rem"])}
        </Item>
        <Item
            label={"字体样式"}
        >
            <Item
                noStyle
                name={[].concat(parentKeyName).concat("fontBold")}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Checkbox>加粗</Checkbox>
            </Item>
            <Item
                noStyle
                name={[].concat(parentKeyName).concat("fontItalic")}
                rules={[{required: true}]}
                valuePropName={"checked"}
            >
                <Checkbox>倾斜</Checkbox>
            </Item>
        </Item>
    </>
}

//图标配置(大小，地址)
function getIconSet(parentKeyName,getFieldValue){
    let defaultImgUrl = getFieldValue([parentKeyName, "upload_imageUrl"]);

    return <>
        <Item
            label={"大小设置"}
        >
            <Item
                label={"宽"}
            >
                {distanceSet([parentKeyName, "iconW"], [parentKeyName, "iconWUnit"], ["px", "rem"])}
            </Item>
            <Item
                label={"高"}
            >
                {distanceSet([parentKeyName, "iconH"], [parentKeyName, "iconHUnit"], ["px", "rem"])}
            </Item>
        </Item>
        <Item
            name={[parentKeyName, "upload_imageUrl"]}
            label={"上传图标"}
            rules={[{required: true}]}
            normalize={(value, prevValue, prevValues) => {
                value = value?.[0]?.url || null
                return value
            }}
        >
            <UploadFile
                toCN={true}
                accept={"image/*"}
                size={1}
                data={{
                    option: {
                        size: 1,
                        defaultValue: defaultImgUrl ? [{
                            url: defaultImgUrl
                        }] : null
                    }
                }}
            />
        </Item>
    </>
}

function getFontFamilySelect(){
    return <Select>
        <Select.Option value={"default"}>跟随网站系统</Select.Option>
        <Select.Option value={"STSong,SimSun"}>宋体</Select.Option>
        <Select.Option value={"STFangsong,FangSong"}>仿宋</Select.Option>
        <Select.Option value={"STHeiti,SimHei"}>黑体</Select.Option>
        <Select.Option value={"STKaiti,KaiTi"}>楷体</Select.Option>
        <Select.Option value={"cursive"}>手写字体</Select.Option>
        <Select.Option value={"Date-Number"}>段码数字</Select.Option>
        <Select.Option value={"Beautiful-Number"}>特化数字</Select.Option>
    </Select>
}

//阴影配置
function getShadowSet(parentKeyNameArr){
    return <>
        <Item
            label={"大小"}
        >
            {getSliderAndInputItemGroup(parentKeyNameArr.concat("blur"),{
                min: 0,
                max: 100,
                step: 1
            })}
        </Item>
        <Item
            label={"颜色"}
        >
            {getColorSetItemInner(parentKeyNameArr.concat("color"))}
        </Item>
        <Item
            label={"不透明度"}
        >
            {getSliderAndInputItemGroup(parentKeyNameArr.concat("opacity"),{
                min: 0,
                max: 100,
                step: 1
            })}
        </Item>
    </>
}

//颜色配置
function getColorSetItemInner(field,extraOption={hideOpacity:true}){
    return  <Form.Item
        name={field}
    >
        <ColorPicker
            {...extraOption}
        />
    </Form.Item>
}

//简易背景配置
function getSimpleBgSet(parentKeyName){
    return <>
        <Item
          label={"启用背景"}
          name={[parentKeyName,"use"]}
          rules={[{required: true}]}
          valuePropName={"checked"}
        >
            <Switch
              checkedChildren={"开"}
              unCheckedChildren={"关"}
            />
        </Item>
        <Item
          label={"背景圆角"}
        >
            {this.distanceSet([parentKeyName,"radiusSet", "num"],[parentKeyName,"radiusSet", "unit"],["px","rem","%"])}
        </Item>
        <Item
          label={"不透明度"}
        >
            {this.getSliderAndInputItemGroup([parentKeyName,"opacity"], {
                min: 0,
                max: 100
            })}
        </Item>
        <Item
          label={"背景类型"}
          name={[parentKeyName,"modeSet", "mode"]}
          rules={[
              {
                  required: true,
              },
          ]}
        >
            <Radio.Group>
                <Radio value="color">纯色</Radio>
                <Radio value="border">边框</Radio>
                <Radio value="svgBorder">图形边框</Radio>
                <Radio value="image">图片</Radio>
            </Radio.Group>
        </Item>
        <Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues[parentKeyName].modeSet?.mode !== currentValues[parentKeyName].modeSet?.mode}
        >
            {({getFieldValue}) => {
                let showItem;
                let defaultImgUrl = getFieldValue([parentKeyName,"modeSet", "upload_imageUrl"]);

                switch (getFieldValue([parentKeyName,"modeSet", "mode"])) {
                    case "color":
                        showItem = <Item
                          label={"背景颜色"}
                        >
                            {this.getColorSetItemInner([parentKeyName,"modeSet", "color"])}
                        </Item>
                        break;
                    case "border":
                        showItem = <>
                            <Item
                              label={"边框颜色"}
                            >
                                {this.getColorSetItemInner([parentKeyName,"modeSet", "color"])}
                            </Item>
                            <Form.Item
                              label={"边框类型"}
                              name={[parentKeyName,"modeSet", "borderBgSet", "type"]}
                              rules={[{required: true}]}
                            >
                                <Radio.Group>
                                    <Radio value="solid">实线</Radio>
                                    <Radio value="dashed">虚线</Radio>
                                    <Radio value="dotted">点线</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                              label={"边框粗细"}
                              name={[parentKeyName,"modeSet", "borderBgSet", "width"]}
                              rules={[{required: true}]}
                            >
                                <InputNumber
                                  min={0}
                                  max={100}
                                  step={1}
                                  precision={0}
                                  addonAfter={<span>px</span>}
                                />
                            </Form.Item>
                        </>
                        break;
                    case "image":
                        showItem = <>
                            <Form.Item
                              name={[parentKeyName,"modeSet", "backgroundImgSize"]}
                              label={"图片大小"}
                              rules={[{required: true}]}
                            >
                                <Radio.Group>
                                    <Radio value="cover">剪裁铺满</Radio>
                                    <Radio value="contain">包含</Radio>
                                    <Radio value="100% 100%">拉伸填充</Radio>
                                    <Radio value="diy">自定义比例</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) => prevValues[parentKeyName].modeSet?.backgroundImgSize !== currentValues[parentKeyName].modeSet?.backgroundImgSize}
                            >
                                {({getFieldValue}) => {
                                    let showItem;
                                    if (getFieldValue([parentKeyName,"modeSet", "backgroundImgSize"]) === "diy") {
                                        showItem = <>
                                            <Item
                                              label={"宽"}
                                            >
                                                {this.distanceSet([parentKeyName,"modeSet", "backgroundImgSizeDiy", "width"], [parentKeyName,"modeSet", "backgroundImgSizeDiy", "widthUnit"])}
                                            </Item>
                                            <Item
                                              label={"高"}
                                            >
                                                {this.distanceSet([parentKeyName,"modeSet", "backgroundImgSizeDiy", "height"], [parentKeyName,"modeSet", "backgroundImgSizeDiy", "heightUnit"])}
                                            </Item>
                                        </>
                                    } else {
                                        showItem = <>

                                        </>
                                    }
                                    return showItem
                                }}
                            </Item>
                            <Form.Item
                              name={[parentKeyName,"modeSet", "backgroundImgRepeat"]}
                              label={"缺省设置"}
                              rules={[{required: true}]}
                            >
                                <Radio.Group>
                                    <Radio value="repeat">自动填充缺省部分</Radio>
                                    <Radio value="no-repeat">不填充缺省</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                              label={"图片位置"}
                            >
                                <Item
                                  label={"距左"}
                                >
                                    {this.distanceSet([parentKeyName,"modeSet", "bgImgPositionSet", "left"], [parentKeyName,"modeSet", "bgImgPositionSet", "leftUnit"])}
                                </Item>
                                <Item
                                  label={"距顶"}
                                >
                                    {this.distanceSet([parentKeyName,"modeSet", "bgImgPositionSet", "top"], [parentKeyName,"modeSet", "bgImgPositionSet", "topUnit"])}
                                </Item>
                            </Form.Item>
                            <Form.Item
                              name={[parentKeyName,"modeSet", "upload_imageUrl"]}
                              label={"上传背景图"}
                              rules={[{required: true}]}
                              normalize={(value, prevValue, prevValues) => {
                                  value = value?.[0]?.url || null
                                  return value
                              }}
                            >
                                <UploadFile
                                  accept={"image/*"}
                                  size={1}
                                  bigLabel={true}
                                  data={{
                                      option: {
                                          size: 1,
                                          defaultValue: defaultImgUrl ? [{
                                              url: defaultImgUrl
                                          }] : null
                                      }
                                  }}
                                />
                            </Form.Item>
                        </>
                        break;
                    case "svgBorder": showItem = <>
                        <Item
                          label={"边框颜色"}
                        >
                            {this.getColorSetItemInner([parentKeyName,"modeSet", "color"])}
                        </Item>
                        <Form.Item
                          label={"边框类型"}
                          name={[parentKeyName,"modeSet", "borderSVGType"]}
                        >
                            <SVGBorderTypeSel
                              color={getFieldValue([parentKeyName,"modeSet", "color"])}
                            />
                        </Form.Item>
                    </>
                }
                return showItem
            }}
        </Item>
    </>
}

function getSimplePaddingSet(parentKeyName){
    return  <>
        <Item
          label={"上"}
        >
            {this.distanceSet([parentKeyName,"padding", "top"], [parentKeyName,"padding", "topUnit"], ["px", "rem"])}
        </Item>
        <Item
          label={"下"}
        >
            {this.distanceSet([parentKeyName,"padding", "bottom"], [parentKeyName,"padding", "bottomUnit"], ["px", "rem"])}
        </Item>
        <Item
          label={"左"}
        >
            {this.distanceSet([parentKeyName,"padding", "left"], [parentKeyName,"padding", "leftUnit"], ["px", "rem"])}
        </Item>
        <Item
          label={"右"}
        >
            {this.distanceSet([parentKeyName,"padding", "right"], [parentKeyName,"padding", "rightUnit"], ["px", "rem"])}
        </Item>
    </>
}

//准备键转换下拉内容
function getAutoCompleteOptions(keyStrList){
    if(!keyStrList?.length){
        return [];
    }

    return keyStrList.map((keyStr)=>{
        let lastKey = keyStr.split(".").pop();
        return {value: lastKey, label: keyStr, key: keyStr}
    })
}

//提取下拉key
function getOriginKeyListByData(data){
    if(!data || typeof data !== "object"){
        return [];
    }

    let keyMap = new Map();

    let dealAction = (data,parentKey)=>{
        let prototypeStr = Object.prototype.toString.call(data);
        if(prototypeStr === '[object Object]'){
            //对象，提取键
            Object.keys(data).forEach((eachKey)=>{
                let currKey = parentKey + (parentKey?".":"") + eachKey;
                if(!keyMap.has(currKey)){
                    keyMap.set(currKey,true)
                }
                dealAction(data[eachKey],currKey)
            })
        }else if(prototypeStr === '[object Array]'){
            //数组
            data.forEach((eachItem)=>{
                dealAction(eachItem,parentKey)
            })
        }else{
            //其它，不处理
        }
    }

    dealAction(data,"");
    // console.log(keyMap);
    return Array.from(keyMap.keys());
}

//上两步的结合
function getAutoCompleteOptionsByBindData(data){
    return getAutoCompleteOptions(getOriginKeyListByData(data))
}

export default {
    distanceSet,
    getSliderAndInputItemGroup,
    getFontSet,
    getIconSet,
    getFontFamilySelect,
    getShadowSet,
    getColorSetItemInner,
    getOriginKeyListByData,
    getAutoCompleteOptions,
    getAutoCompleteOptionsByBindData,
    getSimpleBgSet,
    getSimplePaddingSet
}