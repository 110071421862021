import AMapLoader from '@amap/amap-jsapi-loader';
import { CloseCircleFilled, FullscreenOutlined } from '@ant-design/icons';
import { Button, Modal, Tag } from 'antd';
import { Component } from 'react';
import allWorldJson from './allworld.json';
import { DefaultIcon, _renderClusterMarker } from './defuaultIcon';
import styles from './MapShowComponent.less';
import config from "../../../config/config";

class MapContainer extends Component {
  constructor() {
    super();
    this.state = {
      mapStyleLoaded: false,
      noLongLatDevices: [],
      deviceTotal: 0,
      error: null,
      points: [],
    };
    this.mapId = window.getUID();
    this.map = null;
    this.AMap = null;
    this.firstRenderData = null;
    this.cluster = null;
    this.myMap = null;
  }

  componentDidMount() {
    AMapLoader.load({
      key: config.ampKey, //需要设置您申请的key
      version: '2.0',
    })
      .then((AMap) => {
        console.log("加载完成了",AMap)


        let defaultLayer = new AMap.createDefaultLayer();
        let disCountry = new AMap.DistrictLayer.Country({
          zIndex: 10,
          SOC: 'CHN',
          depth: 1,
          styles: {
            'nation-stroke': '#cd9a5b',
            'coastline-stroke': 'rgba(0,0,0,0)',
            'province-stroke': 'rgba(0,0,0,0)',
            'city-stroke': 'rgba(0,0,0,0)',
            fill: 'rgba(0,0,0,0.2)',
            'stroke-width': '3',
          },
        });
        this.map = new AMap.Map(this.mapId, {
          viewMode: '2D',
          zoom: 2,
          zooms: [1, 20],
          // center: [105.602725, 37.076636],
          mapStyle: 'amap://styles/fresh',
          showLabel: false,
          // labelRejectMask: true,
          layers: [disCountry, defaultLayer],
        });
        const that = this;
        this.map.plugin(['AMap.MarkerCluster'], function () {
          that.cluster = new AMap.MarkerCluster(that.map, that.state.points, {
            gridSize: 80, // 聚合网格像素大小
            renderMarker: that.renderMarker,
            renderClusterMarker: _renderClusterMarker, // 自定义聚合点样式
            maxZoom: 7,
          });
        });
        // 绘制各个国name
        allWorldJson.forEach((item) => {
          const { properties } = item;
          if (properties.name && properties.level === 'country') {
            var text = new AMap.Text({
              position: new AMap.LngLat(
                properties.centroid[0],
                properties.centroid[1],
              ),
              text: properties.name,
              style: {
                background: 'none',
                color: '#009ad6',
                height: 20,
                fontSize: '10px',
                border: 'none',
                fontWeight: 'bold',
              },
              zooms: properties.zoom ?? [4, 7],
            });
            this.map.add(text);
          }
        });
        console.log("this.firstRenderData",this.firstRenderData)
        if (this.firstRenderData) {
          this.renderDataToMap(this.firstRenderData);
        }
        this.removeAmapLogo();
        this.setState({
          mapStyleLoaded: true,
        });
        // this.renderDataToMap([]);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  removeAmapLogo(){
    this.autTIme = setInterval(() => {
      let list = document.getElementsByClassName("amap-logo");
      if (list) {
        for (let d of list) {
          d.style.opacity = 0
        }
      }
      let list2 = document.getElementsByClassName("amap-copyright");
      if (list2) {
        for (let d of list2) {
          d.style.opacity = 0
        }
      }
    }, 0)
  }


  // 自定义点位渲染方法
  renderMarker(context) {
    const { marker, data } = context;
    const item = data[0];
    let iconUrl = null;
    if (!item.noDevice) {
      iconUrl = item._id && item.gender ? DefaultIcon(item.gender) : null;
    }
    if (item.icon) {
      iconUrl = item.icon;
    }
    marker.setContent(DefaultIcon('dom', item));
    marker.on('click', () => {
      if (item._id) {
        //后台不做点击事件处理
      }
    });
  }
  renderDataToMap(renderData) {
    console.log("开始渲染数据",renderData)
    console.log("this.map",this.map)

    if (!this.map) {
      this.firstRenderData = renderData;
      return;
    } else {
      this.firstRenderData = null;
      let { devices, zoom } = renderData;

      if (zoom) {
        // this.map.setZoom(zoom);
      }
      // mock 多个点位
      // let newArr = Array.from({ length: 1000 }, (item, index) => {
      //   return {
      //     lng: Math.random() * (122 - 90) + 100,
      //     lat: Math.random() * (30 - 14) + 11,
      //     _id: '66aaf32bdd354c529ac667e5',
      //     name: 'Dmitri Alexandrovich',
      //     status: 3,
      //     gender: 'Male',
      //   };
      // });
      devices = devices.map((item) => {
        return { ...item, weight: 1, lnglat: [item.lng, item.lat] };
      });
      if (devices && devices.length) {
        let noLongLatDevices = [];
        let upLng,
          upLat,
          count = 0;
        let arrList = [];
        devices.forEach((item, index) => {
          if (item.lng && item.lat) {
            if (item.lng === upLng && item.lat === upLat) {
              // 相邻重复点位
              //和上个点重复，
              count += 1;
              item.lng = (
                (parseFloat(item.lng) * 1000 + count) /
                1000
              ).toString();
            } else {
              //不重新，清空
              count = 0;
              upLng = item.lng;
              upLat = item.lat;
            }
            arrList.push(item);
          } else {
            noLongLatDevices.push(item);
          }
        });
        this.setState({
          deviceTotal: devices.length,
          noLongLatDevices: noLongLatDevices,
          error: null,
          points: arrList,
        });
        if (this.cluster) {
          this.cluster.setData(arrList);
        }
      } else {
        // 无设备情况下
        this.setState({
          deviceTotal: 0,
          noLongLatDevices: [],
          error: null,
        });
        this.map.setZoomAndCenter(1, [90.25, 39.28]);
      }
      this.setState({
        mapStyleLoaded: true,
      });
    }
  }
  //全屏
  fullScreenShow() {
    let view = document.getElementById(this.mapId);
    if (view) {
      //兼容不同的浏览器
      let requestMethod =
        view.requestFullScreen ||
        view.webkitRequestFullScreen ||
        view.mozRequestFullScreen ||
        view.msRequestFullScreen;

      if (requestMethod) {
        requestMethod.call(view);
      } else if (typeof window.ActiveXObject !== 'undefined') {
        //模拟F11 实现全屏
        let wscript = new ActiveXObject('WScript.Shell');

        if (wscript !== null) {
          wscript.SendKeys('{F11}');
        }
      }
    }
  }
  showNoLongLatDevices() {
    let { noLongLatDevices } = this.state;
    Modal.info({
      title: '坐标异常的数字人编号',
      width: '60%',
      content: (
        <div className={styles.tagContent}>
          {noLongLatDevices.map((item, index) => {
            return (
              <Tag
                key={index}
                onClick={() => {
                  window.copyText(item._id);
                }}
              >
                {item._id}
              </Tag>
            );
          })}
        </div>
      ),
    });
  }
  render() {
    // 1.创建地图容器
    let { noLongLatDevices, deviceTotal, mapStyleLoaded } = this.state;
    return (
      <div className={styles.container} style={this.props.style || {}}>
        {/* loading */}
        <div
          className={
            mapStyleLoaded ? styles.mayLoadLayerHide : styles.mayLoadLayer
          }
        >
          <div className={styles['loadingio-spinner-double-ring-v0383conw4g']}>
            <div className={styles['ldio-10d8267j8wu']}>
              <div></div>
              <div></div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.deviceContainer}>
          <div id={this.mapId} className={styles.mapShowBox}></div>
        </div>

        {this.state.error ? (
          <div className={styles.error}>{this.state.error}</div>
        ) : null}
        {!this.props.hideBottomMsg ? (
          <div className={styles.pagContainer}>
            <Button
              type="primary"
              onClick={() => {
                this.fullScreenShow();
              }}
            >
              <FullscreenOutlined />
              &nbsp;&nbsp; 全屏展示
            </Button>
            <div>
              {noLongLatDevices.length ? (
                <span style={{ paddingRight: '200px' }}>
                  {'提示：本页存在 ' +
                    noLongLatDevices.length +
                    ' 名无坐标信息的数字人'}
                  <Button
                    type={'link'}
                    onClick={() => {
                      this.showNoLongLatDevices();
                    }}
                  >
                    查看编号
                  </Button>
                </span>
              ) : null}
              <span>{`一共 ${deviceTotal} 名数字人`}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default MapContainer;
