import { useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./PositionLogCount.less";
import { Select } from 'antd';
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts'

type BarData = {
    name: string;
    type: string;
    sum: number;
}

const DefaultKeyConfigs = {
    distribute: '分发',
    noExec: '未执行',
    running: '执行中',
    success: '成功',
    timeout: '超时',
    unDistribute: '未分发'
}

const PositionLogCount: React.FC<any> = (props) => {
    const [errorMsg, setErrorMsg] = useState(null)
    const [dataSource, setDataSource] = useState<Array<BarData>>([])
    const [time, setTime] = useState<number>(1)
    const [type, setType] = useState<number>(1)

    // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}execUnit/getPositionLogCount`, {
    //     data: {
    //         execUnitId: [props.contentMsg?.proConfig?.usageParams?.businessId],
    //         time: time,
    //         type: type,
    //     }
    // }), {
    //     formatResult(res) {
    //         return res
    //     },
    //     refreshDeps: [props.dataUpdate, time, type],
    //     onSuccess: (res) => {
    //         if (res.code === 200) {
    //             //转化数据成需要的格式
    //             setDataSource(res?.data || [])
    //         } else {
    //             setErrorMsg(res.message)
    //         }
    //     },
    //     onError(err: any) {
    //         setErrorMsg(err.toString())
    //     }
    // })
    const getSeriesValuesData = (key: string) => {
        let key_datas: Array<number> = []
        Object.values(dataSource).forEach((value: any) => {
            key_datas.push(value[key])
        })
        return key_datas
    }
    const getSeries = () => {
        let seriesDatas: { name: any; type: string; stack: string; data: number[]; }[] = []
        Object.keys(DefaultKeyConfigs).forEach(key => {
            seriesDatas.push({
                name: DefaultKeyConfigs[key],
                type: 'line',
                stack: 'Total',
                data: getSeriesValuesData(key),
            })
        })
        console.error(seriesDatas)
        return seriesDatas
    }
    const getOption = (): echarts.EChartsOption => {
        return {
            tooltip: {
                trigger: 'axis',
            },
            legend: {
                textStyle: {
                    color: '#fff', // 图例文字颜色
                },
                data: Object.values(DefaultKeyConfigs),
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: Object.keys(dataSource),
                axisLine: {
                    lineStyle: {
                        color: '#fff', // X轴线颜色
                    },
                },
                axisLabel: {
                    color: '#fff', // X轴标签颜色
                },
            },
            yAxis: {
                type: 'value',
                axisLine: {
                    lineStyle: {
                        color: '#fff', // Y轴线颜色
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.2)', // 网格线颜色
                    },
                },
                axisLabel: {
                    color: '#fff', // Y轴标签颜色
                },
            },
            series: getSeries()
        };
    };

    return <div className={styles.outer + " swiper-no-swiping"}>
        <div className={styles.controlBar}>
            <Select
                style={{ width: "90px" }}
                value={time}
                onChange={(v) => {
                    setTime(v)
                }}
                options={[
                    { label: "30日", value: 1 },
                    { label: "15日", value: 2 },
                    { label: "7日", value: 3 },
                ]}
            />
            <Select
                style={{ width: "120px" }}
                value={type}
                onChange={(v) => {
                    setType(v)
                }}
                options={[
                    { label: "全部任务", value: 1 },
                    { label: "单次任务", value: 2 },
                    { label: "周期任务", value: 3 },
                ]}
            />
        </div>
        {
            errorMsg
                ? <div className={styles.errorMsg}>
                    {errorMsg}
                </div>
                : <EChartsReact style={{ width: '100%', height: '100%' }} option={getOption()} />
        }
    </div>
}

export default PositionLogCount