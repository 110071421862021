import React from "react";
import {
  Form,
  Divider,
  Radio,
  Switch,
  AutoComplete,
  InputNumber
} from "antd";
import CommonFormItems from "../../../CommonFormItems";
import ColorPicker from "../../../formDiyItem/ColorPicker";

const Item = Form.Item;

function getSetItems(extendData= {}){
  let opts = [];
  if(extendData?.bindData){
    opts = CommonFormItems.getAutoCompleteOptionsByBindData(extendData?.bindData)
  }
  return <>
    <Divider orientation="left">
      数据对接设置
    </Divider>
    <Item
      label={"图片字段"}
      name={"urlField"}
      rules={[{required: true}]}
    >
      <AutoComplete
        placeholder={"请选择或填写对接字段"}
        options={opts}
      />
    </Item>
    <Item
      label={"文本字段"}
      name={"nameField"}
      rules={[{required: false}]}
      extra={"无文案可不配置此项"}
    >
      <AutoComplete
        placeholder={"请选择或填写对接字段"}
        options={opts}
      />
    </Item>
    <Divider orientation="left">
      轮播设置
    </Divider>
    <Item
      name={["carouselSet","dotPosition"]}
      label={"轮播方向"}
      rules={[{required: true}]}
    >
      <Radio.Group>
        <Radio value="bottom">向左</Radio>
        <Radio value="left">向上</Radio>
      </Radio.Group>
    </Item>
    <Item
      name={["carouselSet","_effect"]}
      label={"切换效果"}
      rules={[{required: true}]}
    >
      <Radio.Group>
        <Radio value="slider">滑动</Radio>
        <Radio value="fade">渐变</Radio>
      </Radio.Group>
    </Item>
    <Item
      name={["carouselSet","autoplay"]}
      label={"自动切换"}
      rules={[{required: true}]}
      valuePropName={"checked"}
    >
      <Switch
        checkedChildren={"是"}
        unCheckedChildren={"否"}
      />
    </Item>
    <Item
      label={"自动切换间隔"}
      name={["carouselSet","_autoplaySpeed"]}
    >
      <InputNumber
        min={1}
        max={60}
        step={1}
        precision={0}
        addonAfter={"秒"}
      />
    </Item>
    <Item
      name={["carouselSet","infinite"]}
      label={"无限轮播"}
      rules={[{required: true}]}
      valuePropName={"checked"}
    >
      <Switch
        checkedChildren={"是"}
        unCheckedChildren={"否"}
      />
    </Item>
    <Item
      name={["carouselSet","arrows"]}
      label={"显示箭头"}
      rules={[{required: true}]}
      valuePropName={"checked"}
    >
      <Switch
        checkedChildren={"是"}
        unCheckedChildren={"否"}
      />
    </Item>
    <Divider orientation="left">
      图片样式设置
    </Divider>
    <Item
      name={["imgSet","objectFit"]}
      label={"填充方式"}
      rules={[{required: true}]}
    >
      <Radio.Group>
        <Radio value="cover">剪裁铺满</Radio>
        <Radio value="contain">包含</Radio>
        <Radio value="fill">拉伸填充</Radio>
      </Radio.Group>
    </Item>
    <Item
      label={"边框颜色"}
      name={["borderSet","color"]}
    >
      <ColorPicker/>
    </Item>
    <Item
      label={"边框宽度"}
    >
      {CommonFormItems.distanceSet(["borderSet", "width"], ["borderSet", "widthUnit"], ["px", "rem"])}
    </Item>
    <Item
      label={"圆角"}
    >
      {CommonFormItems.distanceSet(["borderSet", "radius"], ["borderSet", "radiusUnit"], ["px", "%"])}
    </Item>
    <Divider orientation="left">
      文本样式设置
    </Divider>
    <Item
      name={["nameSet","position"]}
      label={"名称位置"}
      rules={[{required: true}]}
    >
      <Radio.Group>
        <Radio value="top">顶部</Radio>
        <Radio value="bottom">底部</Radio>
        <Radio value="insideBottom">内嵌</Radio>
      </Radio.Group>
    </Item>
    <Item
      label={"与图片间距"}
      rules={[{required: true}]}
    >
      {CommonFormItems.distanceSet(["nameSet","gapWithImg"], ["nameSet","gapUnit"], ["px", "rem"])}
    </Item>
    {CommonFormItems.getFontSet("fontSet",true)}
  </>
}

function getDefaultValues(){
  return {
    urlField: null,
    nameField: null,
    carouselSet: {
      dotPosition: "bottom",
      _effect: "slider",
      autoplay: true,
      _autoplaySpeed: 3,
      infinite: true,
      arrows: false,
    },
    imgSet: {
      objectFit: "cover"
    },
    borderSet: {
      width: 0,
      widthUnit: "px",
      color: "#ffffffff",
      radius: 0,
      radiusUnit: "%"
    },
    nameSet: {
      position: "bottom",
      gapWithImg: 4,
      gapUnit: "px",
    },
    fontSet: {
      color: "#ffffff",
      fontFamily: "default",
      fontSize: 12,
      sizeUnit: "px", //px rem
      fontBold: false, //加粗
      fontItalic: false, //倾斜
    }
  }
}

function getDemoCode(){
  return `
    以下示例中 url、text 均可在 数据对接设置 中修改替换成其它单词

    1.可返回对象数组，指定每个图片的名称
    [
        {
            url: "http://example.a.jpg",
            text: "狸花猫",
        },
        {
            url: "http://example.b.jpg",
            text: "石斑鱼",
        },
        ...
    ]
    `
}

export default {
  getSetItems,
  getDefaultValues,
  getDemoCode
}