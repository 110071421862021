import React, { memo, useEffect, useState } from 'react';
import { request } from '../../../../../../tool/http'
import styles from "./taskList.less";
import { Flex, Select, Table, Tabs } from "antd";
// import ProgressBarForPlanTask from '@/pages/containers/trigger/components/progressBarForPlanTask';

//封装进度条样式展示
const Progress = (props: any) => {
  const { done, total, fail } = props?.record?.taskDis || {}
  const [progress, setProgress] = useState(0)
  useEffect(() => {
    //取整
    let progress = Math.floor((done + fail) / total * 100) || 0
    setProgress(progress)
  }, [done, total])
  return (
    <Flex align='center' gap={8}>
      <Flex className={styles.progressBox} >
        {progress !== 0 && <Flex align='center' justify='center' className={styles.progress} style={{ width: `${progress}%` }} >
          <div>已完成:{done + fail}</div>
        </Flex>}
        {/* 未完成 */}
        {progress !== 100 && <Flex align='center' justify='center' style={{ width: `${100 - progress}%` }} >
          <div className={styles.hangInTheAir}>未开始</div>
        </Flex>}
      </Flex>
      <div className={styles.progressText}>{progress}%</div>
    </Flex>

  )
}

function TaskList(props: any) {
  //表格列配置类型
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  const [errorMsg, setErrorMsg] = useState(null)
  const [order, setOrder] = useState("sum")
  const [tabType, setTabType] = useState(showType[0] || 0)
  const [statusType, setStatusType] = useState(0)
  const [data, setData] = useState(null)
  const [size, setSize] = useState(10)

  //
  // console.log(showType, 'showType')
  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    let data = {
      businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
      businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
      type: tabType,
      status: statusType ? [statusType] : 0,
      size
    }
    if (!data.type) delete data.type
    if (!data.status) delete data.status
    console.log('请求参数---', data)
    request(`${props.contentMsg?.proConfig?.apiPrefix}taskStatistics/getBatchList`, {
      data
    }).then((res) => {
      if (res.code === 200) {
        console.log('任务列表', res.data)
        setData(res.data.map((item: any) => {
          return {
            ...item,
            key: item._id
          }
        }))
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }


  useEffect(() => {
    getBusinessData()
  }, [tabType, props.dataUpdate, statusType, size])

  useEffect(() => {
    //判断tab类型是否在配置showType中且选中的值不相等，则默认选中第一个
    if (showType[0] !== tabType && !showType.includes(tabType)) {
      setTabType(showType[0])
    }
  }, [showType])


  //表格列配置
  const columns = [
    {
      title: '任务名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '任务类型',
      dataIndex: 'type',
      key: 'type',
      render: (text: number) => {
        return {
          2: "单次任务",
          3: "周期任务",
        }[text]
      }
    },
    {
      title: '任务状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: number) => {
        return {
          1: "进行中",
          2: "成功",
          3: "未开始",
          4: "已终止",
        }[text]
      }
    },
  ]
  const singTaskColumns = [
    {
      title: '触发任务名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: number) => {
        return {
          1: "进行中",
          2: "成功",
          3: "未开始",
          4: "已终止",
        }[text]
      }
    },
    {
      title: '进度',
      dataIndex: 'speedOfProgress',
      key: 'speedOfProgress',
      width: 150,
      render: (text: number, record: any) => {
        console.log(record, 'singTaskColumns')
        //任务进度  
        let taskTotal = record?.taskDis?.total;
        if (record.type === 1) {
          //普通型
        } else if (record.type === 2) {
          //数量型
          if (taskTotal < record?.targetDis?.total) {
            taskTotal = record.targetDis.total;
          }
        } else if (record.type === 3) {
          // 进度型
          if (taskTotal <= record.total) {
            taskTotal = record.total;
          } else {
            //把进度条拉长，保持不满
            taskTotal = Math.ceil(taskTotal * 1.2);
          }
        }
        return <Flex vertical>
          <Flex flex={1} >
            {/* <ProgressBarForPlanTask
              total={taskTotal}
              distrib={record?.taskDis?.distrib}
              done={record?.taskDis?.done}
              fail={record?.taskDis?.fail}
              success={record?.taskDis?.success}
            /> */}
          </Flex>
          <div>已完成:{record?.taskDis?.done + record?.taskDis?.fail}</div>
          <div>未开始:{taskTotal - record?.taskDis?.distrib}</div>
        </Flex>
      }
    },
    {
      title: '数字人',
      dataIndex: 'nums',
      key: 'nums',
    },
  ]


  const cycleTaskColumns = [
    {
      title: '周期任务名称',
      dataIndex: 'name',
      key: 'name',

    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (text: number) => {
        return {
          1: "进行中",
          2: "成功",
          3: "未开始",
          4: "已终止",
        }[text]
      }
    },
    {
      title: '最近期数',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
    },
    {
      title: '进度',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: (text: number, record: any) => {
        // console.log(record, 'cycleTaskColumns')
        return <Flex vertical>
          <Flex flex={1}>
            {/* <ProgressBarForPlanTask
              total={record?.taskDis?.total}
              distrib={record?.taskDis?.distrib}
              done={record?.taskDis?.done}
              fail={record?.taskDis?.fail}
              success={record?.taskDis?.success}
            /> */}
          </Flex>
          <div>已完成:{record?.taskDis?.done + record?.taskDis?.fail}</div>
          <div>未开始:{record?.taskDis?.total - record?.taskDis?.distrib}</div>
        </Flex>

      }
    },
    {
      title: '数字人',
      dataIndex: 'nums',
      key: 'nums',
    },
  ]




  let items = [];
  if (showType.includes(0)) {
    items.push({
      key: 0,
      label: '全部',
      children: (
        <div className={styles.tabPaneBox}>
          {
            errorMsg
              ? <div className={styles.errorMsg}>
                {errorMsg}
              </div>
              : <Table
                size={"small"}
                bordered={true}
                columns={columns}
                dataSource={data}
                pagination={false}
              />
          }
          {/* <div>全部</div> */}
        </div>
      ),
    })
  }

  if (showType.includes(2)) {
    items.push({
      key: 2,
      label: '单次任务',
      children: (
        <div className={styles.tabPaneBox}>
          {
            errorMsg
              ? <div className={styles.errorMsg}>
                {errorMsg}
              </div>
              : <Table
                size={"small"}
                bordered={true}
                tableLayout={"auto"}
                columns={singTaskColumns}
                dataSource={data}
                pagination={false}
              />
          }
        </div>
      ),
    })
  }

  if (showType.includes(3)) {
    items.push({
      key: 3,
      label: '周期任务',
      children: (
        <div className={styles.tabPaneBox}>
          {
            errorMsg
              ? <div className={styles.errorMsg}>
                {errorMsg}
              </div>
              : <Table
                size={"small"}
                bordered={true}
                tableLayout={"auto"}
                columns={cycleTaskColumns}
                dataSource={data}
                pagination={false}
              />
          }
        </div>
      ),
    })
  }

  return <div
    className={styles.outer + " swiper-no-swiping"}
  >
    <Tabs
      className={styles.tabsBox}
      destroyInactiveTabPane={true}
      activeKey={tabType}
      onChange={(key) => {
        setTabType(key)
      }}
      tabBarExtraContent={
        <Flex>
          <Select
            style={{ width: "120px" }}
            value={statusType}
            onChange={(v) => {
              setStatusType(v)
            }}
            options={[
              { label: "全部状态", value: 0 },
              { label: "进行中", value: 1 },
              { label: "成功", value: 2 },
              { label: "未开始", value: 3 },
              { label: "已终止", value: 4 },
            ]}
          />
          <Select
            style={{ width: "120px" }}
            value={size}
            onChange={(v) => {
              setSize(v)
            }}
            options={[
              { label: "最近10条", value: 10 },
              { label: "最近5条", value: 5 },
              { label: "最近20条", value: 20 },
            ]}
          />
        </Flex>
      }
      items={items}
    />
  </div>
}
export default memo(TaskList)