import React from "react";
import styles from "./DataDisposition.less";
import HttpTool from "../../../../tool/HttpTool";
import {Spin, message, Empty, Divider, Space,Button,Modal,Input,Form,Descriptions,Tooltip,InputNumber,Select,Switch} from "antd";
import {
    BgColorsOutlined,
    FormatPainterOutlined,
    QuestionCircleFilled,
} from "@ant-design/icons";
import ModalConfig from "../../../../components/modalConfig/index";
import ShowCode from "../../../edit/ShowCode";
import TimeHelp from '../../../../tool/TimeHelp';
import LocalStorage from '../../../../tool/LocalStorage';
import DataGetHelp from "../../dataSet/components/dataSet/DataGetHelp";
import DataDefinitionFile from "./DataDefinitionFile";

// import TeamIdSel from "../../teamCenter/dataSet/bindType/specialParams/TeamIdSel";
// import PositionIdSel from "../../teamCenter/dataSet/bindType/specialParams/PositionIdSel";
// import TriggerBatchIdSel from "../../teamCenter/dataSet/bindType/specialParams/TriggerBatchIdSel";
// import ModuleIdSel from "../../teamCenter/dataSet/bindType/specialParams/ModuleIdSel";
// import ModuleVersionIdSel from "../../teamCenter/dataSet/bindType/specialParams/ModuleVersionIdSel";

const ContentParamsSetSaveKey = "ContentParamsSetSaveKey";
const Item = Form.Item;

const DefaultFormatOptionTxt = `
                /**
                 * formatOptionFunc
                 * @param option  当前Echarts的配置参数
                 * @param resultData  数据集返回的数据
                 */
                function formatOptionFunc(option,resultData){
                    
                    return option
                }
            `;
const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

export default class DataDisposition extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            contentMsg: _.cloneDeep(this.props.contentMsg),
            dataBindDetail: null,
            bindData: null, //绑定的数据，用于数据对接键的下拉
            formUpdate: 0,
        }

        this.DataGetHelp = new DataGetHelp();
    }

    componentDidMount() {
        if (this.state.contentMsg?.dataConfig?.dataSetMdId) {
            this.loadDataSetDetail(this.state.contentMsg.dataConfig.dataSetMdId)
        }
        //自定义组件的请求体(且 dataType === 1 的时候，可以与数据集类似处理)
        if (this.state.contentMsg?.dataConfig?.dataRequestConfig && this.state.contentMsg?.dataConfig?.dataRequestConfig.dataType === 1) {
            this.setState({
                dataBindDetail: {
                    _id: this.props.uid, //没有数据集id，采用内容id代替
                    title: "自定义组件请求参数",
                    type: 1, //伪装成数据集的独立数据类型
                    ...this.state.contentMsg?.dataConfig?.dataRequestConfig
                },
            })
        }
        //系统组件的参数 按照数据集的结构去模拟
        if (this.state.contentMsg?.contentMode === "systemComponent") {
            this.setState({
                dataBindDetail: {
                    _id: this.props.uid, //没有数据集id，采用内容id代替
                    title: "系统组件请求参数",
                    type: 1, //伪装成数据集的独立数据类型
                    dataType: 1,    //数据集的元数据查询类型
                    configs: this.state.contentMsg?.dataConfig.dataParamsConfig
                },
            })
        }
    }

    componentWillUnmount(){
        if(this.delayMark){
            clearTimeout(this.delayMark);
            this.delayMark = null;
        }
    }

    /**
     * 统一的请求展示交互，目前是loading
     * @param path          请求地址
     * @param param         请求参数
     * @param successCB     成功的回调
     * @param failureCB     失败的回调
     * @param showAction    是否展示交互，默认打开
     */
    commonRequest(path, param, successCB, failureCB, showAction = true) {
        if (!showAction) {
            HttpTool.post(path, successCB, failureCB,param);
        } else {
            let newSuccessCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    successCB && successCB(code, msg, obj, option);
                })
            };
            let newFailureCB = (code, msg, obj, option) => {
                this.setState({
                    loading: false,
                }, () => {
                    failureCB && failureCB(code, msg, obj, option);
                })
            };

            this.setState({
                loading: true
            }, () => {
                HttpTool.post(path, newSuccessCB, newFailureCB,param);
            })
        }
    }

    //延迟触发，避免太频繁
    delayChangeAction(action){
        if(this.delayMark){
            clearTimeout(this.delayMark);
        }
        this.delayMark = setTimeout(()=>{
            action?.();
        },300)
    }

    saveAll(onlyStyleUpdate=false){
        this.props.onSave?.(this.state.contentMsg.dataConfig,onlyStyleUpdate);
    }

    //请求数据集数据
    loadDataSetDetail(dataSetMdId) {
        this.setState({
            loading: true
        }, async () => {
            let err;
            let resultData = await this.DataGetHelp.getDataByDataSetMdId({
                mdId: dataSetMdId,
                spaceId: undefined,
                execUnitId: undefined
            }).catch((e) => {
                err = e;
            })

            if (err) {
                Modal.error("数据集请求失败：" + err)
                this.setState({
                    loading: false,
                    bindData: null,
                    dataBindDetail: null,
                })
            } else {
                this.setState({
                    bindData: resultData.tranData,
                    dataBindDetail: resultData.dataSetDetail,
                    loading: false
                })
            }
        })
    }

    render() {
        let {loading, contentMsg} = this.state;
        if (!contentMsg) {
            return <Empty description={"错误：未传入容器信息"} style={{marginTop: "150px"}}/>
        }
        return <Spin
            size={"large"}
            spinning={loading}
            wrapperClassName={styles.spinBoxStyle}
        >
            <div className={styles.outerBox}>
                {/*<Button*/}
                {/*    type={"primary"}*/}
                {/*    size={"small"}*/}
                {/*    shape={"round"}*/}
                {/*    style={{color: "#fff",backgroundColor: "limegreen",borderColor: "limegreen"}}*/}
                {/*    onClick={()=>{*/}
                {/*        this.saveAll();*/}
                {/*    }}*/}
                {/*>更新容器</Button>*/}
                <Descriptions
                    bordered
                    column={1}
                    size={"small"}
                    labelStyle={{width: "80px"}}
                >
                    <Descriptions.Item label="容器标题">
                        <Input
                            value={contentMsg.dataConfig.contentTitle}
                            maxLength={200}
                            onChange={(e)=>{
                                contentMsg.dataConfig.contentTitle = e.target.value;
                                this.setState({})
                            }}
                            onBlur={()=>{
                                this.saveAll(true)
                            }}
                        />
                    </Descriptions.Item>
                </Descriptions>
                {this.getConfigViewRender()}
            </div>
        </Spin>
    }

    //根据不同的容器类型渲染不同的视图
    getConfigViewRender() {
        let {contentMsg,dataBindDetail} = this.state;
        let result;
        switch (contentMsg.contentMode) {
            //视图
            case "view":
                result = <div>
                    <Descriptions
                        bordered
                        column={1}
                        size={"small"}
                        labelStyle={{width: "80px"}}
                    >
                        <Descriptions.Item label="数据集">
                            <Space>
                                <div>
                                    {
                                        contentMsg.dataConfig.dataSetId
                                            ?<span>
                                    {contentMsg.dataConfig.dataSetTitle}
                                </span>
                                            :<span>未设置</span>
                                    }
                                </div>
                                <Space
                                    direction={"vertical"}
                                    align={"center"}
                                    size={4}
                                >
                                    <Button
                                        className={styles.desBtns}
                                        size={"small"}
                                        onClick={()=>{
                                            this.selectDataSet(contentMsg.dataConfig)
                                        }}
                                    >设置数据集</Button>
                                    {
                                        dataBindDetail
                                            ? <Button
                                                className={styles.desBtns}
                                                size={"small"}
                                                onClick={()=>{
                                                    this.openDataSetSearchParams()
                                                }}
                                            >修改查询参数</Button>
                                            :null
                                    }
                                    {
                                        contentMsg.dataConfig.dataSetId
                                            ?<Button
                                                className={styles.desBtns}
                                                size={"small"}
                                                onClick={()=>{
                                                    Modal.confirm({
                                                        title: "提示",
                                                        content: "是否解绑数据集？",
                                                        onOk: ()=>{
                                                            this.unbindDataSet()
                                                        }
                                                    })
                                                }}
                                            ><span style={{color:"darkorange"}}>解绑数据集</span></Button>
                                            :null
                                    }
                                </Space>
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="视图类型"
                            style={{
                                position: "relative"
                            }}
                        >
                            <Space>
                                {DataDefinitionFile.getChartTypeIcon(contentMsg.dataConfig.contentType)}
                                <Space
                                    direction={"vertical"}
                                    align={"center"}
                                    size={4}
                                >
                                    {
                                        contentMsg.dataConfig.contentType?.indexOf("echart_") === 0 || ["wordCloud2d","liquidFill"].includes(contentMsg.dataConfig.contentType)
                                            ?<Button
                                                className={styles.desBtns}
                                                size={"small"}
                                                onClick={()=>{
                                                    this.setFormatOptionFunc(contentMsg.dataConfig)
                                                }}
                                            >配置转换代码</Button>
                                            :null
                                    }
                                </Space>
                            </Space>
                            {
                                contentMsg.dataConfig.contentType
                                    ?<Tooltip
                                        title={"查看所需数据结构"}
                                    >
                                        <QuestionCircleFilled
                                            className={styles.askIcon}
                                            onClick={()=>{
                                                this.showDemoData(contentMsg.dataConfig.contentType)
                                            }}
                                        />
                                    </Tooltip>
                                    :null
                            }
                        </Descriptions.Item>
                    </Descriptions>
                    {this.getChartOptionSetView(contentMsg.dataConfig)}
                </div>
                break;
            //部件
            case "widget":
                result = <div>
                    <Descriptions
                        bordered
                        column={1}
                        size={"small"}
                        labelStyle={{width: "80px"}}
                    >
                        <Descriptions.Item label="部件类型">
                            {DataDefinitionFile.getChartTypeIcon(contentMsg.dataConfig.contentType)}
                        </Descriptions.Item>
                    </Descriptions>

                    {this.getWidgetParamsOptionSetView(contentMsg.dataConfig)}
                </div>
                break;
            //参数
            case "parameter":
                result = <div>
                    <Descriptions
                        bordered
                        column={1}
                        size={"small"}
                        labelStyle={{width: "80px"}}
                    >
                        <Descriptions.Item label="参数类型">
                            {DataDefinitionFile.getChartTypeIcon(contentMsg.dataConfig.contentType)}
                        </Descriptions.Item>
                    </Descriptions>

                    {this.getParameterOptionSetView(contentMsg.dataConfig)}
                </div>
                break;
            //系统组件
            case "systemComponent":
                result = <div>
                    <Descriptions
                      bordered
                      column={1}
                      size={"small"}
                      labelStyle={{width: "80px"}}
                    >
                        <Descriptions.Item label="查询参数">
                            <Space
                              direction={"vertical"}
                              align={"center"}
                              size={4}
                            >
                                {
                                    dataBindDetail
                                      ? <Button
                                        className={styles.desBtns}
                                        size={"small"}
                                        onClick={()=>{
                                            this.openDataSetSearchParams()
                                        }}
                                      >设置系统组件查询参数</Button>
                                      :null
                                }
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="系统组件类型">
                            {DataDefinitionFile.getChartTypeIcon(contentMsg.dataConfig.contentType)}
                        </Descriptions.Item>
                    </Descriptions>

                    {this.getSysComponentParamsOptionSetView(contentMsg.dataConfig)}
                </div>
                break;
          //自定义组件
            case "customComponent":
                result = <div>
                    <Descriptions
                      bordered
                      column={1}
                      size={"small"}
                      labelStyle={{width: "80px"}}
                    >
                        <Descriptions.Item label="查询参数">
                            <Space
                              direction={"vertical"}
                              align={"center"}
                              size={4}
                            >
                                {
                                    dataBindDetail
                                      ? <Button
                                        className={styles.desBtns}
                                        size={"small"}
                                        onClick={()=>{
                                            this.openDataSetSearchParams()
                                        }}
                                      >设置自定义组件查询参数</Button>
                                      :null
                                }
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="视图类型"
                                           style={{
                                               position: "relative"
                                           }}
                        >
                            <Space>
                                {DataDefinitionFile.getChartTypeIcon(contentMsg.dataConfig.contentType)}
                                <Space
                                  direction={"vertical"}
                                  align={"center"}
                                  size={4}
                                >
                                    {
                                        contentMsg.dataConfig.contentType?.indexOf("echart_") === 0 || ["wordCloud2d","liquidFill"].includes(contentMsg.dataConfig.contentType)
                                          ?<Button
                                            className={styles.desBtns}
                                            size={"small"}
                                            onClick={()=>{
                                                this.setFormatOptionFunc(contentMsg.dataConfig)
                                            }}
                                          >配置转换代码</Button>
                                          :null
                                    }
                                </Space>
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>
                    {this.getChartOptionSetView(contentMsg.dataConfig)}
                </div>
                break;
        }

        return result;
    }


    //不同类型的视图，有不同的配置项
    getChartOptionSetView(dataConfig){
        if(!dataConfig?.contentType){
            return null
        }

        let itemArr = DataDefinitionFile.getViewSetItems(dataConfig.contentType,{
            bindData: this.state.bindData,
            currentSceneMsg: this.props.sceneItemMsg
        });
        let initialValues = dataConfig.contentParams;

        let onlyStyleUpdate = true;

        return <Form
            key={"form_" + dataConfig.contentType + this.state.formUpdate}
            ref={ref=>this.formForViewChartOption = ref}
            initialValues={initialValues}
            requiredMark={false}
            size={"small"}
            {...formItemLayout}
            onFieldsChange={(changedFields, allFields)=>{
                // console.log("changedFields",changedFields)
                this.delayChangeAction(()=>{
                    dataConfig.contentParams = this.formForViewChartOption.getFieldsValue();
                    this.setState({},()=>{
                        if(changedFields[0]?.name?.indexOf("clickActionSet") !== -1){
                            this.saveAll(false)
                        }else{
                            this.saveAll(onlyStyleUpdate)
                        }
                    })
                })
            }}
        >
            {
                itemArr
                    ?this.getStylesCopyBtns(dataConfig.contentType,()=>{
                        return this.formForViewChartOption.getFieldsValue();
                    },(newViewParams)=>{
                        dataConfig.contentParams = newViewParams;
                        this.setState({
                            formUpdate: this.state.formUpdate + 1
                        },()=>{
                            //整个参数替换了，直接强刷
                            this.saveAll(false)
                        })
                    })
                    :null
            }
            {itemArr}
        </Form>
    }

    //不同类型的部件，有不同的配置项
    getWidgetParamsOptionSetView(dataConfig){
        if(!dataConfig?.contentType){
            return null
        }

        let itemArr = DataDefinitionFile.getViewSetItems(dataConfig.contentType);
        let initialValues = dataConfig.contentParams;

        return <Form
            key={"form_com_" + dataConfig.contentType + this.state.formUpdate}
            ref={ref=>this.formForComponentOption = ref}
            initialValues={initialValues}
            requiredMark={false}
            {...formItemLayout}
            onFieldsChange={(changedFields, allFields)=>{
                this.delayChangeAction(()=>{
                    dataConfig.contentParams = this.formForComponentOption.getFieldsValue();
                    this.setState({},()=>{
                        this.saveAll(true)
                    })
                })
            }}
        >
            {
                itemArr
                    ?this.getStylesCopyBtns(dataConfig.contentType,()=>{
                        return this.formForComponentOption.getFieldsValue();
                    },(newViewParams)=>{
                        dataConfig.contentParams = newViewParams;
                        this.setState({
                            formUpdate: this.state.formUpdate + 1
                        },()=>{
                            this.saveAll(true)
                        })
                    })
                    :null
            }
            {itemArr}
        </Form>
    }

    //不同类型的系统组件，有不同的配置项
    getSysComponentParamsOptionSetView(dataConfig){
        if(!dataConfig?.contentType){
            return null
        }

        let itemArr = DataDefinitionFile.getViewSetItems(dataConfig.contentType);
        let initialValues = dataConfig.contentParams;

        return <Form
            key={"form_com_" + dataConfig.contentType + this.state.formUpdate}
            ref={ref=>this.formForComponentOption = ref}
            initialValues={initialValues}
            requiredMark={false}
            {...formItemLayout}
            onFieldsChange={(changedFields, allFields)=>{
                this.delayChangeAction(()=>{
                    dataConfig.contentParams = this.formForComponentOption.getFieldsValue();
                    this.setState({},()=>{
                        this.saveAll(true)
                    })
                })
            }}
        >
            {
                itemArr
                    ?this.getStylesCopyBtns(dataConfig.contentType,()=>{
                        return this.formForComponentOption.getFieldsValue();
                    },(newViewParams)=>{
                        dataConfig.contentParams = newViewParams;
                        this.setState({
                            formUpdate: this.state.formUpdate + 1
                        },()=>{
                            this.saveAll(true)
                        })
                    })
                    :null
            }
            {itemArr}
        </Form>
    }


    //不同类型的参数，有不同的配置项
    getParameterOptionSetView(dataConfig){
        if(!dataConfig?.contentType){
            return null
        }

        let itemArr = DataDefinitionFile.getViewSetItems(dataConfig.contentType);
        let initialValues = dataConfig.contentParams;

        return <Form
            key={"form_com_" + dataConfig.contentType + this.state.formUpdate}
            ref={ref=>this.formForParameterTypeOption = ref}
            initialValues={initialValues}
            requiredMark={false}
            {...formItemLayout}
            onFieldsChange={(changedFields, allFields)=>{
                this.delayChangeAction(()=>{
                    dataConfig.contentParams = this.formForParameterTypeOption.getFieldsValue();
                    this.setState({},()=>{
                        this.saveAll(true)
                    })
                })
            }}
        >
            {
                itemArr
                    ?this.getStylesCopyBtns(dataConfig.contentType,()=>{
                        return this.formForParameterTypeOption.getFieldsValue();
                    },(newViewParams)=>{
                        dataConfig.contentParams = newViewParams;
                        this.setState({
                            formUpdate: this.state.formUpdate + 1
                        },()=>{
                            this.saveAll(true)
                        })
                    })
                    :null
            }
            {itemArr}
        </Form>
    }


    //设置数据集
    selectDataSet(dataConfig){
        console.log("dataConfig",dataConfig)

        Modal.confirm({
            title: "设定图表数据来源",
            content: <div className={styles.selDataOuter}>
                后台项目无法选择数据集
            </div>,
            maskClosable: true,
            width: "500px",
            centered: true,
            okText: "确定",
            onOk: ()=>{
                let selNode = this.tsim.getSelNode();
                if(!selNode){
                    message.warning("还没有选择好数据集");
                    return ;
                }
                if(selNode.type === 3){
                    message.warning("不能选择分组作为视图的数据来源");
                    return ;
                }
                console.log(selNode)
                let {contentMsg} = this.state;
                contentMsg.dataConfig.dataSetId = selNode._id;
                contentMsg.dataConfig.dataSetMdId = selNode.mdId;
                contentMsg.dataConfig.fromOtherEU = selNode.fromOtherEU;
                contentMsg.dataConfig.unitId = selNode.unitId;
                contentMsg.dataConfig.dataSetTitle = selNode.title;
                contentMsg.dataConfig.contentTitle = selNode.title;
                this.setState({},()=>{
                    this.loadDataSetDetail(selNode.mdId)
                    this.saveAll()
                })
            }
        })
    }

    //解绑数据集
    unbindDataSet(){
        let {contentMsg} = this.state;
        contentMsg.dataConfig.dataSetId = null;
        contentMsg.dataConfig.dataSetMdId = null;
        contentMsg.dataConfig.dataSetTitle = null;
        contentMsg.dataConfig.contentTitle = null;

        this.setState({
            dataBindDetail: null
        },()=>{
            this.saveAll()
        })
    }

    //修改数据集查询参数
    openDataSetSearchParams(){
        let {contentMsg,dataBindDetail} = this.state;

        Modal.info({
            destroyOnClose:true,
            // icon: null,
            maskClosable: false,
            title: null,
            closable: true,
            width: "60%",
            centered: true,
            okButtonProps: {
                style: {display: "none"}
            },
            content: <div
                style={{
                    height: "70vh",
                    paddingTop: "20px",
                    position: "relative",
                    // marginBottom: "40px"
                }}
            >
                <div className={styles.pramsSetBox}>
                    {this.getPramsSetViewForSelectNode(dataBindDetail)}
                </div>
            </div>
        })
    }

    //查看图表数据结构参考
    showDemoData(type){
        let demoCode = DataDefinitionFile.getViewDemoCode(type);

        if(!demoCode){
            demoCode = "无数据结构可参考"
        }

        ModalConfig.show({
            title: "查看图表所需数据结构",
            maskClosable: false,
            width: "60%",
            footer: <Space size={20}>
                <Button
                    type={"primary"}
                    onClick={()=>{
                        ModalConfig.close();
                    }}
                >确定</Button>
            </Space>,
        }, <div className={styles.showCodeBox}>
            <ShowCode
                ref={ref => this.showDemo = ref}
                hiddenHelp={true}
                readOnly={true}
                wait={500}
                defaultValue={demoCode}
            />
        </div>)
    }


    //设置转换option的函数
    setFormatOptionFunc(dataConfig){

        let defaultTxt = dataConfig.formatOptionCode;
        if(!defaultTxt){
            defaultTxt = DefaultFormatOptionTxt
        }

        ModalConfig.show({
            title: "编写转换Echarts配置项的函数",
            maskClosable: false,
            width: "60%",
            footer: <Space size={20}>
                <Button
                    onClick={()=>{
                        this.formatOptionCodeBox && this.formatOptionCodeBox.showValue(DefaultFormatOptionTxt)
                    }}
                >重置</Button>
                <Button
                    type={"primary"}
                    onClick={()=>{
                        let t = this.formatOptionCodeBox && this.formatOptionCodeBox.getValue()
                        dataConfig.formatOptionCode = t;
                        this.setState({},()=>{
                            this.saveAll()
                        })
                        ModalConfig.close();
                    }}
                >确定</Button>
            </Space>,
        }, <div className={styles.showCodeBox}>
            <ShowCode
                ref={ref => this.formatOptionCodeBox = ref}
                hiddenHelp={true}
                readOnly={false}
                wait={500}
                defaultValue={defaultTxt}
            />
        </div>)
    }

    //数据集参数配置
    getPramsSetViewForSelectNode(selectNodeDetail){
        if(!selectNodeDetail){
            return null;
        }

        let childInputArgParamsMap = {};

        let dealAction = (data)=>{
            if(data.type === 1){
                //独立数据
                if(data.dataType ===1 && data.configs?.inputArg && Object.keys(data.configs?.inputArg).length){
                    //是元数据查询类型，且需要配置输入参数
                    let dataSetId = data.mdId || data._id;
                    childInputArgParamsMap[dataSetId] = {
                        dataType: 1,
                        title: data.title,
                        inputArg: _.cloneDeep(data.configs.inputArg)
                    };
                }else if(data.dataType ===2){
                    //是绑定通用数据类型，且需要配置输入参数
                    childInputArgParamsMap[dataSetId] = {
                        dataType: 2,
                        title: data.title,
                        inputArg: _.cloneDeep(data.configs.configs)
                    };
                }
            }else if(data.type === 2){
                //联合数据
                data.children?.forEach((eachChild)=>{
                    dealAction(eachChild);
                })
            }
        }

        dealAction(selectNodeDetail);

        let keys = Object.keys(childInputArgParamsMap);

        if(!keys.length){
            return <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={"没有参数需要配置"}
            />
        }

        this.layoutMap = {};

        return <div>
            <div className={styles.paramsContentHead}>
                <Space>
                    <Button
                        size={"small"}
                        shape="round"
                        type={"danger"}
                        onClick={() => {
                            Modal.destroyAll();
                        }}
                    >取消</Button>
                    <Button
                        size={"small"}
                        shape="round"
                        type={"primary"}
                        onClick={() => {
                            let err;
                            keys.forEach((keyStr)=>{
                                let error = this.layoutMap[keyStr]?.getFieldsError();
                                let formValues = this.layoutMap[keyStr]?.getFieldsValue();
                                // console.log(error)
                                // console.log(formValues)

                                error.forEach((errorItem)=>{
                                    if(errorItem.errors?.length){
                                        err = true;
                                        message.warning(`请完善【${childInputArgParamsMap[keyStr].title}】的参数填写`);
                                    }
                                })

                                if(!err){
                                    childInputArgParamsMap[keyStr].inputArg = this.getChangedValueByLayout(childInputArgParamsMap[keyStr].inputArg,formValues);
                                }
                            })

                            if(!err){
                                this.state.contentMsg.dataConfig.dataSetInputMap = childInputArgParamsMap;
                                this.setState({},()=>{
                                    Modal.destroyAll();
                                    this.saveAll();
                                })
                            }
                        }}
                    >确认修改</Button>
                </Space>
            </div>
            <div className={styles.paramsContentBody}>
                {
                    keys.map((keyStr)=>{
                        console.log(this.state.contentMsg?.dataConfig)

                        return <div
                            key={"lay_" + keyStr}
                        >
                            <Divider orientation={"left"}>{childInputArgParamsMap[keyStr].title}</Divider>
                            {/*<Layout*/}
                            {/*    key={'inputArg_' + keyStr + selectNodeDetail.updatetime}*/}
                            {/*    ref={(ref) => {*/}
                            {/*        this.layoutMap[keyStr] = ref;*/}
                            {/*    }}*/}
                            {/*    {*/}
                            {/*        ...this.getInputArgConfig(childInputArgParamsMap[keyStr].inputArg,this.state.contentMsg?.dataConfig?.dataSetInputMap?.[keyStr]?.inputArg || childInputArgParamsMap[keyStr].inputArg)*/}
                            {/*    }*/}
                            {/*/>*/}
                            <Form
                                key={'inputArg_' + keyStr + selectNodeDetail.updatetime}
                                labelCol={{ span: 5 }}
                                wrapperCol={{ span: 14 }}
                                ref={(ref) => {
                                    this.layoutMap[keyStr] = ref;
                                }}
                                size={"small"}
                            >
                                {this.getInputArgItem(childInputArgParamsMap[keyStr],this.state.contentMsg?.dataConfig?.dataSetInputMap?.[keyStr]?.inputArg)}
                            </Form>
                        </div>
                    })
                }
            </div>
        </div>

    }

    //参数配置
    //采用新版本的form收集值，替换老版本的Layout组件配置 --2023.01.12
    getInputArgItem(eachStructure,valueObj){
        let scriptObj = eachStructure.inputArg;
        let dataType = eachStructure.dataType;

        if (!scriptObj || typeof scriptObj !== "object") {
            message.error("传入模板参数格式错误");
            return null;
        }

        return Object.keys(scriptObj).map((key)=>{
            let item = scriptObj[key];
            let valueObjItem = valueObj?.[key];

            let defaultValue = null;
            if(valueObjItem && valueObjItem.value && valueObjItem.value.hasOwnProperty("value")){
                //设置过，取存储中的值
                defaultValue = valueObjItem.value.value
            }else if(item.value){
                //取数据集自带的
                if(item.value.hasOwnProperty("value")){
                    //如果之前设置过，但是没有值，那就是故意不填写值，不能把默认值给他填上
                    defaultValue = item.value.value
                }else{
                    defaultValue = item.value.defaultValue
                }
            }

            //是否必填
            let ifRequired = dataType === 1;
            if(item.hasOwnProperty("required")){
                //以配置项为准
                ifRequired = item.required;
            }

            //项目迁移，特殊处理的参数暂不做成智能选择 --lxd
            // //处理特殊类型
            // if(/团队ID/i.test(item.name)){
            //     return <Item
            //         key={key}
            //         name={key}
            //         label={item.name}
            //         extra={item.descs}
            //         initialValue={defaultValue}
            //         rules={[{required: dataType === 1}]}//模块录制定义的必填，绑定通用数据非必填
            //     >
            //         <TeamIdSel/>
            //     </Item>
            // }
            // if(/岗位ID/i.test(item.name)){
            //     return <Item
            //         key={key}
            //         name={key}
            //         label={item.name}
            //         extra={item.descs}
            //         initialValue={defaultValue}
            //         rules={[{required: dataType === 1}]}
            //     >
            //         <PositionIdSel/>
            //     </Item>
            // }
            // if(/触发(器){0,}批次ID/i.test(item.name)){
            //     return <Item
            //         key={key}
            //         name={key}
            //         label={item.name}
            //         extra={item.descs}
            //         initialValue={defaultValue}
            //         rules={[{required: dataType === 1}]}
            //     >
            //         <TriggerBatchIdSel/>
            //     </Item>
            // }
            // if(/模块ID/i.test(item.name)){
            //     return <Item
            //         key={key}
            //         name={key}
            //         label={item.name}
            //         extra={item.descs}
            //         initialValue={defaultValue}
            //         rules={[{required: dataType === 1}]}
            //     >
            //         <ModuleIdSel/>
            //     </Item>
            // }
            // if(/模块版本ID/i.test(item.name)){
            //     return <Item
            //       key={key}
            //       name={key}
            //       label={item.name}
            //       extra={item.descs}
            //       initialValue={defaultValue}
            //       rules={[{required: dataType === 1}]}
            //     >
            //         <ModuleVersionIdSel/>
            //     </Item>
            // }

            let v;
            switch (item.type){
                case "By": v=<Item
                    key={key}
                    name={key}
                    label={item.name}
                    extra={item.descs}
                    initialValue={defaultValue}
                    rules={[{required: ifRequired}]}
                >
                    <Input.TextArea
                        allowClear={true}
                        autoSize={{ minRows: 1, maxRows: 6 }}
                        maxLength={10000}
                        placeholder={"请填写"}
                    />
                </Item>;break;
                case "Url":
                case "File":
                case "String": v=<Item
                    key={key}
                    name={key}
                    label={item.name}
                    extra={item.descs}
                    initialValue={defaultValue}
                    rules={[{required: ifRequired}]}
                >
                    <Input.TextArea
                        allowClear={true}
                        autoSize={{ minRows: 1, maxRows: 6 }}
                        maxLength={10000}
                        placeholder={"请填写"}
                    />
                </Item>;break;
                case "Number": v=<Item
                    key={key}
                    name={key}
                    label={item.name}
                    extra={item.descs}
                    initialValue={defaultValue}
                    rules={[{required: ifRequired}]}
                >
                    <InputNumber
                        style={{width: "100%"}}
                        min={-999999999}
                        max={999999999}
                        precision={2}
                        placeholder={"请填写"}
                    />
                </Item>;break;
                case "Boolean": v=<Item
                    key={key}
                    name={key}
                    label={item.name}
                    extra={item.descs}
                    initialValue={defaultValue}
                    valuePropName={"checked"}
                    rules={[{required: ifRequired}]}
                >
                    <Switch
                        checkedChildren={"真"}
                        unCheckedChildren={"假"}
                    />
                </Item>;break;
                case "Multiple":
                case "Select": v=<Item
                    key={key}
                    name={key}
                    label={item.name}
                    extra={item.descs}
                    initialValue={defaultValue}
                    rules={[{required: ifRequired}]}
                >
                    <Select
                        mode={item.type==="Multiple"?"multiple":"single"}
                        placeholder={"请选择"}
                    >
                        {item.list?.map((str)=>{
                            return <Select.Option
                                key={str}
                                value={str}
                            >
                                {str}
                            </Select.Option>
                        })}
                    </Select>
                </Item>;break;
                case "Array_String": v=<Item
                    key={key}
                    name={key}
                    label={item.name}
                    extra={item.descs}
                    initialValue={defaultValue}
                    rules={[{required: ifRequired}]}
                >
                    <Select
                        allowClear={true}
                        mode={"tags"}
                    >
                        {item.list?.map((str)=>{
                            return <Select.Option
                                key={str}
                                value={str}
                            >
                                {str}
                            </Select.Option>
                        })}
                    </Select>
                </Item>;break;
                case "Array_Number": v=<Item
                    key={key}
                    name={key}
                    label={item.name}
                    extra={item.descs}
                    initialValue={defaultValue}
                    // normalize={(value, prevValue, prevValues) => {
                    //     return value
                    // }}
                    rules={[
                        {
                            required: ifRequired,
                            type: "array",
                            defaultField: {
                                message: "必须填写数字",
                                transform: (v)=>{return Number(v)},
                                required: true,
                                type: "number"
                            }
                        },
                    ]}
                >
                    <Select
                        allowClear={true}
                        mode={"tags"}

                    >
                        {item.list?.map((str)=>{
                            return <Select.Option
                                key={str}
                                value={str}
                            >
                                {str}
                            </Select.Option>
                        })}
                    </Select>
                </Item>;break;
            }
            return v;
        })
    }

    //模块参数配置
    getInputArgConfig(scriptObj, valueObj) {
        if (!scriptObj || typeof scriptObj !== "object") {
            message.error("传入模板参数格式错误");
            return;
        }
        let parameterArr = [];

        Object.keys(scriptObj).forEach((key) => {

            let item = scriptObj[key];
            let defaultItem = valueObj && valueObj[key] && valueObj[key]["value"];

            let options = {
                ver: true,
                name: item["name"],
                extra: item["descs"],
                field: key,
                required: true,
            };

            let inputItem = null;
            switch (item.type) {
                case "By":
                    inputItem = {
                        ...options,
                        type: "TextArea",
                        reg: (v) => {
                            return !!v
                        },
                        verMessage: "请填写" + item["name"] + "，最多10000个字符",
                        option: {
                            maxLength: 10000,
                            autoSize: {
                                minRows: 1,
                                maxRows: 1,
                            },
                            placeholder: "请填写" + item["name"] + "，最多10000个字符",
                            defaultValue: (defaultItem && defaultItem.value) || (item.value && item.value.defaultValue) || undefined,
                        },
                    };
                    break;
                case "String":
                    inputItem = {
                        ...options,
                        type: "TextArea",
                        reg: /^[\S\s]{1,10000}$/,
                        verMessage: "请填写" + item["name"] + "，最多10000个字符",
                        option: {
                            maxLength: 10000,
                            autoSize: {
                                minRows: 1,
                                maxRows: 6,
                            },
                            placeholder: "请填写" + item["name"] + "，最多10000个字符",
                            defaultValue: (defaultItem && defaultItem.value) || (item.value && item.value.defaultValue) || undefined,
                        },
                    };
                    break;
                case "Number":
                    inputItem = {
                        ...options,
                        type: "InputNumber",
                        reg: /^[\d.-]{1,12}$/,
                        verMessage: "请填写" + item["name"] + "，最大9位数",
                        option: {
                            style: {
                                width: "100%",
                            },
                            placeholder: "请填写" + item["name"] + "，最大9位数",
                            precision: 2,
                            min: -999999999,
                            max: 999999999,
                            defaultValue: (defaultItem && typeof defaultItem.value === "number" && defaultItem.value) || (item.value && typeof item.value.defaultValue === "number" && item.value.defaultValue) || undefined,
                        },
                    };
                    break;
                case "Boolean":
                    inputItem = {
                        ...options,
                        type: "Radio",
                        reg: (t) => {
                            return !!t
                        },
                        verMessage: "请选择" + item["name"],
                        option: {
                            defaultValue: (defaultItem && typeof defaultItem.value === "boolean" && "" + defaultItem.value) || (item.value && typeof item.value.defaultValue === "boolean" && "" + item.value.defaultValue) || undefined,
                        },
                        data: [
                            {
                                title: "真",
                                value: "true",
                            },
                            {
                                title: "假",
                                value: "false",
                            },
                        ]
                    };
                    break;
                case "Array_String":
                    inputItem = {
                        ...options,
                        type: 'SelectAll',
                        selectType: 'value',
                        reg: (v) => {
                            return !!v
                        },
                        verMessage: "请填写",
                        option: {
                            allowClear: true,
                            mode: "tags",
                            onSearch: () => {
                            },
                            placeholder: "请填写",
                            defaultValue: (defaultItem && defaultItem.value && defaultItem.value.map((str) => {
                                return {label: str, title: str, key: str, value: str}
                            })) || (item.value.defaultValue && item.value.defaultValue.map((str) => {
                                return {label: str, title: str, key: str, value: str}
                            })) || undefined,
                        },
                    };
                    break;
                case "Array_Number":
                    inputItem = {
                        ...options,
                        type: 'SelectAll',
                        selectType: 'value',
                        reg: (vArr) => {
                            let ifOk = true;
                            if (vArr) {
                                vArr.forEach((item) => {
                                    let temp = typeof item === "object" ? (item.key || item.value) : item;
                                    if (temp === "0") {

                                    } else if (!Number.parseFloat(temp)) {
                                        ifOk = false;
                                    }
                                })
                            }
                            return ifOk
                        },
                        verMessage: "请填写数字内容",
                        option: {
                            allowClear: true,
                            mode: "tags",
                            onSearch: () => {
                            },
                            placeholder: "请填写",
                            defaultValue: (defaultItem && defaultItem.value && defaultItem.value.map((str) => {
                                return {label: str, title: str, key: str, value: str}
                            })) || (item.value.defaultValue && item.value.defaultValue.map((str) => {
                                return {label: str, title: str, key: str, value: str}
                            })) || undefined,
                        },
                    };
                    break;
                case "Select":
                    inputItem = {
                        ...options,
                        type: "Select",
                        selectType: "value",
                        reg: (v) => {
                            return !!v
                        },
                        verMessage: "请选择",
                        option: {
                            placeholder: "请选择",
                            defaultValue: (defaultItem && defaultItem.value && {
                                key: defaultItem.value,
                                value: defaultItem.value,
                            }) || (item.value.defaultValue && {
                                key: item.value.defaultValue,
                                value: item.value.defaultValue,
                            }) || undefined,
                        },
                        data: item.list && item.list.map((str) => {
                            return {
                                title: str,
                                value: str
                            }
                        })
                    };
                    break;
                default:
            }
            parameterArr.push(inputItem);
        });

        return {
            colCount: 1,
            formItemLayout: {
                labelCol: {span: 5},
                wrapperCol: {span: 16, offset: 0},
            },
            parameterArr: parameterArr,
        };
    }


    //将layout收集到的参数，赋值到原始的参数定义上
    getChangedValueByLayout(inputsOrigin, newParams) {
        let inputs = _.cloneDeep(inputsOrigin);
        Object.keys(newParams).forEach((field) => {
            if (inputs[field]) {
                if (!inputs[field].value) {
                    inputs[field].value = {
                        source: "work"
                    }
                }
                if (inputs[field].type === "Boolean") {
                    //布尔的要处理下
                    console.log("布尔的要处理下",newParams[field])
                    inputs[field].value.value = (newParams[field] === "true" || newParams[field] === true)
                } else if (inputs[field].type === "Array_String") {
                    //文本数组
                    inputs[field].value.value = newParams[field] ? newParams[field].map((item) => {
                        return typeof item === "object" ? (item.key || item.value) : item
                    }) : [];
                } else if (inputs[field].type === "Array_Number") {
                    //数字数组
                    let v = [];
                    if (newParams[field]) {
                        newParams[field].forEach((item) => {
                            let temp = typeof item === "object" ? (item.key || item.value) : item;
                            if (temp === "0") {
                                v.push(0);
                            } else if (Number.parseFloat(temp)) {
                                v.push(Number.parseFloat(temp));
                            }
                        })
                    }

                    inputs[field].value.value = v;
                } else {
                    inputs[field].value.value = newParams[field]
                }
            }
        })

        // console.log("收集inputs",inputs)
        return inputs;
    }

    getStylesCopyBtns(formName,getCurrentParams,setNewParams){
        return <div
            className={styles.copyItemsBtnBox}
        >
            <Space>
                <Tooltip
                    title={"收集配置"}
                >
                    <Button
                        icon={<FormatPainterOutlined />}
                        type={"primary"}
                        ghost={true}
                        size={"small"}
                        onClick={(e)=>{
                            LocalStorage.setWithoutId(ContentParamsSetSaveKey,{
                                formName: formName,
                                time: Date.now(),
                                styleObj: getCurrentParams()
                            })
                            message.success(`已收集到配置`);
                        }}
                    >

                    </Button>
                </Tooltip>
                <Tooltip
                    title={"粘贴配置"}
                >
                    <Button
                        icon={<BgColorsOutlined />}
                        type={"primary"}
                        ghost={true}
                        size={"small"}
                        onClick={()=>{
                            let obj = LocalStorage.getWithoutId(ContentParamsSetSaveKey);

                            if(!obj){
                                message.warning("缓存中无收集到的配置");
                                return;
                            }

                            if(obj.formName !== formName){
                                message.warning(`收集的配置 与 该栏目不匹配`);
                                return;
                            }


                            Modal.confirm({
                                title: "提示",
                                content: <div>
                                    <div>是否将参数配置替换成【收集】到的配置？</div>
                                    <div>收集时间：{TimeHelp.getYMDHMS(obj.time,true)}</div>
                                </div>,
                                onOk: ()=>{
                                    setNewParams?.(obj.styleObj)
                                }
                            })
                        }}
                    >

                    </Button>
                </Tooltip>
            </Space>
        </div>
    }
}