import React, {Component} from 'react';
import styles from "./Carousel.less";
import CommonViewDiv from "../../../CommonViewDiv";
import {message,Carousel} from "antd";

export default class CarouselBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listData: [],
      upDate: 0
    }
  }

  showValue(value){
    this.setState({
      listData: value
    },()=>{
      setTimeout(()=>{
        this.setState({
          upDate: this.state.upDate + 1
        })
      },200)
    })
  }

  render() {
    let contentMsg = this.props.contentMsg;
    let {contentParams} = contentMsg.dataConfig;

    if (!contentParams) {
      return null
    }

    let {listData} = this.state;

    if (!listData?.length) {
      return null
    }

    if (!Array.isArray(listData)) {
      return "传入的数据不是数组类型";
    }

    let carouselSet = contentParams.carouselSet;
    carouselSet.autoplaySpeed = carouselSet._autoplaySpeed * 1000;
    carouselSet.fade = carouselSet._effect === "fade";

    return <div
      className={styles.outerBox}
      ref={ref=>this.outerDom = ref}
    >
      {
        this.outerDom
          ?<Carousel
            {...carouselSet}
            dots={false}
          >
            {
              listData?.map((eachItem,index)=>{
                let url = eachItem[contentParams.urlField];
                let name = eachItem[contentParams.nameField];

                return <div
                  key={"c_" + index}
                >
                  <div
                    key={"ea_" +index}
                    className={styles.contentBox}
                    style={{
                      height: this.outerDom.clientHeight,
                      justifyContent: contentParams.flexSet?.justifyContent || "center",
                      gap: window.changeRemNum(contentParams.nameSet,"gapWithImg","gapUnit"),
                    }}
                  >
                    {
                      name && contentParams.nameSet?.position !== "insideBottom"
                        ?<div
                          className={styles.nameBox}
                          style={this.getNameStyleObj(contentParams)}
                        >
                          {name}
                        </div>
                        :null
                    }
                    <div
                      className={styles.imgBoxTwo}
                      style={{
                        order: 5,
                      }}
                    >
                      <div
                        className={styles.imgPos}
                      >
                        <img src={url}
                             crossOrigin={"anonymous"}
                             style={{
                               objectFit: contentParams.imgSet?.objectFit,
                               width: "100%",
                               height: "100%",
                               borderRadius: contentParams.borderSet?.radius + contentParams.borderSet?.radiusUnit,
                               border: `${window.changeRemNum(contentParams.borderSet,"width","widthUnit")} solid ${contentParams.borderSet?.color}`,
                             }}
                        />
                      </div>
                      {
                        name && contentParams.nameSet?.position === "insideBottom"
                          ?<div
                            className={styles.nameBox}
                            style={this.getNameStyleObj(contentParams)}
                          >
                            {name}
                          </div>
                          :null
                      }
                    </div>
                  </div>
                </div>
              })
            }
          </Carousel>
          :null
      }
    </div>
  }


  getNameStyleObj(contentParams){
    let base = CommonViewDiv.getTextStyleObj(contentParams.fontSet);

    if(contentParams.typesetting === "floatType"){
      base.width = window.changeRemNum(contentParams.floatSet,"width","widthUnit");
    }

    base.display = "block";

    if(contentParams.nameSet.position === "insideBottom"){
      //需要绝对定位
      base.position = "absolute";
      base.left = 0;
      base.right = 0;
      base.bottom = window.changeRemNum(contentParams.nameSet,"gapWithImg","gapUnit");
    }else{
      base.order = contentParams.nameSet?.position === "bottom"?10:0;
    }

    return base
  }
}