import { Form, Divider, Checkbox } from "antd";
import ColorPicker from "../../../formDiyItem/ColorPicker";

//与图表展示方式有关的配置
function getSetItems() {
    return <>
        <Divider orientation="left">
            展示方式配置
        </Divider>
        <Form.Item
            name={"typeShowSel"}
            label={"展示数据"}
            rules={[{ required: true }]}
        >
            <Checkbox.Group
                options={[
                    { label: "数字人工作时间占比", value: "digitalHumanWorkTimeRate" },
                ]}
            />
        </Form.Item>

        <Form.Item
            name={"typeWorkSelColor"}
            label={"工作中进度条色值"}
            rules={[{ required: true }]}
        >
            <ColorPicker />
        </Form.Item>

        <Form.Item
            name={"typeOnlineSelColor"}
            label={"在线进度条色值"}
            rules={[{ required: true }]}
        >
            <ColorPicker />
        </Form.Item>
    </>
}
//getSetItems配置内容的默认值
function getDefaultValues() {
    return {
        typeShowSel: ["digitalHumanWorkTimeRate"],
        typeWorkSelColor: "#FF7D00ff",
        typeOnlineSelColor: "#573fcdff"
    }
}

//可由外部传入的参数定义
function getInputArg() {
    //不需要额外的查询参数传入，全部组件内部处理，返回空对象
    return {}
}

export default {
    getSetItems,
    getDefaultValues,
    getInputArg
}