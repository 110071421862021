import React, { memo, useEffect, useState } from 'react'
import styles from './taskLogUnitDistribution.less'
import { Flex } from 'antd'
import { request } from '../../../../../../tool/http'
// import HumanLineChart from '@/pages/containers/dashboard/contentView/view/showEchart/humanLineChart/humanLineChart'


function TaskLogUnitDistribution(props: any) {
  console.log('每天的在线人数---展示', props)
  let showType = props.contentMsg?.dataConfig?.contentParams?.typeShowSel || [1]
  const [errorMsg, setErrorMsg] = useState('')
  const [data, setData] = useState<any>([])
  const [option, setOption] = useState<any>({})
  //请求接口
  const getBusinessDataQuery = async () => {
    try {
      let data = await request(`${props.contentMsg?.proConfig?.apiPrefix}/taskStatistics/getDistribution`, {
        data: {
          businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
          businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
          group: 'execUnitId'
        }
      })
      if (data.code === 200) {
        return data.data
      } else {
        setErrorMsg(data.message)
      }
    } catch (error: any) {
      setErrorMsg(error.toString())
    }
  }

  //请求业务数据
  const getBusinessData = async () => {
    let data = await getBusinessDataQuery()
    setData(data)
  }
  useEffect(() => {
    getBusinessData()
  }, [showType, props.dataUpdate])

  //获取图表数据
  const getOption = () => {
    //对应数据字段
    //对应数据字段
    let dataKey: any = {
      // running: '进行中',
      success: '成功',
      fail: '失败',
      noExec: '未执行',
      timeout: '超时',
      undistributed: '未分发'
    }
    let dataFilter = [] as any[]
    Object.keys(dataKey).forEach((key: any) => {
      dataFilter.push({
        _id: dataKey[key],
        count: data.map((item: any) => item[key] || 0)
      })
    })
    console.log('dataFilter叠加数据-----', dataFilter)
    let xAxis = [
      {
        data: data.map((item: any) => item.name),
        //设置角度
        axisLabel: {
          rotate: 30
        }
      }
    ]

    let series = dataFilter.map((item: any) => {
      return {
        name: item._id,
        type: 'bar',
        xAxisIndex: 0,
        stack: 'all',
        label: {
          show: true
        },
        data: item.count,
      }
    })
    let option = {
      xAxis,
      series
    }
    console.log('option', option)
    setOption(option)
  }

  useEffect(() => {
    console.log('任务统计的图表', data)
    if (data.length > 0) {
      getOption()
    }
  }, [data])

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      <div
        className={styles.chartBox}
      >
        {
          errorMsg
            ? <div className={styles.errorMsg}>
              {errorMsg}
            </div>
            : <Flex vertical style={{ width: '100%', height: '100%' }}>
              {/* <Flex gap={24} style={{ padding: 16 }}>
                <Flex align='center' gap={16} >
                  <div>单次任务</div>
                  <Statistic value={data?.[0]?.running + data?.[0]?.success + data?.[0]?.end + data?.[0]?.noStart} />
                </Flex>
                <Flex align='center' gap={16} >
                  <div>周期任务</div>
                  <Statistic value={data?.[1]?.running + data?.[1]?.end} />
                </Flex>
                <Flex justify='end' flex={1} className={" swiper-no-swiping"}>
                  <Select
                    style={{ width: "120px" }}
                    value={time}
                    onChange={(v) => {
                      setTime(v)
                    }}
                    options={[
                      { label: "今日", value: 1 },
                      { label: "30日", value: 30 },
                      { label: "15日", value: 15 },
                      { label: "7日", value: 7 },
                    ]}
                  >
                  </Select>
                </Flex>
              </Flex> */}

              {/* {showType.includes(1) && <HumanLineChart option={option}></HumanLineChart>} */}
            </Flex>
        }
      </div>
    </div>

  )
}
export default memo(TaskLogUnitDistribution)