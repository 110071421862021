import React, { useRef, useEffect, useState } from 'react'
import styles from './DigitalHumanSpaceAnaLine.less'
import { request } from '../../../../../../tool/http'

function DigitalHumanSpaceAnaLine(props) {
  //只有一个展示内容，不需要勾选
  // const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || ["space"])
  const chartRef = useRef(null)
  const chartInstance = useRef(null);
  const [errorMsg, setErrorMsg] = useState(null)

  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/spaceRatio`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],//公司id
      }
    }).then((res) => {
      if (res.code === 200) {
        showValue(res.data)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }

  useEffect(() => {
    getBusinessData()
  }, [props.dataUpdate])

  useEffect(() => {
    chartInstance.current?.resize()
  }, [props.styleUpdate])

  // 初始化图表
  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current,"dark",{
        locale: "ZH"
      });
    }
    return () => {
      // 清理图表实例
      chartInstance.current?.dispose();
    };
  }, []);

  //绘制数据
  const showValue=(data)=>{
    let option = {
      grid: {
        left: '10',
        right: '10',
        bottom: '10',
        top: '30',
        containLabel: true
      },
      backgroundColor: "transparent",
      color: [ '#9EB1FF', '#46FEA5', '#FFA057'],
      legend: {
        show: true,
      },
      tooltip: {
        show: true,
        trigger: "axis",
        // triggerOn: "mousemove",
      },
      xAxis: {
        show: true,
        type: 'category',
        axisLabel: {
          interval: 0,
          rotate: -45,
        },
      },
      yAxis: {
        show: true,
        type: "value",
        minInterval: 1,
      },
      dataset: {
        dimensions: ["空间","空闲","忙碌","缺勤"],
        source: []
      },
      series: [
        {
          type: 'line',
          name: "忙碌",
        },
        {
          type: 'line',
          name: "空闲",
        },
        {
          type: 'line',
          name: "缺勤",
        }
      ]
    }

    let source = [];
    if(data){
      data.forEach((item)=>{
        if(item.events?.length){
          let formatItem = {
            name: item.spaceName,
            busy: 0,
            leisure: 0,
            absence: 0,
          }
          item.events.forEach((eventItem)=>{
            if(eventItem.status === 1){
              formatItem.absence = eventItem.sum;
            }else if(eventItem.status === 2){
              formatItem.leisure = eventItem.sum;
            }else if(eventItem.status === 3){
              formatItem.busy = eventItem.sum;
            }
          })

          source.push([
            formatItem.name,
            formatItem.busy,
            formatItem.leisure,
            formatItem.absence,
          ])
        }
      })

      option.dataset.source = source
    }

    //渲染
    console.log("最终渲染option",option)
    chartInstance.current?.setOption(option)
  }

  return (
    <div className={styles.outer + " swiper-no-swiping"}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : null
      }
      <div
        ref={chartRef}
        className={styles.chartBox}
      >
      </div>
    </div>

  )
}
export default DigitalHumanSpaceAnaLine