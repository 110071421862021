import React, {useEffect, useState } from 'react'
import styles from './DigitalHumanAreaBarAna.less'
import { request } from '../../../../../../tool/http'
import {Flex} from "antd"

function DigitalHumanAreaBarAna(props) {
  //只有一个展示内容，不需要勾选
  // const [showType, setShowType] = useState(props.contentMsg?.dataConfig?.contentParams?.typeShowSel || ["gender"])
  const [errorMsg, setErrorMsg] = useState(null)
  const [genderData,setGenderData] = useState(null)

  //请求业务数据
  const getBusinessData = () => {
    setErrorMsg(null)
    request(`${props.contentMsg?.proConfig?.apiPrefix}/digitalHuman/genderRatio`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
      }
    }).then((res) => {
      if (res.code === 200) {
        let total = 0;
        let genderMsg = {
          maleNum: 0,
          malePercent: 0,
          femaleNum: 0,
          femalePercent: 0,
        }
        res.data.forEach((item)=>{
          if(item.gender === "Male"){
            total += item.sum;
            genderMsg.maleNum = item.sum;
          }
          if(item.gender === "Female"){
            total += item.sum;
            genderMsg.femaleNum = item.sum;
          }
        })

        if(total!==0){
          genderMsg.malePercent = Math.round(genderMsg.maleNum*100/total)
          genderMsg.femalePercent = Math.round(genderMsg.femaleNum*100/total)
        }

        setGenderData(genderMsg)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }

  useEffect(() => {
    //数据更新
    getBusinessData()
  }, [props.dataUpdate])

  useEffect(() => {
    //样式更新
  }, [props.styleUpdate])


  return (
    <div className={styles.outer}>
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : <div
            className={styles.chartBox}
          >
            {
              genderData
                ?<Flex gap={10} align={"center"} justify={"space-between"}>
                  <div>男</div>
                  <div className={styles.barBox}>
                    <div
                      className={styles.maleBar}
                      style={genderData.maleNum?{
                        flex: genderData.malePercent,
                      }:{
                        flex: genderData.malePercent,
                        backgroundColor: "transparent"
                      }}
                    >
                      {genderData.maleNum}，{genderData.malePercent}%
                    </div>
                    <div
                      className={styles.femaleBar}
                      style={genderData.femaleNum?{
                        flex: genderData.femalePercent,
                      }:{
                        flex: genderData.femalePercent,
                        backgroundColor: "transparent"
                      }}
                    >
                      {genderData.femaleNum}，{genderData.femalePercent}%
                    </div>
                  </div>
                  <div>女</div>
                </Flex>
                :null
            }
          </div>
      }
    </div>

  )
}
export default DigitalHumanAreaBarAna