import React, { useEffect, useRef, useState } from 'react';
import PieShow from "../../view/showEchart/pie/PieShow";
import { request } from '../../../../../../tool/http'
import styles from "./PutDatgaSourceCount.less";
import { Select } from "antd";

export default function PutDataSourceCount(props) {
  const chartRef = useRef(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [type, setType] = useState('input')
  // const [count,setCount] = useState(props.inputArgValues?.count)
  const [order,setOrder] = useState(()=>{
    return {
      "按条数": "sum",
      "按次数": "count",
    }[props.inputArgValues?.order] || "sum"
  })

  useEffect(() => {
    //后台、平台端、空间端，会传入不同的前缀
    console.log("请求前缀:", props.contentMsg?.proConfig?.apiPrefix)

    //告诉组件，展示在了什么位置
    //usage  展示在哪里(英文)   usageCode展示在哪里(数字)
    console.log("使用场景信息:", props.contentMsg?.proConfig?.usageParams)

    //空间、执行单元、数字人，会传入代表各自的id，字段都是businessID
    console.log("id信息:", props.contentMsg?.proConfig?.usageParams?.businessId)

    //定义可以由外部传入的参数结构(带值)
    console.log("外部参数:", props.inputArgValues)
  }, [])

  useEffect(() => {
    // setCount(props.inputArgValues?.count)
    setOrder(()=>{
      return {
        "按条数": "sum",
        "按次数": "count",
      }[props.inputArgValues?.order] || "sum"
    })
  }, [props.inputArgValues.count,props.inputArgValues?.order])

  useEffect(() => {
    getBusinessData()
  }, [order, type, props.contentMsg?.dataConfig?.contentParams?.type, props.dataUpdate])

  //请求业务数据
  const getBusinessData = () => {
    request(`${props.contentMsg?.proConfig?.apiPrefix}/execUnit/getIoDataSourceCount`, {
      data: {
        businessType: props.contentMsg?.proConfig?.usageParams?.usageCode,  //1.公司 2.空间 3.执行单元
        businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
        type: type, //从自定义配置里取参
        order: order,
      }
    }).then((res) => {
      console.error(res)
      if (res.code === 200) {

        console.log(chartRef.current)
        //转化数据成饼图需要的格式
        let needData = [];
        res.data?.forEach((eachItem) => {
          needData.push({
            label: {
              1: "API",
              2: "手动",
              3: "执行单元输入",
              4: "模块",
              5: "AI生成",
              6: "工作表",
              7: "触发器",
              8: "周期任务",
            }[eachItem.from],
            value: eachItem[order], //根据count的类型来取值
          })
        })

        chartRef.current?.showValue(needData)
      } else {
        setErrorMsg(res.message)
      }
    }).catch((err) => {
      setErrorMsg(err.toString())
    })
  }

  //饼图数据字段映射设置
  props.contentMsg.dataConfig.contentParams.dataSetConfig = {
    labelField: "label",
    valueField: "value",
  }

  return <div
    className={styles.outer + " swiper-no-swiping"}
  >
    <div className={styles.controlBar}>
      <Select
        style={{ width: "90px" }}
        value={type}
        onChange={(v) => {
          setType(v)
        }}
        options={[
          { label: "输入", value: "input" },
          { label: "输出", value: "output" },
        ]}
      />
      <Select
        style={{ width: "90px" }}
        value={order}
        onChange={(v) => {
          setOrder(v)
        }}
        options={[
          {label: "按条数",value:"sum"},
          {label: "按次数",value:"count"},
        ]}
      />
      {/* <InputNumber
        style={{width:"120px"}}
        size={"small"}
        addonBefore={"数量"}
        controls={false}
        min={0}
        max={999999999}
        precision={0}
        value={count}
        onClick={(e)=>{
          let inputDom = e.target;
          if(inputDom){
            inputDom.focus();
            let contentLen = inputDom.value.length;
            inputDom.setSelectionRange(contentLen, contentLen);
          }
        }}
        onDoubleClick={(e)=>{
          let inputDom = e.target;
          if(inputDom){
            inputDom.focus();
            let contentLen = inputDom.value.length;
            inputDom.setSelectionRange(0, contentLen);
          }
        }}
        onBlur={(e)=>{
          setCount(e.target.value)
        }}
        // onChange={(v)=>{
        //   setCount(v);
        // }}
      /> */}
    </div>
    <div
      className={styles.chartBox}
    >
      {
        errorMsg
          ? <div className={styles.errorMsg}>
            {errorMsg}
          </div>
          : <PieShow
            ref={chartRef}
            showType={props.showType}    //录制模式、展示模式
            styleUpdate={props.styleUpdate}
            contentMsg={props.contentMsg}
            broadcastData={props.broadcastData} //可以在内部发出广播
          />
      }
    </div>
  </div>
}