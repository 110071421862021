import React from "react";
import styles from "./ContentView.less";
import SVGForDivBorder from "./SVGForDivBorder";

export default class BackgroundBgDiv extends React.Component{
    constructor(props) {
        super(props);
    }

    render(){
        //contentBackgroundSet
        let bgStyles = this.props.backgroundSet;

        if(!bgStyles || bgStyles.show === false || bgStyles.use === false){
            return null
        }

        let stylesObj;

        switch (bgStyles.modeSet.mode) {
            //纯色
            case "color":
                stylesObj = {
                    zIndex: this.props.zIndex || -1,
                    opacity: bgStyles.opacity / 100,
                    filter: `brightness(${bgStyles.brightness / 100}) blur(${bgStyles.blur || 0}px)`,
                    backgroundColor: bgStyles.modeSet.color,
                    borderRadius: window.changeRemNum(bgStyles.radiusSet),
                }
                break;
            //边框
            case "border":
                stylesObj = {
                    zIndex: this.props.zIndex || -1,
                    opacity: bgStyles.opacity / 100,
                    filter: `brightness(${bgStyles.brightness / 100}) blur(${bgStyles.blur || 0}px)`,
                    border: `${bgStyles.modeSet?.borderBgSet?.width}px ${bgStyles.modeSet?.borderBgSet?.type} ${bgStyles.modeSet?.color}`,
                    borderRadius: window.changeRemNum(bgStyles.radiusSet),

                }
                break;
                //差异边框
            case "eachBorder":
                stylesObj = {
                    zIndex: this.props.zIndex || -1,
                    opacity: bgStyles.opacity / 100,
                    filter: `brightness(${bgStyles.brightness / 100}) blur(${bgStyles.blur || 0}px)`,
                    borderRadius: window.changeRemNum(bgStyles.radiusSet),
                    borderTop: `${bgStyles.modeSet?.borderTop?.borderBgSet?.width}px ${bgStyles.modeSet?.borderTop?.borderBgSet?.type} ${bgStyles.modeSet?.borderTop?.color}`,
                    borderBottom: `${bgStyles.modeSet?.borderBottom?.borderBgSet?.width}px ${bgStyles.modeSet?.borderBottom?.borderBgSet?.type} ${bgStyles.modeSet?.borderBottom?.color}`,
                    borderLeft: `${bgStyles.modeSet?.borderLeft?.borderBgSet?.width}px ${bgStyles.modeSet?.borderLeft?.borderBgSet?.type} ${bgStyles.modeSet?.borderLeft?.color}`,
                    borderRight: `${bgStyles.modeSet?.borderRight?.borderBgSet?.width}px ${bgStyles.modeSet?.borderRight?.borderBgSet?.type} ${bgStyles.modeSet?.borderRight?.color}`,
                }
                break;
            //图片  injectImage类型，图片地址依赖注入
            case "injectImage":
            case "image":
                stylesObj = {
                    zIndex: this.props.zIndex || -1,
                    opacity: bgStyles.opacity / 100,
                    filter: `brightness(${bgStyles.brightness / 100}) blur(${bgStyles.blur || 0}px)`,
                    backgroundImage: bgStyles.modeSet.mode === "image"
                        ?`url(${bgStyles.modeSet.upload_imageUrl})`
                        :`url(${this.props.injectImgUrl})`
                    ,
                    backgroundSize: bgStyles.modeSet.backgroundImgSize === "diy"
                        ?`${bgStyles.modeSet.backgroundImgSizeDiy.width}${bgStyles.modeSet.backgroundImgSizeDiy.widthUnit} ${bgStyles.modeSet.backgroundImgSizeDiy.height}${bgStyles.modeSet.backgroundImgSizeDiy.heightUnit}`
                        :bgStyles.modeSet.backgroundImgSize,
                    backgroundPosition:`${bgStyles.modeSet.bgImgPositionSet?.left}${bgStyles.modeSet.bgImgPositionSet?.leftUnit} ${bgStyles.modeSet.bgImgPositionSet?.top}${bgStyles.modeSet.bgImgPositionSet?.topUnit}`,
                    backgroundRepeat: bgStyles.modeSet.backgroundImgRepeat || "no-repeat",
                    borderRadius: window.changeRemNum(bgStyles.radiusSet),
                }
                break;
            case "video":
                stylesObj = {
                    zIndex: this.props.zIndex || -1,
                    opacity: bgStyles.opacity / 100,
                    filter: `brightness(${bgStyles.brightness / 100}) blur(${bgStyles.blur || 0}px)`,
                    borderRadius: window.changeRemNum(bgStyles.radiusSet),
                }
                break;
            case "svgBorder":
                stylesObj = {
                    zIndex: this.props.zIndex || -1,
                    color: bgStyles.modeSet.color,
                    opacity: bgStyles.opacity / 100,
                    filter: `brightness(${bgStyles.brightness / 100}) blur(${bgStyles.blur || 0}px)`,
                    borderRadius: window.changeRemNum(bgStyles.radiusSet),
                }
        }

        if(bgStyles.modeSet.mode !== "color" && bgStyles.baseLaySet){
            //设置基底颜色
            stylesObj.backgroundColor = bgStyles.baseLaySet.color + window.getOpacityStr(bgStyles.baseLaySet.opacity)
        }

        let bgDiv;
        switch (bgStyles.modeSet.mode) {
            //纯色
            case "color":
            case "border":
            case "eachBorder":
            case "injectImage":
            case "image":
                bgDiv = <div
                    className={styles.contentBgBox}
                    style={stylesObj}
                >
                </div>
                break;
            case "video":
                bgDiv = <div
                    className={styles.contentBgBox}
                    style={stylesObj}
                >
                    <video
                        crossOrigin={"anonymous"}
                        src={bgStyles.modeSet.upload_videoUrl}
                        autoPlay={true}
                        muted={true}
                        loop={true}
                        className={styles.videoBgStyle}
                        style={{
                            objectFit: `${bgStyles.modeSet.bgVdoPositionSet.objectFit}`,
                            transformOrigin: `${bgStyles.modeSet.bgVdoPositionSet.scaleOption}`,
                            transform: `scale(${bgStyles.modeSet.bgVdoPositionSet.scale}) translate(${bgStyles.modeSet.bgVdoPositionSet.absoluteSet.translateX}${bgStyles.modeSet.bgVdoPositionSet.absoluteSet.translateXUnit}, ${bgStyles.modeSet.bgVdoPositionSet.absoluteSet.translateY}${bgStyles.modeSet.bgVdoPositionSet.absoluteSet.translateYUnit})`,
                        }}
                    ></video>
                </div>
                break;
            case "svgBorder":
                bgDiv = <div
                    className={styles.contentBgBox}
                    style={stylesObj}
                >
                    {SVGForDivBorder.getSVGBorder(bgStyles.modeSet?.borderSVGType)}
                </div>
                break;
            default: bgDiv = <div></div>
        }

        return bgDiv;
    }
}