import React, { useEffect, useRef, useState } from 'react';
// import { request, useRequest } from "umi";
import styles from "./DigitalHumanDataSourcePie.less";
import EChartsReact from 'echarts-for-react'
import * as echarts from 'echarts'

const DefaultFromArray = {
  'offline': '离线',
  'idle': '空闲',
  'work': '忙碌',
  'shutDown': '停工',
}

const DigitalHumanDataSourcePie: React.FC<any> = (props) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const [errorMsg, setErrorMsg] = useState(null)
  const [dataSource, setDataSource] = useState<Array<{ name: string, value: number }>>([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (chartRef.current) {
      chartInstance.current = echarts.init(chartRef.current, "dark", {
        locale: "ZH"
      });
    }
    return () => {
      chartInstance.current?.dispose();
    };
  }, []);

  useEffect(() => {
    chartInstance.current?.resize()
  }, [props.styleUpdate])

  useEffect(()=> {
    if (dataSource) {
      chartInstance.current?.setOption(getOption())
    }
  }, [dataSource])

  // useRequest(() => request(`${props.contentMsg?.proConfig?.apiPrefix}digitalHuman/statusRatio`, {
  //   data: {
  //     businessId: [props.contentMsg?.proConfig?.usageParams?.businessId],
  //     businessType: props.contentMsg?.proConfig?.usageParams?.usageCode
  //   }
  // }), {
  //   formatResult(res) {
  //     return res
  //   },
  //   refreshDeps: [props.dataUpdate],
  //   onSuccess: (res) => {
  //     if (res.code === 200) {
  //       setTotal(Object.values(res?.data).reduce((a, b) => a + b, 0))
  //       //转化数据成需要的格式
  //       setDataSource(Object.keys(res?.data)?.map(key => ({
  //         name: DefaultFromArray[key],
  //         value: res?.data[key]
  //       })) || [])
  //     } else {
  //       setErrorMsg(res.message)
  //     }
  //   },
  //   onError(err: any) {
  //     setErrorMsg(err.toString())
  //   }
  // })

  const getOption = (): echarts.EChartsOption => {
    return {
      backgroundColor: 'transparent',
      grid: {
        left: 'center',
        right: 'center',
        top: '16px',
        bottom: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
      },
      title: {
        text: `数字人总量\n${total}`, // 显示总量
        left: 'center',
        top: 'center',
        textStyle: {
          fontSize: '1.125rem',
          color: '#fff',
          fontWeight: 'bold',
          lineHeight: 40, // 处理换行
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 18,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          label: {
            show: true, // 显示标签
            formatter: '{b} {c}', // 标签格式，显示名称和数量
            color: '#fff', // 标签文字颜色
          },
          data: dataSource
        }
      ]
    };
  };

  return <div className={styles.outer + " swiper-no-swiping"}>
    {
      errorMsg
        ? <div className={styles.errorMsg}>
          {errorMsg}
        </div>
        : <div
          ref={chartRef}
          className={styles.chartBox}
        >
        </div>
    }
  </div>
}


export default DigitalHumanDataSourcePie