import React from "react";
import ReactDOM from 'react-dom';
import AMapLoader from '@amap/amap-jsapi-loader';
import { Button, Modal, Tag } from 'antd';
import { Component } from 'react';
import styles from './AreaMapShow.less';
import config from "../../../../../../config/config";

export default class AreaMapShow extends Component {
  constructor() {
    super();
    this.state = {
      mapStyleLoaded: false,
      error: null,
      legendData: []
    };
    this.mapId = window.getUID();
    this.map = null;
    this.AMap = null;
    this.firstRenderData = null;
    this.markerGroup = null;

    this.dotColorList = ["#71FFA0","#01E4F7","#01E4F7","#FFEC6B","#FF8838","#F94545"];
    this.colorGroupList = [
      {
        border: ["#79FFAE","#4AFF5E"],
        background: ["#0DA967B2","#0DA967B2"],
        line: ["#52FF6B00","#52FF6BFF"],
        circle: "#71FFA0FF",
        shadowCode: `inset 0px 1px 9px 0px #3FFFAB, inset 0px 1px 4px 0px #3FFFAB`,
      },
      {
        border: ["#02FAFCFF","#01F4F8FF"],
        background: ["#0E7FA2FF","#1D92CDE9"],
        line: ["#02FAFC00","#02FAFCFF"],
        circle: "#01E4F7FF",
        shadowCode: `inset 0px 1px 9px 0px #59FFFF, inset 0px 1px 4px 0px #3FE1FF, inset 0px 1px 8px 0px rgba(0,255,240,0.5)`,
      },
      {
        border: ["#2798FFFF","#2798FFFF"],
        background: ["#2798FFFF","#1272CAE9"],
        line: ["#2798FF00","#2798FFFF"],
        circle: "#2798FFFF",
        shadowCode: `inset 0px 1px 9px 0px #2798FF, inset 0px 1px 4px 0px #2798FF, inset 0px 1px 8px 0px rgba(39,152,255,0.702)`,
      },
      {
        border: ["#FFFD70FF","#F8F801FF"],
        background: ["#AC8518B2","#AC8518B2"],
        line: ["#FCCB0200","#FCE602FF"],
        circle: "#FFEC6BFF",
        shadowCode: `inset 0px 1px 9px 0px #FFED59, inset 0px 1px 4px 0px #FAFF3F`,
      },
      {
        border: ["#FF973FFF","#FF973FFF"],
        background: ["#9B3311B2","#9B3311B2"],
        line: ["#FF460800","#FF8D50FF"],
        circle: "#FF8838FF",
        shadowCode: `inset 0px 1px 8px 1px #FF8330`,
      },
      {
        border: ["#F94545FF","#F94545FF"],
        background: ["#7E0A0AB2","#7E0A0AB2"],
        line: ["#F9454500","#F94545FF"],
        circle: "#F94545FF",
        shadowCode: `inset 0px 1px 9px 0px #F94545, inset 0px 1px 4px 0px #F94545`,
      }
    ]
  }

  componentDidMount() {
    AMapLoader.load({
      key: config.ampKey, //需要设置您申请的key
      version: '2.0',
    })
      .then((AMap) => {
        let defaultLayer = new AMap.createDefaultLayer();
        let disCountry = new AMap.DistrictLayer.Country({
          zIndex: 10,
          SOC: 'CHN',
          depth: 1,
          styles: {
            'nation-stroke': '#cd9a5b',
            'coastline-stroke': 'rgba(0,0,0,0)',
            'province-stroke': 'rgba(0,0,0,0)',
            'city-stroke': 'rgba(0,0,0,0)',
            fill: 'rgba(0,0,0,0.2)',
            'stroke-width': '3',
          },
        });
        this.markerGroup = new AMap.OverlayGroup();

        this.map = new AMap.Map(this.mapId, {
          viewMode: '2D',
          zoom: 2,
          zooms: [1, 20],
          // center: [105.602725, 37.076636],
          mapStyle: 'amap://styles/grey',
          showLabel: true,
          // labelRejectMask: true,
          layers: [disCountry, defaultLayer],
        });


        if (this.firstRenderData) {
          this.renderDataToMap(this.firstRenderData);
        }

        this.markerGroup.setMap(this.map);

        this.removeAmapLogo();

        this.setState({
          mapStyleLoaded: true,
        });

      })
      .catch((e) => {
        console.log(e);
      });
  }

  removeAmapLogo(){
    this.autTIme = setInterval(() => {
      let list = document.getElementsByClassName("amap-logo");
      if (list) {
        for (let d of list) {
          d.style.opacity = 0
        }
      }
      let list2 = document.getElementsByClassName("amap-copyright");
      if (list2) {
        for (let d of list2) {
          d.style.opacity = 0
        }
      }
    }, 0)
  }

  renderDataToMap(renderData) {
    if (!this.map) {
      this.firstRenderData = renderData;
      return;
    } else {
      this.firstRenderData = null;
      let { areaData, zoom ,status} = renderData;

      //先清空
      this.markerGroup.clearOverlays();
      if (areaData && areaData.length) {
        this.setState({
          error: null,
        });

        //计算所有数据的最大最小
        let maxNum = Number.MIN_VALUE;
        let minNum = Number.MAX_VALUE;
        areaData.forEach((eachData)=>{
          eachData.total = 0;
          eachData.statusMap = {
            online: 0,
            offline: 0,
          };
          eachData.event?.forEach((eachStatusData)=>{
            eachData.total += eachStatusData.sum;

            if(eachStatusData.status === 1){
              eachData.statusMap["online"] = eachStatusData.sum;
            }else if(eachStatusData.status === 2){
              eachData.statusMap["offline"] = eachStatusData.sum;
            }
          })
          maxNum = Math.max(maxNum,eachData.total)
          minNum = Math.min(minNum,eachData.total)
        })

        //得出范围
        let range = maxNum - minNum;

        this.setLegendData(maxNum,minNum)

        if (this.markerGroup) {
          areaData.sort((a,b)=>{
            return a.total - b.total
          }).forEach((item, index) => {
            if(item.geo?.coordinates && item.geo?.type === "Point"){

              let colorGroup = this.getColorGroup(range,minNum,item.total)

              let el = document.createElement('div');

              let content = <div
                className={styles.markerBox}
              >
                <div
                  className={styles.markerNameBox}
                  style={{
                    background: `linear-gradient(180deg, ${colorGroup.background[0]} 0%, ${colorGroup.background[1]} 100%)`,
                    boxShadow: colorGroup.shadowCode,
                    borderImage: `linear-gradient(270deg, ${colorGroup.border[0]}, ${colorGroup.border[1]}) 1 1`
                  }}
                >
                  {item._id}：{item.total}
                  <div className={styles.markerNum}>
                    {
                      status?.includes(1)?<div>在线：{item.statusMap.online}</div>:null
                    }
                    {
                      status?.includes(2)?<div>离线：{item.statusMap.offline}</div>:null
                    }
                  </div>
                </div>
                <div
                  className={styles.markerLine}
                  style={{
                    background: `linear-gradient(360deg, ${colorGroup.line[0]} 0%, ${colorGroup.line[1]} 100%)`
                  }}
                ></div>
                <div className={styles.pointBox}>
                  <div className={styles.center}
                       style={{
                         backgroundColor: colorGroup.circle
                       }}
                  ></div>
                  <div className={styles.firstRoll}
                       style={{
                         borderColor: colorGroup.circle
                       }}
                  ></div>
                  <div className={styles.secondRoll}
                       style={{
                         borderColor: colorGroup.circle
                       }}
                  ></div>
                </div>
              </div>;

              let root = ReactDOM.createRoot(el)
              root.render(content);

              let marker = new AMap.Marker({
                position: item.geo?.coordinates,
                content: el, // 这里可以是一个 DOM 元素或者 HTML 字符串
                map: this.map,
                anchor: 'bottom-center' // 设置锚点为底部中心
              });
              this.markerGroup.addOverlay(marker);
            }
          });
        }
      } else {
        // 无数据情况下
        this.setState({
          error: null,
        });
        this.map.setZoomAndCenter(1, [90.25, 39.28]);
      }
      this.setState({
        mapStyleLoaded: true,
      });
    }
  }

  //匹配颜色
  getColorGroup(range,min,current){
    if(range === 0){
      //所有数值都相同，返回最高亮颜色
      return this.colorGroupList[this.colorGroupList.length - 1]
    }
    let index = Math.floor((current-min)/(range + 1) * (this.colorGroupList.length))

    return this.colorGroupList[index]
  }

  //设置颜色梯度
  setLegendData(max,min){
    // console.log(max)
    // console.log(min)
    let list = [];
    let range = max - min;
    if(range === 0){
      list.push({
        title: `1-${max}`,
        color: this.colorGroupList[this.colorGroupList.length - 1].circle
      })
    }else{
      let len = this.colorGroupList.length;
      let step = Math.floor(range/len) || 1;

      for(let i=0;i<len;i++){
        let num = i + 1;
        if(i===0){
          list.push({
            title: `1-${min + step * num}`,
            color: this.colorGroupList[i].circle
          })
        }else if(i<len-1){
          list.push({
            title: `${min + step * (num-1) +1}-${min + step * (num)}`,
            color: this.colorGroupList[i].circle
          })
        }else{
          list.push({
            title: `${min + step * (num-1) +1}+`,
            color: this.colorGroupList[i].circle
          })
        }
      }
    }

    console.log("设置梯度",list)

    this.setState({
      legendData: list
    })
  }

  render() {
    // 1.创建地图容器
    let { mapStyleLoaded,legendData } = this.state;
    return (
      <div className={styles.container} style={this.props.style || {}}>
        {/* loading */}
        <div
          className={
            mapStyleLoaded ? styles.mayLoadLayerHide : styles.mayLoadLayer
          }
        >
          <div className={styles['loadingio-spinner-double-ring-v0383conw4g']}>
            <div className={styles['ldio-10d8267j8wu']}>
              <div></div>
              <div></div>
              <div>
                <div></div>
              </div>
              <div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.legendBox}>
          {
            legendData?.map((legendItem,index)=>{
              return <div key={"k_"+index} className={styles.legendItem}>
                <div className={styles.colorTitle}>{legendItem.title}</div>
                <div className={styles.colorItem}
                     style={{background: `${legendItem.color}`}}
                ></div>
              </div>
            })
          }
        </div>
        <div className={styles.deviceContainer}>
          <div id={this.mapId} className={styles.mapShowBox}></div>
        </div>
        {this.state.error ? (
          <div className={styles.error}>{this.state.error}</div>
        ) : null}
      </div>
    );
  }
}
